import { Component, OnInit, Inject } from '@angular/core';
import { DevService } from '../../../DataService/Dev.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-DevSetting_Edit',
  templateUrl: './DevSetting_Edit.component.html',
  providers: [DevService, MyCommon,Permissions]
})
export class DevSetting_EditComponent implements OnInit {


  public ViewPermissions:boolean=false;
  MACID: any;
  DevName:any="";
  Ctrl_Port:any;

  constructor(
    @Inject(DevService) private DevData: DevService,
		@Inject(Permissions) private PermissionsData: Permissions,
		@Inject(MyCommon) private MyCommon: MyCommon,
		private activatedRoute: ActivatedRoute,
		private router: Router
  ) { }

  ngOnInit() {
    //判斷頁面權限
		let sbody = {
			UrlList:"DevSettingEdit",
			Key:localStorage.getItem("Token")
		};

		var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
		stralert.subscribe(
			(obj) => {
			this.MyCommon.CheckToken(obj);
			for (let Site of obj) {
				if(Site.path=="DevSettingEdit")
					this.ViewPermissions=true;
			}
			try{
				this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
			}catch(err){}
			},
			err => {  },
			() => { }
		);

		this.SetData();
  }

  SetData() {
		this.activatedRoute.params.subscribe((params: Params) => {
			this.MACID = params['id'];
		});
		var StrWhere = " where DEV.MACID=" + this.MACID+' and DEV.DataType=0';

		let sbody = {
			TopN: " ",
			StrWhere: StrWhere,
			Sort: " ",
			Key:localStorage.getItem("Token")
		};
		this.DevData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				this.MyCommon.CheckToken(obj);
				this.DevName = obj[0].Dev_Name;
				this.Ctrl_Port = obj[0].Ctrl_Port;
			},
			err => { console.log(err); }
		);
	}

  clicked() {
		if (confirm("確定要修改嗎?")) {
					this.Update();
		}
	}

	Update() {

		let sbody = {
			"MACID": this.MACID,
			"DevName": this.DevName,
			"Ctrl_Port": this.Ctrl_Port,
			Key:localStorage.getItem("Token")
		};
		this.DevData.UpdateData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				this.MyCommon.CheckToken(obj);
				alert('修改成功');
				this.router.navigate(['/pages/DevSetting']);
			},
			err => { alert('修改失敗'); }
		);
	}

	back() {
		this.router.navigate(['/pages/DevSetting']);
	}


}
