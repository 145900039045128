import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class SystemService {

    constructor( @Inject(HttpClient)
    private http: HttpClient,
        public MyCommon: MyCommon) { }

    GetData(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetSettingAPISPUrl('GetSYSInfo');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetNewID() {
        var url = this.MyCommon.GetAPISPUrl('SystemSettingNewID');
        return this.http.get(url)
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    InsertData(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('SystemSettingData');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateData(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('SystemSettingData');
        return this.http.put(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    DeleteData(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('SystemSettingDelete');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetBackup(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('GetBackup');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    Restore(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('Restore');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    RestoreDelete(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('Restore_Delete');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetSystemSetting(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('SystemSetting');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateSystemSetting(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('SystemSetting');
        return this.http.put(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    CheckSchemaData(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('CheckSchemaData');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    SchemaRestore(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('SchemaRestore');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    SchemaRestoreDelete(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('SchemaRestore_Delete');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    ClearTable(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('ClearTable');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateOther(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('UpdateOther');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    ReadConfig(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('ReadConfig');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    WriteConfig(sbody: any, headers: any = "") {
        var url = this.MyCommon.GetAPISPUrl('WriteConfig');
        return this.http.post(url, sbody, { headers: headers })
            .map(res=>JSON.parse(JSON.stringify(res)));
    }

}