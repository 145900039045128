import { Component, OnInit, Inject } from '@angular/core';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import 'hammerjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';
import { ZigbeeDeviceService } from '../../../DataService/ZigbeeDevice.service';

@Component({
  selector: 'app-ZigbeeDevice',
  templateUrl: './ZigbeeDevice.component.html',
  styleUrls: ['./ZigbeeDevice.component.scss'],
	providers: [ZigbeeDeviceService, MyCommon,Permissions]
})
export class ZigbeeDeviceComponent implements OnInit {
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  /*private pageSize: number = 10;
  private skip: number = 0;*/

  constructor(
  @Inject(ZigbeeDeviceService) private ZigbeeData: ZigbeeDeviceService,
  @Inject(Permissions) private PermissionsData: Permissions,
  @Inject(MyCommon) private MyCommon: MyCommon,
  private activatedRoute: ActivatedRoute,
  private router: Router) { }

  public ZBList_Source=[];
  public ZBData_Source=[];
  public BasicData=[];
  public DevData=[];
  public SensorData=[];
  public SetItem=[];
  public Set_DevIndex:string="";
  public Set_SnrData=[];


  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  ngOnInit() {
    /*   //判斷頁面權限
       let sbody = {
        UrlList:"ZigbeeSetting",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
            if(Site.path=="ZigbeeSetting")
              this.ViewPermissions=true;
          } 
        
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );*/


    this.SetZBData(true);
  }

  SetZBData(load){
    try{
      let sbody = {
        Key:localStorage.getItem("Token")
      };
      
      this.ZigbeeData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          //this.MyCommon.CheckToken(obj);
          try {
            let data=[];
            try{
              if(obj[0]=="["){
                data=JSON.parse(obj);
              }else{
                data=obj;
              }
            }catch(err){
    
            }
            this.ZBData_Source=data;
            this.BasicData=this.ZBData_Source[0];
            this.DevData=JSON.parse(this.BasicData["DevList"]);
            this.SensorData=JSON.parse(this.BasicData["SensorList"]);
            //編序號
            if(this.DevData !=null){
              this.ZBList_Source=this.DevData;
              for(let i in this.DevData){
                this.DevData[i]["D_ID"]=parseInt(i)+1;
                if(String(i)=="0"){
                  var DevIndex=this.DevData[i]["DevIndex"];
                  this.Set_DevIndex=DevIndex;
                  this.SetItem=this.DevData[i];
                  this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex;});
                  this.gridView = {
                    data: orderBy(this.Set_SnrData, this.sort),
                    total: this.Set_SnrData.length
                  };
                }
              }
            }else{
              this.ZBData_Source=[];
              this.BasicData=[];
            }
          } catch (error) {
            this.ZBData_Source=[];
            this.BasicData=[];
          }
          
        },
        err => { console.log(err); }
      );
    }catch(err){}
  }

  onInsert(){
    this.onAddDev();
    this.UpdateData();
  }
  onCopy(){
    this.onCopyDev();
    this.UpdateData();
  }

  onUdpate(){
    this.UpdateData();
  }

  onDelete(){
    if (confirm("確定要刪除嗎?")) {
      this.onDeleteDev();
      this.UpdateData();
    }
  }

  //新增設備
  onAddDev(){
    //取最大值
    var TempDevData = this.DevData.sort(function(a, b){ return  b.DevIndex-a.DevIndex});
    var MaxDevIndex=1;
    if(TempDevData.length>0){
      MaxDevIndex=parseInt(TempDevData[0]["DevIndex"])+1;

    }
    this.DevData.push({"DevIndex":String(MaxDevIndex),"DevID":"-1","DevName":"設備"+String(MaxDevIndex),"DataPacket":"0","SnrCount":"0","D_ID":-1});
    //重設流水號
    for(let i in this.DevData.sort(function(a, b){ return  a.DevIndex-b.DevIndex})){
      this.DevData[i]["D_ID"]=parseInt(i)+1;
      //設定新資料
      if(i==String(this.DevData.length-1)){
        var DevIndex=this.DevData[i]["DevIndex"];
        this.Set_DevIndex=DevIndex;
        this.SetItem=this.DevData[i];
        this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex;});
        this.gridView = {
          data: orderBy(this.Set_SnrData, this.sort),
          total: this.Set_SnrData.length
        };
      }
    }
  }
   //複製設備
   onCopyDev(){
     //設備取最大值
    var TempDevData = this.DevData.sort(function(a, b){ return  b.DevIndex-a.DevIndex});
    var MaxDevIndex=1;
    if(TempDevData.length>0){
      MaxDevIndex=parseInt(TempDevData[0]["DevIndex"])+1;
    }
    //感測點取最大值
    var TempDevSensorData = this.SensorData.sort(function(a, b){ return  b.SnrIndex-a.SnrIndex});
    var MaxSnrIndex=1;
    if(TempDevSensorData.length>0){
      MaxSnrIndex=parseInt(TempDevSensorData[0]["SnrIndex"])+1;
    }
    //取複製前編號
    var AfterDevIndex=this.SetItem["DevIndex"];
    //取得複製值
    var Data=this.SetItem;
    this.DevData.push({"DevIndex":String(MaxDevIndex),"DevID":Data["DevID"],"DevName":Data["DevName"]
    ,"DataPacket":Data["DataPacket"],"SnrCount":Data["SnrCount"],"D_ID":-1});
    //Sensor資料
     
    var SnrData=this.SensorData.filter(function (d) {return d.DevIndex == AfterDevIndex}).sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
    for(let i in SnrData){
      this.SensorData.push({"SnrIndex":String(MaxSnrIndex+Number(i)),"DevIndex":String(MaxDevIndex)
      ,"ReadLocation":SnrData[i]["ReadLocation"],"SnrName":SnrData[i]["SnrName"]
      ,"Coefficient":SnrData[i]["Coefficient"],"Unit":SnrData[i]["Unit"]
      ,"Code":SnrData[i]["Code"]});
    }
    //重設流水號
    for(let i in this.DevData.sort(function(a, b){ return  a.DevIndex-b.DevIndex})){
      this.DevData[i]["D_ID"]=parseInt(i)+1;
      //設定新資料
      if(i==String(this.DevData.length-1)){
        var DevIndex=this.DevData[i]["DevIndex"];
        this.Set_DevIndex=DevIndex;
        this.SetItem=this.DevData[i];
        this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex;});
        this.gridView = {
          data: orderBy(this.Set_SnrData, this.sort),
          total: this.Set_SnrData.length
        };
      }
    }

  }
   //刪除設備
   onDeleteDev(){
    var AfterDevIndex=this.SetItem["DevIndex"];
    //清除資料
    this.DevData=this.DevData.filter(function (d) {return d.DevIndex != AfterDevIndex}).sort(function(a, b){ return  a.DevIndex-b.DevIndex});
    this.SensorData=this.SensorData.filter(function (d) {return d.DevIndex != AfterDevIndex}).sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
    this.ZBList_Source=this.DevData;
     //重設流水號
     for(let i in this.DevData.sort(function(a, b){ return  a.DevIndex-b.DevIndex})){
      this.DevData[i]["D_ID"]=parseInt(i)+1;
      //設定新資料
      if(i==String(this.DevData.length-1)){
        var DevIndex=this.DevData[i]["DevIndex"];
        this.Set_DevIndex=DevIndex;
        this.SetItem=this.DevData[i];
        this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex;});
        this.gridView = {
          data: orderBy(this.Set_SnrData, this.sort),
          total: this.Set_SnrData.length
        };
      }
    }
   }

  //新增感測點
  onAddSensor(){
    //取最大值
    var TempDevSensorData = this.SensorData.sort(function(a, b){ return  b.SnrIndex-a.SnrIndex});
    var MaxSnrIndex=1;
    if(TempDevSensorData.length>0){
      MaxSnrIndex=parseInt(TempDevSensorData[0]["SnrIndex"])+1;

    }
    var DevIndex=this.SetItem["DevIndex"];
    this.SensorData.push({"SnrIndex":String(MaxSnrIndex),"DevIndex":DevIndex,"ReadLocation":"-","SnrName":"感測名稱"+String(MaxSnrIndex)
    ,"Coefficient":"0","Unit":"-","Code":"0"});
    //重Bind Sensor資料
    this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex}).sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
    this.gridView = {
      data: orderBy(this.Set_SnrData, this.sort),
      total: this.Set_SnrData.length
    };
    this.SetItem["SnrCount"]=this.Set_SnrData.length;
  }
  //刪除感測點
  onDelSensor(dataItem){
    
    var TempDevSensorData=this.SensorData.filter(function (d) {return d.SnrIndex != dataItem.SnrIndex;});
    //回寫原本資料
    TempDevSensorData = TempDevSensorData.sort(function(a, b){ return Number(a.SnrIndex) - Number(b.SnrIndex)});
    this.SensorData=TempDevSensorData;
    //重Bind Sensor資料
    var DevIndex=this.SetItem["DevIndex"];
    this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex}).sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
    this.gridView = {
      data: orderBy(this.Set_SnrData, this.sort),
      total: this.Set_SnrData.length
    };
    this.SetItem["SnrCount"]=this.Set_SnrData.length;
    //
  }

  RindData(item) {
    this.SetItem = item;
    //取得設備編號
    var DevIndex = this.SetItem["DevIndex"];
    //Sensor資料
    this.Set_SnrData = this.SensorData.filter(function (d) { return d.DevIndex == item.DevIndex }).sort(function (a, b) { return a.SnrIndex - b.SnrIndex });
    this.gridView = {
      data: orderBy(this.Set_SnrData, this.sort),
      total: this.Set_SnrData.length
    };
    /*
    this.SetZBData(false);*/
  }

  //排序
    sortChange(sort: SortDescriptor[]): void {
      this.sort = sort;
      //this.isToken();
      //this.SetData();
    }

  UpdateData() {
     //重新排列順序
     this.DevData=this.DevData.sort(function(a, b){ return  a.DevIndex-b.DevIndex});
     this.SensorData=this.SensorData.sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
		let sbody = {
      Dev_Data: this.DevData,
			Sensor_Data: this.SensorData,
			Key:localStorage.getItem("Token")
		};
		
		this.ZigbeeData.UpdateData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				//this.MyCommon.CheckToken(obj);
        alert("設定完成!");
			},
			err => { console.log(err); }
		);
  }
  
 
}
