import { Component, Input } from '@angular/core';

@Component({
  selector: 'sy-Tag',
  templateUrl: './sy-Tag.component.html',
  styleUrls: ['./sy-Tag.component.scss']
})
export class SyTagComponent{
  @Input() SetWidth:String;
}
/*
[align=left]
[align=right]

[type=text]
[type=DropDown]
[type=DatePicker]
[type=Btn]
[type=Radio]
[type=textbox]*/