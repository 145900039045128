import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class DemandCtrlService {

constructor(@Inject(HttpClient)  
        private http : HttpClient,
        public MyCommon:MyCommon) { }

    GetContrllerData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('GetMeterDemandContrller');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

    UpdateContrllerData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('UpdateMeterDemandContrller');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

    GetItemData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('GetMeterDemandItem');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetLogData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('GetMeterDemandLog');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetShowMsgData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('GetMeterDemandShowMsg');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
}