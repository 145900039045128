import { Component, OnInit, Inject } from '@angular/core';
import { IBas_Layout_ObjectService,iBas_Permissions } from '../../../DataService';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-LayoutObject',
  templateUrl: './LayoutObject.component.html',
  styleUrls: ['./LayoutObject.component.css'],
  providers: [IBas_Layout_ObjectService, MyCommon,iBas_Permissions]
})
export class LayoutObjectComponent implements OnInit {

  //系統資料
  SYS_Source: any;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  //查詢參數
  Search_Category:any="Name";
  Search_Keyword:any="";
  Search_Enable:any="-1";

  public SelectItem;

  constructor(
    private MyCommon: MyCommon,
    private PermissionsData: iBas_Permissions,
    private Layout_Object: IBas_Layout_ObjectService,
    private router: Router
  ) { }

  ngOnInit() {
     //判斷頁面權限
     let sbody = {
        UrlList:"LayoutObjectView,LayoutObjectAdd,LayoutObjectEdit,LayoutObjectDelete",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="LayoutObjectView")
                this.ViewPermissions=true;
              if(Site.path=="LayoutObjectAdd")
                this.AddPermissions=true;
              if(Site.path=="LayoutObjectEdit")
                this.EditPermissions=true;
              if(Site.path=="LayoutObjectDelete")
                this.DeletePermissions=true;
          }
          if(this.EditPermissions){
            this.splitbutton_data.push(
            {
              text: '修改',
              iconClass: 'fa fa-pencil-square-o',
              click: () => {this.router.navigate(['/ibas/LayoutObjectEdit', this.SelectItem.Object_ID]); }
            });
          }
          if(this.DeletePermissions){
            this.splitbutton_data.push(
            {
              text: '刪除',
              iconClass: 'fa fa-trash-o',
              click: () => { this.Delete(this.SelectItem);}
            });
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
      
      this.GetData();
  }

  public splitbutton_data: Array<any> = [];

  public onPaste(obj): void {
    this.SelectItem=obj;
  }

  public onItemTap(args) {
      console.log(args);
  }

 //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    //this.isToken();
    this.GetData();
  }
   //換頁
  protected pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.GetData();
    }

  //取得系統資料
  GetData() {
    let StrWhere=" Where 1=1";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.Layout_Object.GetDataList(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data:  orderBy(obj.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }
  //刪除資料
  Delete(del: any){
    if (confirm("確定要刪除嗎?")) {
      let sbody = {
        Object_ID: del.Object_ID,
        Key:localStorage.getItem("Token")
      };
      var stralert = this.Layout_Object.DeleteData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          alert('刪除成功'); this.GetData(); },
        err => alert('刪除失敗'),
        () => { }
      );
      }
  }

  Search(){
    this.skip=0;
    this.GetData();
  }

}
