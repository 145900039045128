import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Permissions,iBas_Permissions, RealTimeService, SystemService, EventLogService } from '../../../DataService';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [Permissions,iBas_Permissions, RealTimeService, SystemService, EventLogService, MyCommon]
})
export class HeaderComponent implements OnInit {
    pushRightClass: string = 'push-right';

    public ViewPermissions: boolean = false;
    public HomeUrl: string = "";

    Count: any = 0;
    ChangeCount: any = 10;
    EventMessage: any = [];
    EventData = [];
    public notifications:Array<Object>;
    public messages:Array<Object>;
    ImportantValue: any = -1;

    constructor(
        private EventLogData: EventLogService,
        private SystemData: SystemService,
        private RealTimeData: RealTimeService,
        private PermissionsData: Permissions,
        private iBas_PermissionsData: iBas_Permissions,
        @Inject(MyCommon) private MyCommon: MyCommon,
        private translate: TranslateService,
        public router: Router) {


        this.translate.setDefaultLang('en');
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|fr|ur|es|it|fa|de|zh-CHS/) ? browserLang : 'en');

        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 1280 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });
        if(localStorage.getItem("system")=="1"){
          
        }else{
            let Realtimer = Observable.timer(1000,1000);
            let UpdateTime=this.MyCommon.GetUpdateTime();
      
            Realtimer.subscribe(t=> {
                if((t % UpdateTime)==0){
                    this.GetEvent();
                }
            });
            //跑馬燈切換
            let timer = Observable.timer(1000, 1000);
            timer.subscribe(t => {

                if (this.Count == 0) {
                    this.SetBanner();
                    this.Count = this.Count + 1;
                } else {
                    this.Count = this.Count + 1;
                    if (this.Count >= (this.ChangeCount - 1)) {
                        this.Count = 0;
                    }
                }
            });
        }
    }

    ngOnInit() {
        if(localStorage.getItem("system")=="1"){
            this.GetIBasPermissions();
        }else{
           this.GetPermissions();
        }
    }

    GetPermissions(){
        //判斷頁面權限
        let sbody = {
            UrlList: "Itri",
            Key: localStorage.getItem("Token")
        };
        //設定logo路徑
        var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
        stralert.subscribe(
            (obj) => {
                this.MyCommon.CheckToken(obj);
                for (let Site of obj) {
                    if (Site.path == "Itri")
                        this.ViewPermissions = true;
                }
                try {
                    if (!this.ViewPermissions) {
                        if (obj[0].path == "RealTime") {
                            let sbody2 = {
                                TopN: " ",
                                StrWhere: " where Sys_Enabled=1",
                                Sort: " ",
                                Key: localStorage.getItem("Token")
                            };
                            this.SystemData.GetData(sbody2, this.MyCommon.SetHeaders()).subscribe(
                                (obj) => {
                                    this.HomeUrl = '/pages/RealTime/' + obj[0]["ID"];
                                },
                                err => { console.log(err); this.HomeUrl = '/pages/RealTime/'; }
                            );
                        }
                        else
                            this.HomeUrl = '/pages/' + obj[0].path;
                    }
                } catch (err) { }
            },
            err => { },
            () => { }
        );
    }

    GetIBasPermissions(){
        //判斷頁面權限
        let sbody = {
            UrlList: "Itri",
            Key: localStorage.getItem("Token")
        };
        //設定logo路徑
        var stralert = this.iBas_PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
        stralert.subscribe(
            (obj) => {
                this.MyCommon.CheckToken(obj);
                for (let Site of obj) {
                    if (Site.path == "Itri")
                        this.ViewPermissions = true;
                }
                try {
                    if (!this.ViewPermissions) {
                       this.HomeUrl = '/ibas/' + obj[0].path;
                    }
                } catch (err) { }
            },
            err => { },
            () => { }
        );
    }

    //取得警報
    GetEvent() {
        var StrWhere=" Where EventLog.UserChk in(0,-1)";

        let sbody = {
        TopN: " ",
        StrWhere: StrWhere,
        Sort: " limit 0,20 ",
        Key:localStorage.getItem("Token")
        };

        this.EventLogData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
            //this.MyCommon.CheckToken(obj);
            this.EventData=[];
            this.notifications=[];
            for(let i=0;i<obj.length;i++){
                this.EventData.push(obj[i]["ErrorMsg"]);
                this.notifications.push({text:obj[i]["ErrorMsg"],time: i+'分鐘前'});
            }
            this.SetBanner();
        },
        err => { console.log(err); }
        );
    }

    SetBanner() {
        //取得輪播間隔
        let sbody = {
            TopN: " ",
            StrWhere: " ",
            Sort: " "
        };
        this.SystemData.GetSystemSetting(sbody, this.MyCommon.SetHeaders()).subscribe(
            (obj) => {
                if (Number(obj[0].Banner_Time_Sec) > 0) {
                    this.ChangeCount = obj[0].Banner_Time_Sec;
                }
            },
            err => { }
        );
        if ((this.ImportantValue + 1) < this.EventData.length) {
            this.ImportantValue = this.ImportantValue + 1;
        } else {
            this.ImportantValue = 0;
        }
        this.EventMessage = [];
        this.EventMessage.push(this.EventData[this.ImportantValue]);
    }

    link(){
        return false;
      }
      
    linkEventLog(){
        //存入localStorage
        localStorage.setItem("EventLog","0");
        this.router.navigate(['/pages/EventLog']);
     }
  

    //刪除資料
    onLogout() {
        if (confirm("確定要登出嗎?")) {
            localStorage.removeItem("User");
            localStorage.removeItem("Account");
            localStorage.removeItem("Token");
            this.router.navigate(['/login']);
        }
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    /*rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    onLoggedout() {
        localStorage.removeItem('isLoggedin');
    }

    changeLang(language: string) {
        this.translate.use(language);
    }*/
}
