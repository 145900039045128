import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { IBas_Layout_ObjectService,iBas_Permissions } from '../../../../DataService';
import { MyCommon } from '../../../../App_Code/MyCommon';

@Component({
  selector: 'app-LayoutObjectEdit',
  templateUrl: './LayoutObjectEdit.component.html',
  styleUrls: ['./LayoutObjectEdit.component.scss'],
  providers: [IBas_Layout_ObjectService, MyCommon,iBas_Permissions]
})
export class LayoutObjectEditComponent implements OnInit {


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private Layout_Object: IBas_Layout_ObjectService,
    private MyCommon: MyCommon,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.ObjectID = params['id'];
      this.GetData();
    });
  }
  GetData() {
      let sbody = {
        Object_ID:this.ObjectID,
          Key: localStorage.getItem("Token")
        };
      
        this.Layout_Object.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
          (obj) => {
            this.MyCommon.CheckToken(obj);
            let data=[];
            try{
              if(obj[0]=="["){
                data=JSON.parse(obj);
              }else{
                data=obj;
              }
            }catch(err){

            }
            let Data=data;
            if(Data[0]!=null){
              this.ObjectName=Data[0].ObjectName;
              this.ObjectPath=Data[0].ObjectPath;
              this.ObjectScale=Number(Data[0].ObjectScale);
              this.ObjectWidth=Number(Data[0].ObjectWidth);
              this.ObjectHeight=Number(Data[0].ObjectHeight);
              this.Item_List=JSON.parse(Data[0].Taglist);
            }
          },
          err => { console.log(err); }
        );
    }
    
  
  Item_List:any=[];
  ObjectID:any=0;
  ObjectName:any="";
  ObjectPath:any = "";
  ObjectScale:any=50;
  ObjectWidth:any=0;
  ObjectHeight:any=0;
  onFileChange(event) {
    let reader = new FileReader();
    let files = event.target.files;
    if(files && files.length) {
      const [file] = event.target.files;
      reader.onload = (e)=>{

        this.ObjectPath=String(reader.result);
        let img = new Image();
        img.src =  this.ObjectPath;
        img.onload = (e)=>{     
          this.ObjectWidth=img.width;
          this.ObjectHeight=img.height;
        };
      };
      reader.readAsDataURL(file);
    }
  }
  onAddItem(){
    this.Item_List.push({SetName: "項目"+(this.Item_List.length+1),ItemID: (this.Item_List.length+1),Unit: "",StatusItem: "",Object_X:0,Object_Y:0});
  }
  onRemoveItem(index){
    this.Item_List=this.Item_List.filter((item,i)=>{return Number(index)!=i;});
  }
  //移動後
  onMoveEnd(event, item) {
    item.Object_X = event.x;
    item.Object_Y = event.y;
  }
  onSize(SetValue){
    if(this.ObjectScale+SetValue>0 && this.ObjectScale+SetValue<=100){
      this.ObjectScale=this.ObjectScale+SetValue;
    }
  }
  
  //點選儲存
  clicked() {
    if (confirm("確定要修改嗎?")) {
      this.Insert();
    }
  }
  //新增
  Insert() {
    //判斷是否有勾選每週設定
    if (this.ObjectName == "") {
      alert("請輸入名稱");
      return false;
    }
    let sbody = {
      Object_ID:this.ObjectID,
      ObjectName: this.ObjectName,
      ObjectPath: this.ObjectPath,
      ObjectWidth: this.ObjectWidth,
      ObjectHeight: this.ObjectHeight,
      ObjectScale: this.ObjectScale,
      ObjectItemData:this.Item_List,
      Key: localStorage.getItem("Token")
    };

    var stralert = this.Layout_Object.UpdateData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('修改成功');
        this.router.navigate(['/ibas/LayoutObject']);
      },
      err => { alert('修改失敗'); },
      () => { }
    );
    
  }
  //離開
  back() {
    this.router.navigate(['/ibas/LayoutObject']);
  }

}
