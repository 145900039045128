export class EACSettingsColumn {
    constructor(
        public ID: string,
        public EAC_ID: string,
        public SetName: string,
        public MAC: string,
        public SID: string,
        public Unit: string,
        public SetValue: string,
        public Note: string,
        public LayX: string,
        public LayY: string,
        public IO_Type: string
      ) { }
}
