import { Component, OnInit, Inject } from '@angular/core';
import { DisconnAlarmService } from '../../DataService/Alarm.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../DataService/User.service';


@Component({
  selector: 'app-DisconnAlarmSetting',
  templateUrl: './DisconnAlarmSetting.component.html',
  providers: [DisconnAlarmService, MyCommon,Permissions]
})
export class DisconnAlarmSettingComponent implements OnInit {

  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;
  public ColSize: number = 100;

  Search_Category:any="1";
  Search_Keyword:any="";

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

    public SelectItem;
    public splitbutton_data: Array<any> = [];

  constructor(
    @Inject(DisconnAlarmService) private DisconnAlarmData: DisconnAlarmService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {

    //判斷頁面權限
      let sbody = {
        UrlList:"DisconnAlarmSettingView,DisconnAlarmSettingEdit",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="DisconnAlarmSettingView")
                  this.ViewPermissions=true;
              if(Site.path=="DisconnAlarmSettingEdit")
                  this.EditPermissions=true;
          }

          if(this.EditPermissions){
            this.splitbutton_data.push(
            {
              text: '修改',
              iconClass: 'fa fa-pencil-square-o',
              click: () => {this.router.navigate(['/pages/DisconnAlarmSettingEdit', this.SelectItem.MACID]); }
            });
          }

          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
      this.GetData();
  }

  public onPaste(obj): void {
      this.SelectItem=obj;
    }

  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }
  //取得系統資料
  GetData() {
    
    var Search="";
    if(this.Search_Category=="1")
      Search="ifnull(Dev_Info.DevName,0)";
    var StrWhere=" Where "+Search+" Like '%"+this.Search_Keyword+"%'";
    StrWhere+=" and DEV.DataType=0";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.DisconnAlarmData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data: orderBy(obj.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }

    Search(){
    this.skip=0;
    this.GetData();
  }

}
