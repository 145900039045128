import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class DemandService {

constructor(@Inject(HttpClient)  
        private http : HttpClient,
        public MyCommon:MyCommon) { }

        GetAlarmEvent(sbody:any,headers:any=""){
            var url = this.MyCommon.GetAPISPUrl('GetAlarmEvent');
            return this.http.post(url,sbody,{headers: headers})
            .map(res=>JSON.parse(JSON.stringify(res)));
        }
        GetDemand(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetDemand');
            return this.http.post(url,sbody,{headers: headers})
            .map(res=>JSON.parse(JSON.stringify(res)));
        }
        GetMeter(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetMeter');
            return this.http.post(url,sbody,{headers: headers})
            .map(res=>JSON.parse(JSON.stringify(res)));
        }
        
}