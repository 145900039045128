import { Routes, RouterModule } from '@angular/router';
import { iBas_PagesComponent } from './ibas_pages.component';


import { iBas_DefaultComponent } from './Default/Default.component';
/*iBAS*/
import { SystemLayoutComponent } from './SystemLayout';

import { ibas_HistoryComponent,ibas_EventLogComponent} from './History';
import { LayoutObjectComponent,LayoutObjectAddComponent,LayoutObjectEditComponent,LayoutSettingComponent } from '../iBAS_Page/SystemLayoutSetting';
import { Chiller_ParameterComponent,Chiller_Parameter_AddComponent,Chiller_Parameter_EditComponent
  ,Chiller_ScheduleComponent,Chiller_Schedule_AddComponent,Chiller_Schedule_EditComponent
  ,IO_ScheduleComponent,IO_Schedule_AddComponent,IO_Schedule_EditComponent} from '../iBAS_Page/HVAC_Schedule';

import { ibas_UserComponent,ibas_User_AddComponent,ibas_User_EditComponent} from './User/ibas_User';
import { ibas_UserGroupComponent,ibas_UserGroup_AddComponent,ibas_UserGroup_EditComponent} from './User/ibas_UserGroup';

import { SystemSettingPageComponent} from './WebSetting';

import { ObjectSettingComponent} from './ObjectSetting';
import { ibas_ReportDownloadComponent } from './Report/ReportDownload/ibas_ReportDownload.component';

export const ibas_routes: Routes = [
  {
    path: 'ibas', 
    component: iBas_PagesComponent,
    children: [
      { path: '', redirectTo: 'default', pathMatch: 'full' },
      { path: 'default', component: iBas_DefaultComponent},
      
      { path: 'SystemLayout', component: SystemLayoutComponent},
      { path: 'History', component: ibas_HistoryComponent},
      { path: 'EventLog', component: ibas_EventLogComponent},
      { path: 'ChillerParameter', component: Chiller_ParameterComponent},
      { path: 'ChillerParameterAdd', component: Chiller_Parameter_AddComponent},
      { path: 'ChillerParameterEdit/:id', component: Chiller_Parameter_EditComponent},
      { path: 'ChillerSchedule', component: Chiller_ScheduleComponent},
      { path: 'ChillerScheduleAdd', component: Chiller_Schedule_AddComponent},
      { path: 'ChillerScheduleEdit/:id', component: Chiller_Schedule_EditComponent},
      { path: 'IOSchedule', component: IO_ScheduleComponent},
      { path: 'IOScheduleAdd', component: IO_Schedule_AddComponent},
      { path: 'IOScheduleEdit/:id', component: IO_Schedule_EditComponent},

      { path: 'LayoutObject', component: LayoutObjectComponent},
      { path: 'LayoutObjectAdd', component: LayoutObjectAddComponent},
      { path: 'LayoutObjectEdit/:id', component: LayoutObjectEditComponent},
      { path: 'LayoutSetting', component: LayoutSettingComponent},

      { path: 'ReportDownload', component: ibas_ReportDownloadComponent},

      { path: 'User', component: ibas_UserComponent},
      { path: 'UserAdd', component: ibas_User_AddComponent},
      { path: 'UserEdit/:id', component: ibas_User_EditComponent},
      { path: 'UserGroup', component: ibas_UserGroupComponent},
      { path: 'UserGroupAdd', component: ibas_UserGroup_AddComponent},
      { path: 'UserGroupEdit/:id', component: ibas_UserGroup_EditComponent},

      { path: 'SystemSettingPage', component: SystemSettingPageComponent},

      { path: 'ObjectSetting', component: ObjectSettingComponent},
    ]
  }
];

export const ibas_routing = RouterModule.forChild(ibas_routes);
