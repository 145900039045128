import { Component, OnInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { iBas_Permissions, IBas_ScheduleEquipVarService,IBas_ObjectService } from '../../../DataService';

@Component({
  selector: 'app-Chiller_Parameter',
  templateUrl: './Chiller_Parameter.component.html',
  styleUrls: ['./Chiller_Parameter.component.scss'],
  providers: [IBas_ScheduleEquipVarService,IBas_ObjectService, iBas_Permissions, MyCommon]
})
export class Chiller_ParameterComponent implements OnInit {
  //系統資料
  SYS_Source: any;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public DeviceData : any=[];

  private pageSize: number = 10;
  private skip: number = 0;

  public ViewPermissions: boolean = false;
  public AddPermissions: boolean = false;
  public EditPermissions: boolean = false;
  public DeletePermissions: boolean = false;
  public pageable: any = "{numeric: false,previousNext: false,messages: {display: 'Showing {2} data items'}}";
  public SelectItem;
  constructor(private ScheduleEquipVar: IBas_ScheduleEquipVarService,
    private Object: IBas_ObjectService,
    private PermissionsData: iBas_Permissions,
    private MyCommon: MyCommon,
    private router: Router) { }
  ngOnInit() {


    //判斷頁面權限
    let sbody = {
      UrlList: "ChillerParameterView,ChillerParameterAdd,ChillerParameterEdit,ChillerParameterDelete",
      Key: localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
          if (Site.path == "ChillerParameterView")
            this.ViewPermissions = true;
          if (Site.path == "ChillerParameterAdd")
            this.AddPermissions = true;
          if (Site.path == "ChillerParameterEdit")
            this.EditPermissions = true;
          if (Site.path == "ChillerParameterDelete")
            this.DeletePermissions = true;
        }

        if (this.EditPermissions) {
          this.splitbutton_data.push(
            {
              text: '修改',
              iconClass: 'fa fa-pencil-square-o',
              click: () => { this.router.navigate(['/ibas/ChillerParameterEdit', this.SelectItem.EquipVarID]); }
            });
        }
        if (this.DeletePermissions) {
          this.splitbutton_data.push(
            {
              text: '刪除',
              iconClass: 'fa fa-trash-o',
              click: () => { this.Delete(this.SelectItem); }
            });
        }
        try {
          this.MyCommon.PermissionsDefailtPage(obj, !this.ViewPermissions);
        } catch (err) { }
      },
      err => { },
      () => { }
    );

    this.GetData();
    this.GetObject();
  }

  public splitbutton_data: Array<any> = [];

  public onPaste(obj): void {
    this.SelectItem = obj;
  }


  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }
  //取得資料
  GetData() {
    var StrWhere = "";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key: localStorage.getItem("Token")
    };

    this.ScheduleEquipVar.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data: orderBy(obj, this.sort),
          total: obj.length
        };
      },
      err => { }
    );
  }

  GetObject(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.Object.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        this.DeviceData = obj;
      },
      err => { console.log(err); }
    );
  }

  //刪除資料
  Delete(del: any) {

    if (confirm("確定要刪除嗎?")) {
      let sbody = {
        EquipVarID: del.EquipVarID,
        Key: localStorage.getItem("Token")
      };
      var stralert = this.ScheduleEquipVar.DeleteData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.GetData();
        },
        err => alert('刪除失敗'),
        () => { }
      );
    }

  }
  ChangeTimeData(Time_Range) {
    let Time = Time_Range.split(';');
    let Times = [];
    for (let i in Time) {
      if (Time[i] != "")
        Times.push({ Time: Time[i].replace(',', '~') });
    }
    return Times;
  }
  GetWeek(W) {
    let Week = '';
    let WeekList = ['日', '一', '二', '三', '四', '五', '六'];
    for (let i in W.split(',')) {
      if (W.split(',')[i] != null) {
        Week += WeekList[Number(W.split(',')[i]) % 7] + ',';
      }
    }
    return Week.slice(0, Week.length - 1);
  }
  GetDevice(DevList) {
    let List = "";
    DevList=String(DevList);
    if(DevList!=null&&DevList!=""){
      let Device=this.DeviceData.filter(d=>DevList.split(',').indexOf(String(d.MAC_ID))>-1).map(d=>d.S_Name+"("+d.MAC_ID+")");
      List=Device.join(',');
    }
    return List;
  }
}
