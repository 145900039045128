import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { IBas_Layout_ObjectService,IBas_ObjectService,IBas_ObjectAttribService,IBas_Layout_SystemService,IBas_Layout_SystemSettingService,IBas_System_SettingService,iBas_Permissions } from '../../../DataService';
import { MyCommon } from '../../../App_Code/MyCommon';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-LayoutSetting',
  templateUrl: './LayoutSetting.component.html',
  styleUrls: ['./LayoutSetting.component.css'],
  providers: [IBas_Layout_ObjectService,IBas_ObjectService,IBas_ObjectAttribService,IBas_Layout_SystemService,IBas_Layout_SystemSettingService,IBas_System_SettingService, MyCommon,iBas_Permissions]
})
export class LayoutSettingComponent implements OnInit {
   
  
  ShowSetting:any=true;
  LayoutData:any=[];
  ObjectData:any=[];
  DeviceData:any=[];
  DeviceTypeData:any=[];
  IOData:any=[];
  SetDeviceTypeData:any=[];
  SetIOData:any=[];

  SelectLayout:any="";
  Def_Layout:any="";
  SelectTagType:any="1";
  SelectObject:any="-1";
  SelectDevice:any="-1";
  SelectDeviceType:any="-1";
  SelectSettingDevice:any="-1";
  SelectIOType:any="AI";
  SelectIO:any="-1";
  TagLabel:any="";

  PopItem:any=[];
  PopIndex:any=[];
  PopSelectDevice:any="-1";
  PopSelectIOType:any="AI";
  PopSelectIO:any="-1";
  PopIOData:any=[];
  PopSelectGroupID:any=[];
  
  constructor(
    private router: Router,
    private Layout_Object: IBas_Layout_ObjectService,
    private Object: IBas_ObjectService,
    private ObjectAttrib: IBas_ObjectAttribService,
    private LayoutSystemSetting: IBas_Layout_SystemSettingService,
    private LayoutSystem: IBas_Layout_SystemService,
    private System_Setting: IBas_System_SettingService,
    
    private MyCommon: MyCommon,
    private modal: NgbModal,
  ) { }
  

  LayoutItemData:any=[];

  ngOnInit() {
    //this.LayoutPath="../assets/Test.jpg";
    this.GetObject();
    this.GetDevData();
    this.GetSettingData();
    this.GetLayout();
  }

  GetSettingData(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.System_Setting.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        let Data=obj;

        
        if(Data[0].System_Type=="0"){
          this.Def_Layout="HVAC_"+String(Data[0].Chiller_Num)+"_"+String(Data[0].CT_Num);
          if(String(Data[0].CT_Num)=="2"){
            this.Def_Layout=this.Def_Layout+"_"+String(Data[0].CT_Type);
          }
        }
        if(Data[0].System_Type=="1"){
          this.Def_Layout="ZP_"+String(Data[0].ZP_Num);
        }
        if(Data[0].System_Type=="2"){
          this.Def_Layout="CT_"+String(Data[0].CT_Num);
        }
        if(Data[0].System_Type=="3"){
          this.Def_Layout="CH_"+String(Data[0].Chiller_Num);
        }
        this.GetLayout();
      },
      err => { console.log(err); }
    );
  }

  GetLayout(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.LayoutSystem.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        let data=[];
        try{
          if(obj[0]=="["){
            data=JSON.parse(obj);
          }else{
            data=obj;
          }
        }catch(err){

        }
        this.LayoutData = data;
        
        if(this.LayoutData.length>0){
          let SelectData=this.LayoutData.filter(d=>d.SystemName==this.Def_Layout);
          if(SelectData.length>0){
            this.SelectLayout= SelectData[0].Lay_ID;
            this.LayoutPath= SelectData[0].LayoutPath;
          }else{
            this.SelectLayout= this.LayoutData[0].Lay_ID;
            this.LayoutPath= this.LayoutData[0].LayoutPath;
          }
        }
        this.GetLayoutSetting();
      },
      err => { console.log(err); }
    );
  }
  GetLayoutSetting(){
    let sbody = {
      Lay_ID: this.SelectLayout,
      Key:localStorage.getItem("Token")
    };
  
    this.LayoutSystem.GetDataAllSetting(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        let data=[];
        try{
          if(obj[0]=="["){
            data=JSON.parse(obj);
          }else{
            data=obj;
          }
        }catch(err){

        }
        let ALL_Setting=data;
        let SettingData=JSON.parse(ALL_Setting[0].Setting);
        let SettingItemData=JSON.parse(ALL_Setting[0].SettingItem);
        this.LayoutItemData=[];
        //console.log(ALL_Setting[0].Setting);
        SettingData.forEach(d => {
          let Children=[];
          if(Number(d.ItemType)==2){
            let Object=this.ObjectData.filter(o=>String(o.Object_ID)==d.ObjectID);
            //轉為獨立JSON物件
            Object=JSON.parse(JSON.stringify(Object));
            if(Object.length>0){
              let Tags=[];
              try {
                Tags=JSON.parse(Object[0].Taglist);
              } catch(e) {
                Tags=Object[0].Taglist;
              }
              for(let j in Tags){
                Tags[j]["Data_MAC_S"]="-1";
                Tags[j]["PopIOData"]=[];
                Tags[j]["SelectDevice"]="-1";
                Tags[j]["IOType"]="AI";
                Tags[j]["SelectIO"]="-1";
                Tags[j]["ShowEnabled"]="1";
                let SettingItem=SettingItemData.filter(s=>String(s.Tag_ID)==d.Tag_ID && String(s.ItemID)== Tags[j].ItemID);
                //console.log(SettingItemData);
                //console.log(d.Tag_ID+"_"+Tags[j].ItemID);
                if(SettingItem.length>0){
                  let ItemDevice="-1";
                  let ItemIO="-1";
                  if(SettingItem[0].Data_MAC_S!="-1"){
                    ItemDevice=SettingItem[0].Data_MAC_S.split('-')[0];
                    ItemIO=SettingItem[0].Data_MAC_S.split('-')[1].replace('S','');
                  }
                  Tags[j]["Data_MAC_S"]=SettingItem[0].Data_MAC_S;
                  Tags[j]["PopIOData"]=this.IOData.filter(d=>String(d.MAC_ID)==ItemDevice&&d.TypeID==SettingItem[0].IOType);
                  Tags[j]["SelectDevice"]=ItemDevice;
                  Tags[j]["IOType"]=SettingItem[0].IOType;
                  Tags[j]["SelectIO"]=ItemIO;
                  Tags[j]["ShowEnabled"]=SettingItem[0].ShowEnabled;
                }

              }
              Object[0].Taglist=Tags;
              Children= Object;
            }
          
          }
          this.LayoutItemData.push({Tag_ID:Number(d.Tag_ID),Label:d.Label
            ,Data_MAC_S:d.Data_MAC_S,ObjectID:Number(d.ObjectID),GroupID:Number(d.GroupID)
            ,LayX:Number(d.LayX),LayY:Number(d.LayY),ItemType:Number(d.ItemType),IOType:d.IOType,ShowEnabled:d.ShowEnabled,children:Children});
        });
      },
      err => { console.log(err); }
    );
  }

  LayoutChange(){
    let SetLayout=this.LayoutData.filter(d=>d.Lay_ID==this.SelectLayout);
    if(SetLayout.length>0){
      this.LayoutPath= SetLayout[0].LayoutPath;
    }
    this.GetLayoutSetting();
  }
  TagTypeChange(){
    this.SetDeviceTypeData=this.DeviceTypeData.filter(d=>this.DeviceData.filter(I=>I.Type_ID==d.TypeID).length>0);
  }
  DeviceChange(){
    this.SelectIO="-1";
    this.SetIOData=this.IOData.filter(d=>String(d.MAC_ID)==this.SelectDevice&&d.TypeID==this.SelectIOType);
  }

  IOTypeChange(){
    this.SelectIO="-1";
    this.SetIOData=this.IOData.filter(d=>String(d.MAC_ID)==this.SelectDevice&&d.TypeID==this.SelectIOType);
  }
  ObjectChange(){

  }
  DeviceTypeChange(){

  }
  
  IOChange(){}

  Pop_Device_Change(Item){
    this.PopIOData=this.IOData.filter(d=>String(d.MAC_ID)==this.PopSelectDevice&&d.TypeID==this.PopItem.IOType);
    if(this.PopSelectDevice=="-1")
      Item.Data_MAC_S="-1";
  }
  Pop_IO_Type_Change(Item){
    this.PopIOData=this.IOData.filter(d=>String(d.MAC_ID)==this.PopSelectDevice&&d.TypeID==this.PopItem.IOType);
    if(this.PopIOData.length==0){
      this.PopSelectIO="-1";
      Item.Data_MAC_S="-1";
    }
  }
  Pop_IO_Change(Item){
    if(this.PopSelectIO=="-1"){
      Item.Data_MAC_S="-1";
    }else{
      Item.Data_MAC_S=this.PopSelectDevice+"-S"+this.PopSelectIO;
    }
  }

  Pop_Obj_Device_Change(Item){
    Item["PopIOData"]=this.IOData.filter(d=>String(d.MAC_ID)==Item["SelectDevice"]&&d.TypeID==Item["IOType"]);
    if(Item["SelectDevice"]=="-1")
      Item["Data_MAC_S"]="-1";

  }
  Pop_Obj_IO_Type_Change(Item){
    Item["PopIOData"]=this.IOData.filter(d=>String(d.MAC_ID)==Item["SelectDevice"]&&d.TypeID==Item["IOType"]);
    if(this.PopIOData.length==0){
      Item["SelectIO"]="-1";
      Item["Data_MAC_S"]="-1";
    }
  }
  Pop_Obj_IO_Change(Item){
    if(Item["SelectIO"]=="-1"){
      Item["Data_MAC_S"]="-1";
    }else{
      Item["Data_MAC_S"]=Item["SelectDevice"]+"-S"+Item["SelectIO"];
    }
  }

  Pop_Type_Change(Item){
  
    let SelectType=this.SetDeviceTypeData.filter(d=>String(d.TypeID)==this.PopSelectGroupID);
    if(SelectType.length>0){
      Item.Label=SelectType[0].Name;
      Item.GroupID=SelectType[0].TypeID;
    }else{
      Item.Label="";
      Item.GroupID="-1";
    }
  }
  Pop_SettingDev_Change(Item){
  
    let SelectType=this.DeviceData.filter(d=>String(d.MAC_ID)==this.PopSelectGroupID);
    if(SelectType.length>0){
      Item.Label=SelectType[0].DisplayName;
      Item.GroupID=SelectType[0].MAC_ID;
    }else{
      Item.Label="";
      Item.GroupID="-1";
    }
  }
  onAdd(){
    if(this.SelectTagType=="1"){
      let SelectIO=this.IOData.filter(d=>String(d.MAC_ID)==this.SelectDevice&&d.TypeID==this.SelectIOType&&d.TypeID==this.SelectIOType&&String(d.S_Num)==this.SelectIO);
      if(SelectIO.length>0){
        this.LayoutItemData.push({Tag_ID:-1,Label:SelectIO[0].S_Name+"-"+SelectIO[0].Attrib_Name_TC,Data_MAC_S:this.SelectDevice+"-S"+this.SelectIO,ObjectID:-1,GroupID:-1,LayX:0,LayY:0,ItemType:1,IOType:this.SelectIOType,ShowEnabled:"1"});
      }else{
        alert("請選擇設備IO");
      }
    }
    if(this.SelectTagType=="2"){
      let SelectObj=this.ObjectData.filter(d=>String(d.Object_ID)==this.SelectObject);
      if(SelectObj.length>0){
        this.LayoutItemData.push({Tag_ID:-1,Label:SelectObj[0].ObjectName+(this.LayoutItemData.length+1),Data_MAC_S:"",ObjectID:Number(this.SelectObject),GroupID:-1,LayX:0,LayY:0,ItemType:2,IOType:"",ShowEnabled:"1"});
      }else{
        alert("請選擇物件");
      }
    }
    if(this.SelectTagType=="3"){
      let SelectType=this.SetDeviceTypeData.filter(d=>String(d.TypeID)==this.SelectDeviceType);
      if(SelectType.length>0){
        this.LayoutItemData.push({Tag_ID:-1,Label:SelectType[0].Name,Data_MAC_S:"",ObjectID:-1,GroupID:this.SelectDeviceType,LayX:0,LayY:0,ItemType:3,IOType:"",ShowEnabled:"1"});
      }else{
        alert("請選擇系統類別");
      }
    }
    if(this.SelectTagType=="4"){
      this.LayoutItemData.push({Tag_ID:-1,Label:this.TagLabel,Data_MAC_S:"",ObjectID:-1,GroupID:-1,LayX:0,LayY:0,ItemType:4,IOType:"",ShowEnabled:"1"});
    }
    if(this.SelectTagType=="5"){
      let SelectType=this.DeviceData.filter(d=>String(d.MAC_ID)==this.SelectSettingDevice);
      if(SelectType.length>0){
        this.LayoutItemData.push({Tag_ID:-1,Label:SelectType[0].DisplayName,Data_MAC_S:"",ObjectID:-1,GroupID:this.SelectSettingDevice,LayX:0,LayY:0,ItemType:5,IOType:"",ShowEnabled:"1"});
      }else{
        alert("請選擇系統類別");
      }
    }
    this.BindLayoutData();
  }
  //移動
  onMoveing(){
    this.ShowSetting=false;
  }
   //移動後
   onMoveEnd(event, item) {
    item.LayX = event.x;
    item.LayY = event.y;
    this.ShowSetting=true;
  }
  //開啟
  public onSetting(content,item,index){
    this.PopItem=item;
    this.PopIndex=index;
    if(this.PopItem.ItemType==1 && this.PopItem.Data_MAC_S!="-1"){
      if(item.IOType!="")
        this.PopSelectIOType=item.IOType;
      this.PopSelectDevice=item.Data_MAC_S.split('-')[0];
      this.PopSelectIO=item.Data_MAC_S.split('-')[1].replace('S','');
      this.PopIOData=this.IOData.filter(d=>String(d.MAC_ID)==this.PopSelectDevice&&d.TypeID==this.PopItem.IOType);
    }else if (this.PopItem.ItemType==1 && this.PopItem.Data_MAC_S=="-1"){
      this.PopSelectDevice="-1";
      this.PopSelectIO="-1";
    }
    if(this.PopItem.ItemType==3||this.PopItem.ItemType==5){
      this.SetDeviceTypeData=this.DeviceTypeData.filter(d=>this.DeviceData.filter(I=>I.Type_ID==d.TypeID).length>0);
      this.PopSelectGroupID=this.PopItem.GroupID;
    }

    //打開設定頁面
    if(this.PopItem.ItemType==2||this.PopItem.ItemType==1){
      const activeModal = this.modal.open(content, { size: 'lg', backdrop: 'static',centered: true  });
      //傳值到設定頁
      //activeModal.componentInstance.EACSettingsColumn = new EACSettingsColumn(data.ID, data.EAC_ID, data.SetName, data.MAC, data.SID, data.Unit, data.SetValue, data.Note, data.LayX, data.LayY, data.IO_Type);//this.RealTimeColumn;
      //activeModal.componentInstance.Title = data.SetName;
      //判斷在設定頁關閉後做SetData動作
      activeModal.result.then((result) => {
        //this.GetSettingsData();
      });
    }else{
      const activeModal = this.modal.open(content, { size: 'sm', backdrop: 'static',centered: true  });
      //傳值到設定頁
      //activeModal.componentInstance.EACSettingsColumn = new EACSettingsColumn(data.ID, data.EAC_ID, data.SetName, data.MAC, data.SID, data.Unit, data.SetValue, data.Note, data.LayX, data.LayY, data.IO_Type);//this.RealTimeColumn;
      //activeModal.componentInstance.Title = data.SetName;
      //判斷在設定頁關閉後做SetData動作
      activeModal.result.then((result) => {
        //this.GetSettingsData();
      });
    }
  }
  onSettingRemove(modal){
    if (confirm("確定要移除嗎?")) {
      this.LayoutItemData=this.LayoutItemData.filter((item,i)=>{return Number(this.PopIndex)!=i;});
      modal.dismiss();
    }
  
  }
 

  GetObject(){
    let sbody = {
      Key:localStorage.getItem("Token")
    };
  
    this.Layout_Object.GetDataAll(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        let data=[];
        try{
          if(obj[0]=="["){
            data=JSON.parse(obj);
          }else{
            data=obj;
          }
        }catch(err){

        }
        this.ObjectData=data;
        this.BindLayoutData();
      
      },
      err => { console.log(err); }
    );
  }
  
  BindLayoutData(){
    for(let i in this.LayoutItemData){
      if(this.LayoutItemData[i].ItemType==2){
        if(this.LayoutItemData[i]["children"]==null){
          let Object=this.ObjectData.filter(d=>d.Object_ID==this.LayoutItemData[i].ObjectID);
          //轉為獨立JSON物件
          Object=JSON.parse(JSON.stringify(Object));
          if(Object.length==0){
            this.LayoutItemData[i]["children"] = [];
          }else{
            
            let New_Object=[];
            New_Object.push(Object[0]);
            let Tags=[];
            if(New_Object.length>0){
              try {
                Tags=JSON.parse(New_Object[0].Taglist);
              } catch(e) {
                Tags=New_Object[0].Taglist;
              }
              for(let j in Tags){
                Tags[j]["Data_MAC_S"]="-1";
                Tags[j]["PopIOData"]=[];
                Tags[j]["SelectDevice"]="-1";
                Tags[j]["IOType"]="AI";
                Tags[j]["SelectIO"]="-1";
                Tags[j]["ShowEnabled"]="1";
              }
            }
            New_Object[0].Taglist=Tags;
            this.LayoutItemData[i]["children"] = New_Object;
            
          }
        }
      }else{
        this.LayoutItemData[i]["children"] =[];
      }
    }
  }
  GetDevData(){
   
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.Object.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        this.DeviceData = obj;
      },
      err => { console.log(err); }
    );

    sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.Object.GetDataType(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        this.DeviceTypeData = obj;
      },
      err => { console.log(err); }
    );

    sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.ObjectAttrib.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        this.IOData = obj;
      },
      err => { console.log(err); }
    );
  }
 
  LayoutPath:any = "";
  LayoutWidth:any=0;
  LayoutHeight:any=0;
  onFileChange(event) {
    let reader = new FileReader();
    let files = event.target.files;
    if(files && files.length) {
      const [file] = event.target.files;
      reader.onload = (e)=>{

        this.LayoutPath=String(reader.result);
        let img = new Image();
        img.src =  this.LayoutPath;
        img.onload = (e)=>{     
          this.LayoutWidth=img.width;
          this.LayoutHeight=img.height;
        };
      };
      reader.readAsDataURL(file);
    }
  }
  onSave(){
    if (confirm("確定要修改嗎?")) {
      this.UpdateLayoutSetting();
    }
  }

  UpdateLayoutSetting(){
      //底圖更新
    
      let SetLayout=this.LayoutData.filter(d=>d.Lay_ID==this.SelectLayout);
      let ImageName=this.SelectLayout;
      if(SetLayout.length>0){
        ImageName=SetLayout[0].LayoutName+"_"+SetLayout[0].SystemName;
      }
      let sbody = {
        Lay_ID:this.SelectLayout,
        LayoutPath: this.LayoutPath,
        LayoutName:ImageName,
        Key: localStorage.getItem("Token")
      };
  
      var stralert = this.LayoutSystem.UpdateData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          //this.router.navigate(['/pages/LayoutObject']);
        },
        err => { alert('修改失敗'); },
        () => { }
      );
      //設定
      let SetData=this.LayoutItemData;
      let settingData=[];
      SetData.forEach(d => {
        let SetTag={Tag_ID:-1,Lay_ID:"-1",Label:"",Data_MAC_S:"",IOType:"",ObjectID:-1,GroupID:"",LayX:0,LayY:0,ItemType:-1,ShowEnabled:"1",Children:[]};
        //SetTag.Tag_ID=d.Tag_ID;
        SetTag.Lay_ID=this.SelectLayout;
        SetTag.Label=d.Label;
        SetTag.Data_MAC_S=d.Data_MAC_S;
        SetTag.IOType=d.IOType;
        SetTag.ObjectID=d.ObjectID;
        SetTag.GroupID=d.GroupID;
        SetTag.LayX=d.LayX;
        SetTag.LayY=d.LayY;
        SetTag.ItemType=d.ItemType;
        SetTag.ShowEnabled=d.ShowEnabled;
        if(d.children.length>0){
          d.children[0].Taglist.forEach(c => {
            SetTag.Children.push({ItemID:c.ItemID,ObjectID:d.children[0].Object_ID,Data_MAC_S:c.Data_MAC_S,IOType:c.IOType,ShowEnabled:c.ShowEnabled});
          });
        }
        settingData.push(SetTag);
        
      });
      let sbody2 = {
        Lay_ID:this.SelectLayout,
        SettingData: settingData,
        Key: localStorage.getItem("Token")
      };
  
      var stralert = this.LayoutSystemSetting.UpdateData(sbody2, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          alert('修改成功');
          this.RefrashLayout();
          //this.router.navigate(['/pages/LayoutObject']);
        },
        err => { alert('修改失敗'); },
        () => { }
      );
  }

  RefrashLayout(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.LayoutSystem.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        this.LayoutData = obj;
      },
      err => { console.log(err); }
    );
  }

}
