import { Component, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EACSettingsColumn } from '../EACSettingsColumn'
import { RealTimeService } from '../../../DataService/RealTime.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router } from '@angular/router';
import { EACService } from '../../../DataService/EAC.service';

@Component({
  selector: 'app-EACPopup',
  templateUrl: './EACPopup.component.html',
  providers: [RealTimeService, MyCommon,EACService]
})
export class EACPopupComponent implements OnInit {
  //預設欄位
  public SetCtrlType:number;
  PopType:number;
  EACSettingsColumn = new EACSettingsColumn('', '', '', '', '', '', '', '', '', '', '');
  Title:String="";
  //判斷是否能夠控制數值
  IsDisabled:any=true;
  private DateList:any=[];
  constructor(private activeModal: NgbActiveModal,
              @Inject(EACService) private EAC: EACService,
              @Inject(MyCommon) private MyCommon: MyCommon,          
              private router: Router
            ) { }

  ngOnInit() {

  }
  //隱藏Popup
  back() {
    this.activeModal.close();
  }
  //儲存設定
  onCtrl() {
    let sbody = {
      ID: this.EACSettingsColumn.ID,
      EAC_ID: this.EACSettingsColumn.EAC_ID,
      SetName: this.EACSettingsColumn.SetName,
      MAC: this.EACSettingsColumn.MAC,
      SID: this.EACSettingsColumn.SID,
      Unit: this.EACSettingsColumn.Unit,
      SetValue: this.EACSettingsColumn.SetValue,
      Note: this.EACSettingsColumn.Note,
      LayX: this.EACSettingsColumn.LayX,
      LayY: this.EACSettingsColumn.LayY,
      Key:localStorage.getItem("Token")
    };

    var stralert = this.EAC.UpdateEACSetData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('修改成功');
        
        this.activeModal.close();
      },
      err => { alert('修改失敗');console.log(err); },
      () => {}
    );
  }


 
}
