import { Component, OnInit, Inject } from '@angular/core';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy,groupBy  } from '@progress/kendo-data-query';
import { IOCtrlSettingService } from '../../../DataService/IOCtrlSetting.service';
import { SystemService } from '../../../DataService/System.service';
import { IOService } from '../../../DataService/IO.service';
import { DevService } from '../../../DataService/Dev.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';
import { IOCtrlSettingColumn } from '../IOCtrlSettingColumn';

@Component({
  selector: 'app-IOCtrlSetting_Edit',
  templateUrl: './IOCtrlSetting_Edit.component.html',
  styleUrls: ['./IOCtrlSetting_Edit.component.scss'],
  providers: [ DevService,IOService, MyCommon, Permissions,IOCtrlSettingService ]
})
export class IOCtrlSetting_EditComponent implements OnInit {
  StartDate: any = '2017/01/01 00:00:00';
  EndDate: any = '2017/01/01 00:00:00';
  public gridView: GridDataResult;
  public sort: SortDescriptor[]=[{field: 'Sort',dir: 'asc'}];
  public Dev_Source: any=[];
  public MACID:String;
  public IO_Source: any=[];
  public IO_Data:any;
  public IO_Type: any = "";
  public SetCtrlType:number;
  public TextValue: any = '0';
  public SelectValue: any = '0';
  public GridData:any=[];
  public IOCtrl_ID:number;
  public IOCtrl_Condition_Data:any;
  test:any;
  // //DatePick設定
  // Set_Date: any=new Date();
  // Start_Time: any =new Date();
  // End_Time: any =new Date();
  //條件
  Conditional:string;
  //預設欄位
  IOCtrlSettingColumn = new IOCtrlSettingColumn(new Date(), new Date(), new Date(),'',0,0,0,0,'');
  ID:any;
  
  
  public ViewPermissions:boolean=false;
  
  constructor(@Inject(IOCtrlSettingService) private IOCtrlSettingData: IOCtrlSettingService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(DevService) private DevData: DevService,
    @Inject(IOService) private IOData: IOService,
    @Inject(MyCommon) private MyCommon: MyCommon,
  private router: Router,
  private activatedRoute: ActivatedRoute, 
  ) { }
  ngOnInit() {
    let sbody = {
      UrlList:"IOCtrlSettingEdit",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
            if(Site.path=="IOCtrlSettingEdit")
                this.ViewPermissions=true;
        }
        try{
          this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
        }catch(err){}
      },
      err => {  },
      () => { }
    );

  this.SetData();
  }
 //取得設備資料
 GetDevData(){
  var StrWhere = " where  DEV.DataType=0";

  let sbody = {
    TopN: " ",
    StrWhere: StrWhere,
    Sort: " ",
    Key:localStorage.getItem("Token")
  };
  this.DevData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
    (obj) => {
      this.MyCommon.CheckToken(obj);
      this.Dev_Source =obj;
      this.GetIOData();
    },
    err => { console.log(err); }
  );
}
//改變設備
DevChange(){
  this.GetIOData();
}
//取得IO資料
GetIOData(){
  var StrWhere = " where IO_Info.IO_MAC='" + this.MACID + "'  ";
  let sbody = {
    TopN: " ",
    StrWhere: StrWhere,
    Sort: " ",
    Key: localStorage.getItem("Token")
  };
  this.IOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
    (obj) => {
      this.MyCommon.CheckToken(obj);
      this.IO_Source = obj;

    },
    err => { console.log(err); }
  );
}
IOChange() {
  var IODataList = String(this.IO_Data).split('-');
  this.IOCtrlSettingColumn.Ctrl_MAC = Number(IODataList[0]);
  this.IOCtrlSettingColumn.Ctrl_S = Number(IODataList[1]);
  this.IO_Type = IODataList[2];
  this.GetCtrlType( Number(IODataList[1]));
}
GetCtrlType(IO_S){
  var StrWhere = " where IO_Info.IO_MAC='" + this.MACID + "' and IO_S= " + IO_S + " ";
  let sbody = {
    TopN: " ",
    StrWhere: StrWhere,
    Sort: " ",
    Key: localStorage.getItem("Token")
  };
  console.log(JSON.stringify(sbody));
  this.IOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
    (obj) => {
      this.MyCommon.CheckToken(obj);
      this.SetCtrlType = obj[0].SetCtrlType;
      this.IOCtrlSettingColumn.CtrlType=this.SetCtrlType;       
    },
    err => { console.log(err); }
  );
}
//取得資料
GetData() {
  this.gridView = {
    data: orderBy(this.GridData, this.sort),
    total: this.GridData.length
  };
  this.ShowConditional();
}
onItemInsert(){
  let MaxID=0,DefMACID=-1,MaxSort=0;
  //取得最大ID與排序
  for(let i in this.GridData){
    if(MaxID<this.GridData[i].ID)
      MaxID=this.GridData[i].ID;
    if(MaxSort<this.GridData[i].Sort)
      MaxSort=this.GridData[i].Sort;
  }
  //取得預設MACID
  if(this.Dev_Source.length>0){
    DefMACID=this.Dev_Source[0].MACID;
  }
  //新增資料
  this.GridData.push({ID:MaxID+1,IOCtrl_ID:1,Parameter1:DefMACID,P1Value:1,Judgment:1,Parameter2:-1,P2Value:1,Groups:-1,Sort:MaxSort+1,Logic:1});

  this.gridView = {
    data: orderBy(this.GridData, this.sort),
    total: this.GridData.length
  };
  this.ShowConditional();
}
onItemDelete(item: any){
  //重新排序
  this.GridData=this.GridData.filter(function (d) {return d.ID != item.ID;}).sort(function(a, b){ return a.Sort - b.Sort});
  for(let i in this.GridData){
    this.GridData[i].Sort=(Number(i)+1);
  }
  this.gridView = {
    data: orderBy(this.GridData, this.sort),
    total: this.GridData.length
  };
  this.ShowConditional();
}
onItemSort(item: any,SortType:number){
  //取得替換的排序並重新設定
  let SortItem = this.GridData.filter(task => task.Sort == (item.Sort+SortType));
  if(SortItem.length>0){
    SortItem[0].Sort+=(SortType*-1);
  }
  //改變當列排序
  item.Sort+=SortType;
  
  this.gridView = {
    data: orderBy(this.GridData, this.sort),
    total: this.GridData.length
  };
  this.ShowConditional();
}

editHandler(){

  console.log("A");
}
//設備清單、IO清單、排程資料、控制清單資料

//點選儲存
clicked() {
  if (confirm("確定要修改嗎?")) {
    this.Update();     
  }
}
//顯示條件
ShowConditional(){
  //重新排序
  let RowData=this.GridData.sort(function(a, b){ return ((a.Group*100)+ a.Sort ) - ((b.Group*100)+b.Sort)});//.sort(function(a, b){ return a.Sort - b.Sort});
  //群組資料
  let FinalData=groupBy(RowData, [{ field: "Group" }]);
  this.Conditional='';
  for(let i in FinalData){
    let GroupLogic='',GroupCalc='';
    for(let j in FinalData[i]["items"]){
      let item=FinalData[i]["items"][j];
      let Parameter1="",Parameter2="";
      if(Number(item["Parameter1"])==-1)
        Parameter1=item["P1Value"];
      else
        Parameter1='['+item["Parameter1"]+'-S'+item["P1Value"]+']';

      if(Number(item["Parameter2"])==-1)
        Parameter2=item["P2Value"];
      else
        Parameter2='['+item["Parameter2"]+'-S'+item["P2Value"]+']';

      let Calc=''
      switch (Number(item["Judgment"])) {
        case 1:
        Calc='('+Parameter1+'='+Parameter2+')';
          break;
        case 2:
        Calc='('+Parameter1+'>'+Parameter2+')';
          break;
        case 3:
        Calc='('+Parameter1+'<'+Parameter2+')';
          break;
        case 4:
        Calc='('+Parameter1+'<='+Parameter2+')';
          break;
        case 5:
        Calc='('+Parameter1+'>='+Parameter2+')';
          break;
      }
      if(Number(j)>0){
        switch (Number(item["Logic"])) {
          case 1:
            Calc = " & "+Calc;
            break;
          case 2:
            Calc = " | "+Calc;
            break;
        }
      }else if (Number(j)==0&&Number(i)>0){
        switch (Number(item["Logic"])) {
          case 1:
            GroupLogic = " & ";
            break;
          case 2:
            GroupLogic = " | ";
            break;
        }
      }
      GroupCalc+=Calc;
    }
    if (FinalData[i]["items"].length == 1)
      this.Conditional += GroupLogic+GroupCalc;
    else
    this.Conditional += GroupLogic + '(' + GroupCalc + ')';
  }
  this.IOCtrlSettingColumn.CtrlCondition=this.Conditional;
  this.IOCtrl_Condition_Data=this.gridView.data;
}
//設定預設值
SetData() {
  this.activatedRoute.params.subscribe((params: Params) => {
    this.ID = params['id'];
  });
  var StrWhere = " where ID=" + this.ID;
  let sbody = {
    TopN: " ",
    StrWhere: StrWhere,
    Sort: " ",
    Key:localStorage.getItem("Token")
  };
  this.IOCtrlSettingData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
    (obj) => {
      this.MyCommon.CheckToken(obj);
      if(obj[0].TDate==null||obj[0].TDate.length==0){
        this.StartDate = new Date(this.MyCommon.FormateDate(new Date()) + " " + obj[0].STime.slice(0, -3));
        this.EndDate = new Date(this.MyCommon.FormateDate(new Date()) + " " + obj[0].ETime.slice(0, -3));
      }
      else{
        this.StartDate = new Date("2018/" + obj[0].TDate + " " + obj[0].STime.slice(0, -3));
        this.EndDate = new Date("2018/" + obj[0].TDate + " " + obj[0].ETime.slice(0, -3));
      }

      this.IOCtrlSettingColumn.TDate = this.StartDate;
      this.IOCtrlSettingColumn.STime = this.StartDate;
      this.IOCtrlSettingColumn.ETime = this.EndDate;
      this.IOCtrlSettingColumn.CtrlCondition=obj[0].CtrlCondition;
      this.IOCtrlSettingColumn.CtrlValue = obj[0].CtrlValue;
      this.IOCtrlSettingColumn.CtrlType= obj[0].CtrlType;
      this.IOCtrlSettingColumn.Ctrl_MAC = obj[0].Ctrl_MAC;
      this.IOCtrlSettingColumn.Ctrl_S = obj[0].Ctrl_S; 
      this.IO_Data=obj[0].IO_Data;
      this.SetCtrlType = obj[0].CtrlType;
      this.MACID = obj[0].Ctrl_MAC;
      this.Conditional=obj[0].CtrlCondition;
      if (this.IO_Type == 'DO')
      {
        this.SelectValue = this.IOCtrlSettingColumn.CtrlValue;
      }
      else
      {
        this.TextValue = this.IOCtrlSettingColumn.CtrlValue;
      }
      var StrWhere2 = " where IOCtrl_ID=" + this.ID;
      let sbody2 = {
        TopN: " ",
        StrWhere: StrWhere2,
        Sort: " ",
        Key:localStorage.getItem("Token")
      };
      this.IOCtrlSettingData.GetConditionData(sbody2, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.GridData=obj;
          this.GetData();
          this.GetDevData();
        });
   
    }
  );

}
//更新資料
Update() {
  if (this.IO_Type == 'DO')
     this.IOCtrlSettingColumn.CtrlValue = this.SelectValue;
   else
     this.IOCtrlSettingColumn.CtrlValue = this.TextValue;

   if (this.IOCtrlSettingColumn.Ctrl_MAC < 1 || this.IOCtrlSettingColumn.Ctrl_S < 1) {
     alert("請選擇IO!");
     return false;
   }
  
   let sbody3 = {
     ID: this.ID,
     TDate:  String(this.MyCommon.SetZero(Number(this.IOCtrlSettingColumn.TDate.getMonth()) + 1))+"/"+this.MyCommon.SetZero(this.IOCtrlSettingColumn.TDate.getDate()),
     STime: this.MyCommon.FormateTime2(this.IOCtrlSettingColumn.STime)+":00",
     ETime: this.MyCommon.FormateTime2(this.IOCtrlSettingColumn.ETime)+":00",
     CtrlCondition: this.IOCtrlSettingColumn.CtrlCondition,
     CtrlType: this.SetCtrlType,
     CtrlValue: this.IOCtrlSettingColumn.CtrlValue,
     Ctrl_MAC: this.IOCtrlSettingColumn.Ctrl_MAC,
     Ctrl_S: this.IOCtrlSettingColumn.Ctrl_S,
     IOCtrl_Condition_Data:this.IOCtrl_Condition_Data,
     Key: localStorage.getItem("Token")
   };
   var stralert = this.IOCtrlSettingData.UpdateData(sbody3, this.MyCommon.SetHeaders());
   stralert.subscribe(
     (obj) => {
       this.MyCommon.CheckToken(obj);
       alert('修改成功');
       this.router.navigate(['/pages/IOCtrlSetting']);
     },
     err => { alert('修改失敗'); },
     () => { }
   );
 }
 back() {
  this.router.navigate(['/pages/IOCtrlSetting']);
}
}
