import { Injectable } from '@angular/core';
import { isToday, format, getDay,getDate, getISOWeeksInYear, getISOWeek, endOfISOWeek, addDays,addHours,getHours,getMonth,lastDayOfMonth
  ,startOfISOWeek, startOfDay, endOfDay, startOfMonth, endOfMonth, addMonths, startOfWeek, getYear
  , isThisYear, isThisMonth, isThisHour, lastDayOfYear, addMinutes, addYears, differenceInCalendarYears, differenceInCalendarMonths,
  differenceInCalendarDays,differenceInHours,differenceInMinutes, lastDayOfWeek } from 'date-fns';

@Injectable()
export class DateTimeProvider {

  constructor(
   ) {

  }
  Formate(datetime,string){
    return format(datetime,string);
  }
  AddMinute(datetime,num){
    return addMinutes(datetime,num);
  }
  AddHour(datetime,num){
    return addHours(datetime,num);
  }
  AddDay(datetime,num){
    return addDays(datetime,num);
  }
  AddMonth(datetime,num){
    return addMonths(datetime,num);
  }
  AddYear(datetime,num){
    return addYears(datetime,num);
  }
  GetHour(datetime){
    return getHours(datetime);
  }
  GetDay(datetime){
    return getDate(datetime);
  }
  GetMonth(datetime){
    return getMonth(datetime)+1;
  }
  GetYear(datetime){
    return getYear(datetime);
  }
  //抓第一天
  StartDay(datetime,type){
    if(type=="Y"){
      return addMonths(startOfMonth(datetime),-1*getMonth(datetime));
    }else if(type=="M"){
      return startOfMonth(datetime);
    }else if(type=="W"){
      return startOfISOWeek(datetime);
    }
  }
  //抓最後一天
  LastDay(datetime,type){
    if(type=="Y"){
      return lastDayOfYear(datetime);
    }else if(type=="M"){
      return lastDayOfMonth(datetime);
    }else if(type=="W"){
      return lastDayOfWeek(datetime);
    }
  }
  //抓時間差異
  DiffDay(StartDateTime,EndDateTime,type){
    if(type=="Y"){
      return differenceInCalendarYears(EndDateTime,StartDateTime);
    }else if(type=="M"){
      return differenceInCalendarMonths(EndDateTime,StartDateTime);
    }
    else if(type=="D"){
      return differenceInCalendarDays(EndDateTime,StartDateTime);
    }
    else if(type=="H"){
      return differenceInHours(EndDateTime,StartDateTime);
    }
    else if(type=="MIN"){
      return differenceInMinutes(EndDateTime,StartDateTime);
    }
    else{
      return null;
    }
  }
  //是否為今日
  isNowDate(datetime,type){
    if(type=="Y"){
      return isThisYear(datetime);
    }else if(type=="M"){
      return isThisMonth(datetime);
    }else if(type=="D"){
      return isToday(datetime);
    }else if(type=="H"){
      return isThisHour(datetime);
    }else{
      return null;
    }
  }
}
