import { Component, OnInit, Inject } from '@angular/core';
import { ActoolsCtrlService } from '../../DataService/ActoolsCtrl.service';
import { SystemService } from '../../DataService/System.service';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router} from '@angular/router';
import { Permissions } from '../../DataService/User.service';


@Component({
  selector: 'app-Schedule',
  templateUrl: './Schedule.component.html',
  //styleUrls: ['./Schedule.component.css'],
  providers: [ActoolsCtrlService, SystemService,Permissions, MyCommon]
})
export class ScheduleComponent implements OnInit {

  //系統資料
  SYS_Source: any;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public SYS_ID: any = '-1';
  public ModeType: any = '-1';
  
  private pageSize: number = 10;
  private skip: number = 0;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;
  public pageable:any="{numeric: false,previousNext: false,messages: {display: 'Showing {2} data items'}}";
  public SelectItem;
  constructor( @Inject(ActoolsCtrlService) private ACTools_Ctrl: ActoolsCtrlService,
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router) { }
  ngOnInit() {
    
    
      //判斷頁面權限
      let sbody = {
        UrlList:"IOCtrlSettingView,IOCtrlSettingAdd,IOCtrlSettingEdit,IOCtrlSettingDelete",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
            if(Site.path=="IOCtrlSettingView")
              this.ViewPermissions=true;
            if(Site.path=="IOCtrlSettingAdd")
              this.AddPermissions=true;
            if(Site.path=="IOCtrlSettingEdit")
              this.EditPermissions=true;
            if(Site.path=="IOCtrlSettingDelete")
              this.DeletePermissions=true;
          } 
          if(this.EditPermissions){
            this.splitbutton_data.push(
            {
              text: '修改',
              iconClass: 'fa fa-pencil-square-o',
              click: () => {this.router.navigate(['/pages/ScheduleEdit', this.SelectItem.E_ID]); }
            });
          }
          if(this.DeletePermissions){
            this.splitbutton_data.push(
            {
              text: '刪除',
              iconClass: 'fa fa-trash-o',
              click: () => { this.Delete(this.SelectItem);}
            });
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );

    this.GetSysData();
    this.GetData();
  }

    public splitbutton_data: Array<any> = [];

    public onPaste(obj): void {
      this.SelectItem=obj;
    }


  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }
  //取得系統資料
  GetSysData() {
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.SYS_Source = obj;
      },
      err => { console.log(err); }
    );
  }
  //取得資料
  GetData() {
    var StrWhere = " where 1=1 ";
    if (String(this.SYS_ID) != "-1")
      StrWhere+=" and IO_Info.SYS_ID=" + this.SYS_ID;

    if (String(this.ModeType) != "-1")
      StrWhere+=" and ModeType=" + this.ModeType;

    if(localStorage.getItem('DevID')!=""&&localStorage.getItem('DevID')!=null)
    {
      StrWhere +=" and concat(IO_MAC,'-S',IO_S)= '"+localStorage.getItem('DevID')+"'";
      StrWhere +=" and ModeType= '"+localStorage.getItem('ModeType')+"'";
      this.ModeType=localStorage.getItem('ModeType');
      localStorage.setItem('ModeType','');
      localStorage.setItem('DevID','');
    }
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.ACTools_Ctrl.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data: orderBy(obj, this.sort),
          total: obj.length
        };
      },
      err => { }
    );
  }
  //系統選擇選取
  SYSChange() {
    this.skip=0;
    this.GetData();
  }
  //刪除資料
  Delete(del: any) {
    if (confirm("確定要刪除嗎?")) {
      let sbody = {
        E_ID: del.E_ID,
        Key:localStorage.getItem("Token")
      };
      var stralert = this.ACTools_Ctrl.DeleteData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
           this.MyCommon.CheckToken(obj);
           this.GetData(); },
        err => alert('刪除失敗'),
        () => { }
      );
      }
    }
    onOther(date: any) {
      this.router.navigate(['/pages/ScheduleConditionEdit', date.E_ID]); 

    }
}