import { NgModule,NO_ERRORS_SCHEMA,LOCALE_ID  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WebCommonModule } from '../common.module';
import { routing } from './pages.routing';
import { PagesComponent } from './pages.component';

import { DefaultComponent } from './Default/Default.component';
import { PAHomeComponent } from './PowerAnalysis';
import { MeterDemandRealComponent,MeterDemandSearch1Component,MeterDemandSearch2Component } from './MeterDemand';
import { RealTimeComponent,RealTimeSettingComponent } from './RealTime';
import { HistoryComponent,PowerSearchComponent,EventLogComponent} from './History';
import { ReportComponent,ReportSettingComponent,ReportSetting_AddComponent,ReportSetting_EditComponent,
         ReportDownloadComponent,ReportMaintainComponent} from './Report';
import { ScheduleComponent,ScheduleAddComponent,ScheduleEditComponent,ScheduleConditionEditComponent} from './Schedule';
import { SystemSettingComponent,SystemSetting_AddComponent,SystemSetting_EditComponent} from './SystemSetting';
import { PAGroupSettingComponent,PAGroupSettingAddComponent,PAGroupSettingEditComponent,
         PATypeSettingComponent,PATypeSettingAddComponent,PATypeSettingEditComponent} from './PowerAnalysisSetting';
import { DevSettingComponent,DevSetting_EditComponent} from './DevSetting';
import { IOSettingComponent,IOSetting_AddComponent,IOSetting_EditComponent,IOImportComponent} from './IOSetting';
import { AlarmSettingComponent,AlarmSetting_EditComponent} from './AlarmSetting';
import { DisconnAlarmSettingComponent,DisconnAlarmSetting_EditComponent} from './DisconnAlarmSetting';
import { MeterDemandAlarmSettingComponent,MeterDemandAlarmSetting_AddComponent,MeterDemandAlarmSetting_EditComponent} from './MeterDemandAlarmSetting';
import { MeterDemandCtrlComponent,MeterDemandCtrlSettingComponent} from './MeterDemandCtrl';

import { LineComponent,Line_AddComponent,Line_EditComponent} from './Line';
import { UserComponent,User_AddComponent,User_EditComponent} from './User/User';
import { UserGroupComponent,UserGroup_AddComponent,UserGroup_EditComponent} from './User/UserGroup';
import { SystemPageComponent,RealConfigComponent } from './WebSetting';
import { ModbusTCPComponent,RS485Component,SQLDeviceComponent,ZigbeeDeviceComponent,ZigbeeSettingComponent} from './SensorSetting';
import { IOCtrlSettingComponent,IOCtrlSetting_AddComponent,IOCtrlSetting_EditComponent} from './IOCtrlSetting';
import { DemandCtrlSettingComponent,DemandCtrlSetting_AddComponent,DemandCtrlSetting_EditComponent} from './DemandCtrlSetting';


import { EACComponent, EACSettingsComponent, EACPopupComponent ,EACChartComponent} from './EAC';

import { ScheduleACComponent,ScheduleAC_AddComponent,ScheduleAC_EditComponent} from './ScheduleAC';

/*頁面 */
const ProjectPage = [
  DefaultComponent,
  PAHomeComponent,
  MeterDemandRealComponent,
  MeterDemandSearch1Component,
  MeterDemandSearch2Component,
  RealTimeComponent,
  RealTimeSettingComponent,
  HistoryComponent,
  PowerSearchComponent,
  EventLogComponent,
  ReportComponent,
  ReportSettingComponent,
  ReportSetting_AddComponent,
  ReportSetting_EditComponent,
  ReportDownloadComponent,
  ReportMaintainComponent,
  ScheduleComponent,
  ScheduleAddComponent,
  ScheduleEditComponent,
  ScheduleConditionEditComponent,
  SystemSettingComponent,
  SystemSetting_AddComponent,
  SystemSetting_EditComponent,
  PAGroupSettingComponent,
  PAGroupSettingAddComponent,
  PAGroupSettingEditComponent,
  PATypeSettingComponent,
  PATypeSettingAddComponent,
  PATypeSettingEditComponent,
  DevSettingComponent,
  DevSetting_EditComponent,
  IOSettingComponent,
  IOSetting_AddComponent,
  IOSetting_EditComponent,
  IOImportComponent,
  AlarmSettingComponent,
  AlarmSetting_EditComponent,
  DisconnAlarmSettingComponent,
  DisconnAlarmSetting_EditComponent,
  MeterDemandAlarmSettingComponent,
  MeterDemandAlarmSetting_AddComponent,
  MeterDemandAlarmSetting_EditComponent,
  MeterDemandCtrlComponent,
  MeterDemandCtrlSettingComponent,
  LineComponent,
  Line_AddComponent,
  Line_EditComponent,
  UserComponent,
  User_AddComponent,
  User_EditComponent,
  UserGroupComponent,
  UserGroup_AddComponent,
  UserGroup_EditComponent,
  SystemPageComponent,
  RealConfigComponent,
  ModbusTCPComponent,
  RS485Component,
  SQLDeviceComponent,
  ZigbeeDeviceComponent,
  ZigbeeSettingComponent,
  IOCtrlSettingComponent,
  IOCtrlSetting_AddComponent,
  IOCtrlSetting_EditComponent,
  DemandCtrlSettingComponent,
  DemandCtrlSetting_AddComponent,
  DemandCtrlSetting_EditComponent,

  EACComponent,
  EACSettingsComponent,
  EACPopupComponent,
  EACChartComponent,

  ScheduleACComponent,
  ScheduleAC_AddComponent,
  ScheduleAC_EditComponent

];


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    routing,
    WebCommonModule
  ],
  providers: [
    //{ provide: LOCALE_ID, useValue: 'zh-Hant' }
  ],
  declarations: [
    PagesComponent,
    ...ProjectPage,
  ],
  entryComponents: [
    RealTimeSettingComponent,EACPopupComponent,EACChartComponent,MeterDemandCtrlSettingComponent
  ],
  schemas:      [ NO_ERRORS_SCHEMA ]
  })
export class PagesModule { }
