import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';


import { DefaultComponent } from './Default/Default.component';
import { PAHomeComponent } from './PowerAnalysis';
import { MeterDemandRealComponent,MeterDemandSearch1Component,MeterDemandSearch2Component } from './MeterDemand';
import { RealTimeComponent} from './RealTime';
import { HistoryComponent,PowerSearchComponent,EventLogComponent} from './History';
import { ReportComponent,ReportSettingComponent,ReportSetting_AddComponent,ReportSetting_EditComponent,
         ReportDownloadComponent,ReportMaintainComponent} from './Report';
import { ScheduleComponent,ScheduleAddComponent,ScheduleEditComponent,ScheduleConditionEditComponent} from './Schedule';
import { SystemSettingComponent,SystemSetting_AddComponent,SystemSetting_EditComponent} from './SystemSetting';
import { DevSettingComponent,DevSetting_EditComponent} from './DevSetting';
import { IOSettingComponent,IOSetting_AddComponent,IOSetting_EditComponent,IOImportComponent} from './IOSetting';
import { PATypeSettingComponent,PATypeSettingAddComponent,PATypeSettingEditComponent} from './PowerAnalysisSetting/PATypeSetting';
import { PAGroupSettingComponent,PAGroupSettingAddComponent,PAGroupSettingEditComponent} from './PowerAnalysisSetting/PAGroupSetting';
import { AlarmSettingComponent,AlarmSetting_EditComponent} from './AlarmSetting';
import { DisconnAlarmSettingComponent,DisconnAlarmSetting_EditComponent} from './DisconnAlarmSetting';
import { MeterDemandAlarmSettingComponent,MeterDemandAlarmSetting_AddComponent,MeterDemandAlarmSetting_EditComponent} from './MeterDemandAlarmSetting';
import { MeterDemandCtrlComponent} from './MeterDemandCtrl';
import { LineComponent,Line_AddComponent,Line_EditComponent} from './Line';
import { UserComponent,User_AddComponent,User_EditComponent} from './User/User';
import { UserGroupComponent,UserGroup_AddComponent,UserGroup_EditComponent} from './User/UserGroup';
import { SystemPageComponent,RealConfigComponent} from './WebSetting';
import { ModbusTCPComponent,RS485Component,SQLDeviceComponent,ZigbeeDeviceComponent,ZigbeeSettingComponent} from './SensorSetting';
import { IOCtrlSettingComponent,IOCtrlSetting_AddComponent,IOCtrlSetting_EditComponent} from './IOCtrlSetting';
import { DemandCtrlSettingComponent,DemandCtrlSetting_AddComponent,DemandCtrlSetting_EditComponent} from './DemandCtrlSetting';
import { ScheduleACComponent,ScheduleAC_AddComponent,ScheduleAC_EditComponent} from './ScheduleAC';


import { EACComponent} from './EAC';
/*iBAS*/

export const routes: Routes = [
  {
    path: 'pages', 
    component: PagesComponent,
    children: [
      { path: '', redirectTo: 'default', pathMatch: 'full' },
      { path: 'default', component: DefaultComponent},
      { path: 'PAHome', component: PAHomeComponent},
      { path: 'MeterDemandReal', component: MeterDemandRealComponent},
      { path: 'MeterDemandSearch1', component: MeterDemandSearch1Component},
      { path: 'MeterDemandSearch2', component: MeterDemandSearch2Component},
      { path: 'RealTime', component: RealTimeComponent},
      { path: 'RealTime/:id', component: RealTimeComponent},
      { path: 'History', component: HistoryComponent},
      { path: 'PowerSearch', component: PowerSearchComponent},
      { path: 'EventLog', component: EventLogComponent},
      { path: 'Report', component: ReportComponent},
      { path: 'ReportDownload', component: ReportDownloadComponent},
      { path: 'ReportMaintain', component: ReportMaintainComponent},
      { path: 'ReportSetting', component: ReportSettingComponent},
      { path: 'ReportSettingAdd', component: ReportSetting_AddComponent},
      { path: 'ReportSettingEdit/:id', component: ReportSetting_EditComponent},
      { path: 'Schedule', component: ScheduleComponent},
      { path: 'ScheduleAdd', component: ScheduleAddComponent}, 
      { path: 'ScheduleEdit/:id', component: ScheduleEditComponent},
      { path: 'ScheduleConditionEdit/:id', component: ScheduleConditionEditComponent},
      { path: 'SystemSetting', component: SystemSettingComponent},
      { path: 'SystemSettingAdd', component: SystemSetting_AddComponent},
      { path: 'SystemSettingEdit/:id', component: SystemSetting_EditComponent},
      { path: 'DevSetting', component: DevSettingComponent},
      { path: 'DevSettingEdit/:id', component: DevSetting_EditComponent},
      { path: 'IOSetting', component: IOSettingComponent},
      { path: 'IOSettingAdd', component: IOSetting_AddComponent},
      { path: 'IOSettingEdit/:id', component: IOSetting_EditComponent},
      { path: 'IOImport', component: IOImportComponent},
      { path: 'PAGroupSetting', component: PAGroupSettingComponent},
      { path: 'PAGroupSettingAdd', component: PAGroupSettingAddComponent},
      { path: 'PAGroupSettingEdit/:id', component: PAGroupSettingEditComponent},
      { path: 'PATypeSetting', component: PATypeSettingComponent},
      { path: 'PATypeSettingAdd', component: PATypeSettingAddComponent},
      { path: 'PATypeSettingEdit/:id', component: PATypeSettingEditComponent},
      { path: 'AlarmSetting', component: AlarmSettingComponent},
      { path: 'AlarmSettingEdit/:id', component: AlarmSetting_EditComponent},
      { path: 'DisconnAlarmSetting', component: DisconnAlarmSettingComponent},
      { path: 'DisconnAlarmSettingEdit/:id', component: DisconnAlarmSetting_EditComponent},
      { path: 'MeterDemandAlarmSetting', component: MeterDemandAlarmSettingComponent},
      { path: 'MeterDemandAlarmSettingAdd', component: MeterDemandAlarmSetting_AddComponent},
      { path: 'MeterDemandAlarmSettingEdit/:id', component: MeterDemandAlarmSetting_EditComponent},
      { path: 'MeterDemandCtrl', component: MeterDemandCtrlComponent},
      { path: 'Line', component: LineComponent},
      { path: 'LineAdd', component: Line_AddComponent},
      { path: 'LineEdit/:id', component: Line_EditComponent},
      { path: 'User', component: UserComponent},
      { path: 'UserAdd', component: User_AddComponent},
      { path: 'UserEdit/:id', component: User_EditComponent},
      { path: 'UserGroup', component: UserGroupComponent},
      { path: 'UserGroupAdd', component: UserGroup_AddComponent},
      { path: 'UserGroupEdit/:id', component: UserGroup_EditComponent},
      { path: 'SystemPage', component: SystemPageComponent},

      { path: 'RealConfig', component: RealConfigComponent},
      { path: 'RS485', component: RS485Component},
      { path: 'ZigbeeSetting', component: ZigbeeSettingComponent},
      { path: 'ZigbeeDevice', component: ZigbeeDeviceComponent},
      { path: 'ModbusTCP', component: ModbusTCPComponent},
      { path: 'SQLDevice', component: SQLDeviceComponent},
      { path: 'IOCtrlSetting', component: IOCtrlSettingComponent},
      { path: 'IOCtrlSettingAdd', component: IOCtrlSetting_AddComponent},
      { path: 'IOCtrlSettingEdit/:id', component: IOCtrlSetting_EditComponent},
      { path: 'DemandCtrlSetting', component: DemandCtrlSettingComponent},
      { path: 'DemandCtrlSettingAdd', component: DemandCtrlSetting_AddComponent},
      { path: 'DemandCtrlSettingEdit/:id', component: DemandCtrlSetting_EditComponent},

      { path: 'EAC', component: EACComponent},

      { path: 'ScheduleAC', component: ScheduleACComponent},
      { path: 'ScheduleAC_Add', component: ScheduleAC_AddComponent},
      { path: 'ScheduleAC_Edit/:id', component: ScheduleAC_EditComponent},
    ]
  }
];

export const routing = RouterModule.forChild(routes);
