import { Component, OnInit, Inject } from '@angular/core';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { PowerAnalysisTypeService, PowerAnalysisService } from '../../../DataService/PowerAnalysis.service';
import { Observable } from 'rxjs/Rx';
import * as $ from 'jquery';

@Component({
  selector: 'app-PAHome',
  templateUrl: './PAHome.component.html',
  styleUrls: ['./PAHome.component.scss'],
  providers: [MyCommon, PowerAnalysisService, PowerAnalysisTypeService]
})
export class PAHomeComponent implements OnInit {

  Title_info: any = "日用電-每小時資訊"; //標題資訊
  Title_total: any = "日用電比例"; //標題資訊

  collection_RealTime = [] //從WebAPI回傳的資料->即時資訊

  collection_pie = []; //從WebAPI回傳的資料->用電比例

  collection_column = []; //從WebAPI回傳的資料->用電資訊
  collection_column_group_PATypeID: any; //collection_column 將PAType_ID群組
  collection_column_group_DT: any; //collection_column 將DT群組
  collection_column_timebox = []; //時間軸的容器

  collection_line = []; //從WebAPI回傳的資料->用電趨勢
  collection_line_group_PATypeID: any; //collection_line 將PAType_ID群組  
  collection_line_group_DT: any; //collection_line 將DT群組  
  collection_line_timebox = []; //時間軸的容器
  //即時資訊
  RealKw: string = "N/A";
  DemandKw: string = "N/A";
  DaykWh: string = "N/A";
  MonthkWh: string = "N/A";
  YearkWh: string = "N/A";

  line_loading: boolean = true;

  constructor(
    @Inject(MyCommon) private MyCommon: MyCommon,
    @Inject(PowerAnalysisService) private PowerAnalysisData: PowerAnalysisService,
    @Inject(PowerAnalysisTypeService) private PATypeData: PowerAnalysisTypeService
  ) { }
  public pieData: any = []

  Bar_series: any;
  public BarData: any = [];

  Line_series: any;
  public LineData: any = [];

  public TypeData: any = [];

  //上方
  DateTypeA: string = "2";
  SelectYear: string = String(new Date().getFullYear());
  public SelectYear_DS: any = [];
  SelectMonth: string = String(new Date().getMonth() + 1);
  public SelectMonth_DS: any = [];
  SelectDay: string = String(new Date().getDate());
  public SelectDay_DS: any = [];
  //下方DatePick設定
  DateTypeB: string = "4";
  StartDate: Date = new Date('2017/01/01 00:00');
  EndDate: Date = new Date('2017/01/01 00:00');

  ChartStep:Number=8;
  SetChartStep:Number=8;

  ngOnInit() {
    //載入預設日期
    var DiffDay = (24 * 60 * 60 * 1000) * -1;
    var sDiffDate = new Date(new Date().getTime() + DiffDay);

    //下拉選單設定
    this.SelectYear_DS = [];
    this.SelectMonth_DS = [];
    this.SelectDay_DS = [];
    for (let y = 2; y >= 0; y--) {
      this.SelectYear_DS.push({ id: String(new Date().getFullYear() - y), name: String(new Date().getFullYear() - y) + "年" });
    }
    this.SelectYear = String(sDiffDate.getFullYear());

    for (let m = 1; m <= 12; m++) {
      this.SelectMonth_DS.push({ id: String(m), name: String(m) + "月" });
    }
    this.SelectMonth = String(sDiffDate.getMonth() + 1);
    //取最後一天
    let LastDay=new Date(Number(this.SelectYear), Number(this.SelectMonth),1);
    LastDay= new Date(LastDay.getTime() + ((24 * 60 * 60 * 1000) * -1));
    //設定最一天的值
    for (let d = 1; d <= LastDay.getDate(); d++) {
      this.SelectDay_DS.push({ id: String(d), name: String(d) + "日" });
    }
    this.SelectDay = String(sDiffDate.getDate());




  
    //載入預設日期
    var sNowDate = new Date();
    this.StartDate = new Date(sNowDate.getFullYear() + '/' + String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
    //單位為一天的時間
    var DiffDay = (24 * 60 * 60 * 1000) * 0;
    var sDiffDate = new Date(sNowDate.getTime() + DiffDay);
    this.EndDate = new Date(sDiffDate.getFullYear() + '/' + String(Number(sDiffDate.getMonth()) + 1) + '/' + sDiffDate.getDate() + ' 23:59');


    //this.Line_series = groupBy(this.LineData, [{ field: "PAType_ID"}]);
    /*let Realtimer = Observable.timer(1000,1000);
    var UpdateTime=this.MyCommon.GetUpdateTime();
    Realtimer.subscribe(t=> {
      if((t % UpdateTime)==0){

      }
    });*/
    this.SetType();
    this.SetDataRealTime();
    this.SetDataPie();
    this.SetDataBar();
    this.SetDataLine();
  }

  //取得RealTime資料
  SetType() {
    var StrWhere = " ";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key: localStorage.getItem("Token")
    };
    this.PATypeData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.TypeData = obj;
      },
      err => { console.log(err); }
    );
  }

  // 用來依照value也就是PAType_ID傳入0 1 來取得TypeData.PAName 建物總用電或空調系統用
  GetTypeName(value) {
    var filter = this.TypeData.filter(task => task.PAType_ID == value);
    var Name = "N/A";
    if (filter.length > 0) {
      Name = filter[0].PAName;
    }
    return Name;
  }
  GetTypeColor(value) {
    var filter = this.TypeData.filter(task => task.PAType_ID == value);
    var C = "N/A";
    if (filter.length > 0) {
      C = filter[0].Color;
    }
    return C;
  }
  public DateFormate = (e: any) => {
    var Label="";
    try {
      var D = new Date(e.value);
      Label=this.MyCommon.FormateDate2(D) + "\r\n" + this.MyCommon.FormateTime2(D);
    } catch (error) {
      Label="";
    }
    
    return Label;
  }

  //取得RealTime資料
  SetDataRealTime() {
    let sbody = {
      Key: localStorage.getItem("Token")
    };
    this.PowerAnalysisData.GetDataRealTime(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.RealKw = obj[0].RealKw;
        this.DemandKw = obj[0].DemandKw;
        this.DaykWh = obj[0].DaykWh;
        this.MonthkWh = obj[0].MonthkWh;
        this.YearkWh = obj[0].YearkWh;
       
      },
      err => { console.log(err); }
    );
  }


  //取得Pie資料
  SetDataPie() {
    let sbody = {
      YMD: this.DateTypeA.toString(),
      Year: this.SelectYear.toString(),
      Month: this.SelectMonth.toString(),
      Day: this.SelectDay.toString(),
      Key: localStorage.getItem("Token")
    };
    this.PowerAnalysisData.GetDataPie(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.collection_pie = obj;
      },
      err => { console.log(err); }
    );
  }

  //取得Bar資料
  SetDataBar() {
    let sbody = {
      YMD: this.DateTypeA.toString(),
      Year: this.SelectYear.toString(),
      Month: this.SelectMonth.toString(),
      Day: this.SelectDay.toString(),
      Key: localStorage.getItem("Token")
    };
    this.PowerAnalysisData.GetDataBar(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.collection_column = obj;
        this.collection_column_group_PATypeID = groupBy(this.collection_column, [{ field: "PAType_ID" }]);
        this.collection_column_group_DT = groupBy(this.collection_column, [{ field: "DT" }]);
        this.collection_column_timebox = []; //陣列清空

        //DateTypeA=0 點擊年 DateTypeA=1 點擊月 DateTypeA=2 點擊日  
        for (let loop = 0; loop < this.collection_column_group_DT.length; loop++) {
          if (this.DateTypeA == '2') {
            this.collection_column_timebox.push(this.collection_column_group_DT[loop].value.substring(11, 13));
            this.Title_info = "日用電-每小時資訊";
            this.Title_total = "日用電比例";
          } else if (this.DateTypeA == '1') {
            this.collection_column_timebox.push(this.collection_column_group_DT[loop].value.substring(8, 10));
            this.Title_info = "月用電-每日資訊";
            this.Title_total = "月用電比例";
          } else {
            this.collection_column_timebox.push(this.collection_column_group_DT[loop].value.substring(5, 7));
            this.Title_info = "年用電-每月資訊";
            this.Title_total = "年用電比例";
          }
        }
      },
      err => { console.log(err); }
    );
  }

  //取得Line資料
  SetDataLine() {
    this.line_loading=true;
    let sbody = {
      YMD: this.DateTypeB.toString(),
      StartTime: this.MyCommon.FormateDateTime2(this.StartDate),
      EndTime: this.MyCommon.FormateDateTime2(this.EndDate),
     
      Key: localStorage.getItem("Token")
    };

    this.PowerAnalysisData.GetDataLine(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.line_loading=false;
        this.collection_line = obj;
        this.collection_line_group_PATypeID = groupBy(this.collection_line, [{ field: "PAType_ID" }]);
        this.collection_line_group_DT = groupBy(this.collection_line, [{ field: "DT" }]);
        this.collection_line_timebox = []; //陣列清空

        //DateTypeB=0 點擊年 DateTypeB=1 點擊月 DateTypeB=2 點擊日  DateTypeB=3 點擊時 DateTypeB=4 點擊分 
        for (let loop = 0; loop < this.collection_line_group_DT.length; loop++) {
          if (this.DateTypeB == '4') {
            this.collection_line_timebox.push(this.collection_line_group_DT[loop].value.substring(14, 16));
          } else if (this.DateTypeB == '3') {
            this.collection_line_timebox.push(this.collection_line_group_DT[loop].value.substring(11, 13));
          } else if (this.DateTypeB == '2') {
            this.collection_line_timebox.push(this.collection_line_group_DT[loop].value.substring(8, 10));
          } else if (this.DateTypeB == '1') {
            this.collection_line_timebox.push(this.collection_line_group_DT[loop].value.substring(5, 7));
          } else {
            this.collection_line_timebox.push(this.collection_line_group_DT[loop].value.substring(0, 4));
          }
        }
        //Keep
        this.ChartStep=parseInt(String(this.collection_line_timebox.length/8));
        //實際運用
        this.SetChartStep=this.ChartStep;
      },
      err => { console.log(err); }
    );

  }


  //點擊圓扭或下拉式選單將會再向WebAPI要求資料
  SelectDate(DateType:string) {
    if(DateType=="Year"){
      this.SelectMonth="1";
      this.SelectDay="1";
    }
    if(DateType=="Month"){
      this.SelectDay="1";
    }
    //取最後一天
    let LastDay = new Date(Number(this.SelectYear), Number(this.SelectMonth), 1);
    LastDay = new Date(LastDay.getTime() + ((24 * 60 * 60 * 1000) * -1));
    this.SelectDay_DS=[];
    //設定最一天的值
    for (let d = 1; d <= LastDay.getDate(); d++) {
      this.SelectDay_DS.push({ id: String(d), name: String(d) + "日" });
    }
    
    this.onChangeDate();
  }

  onChangeDate() {
    this.SetDataPie();
    this.SetDataBar();
  }
  //點擊查詢按鈕將會再向WebAPI要求資料
  onSearch() {
    this.SetDataLine();
  }
  onResize(event) {
    if(event.target.innerWidth<=1600){
      this.SetChartStep=parseInt(String(this.collection_line_timebox.length/8));
    }
    if(event.target.innerWidth<=754){
      this.SetChartStep=parseInt(String(this.collection_line_timebox.length/4));
    }
    //console.log(event.target.innerWidth);
  }

}
