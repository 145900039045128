import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable({
  providedIn: 'root'
})
export class EACService {

constructor(@Inject(HttpClient) 
            private http : HttpClient,
            public MyCommon:MyCommon) { }


  GetEACSysData(sbody:any,headers:any=""){
    var url = this.MyCommon.GetAPISPUrl('EACSystem');
      return this.http.post(url,sbody,{headers: headers})
      .map(res=>JSON.parse(JSON.stringify(res)));
  }
  InsertEACSysData(sbody:any,headers:any=""){
    var url = this.MyCommon.GetAPISPUrl('EACSystem_Insert');
    return this.http.post(url,sbody,{headers: headers})
    .map(res=>JSON.parse(JSON.stringify(res)));
  }
  UpdateEACSysData(sbody:any,headers:any=""){
    var url = this.MyCommon.GetAPISPUrl('EACSystem_Update');
    return this.http.put(url,sbody,{headers: headers})
    .map(res=>JSON.parse(JSON.stringify(res)));
  }
  DeleteEACSysData(sbody:any,headers:any=""){
    var sbody;
  var url = this.MyCommon.GetAPISPUrl('EACSystem_Delete');
    return this.http.post(url,sbody,{headers: headers})
    .map(res=>JSON.parse(JSON.stringify(res)));
  }

  
  GetEACSetData(sbody:any,headers:any=""){
    var url = this.MyCommon.GetAPISPUrl('EACSettings');
      return this.http.post(url,sbody,{headers: headers})
      .map(res=>JSON.parse(JSON.stringify(res)));
  }
  InsertEACSetData(sbody:any,headers:any=""){
    var url = this.MyCommon.GetAPISPUrl('EACSettings_Insert');
    return this.http.post(url,sbody,{headers: headers})
    .map(res=>JSON.parse(JSON.stringify(res)));
  }
  UpdateEACSetData(sbody:any,headers:any=""){
    var url = this.MyCommon.GetAPISPUrl('EACSettings_Update');
    return this.http.put(url,sbody,{headers: headers})
    .map(res=>JSON.parse(JSON.stringify(res)));
  }
  DeleteEACSetData(sbody:any,headers:any=""){
    var sbody;
  var url = this.MyCommon.GetAPISPUrl('EACSettings_Delete');
    return this.http.post(url,sbody,{headers: headers})
    .map(res=>JSON.parse(JSON.stringify(res)));
  }
  assignValues(target:any,sorce:any){
    Object.assign(target,sorce);
  }
}
