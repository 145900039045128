import { Component, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EACSettingsColumn } from '../EACSettingsColumn'
import { RealTimeService } from '../../../DataService/RealTime.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router } from '@angular/router';
import { EACService } from '../../../DataService/EAC.service';
import { HistoryService } from '../../../DataService';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { setTime } from '@progress/kendo-angular-dateinputs/dist/es2015/util';

@Component({
  selector: 'app-EACChart',
  templateUrl: './EACChart.component.html',
  providers: [RealTimeService, MyCommon,EACService,HistoryService]
})
export class EACChartComponent implements OnInit {
  //預設欄位
  public SetCtrlType:number;
  PopType:number;
  EACSettingsColumn = new EACSettingsColumn('', '', '', '', '', '', '', '', '', '', '');
  Title:String="";
  //判斷是否能夠控制數值
  IsDisabled:any=true;
  constructor(private activeModal: NgbActiveModal,
              @Inject(EACService) private EAC: EACService,
              @Inject(MyCommon) private MyCommon: MyCommon,          
              private router: Router,
              private HistoryData:HistoryService
            ) { }

  ngOnInit() {
    this.showChart();
  }
  //隱藏Popup
  back() {
    this.activeModal.close();
  }
  //儲存設定
  IOList:any=[];
  data:any=[];
  Status:any=[];
  SetStep:any=[];
  onCtrl() {
      //讀出IOList
      this.IOList=JSON.parse(localStorage.getItem("IOList"));
      var IO_Data=this.EACSettingsColumn.MAC+'-S'+this.EACSettingsColumn.SID;
      var DisplayName=this.EACSettingsColumn.SetName;
      //判斷是否重複
      if (this.IOList != null){
        for (var item of this.IOList){
          if (item.id==IO_Data){
            alert('已完成資料選取');
            this.activeModal.close();
            return false;
          }
        }
      }else{
        this.IOList=[];
      }
      this.IOList.push({'id': IO_Data,'name': DisplayName});
      localStorage.setItem("IOList",JSON.stringify(this.IOList));
      alert('已完成資料選取');
    this.activeModal.close();
  }

   //顯示圖表
   showChart() {
      //組查詢IO
      let Search_IO =this.EACSettingsColumn.MAC+'-S'+this.EACSettingsColumn.SID;
      let interval=1;
      let sbody = {
        IO_MAC_S: Search_IO,
        StartTime:this.MyCommon.FormateDateTime(new Date((new Date().getTime()-60*60*1000))),
        EndTime: this.MyCommon.FormateDateTime(new Date()),
        IntervalMinute: 1,
        Key:localStorage.getItem("Token")
      };
      this.Status="查詢中";
      this.data="";
      this.HistoryData.GetHistoryChart(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.data = obj;
          this.Chart();
          this.Status="";
        },
        err => { }
      );

  }
SeriesData:any=[];
series:any=[];
Chart(){
  this.SeriesData=[];


  for (var i = 0; i < this.data.length; i++) {
  
    var labeleList=this.data[i]["DT"].split(' ');
    var labele=labeleList[0]+"\n"+labeleList[1];
    var IO_ID=this.EACSettingsColumn.MAC+'-S'+this.EACSettingsColumn.SID;
    this.SeriesData.push({ interval: labele, service: IO_ID,value: IO_ID});
  }
  
   this.series = groupBy(this.SeriesData, [{ field: "service"}]);
   //將Legend名稱轉成Io名稱
   var i=0;
   for (var item of this.series){
    item.value=this.Title;
    i++;
   }

  //計算lables的step
  this.SetStep=Math.round(this.data.length/6);

}


 
}
