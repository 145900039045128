import { Component,OnInit } from '@angular/core';


@Component({
  selector: 'app-pages',
  templateUrl: './ibas_pages.component.html',
  styleUrls: ['./ibas_pages.component.scss'],
  providers: []
})
export class iBas_PagesComponent implements OnInit {

  constructor( 
  ) { }

  ngOnInit() {
  }

}
