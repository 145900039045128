import { Component, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router } from '@angular/router';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { IBas_ObjectAttribService,IBas_IOAlarmService } from '../../../DataService';


@Component({
  selector: 'app-SystemLayoutPopup',
  templateUrl: './SystemLayoutPopup.component.html',
  providers: [MyCommon,IBas_ObjectAttribService,IBas_IOAlarmService]
})
export class SystemLayoutPopupComponent implements OnInit {
  //預設欄位
  Data_MAC_S:string;
  IOType:string;
  AttribData:string;
  Title:string;
  IOAlarm_Data:any=[];

  MAC_ID:string;
  S_Num:string;
  AO_MOA:any="2";
  AO_C_Value:any="0";
  DO_MOA:any="2";
  DO_C_Value:any="0";
  SET_MOA:any="2";
  SET_S_Value:any="0";

  AI_HH_Check:any=false;
  AI_HH:any="";
  AI_HH_DBand:any="";
  AI_LH_Check:any=false;
  AI_LH:any="";
  AI_LH_DBand:any="";

  
  constructor(private activeModal: NgbActiveModal,
    private ObjectAttrib: IBas_ObjectAttribService,
    private IOAlarm: IBas_IOAlarmService,
    private MyCommon: MyCommon,
              private router: Router
            ) { }

  ngOnInit() {
    if(this.AttribData.length>0){
      if(this.AttribData[0]["Tag_Name"]!=null){
        this.Title=this.AttribData[0]["S_Name"]+'-'+this.AttribData[0]["Tag_Name"].replace("SID","");
      }else if(this.AttribData[0]["Attrib_Name_TC"]!=null){
        this.Title=this.AttribData[0]["S_Name"]+'-'+this.AttribData[0]["Attrib_Name_TC"].replace("SID","");
      }else if(this.AttribData[0]["Attrib_Name"]!=null){
        this.Title=this.AttribData[0]["S_Name"]+'-'+this.AttribData[0]["Attrib_Name"].replace("SID","");
      }

      this.MAC_ID=this.AttribData[0]["MAC_ID"];
      this.S_Num=this.AttribData[0]["S_Num"];
      if(this.IOType=="AO"){
        this.AO_MOA=String(this.AttribData[0]["MOA"]);
        this.AO_C_Value=this.AttribData[0]["C_Value"];
      }
      if(this.IOType=="DO"){
        this.DO_MOA=String(this.AttribData[0]["MOA"]);
        this.DO_C_Value=this.AttribData[0]["C_Value"];
      }
      if(this.IOType=="AI"){
        //console.log(this.IOAlarm_Data);
        if(this.IOAlarm_Data.length>0){
          
          let FilterData=this.IOAlarm_Data.filter(d=>d.MAC_S==this.MAC_ID+"-"+this.S_Num);
          
          if(FilterData.length>0){
            if(String(FilterData[0].Alarm_UpVal) !='9999' && String(FilterData[0].Alarm_UpVal_DB)!='9999'){
              this.AI_HH=FilterData[0].Alarm_UpVal;
              this.AI_HH_DBand=FilterData[0].Alarm_UpVal_DB;
              this.AI_HH_Check=true;
            }
            if(String(FilterData[0].Alarm_DownVal)!='-9999'&&String(FilterData[0].Alarm_DownVal_DB)!='-9999'){
              this.AI_LH=FilterData[0].Alarm_DownVal;
              this.AI_LH_DBand=FilterData[0].Alarm_DownVal_DB;
              this.AI_LH_Check=true;
            }
          }
        }
      }
      if(this.IOType=="SET"){
        this.SET_MOA=String(this.AttribData[0]["MOA"]);
        this.SET_S_Value=this.AttribData[0]["S_Value"];
      }
    }
   
   
    }
    //儲存設定
    IOList:any=[];
    data:any=[];
    Status:any=[];
    SetStep:any=[];

    SeriesData:any=[];
    series:any=[];
    onAI_HH_Change(check){
      this.AI_HH_Check=check;
      if(check){
        this.AI_HH="0";
        this.AI_HH_DBand="0";
      }else{
        this.AI_HH="";
        this.AI_HH_DBand="";
      }
    }
    onAI_LH_Change(check){
      this.AI_LH_Check=check;
      if(check){
        this.AI_LH="0";
        this.AI_LH_DBand="0";
      }else{
        this.AI_LH="";
        this.AI_LH_DBand="";
      }
    }
    //隱藏Popup
    back() {
      this.activeModal.close();
    }
    //儲存設定
    onSave() {
      if(this.IOType=="AI"){
        let Msg="";
        if (this.AI_HH_Check)
        {
            if (this.MyCommon.CheckNumber(this.AI_HH)!=this.AI_HH )
            {
                Msg += "上限警戒值請輸入正確數字!\r\n";
            }
            if (this.MyCommon.CheckNumber(this.AI_HH_DBand)!=this.AI_HH_DBand)
            {
                Msg += "上限警戒Dead-Band值請輸入正確數字!\r\n";
            }
            else if (Number(this.AI_HH_DBand) < 0)
            {
                Msg += "上限警戒Dead-Band值不可為負值!\r\n";
            }
        }
        if (this.AI_LH_Check)
        {
          if (this.MyCommon.CheckNumber(this.AI_LH)!=this.AI_LH )
            {
                Msg += "下限警戒值請輸入正確數字!\r\n";
            }
            if (this.MyCommon.CheckNumber(this.AI_LH_DBand)!=this.AI_LH_DBand)
            {
                Msg += "下限警戒Dead-Band值請輸入正確數字!\r\n";
            }
            else if (Number(this.AI_LH_DBand) < 0)
            {
                Msg += "下限警戒Dead-Band值不可為負值!\r\n";
            }
        }
        if (Msg!="")
        {
          alert(Msg);
          return;
        }

        let sbody = {
          MAC_S:this.MAC_ID+"-"+this.S_Num,
          Alarm_UpVal: this.AI_HH_Check?this.AI_HH:"9999",
          Alarm_UpVal_DB: this.AI_HH_Check?this.AI_HH_DBand:"9999",
          Alarm_DownVal: this.AI_LH_Check?this.AI_LH:"-9999",
          Alarm_DownVal_DB: this.AI_LH_Check?this.AI_LH_DBand:"-9999",
          Key:localStorage.getItem("Token")
        };
        //AI
        this.IOAlarm.UpdateData(sbody, this.MyCommon.SetHeaders()).subscribe(
          (obj) => {
            this.MyCommon.CheckToken(obj);
            this.activeModal.close();
          },
          err => { alert('更新失敗');console.log(err); }
        );
      }
      if(this.IOType=="AO"){
        let sbody = {
          MAC_ID:this.MAC_ID,
          S_Num: this.S_Num,
          MOA: this.AO_MOA,
          C_Value:this.AO_C_Value,
          Key:localStorage.getItem("Token")
        };
        //AI
        this.ObjectAttrib.UpdateDataAO(sbody, this.MyCommon.SetHeaders()).subscribe(
          (obj) => {
            this.MyCommon.CheckToken(obj);
            if(obj.length>0){
              if(obj[0]["Result"]==-1){
                alert('查無此控制設定');
              }
            }
            this.activeModal.close();
          },
          err => { alert('更新失敗');console.log(err); }
        );
      }
      if(this.IOType=="DI"){
        this.activeModal.close();
      }
      if(this.IOType=="DO"){

        let sbody = {
          MAC_ID:this.MAC_ID,
          S_Num: this.S_Num,
          MOA: this.DO_MOA,
          C_Value:this.DO_C_Value,
          Key:localStorage.getItem("Token")
        };
        //AI
        this.ObjectAttrib.UpdateDataDO(sbody, this.MyCommon.SetHeaders()).subscribe(
          (obj) => {
            this.MyCommon.CheckToken(obj);
            if(obj.length>0){
              if(obj[0]["Result"]==-1){
                alert('查無此控制設定');
              }
            }
            this.activeModal.close();
          },
          err => { alert('更新失敗');console.log(err); }
        );
      }
      if(this.IOType=="SET"){
        let sbody = {
          MAC_ID:this.MAC_ID,
          S_Num: this.S_Num,
          MOA: this.SET_MOA,
          S_Value:this.SET_S_Value,
          Key:localStorage.getItem("Token")
        };
        //AI
        this.ObjectAttrib.UpdateDataSET(sbody, this.MyCommon.SetHeaders()).subscribe(
          (obj) => {
            this.MyCommon.CheckToken(obj);
            if(obj.length>0){
              if(obj[0]["Result"]==-1){
                alert('查無此控制設定');
              }
            }
            this.activeModal.close();
          },
          err => { alert('更新失敗');console.log(err); }
        );
      }
    }


 
}
