import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class IBas_ReportService {
        constructor( @Inject(HttpClient)
        private http: HttpClient,
                public MyCommon: MyCommon) { }

        GetReportDownload(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetIBasAPISPUrl('ReportDownload');
                return this.http.post(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
     
}