import { Component, OnInit ,Inject} from '@angular/core';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { iBas_UserGroupService,iBas_Permissions } from '../../../DataService';


@Component({
  selector: 'app-ibas_UserGroup',
  templateUrl: './ibas_UserGroup.component.html',
  //styleUrls: ['./UserGroup.component.css'],
  providers: [iBas_UserGroupService, MyCommon,iBas_Permissions]
})
export class ibas_UserGroupComponent implements OnInit {


public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;


//查詢參數
  Search_Category:any="UGrp_name";
  Search_test:any="";
  Search_Enable:any="-1";

  public SelectItem;

  constructor(
    private UserGroupData: iBas_UserGroupService,
    private PermissionsData: iBas_Permissions,
    private MyCommon: MyCommon,
    private router: Router
  ) { }

ngOnInit() {
        //判斷頁面權限
      let sbody = {
        UrlList:"UserGroupView,UserGroupAdd,UserGroupEdit,UserGroupDelete",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
            for (let Site of obj) {
              if(Site.path=="UserGroupView")
                this.ViewPermissions=true;
              if(Site.path=="UserGroupAdd")
                this.AddPermissions=true;
              if(Site.path=="UserGroupEdit")
                this.EditPermissions=true;
              if(Site.path=="UserGroupDelete")
                this.DeletePermissions=true;
            }
            if(this.EditPermissions){
              this.splitbutton_data.push(
              {
                text: '修改',
                iconClass: 'fa fa-pencil-square-o',
                click: () => {this.router.navigate(['/ibas/UserGroupEdit', this.SelectItem.UGrp_ID]); }
              });
            }
            if(this.DeletePermissions){
              this.splitbutton_data.push(
              {
                text: '刪除',
                iconClass: 'fa fa-trash-o',
                click: () => { this.Delete(this.SelectItem);}
              });
            }
            try{
              this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
            }catch(err){}
          },
          err => {  },
          () => { }
        );
        this.GetData();
  }

  public splitbutton_data: Array<any> = [];

  public onPaste(obj): void {
    this.SelectItem=obj;
  }

 //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    //this.isToken();
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }
  //取得系統資料
  GetData() {
     var StrWhere=" Where 1=1";
    if (this.Search_Enable !="-1"){
      StrWhere +=" AND UGrp_Enabled='"+this.Search_Enable+"'";
    }
    if (this.Search_test !=""){
      StrWhere +=" AND "+this.Search_Category+" Like '%"+this.Search_test+"%'";
    }
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.UserGroupData.GetData(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data:orderBy(obj.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }
  //刪除資料
  Delete(del: any){
    if (confirm("確定要刪除嗎?")) {
      let sbody = {
        UGrp_ID: del.UGrp_ID,
        Key:localStorage.getItem("Token")
      };
      var stralert = this.UserGroupData.DeleteData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => { 
          this.MyCommon.CheckToken(obj);
          this.GetData(); },
        err => alert('刪除失敗'),
        () => { }
      );
      }
  }

   Search(){
     this.skip=0;
    this.GetData();
  }

}