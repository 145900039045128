import { NgModule } from '@angular/core';
import { Routes, RouterModule,NoPreloading } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  //{ path: '**', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'redata',
    loadChildren: 'app/RestoreDataBase/RestoreDataBase.module#RestoreDataBaseModule'
  },
  {
    path: 'reexport',
    loadChildren: 'app/ReportExport/ReportExport.module#ReportExportModule'
  },
  {
    path: 'FirstSetting',
    loadChildren: 'app/FirstSetting/FirstSetting.module#FirstSettingModule'
  },
  { path: 'login',loadChildren: 'app/login/login.module#LoginModule'},
 // { path: 'ibas',loadChildren: 'app/iBAS_Page/ibas_pages.module#iBas_PagesModule'},
 // { path: 'pages',loadChildren: 'app/Page/pages.module#PagesModule'},

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: NoPreloading })],
    exports: [RouterModule]
})
export class AppRoutingModule {}