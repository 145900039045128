import { Component, OnInit } from '@angular/core';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { iBas_Permissions } from '../../../DataService';
import { IBas_ScheduleService,IBas_ObjectService,IBas_ScheduleWSSettingService } from '../../../DataService';
@Component({
  selector: 'app-Chiller_Schedule',
  templateUrl: './Chiller_Schedule.component.html',
  styleUrls: ['./Chiller_Schedule.component.scss'],
  providers: [IBas_ScheduleService,IBas_ObjectService,IBas_ScheduleWSSettingService, iBas_Permissions, MyCommon]
})
export class Chiller_ScheduleComponent implements OnInit {
 //系統資料
 SYS_Source: any;
 public sort: SortDescriptor[] = [];
 public gridView: GridDataResult;
 public WS_MacID: any = '0';
 public Protect_FieldCHW_HighTemp: any = '0';
 public Protect_ChillerAbnormal: any = '0';
 public Protect_ChillerCW_HighTemp: any = '0';

 public DeviceData : any=[];
 public WaterSysData : any=[];

 private pageSize: number = 10;
 private skip: number = 0;

 public ViewPermissions:boolean=false;
 public AddPermissions:boolean=false;
 public EditPermissions:boolean=false;
 public DeletePermissions:boolean=false;
 public pageable:any="{numeric: false,previousNext: false,messages: {display: 'Showing {2} data items'}}";
 public SelectItem;
 constructor(private ScheduleData: IBas_ScheduleService,
   private WSSetting: IBas_ScheduleWSSettingService,
   private Object: IBas_ObjectService,
   private PermissionsData: iBas_Permissions,
   private MyCommon: MyCommon,
   private router: Router,) { }
 ngOnInit() {
   
   
     //判斷頁面權限
     let sbody = {
       UrlList:"ChillerScheduleView,ChillerScheduleAdd,ChillerScheduleEdit,ChillerScheduleDelete",
       Key:localStorage.getItem("Token")
     };

     var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
     stralert.subscribe(
       (obj) => {
         this.MyCommon.CheckToken(obj);
         for (let Site of obj) {
           if(Site.path=="ChillerScheduleView")
             this.ViewPermissions=true;
           if(Site.path=="ChillerScheduleAdd")
             this.AddPermissions=true;
           if(Site.path=="ChillerScheduleEdit")
             this.EditPermissions=true;
           if(Site.path=="ChillerScheduleDelete")
             this.DeletePermissions=true;
         } 
         if(this.EditPermissions){
           this.splitbutton_data.push(
           {
             text: '修改',
             iconClass: 'fa fa-pencil-square-o',
             click: () => {this.router.navigate(['/ibas/ChillerScheduleEdit', this.SelectItem.nID]); }
           });
         }
         if(this.DeletePermissions){
           this.splitbutton_data.push(
           {
             text: '刪除',
             iconClass: 'fa fa-trash-o',
             click: () => { this.Delete(this.SelectItem);}
           });
         }
         try{
           this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
         }catch(err){}
       },
       err => {  },
       () => { }
     );
     this.GetData();
     this.GetObject();
     this.GetWSSetting();
 }
 GetWSSetting(){
  let sbody = {
    TopN: " ",
    StrWhere: " ",
    Sort: " ",
    Key:localStorage.getItem("Token")
  };

  this.WSSetting.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
    (obj) => {
      
      this.MyCommon.CheckToken(obj);
      if(obj.length>0){
        this.WS_MacID=String(obj[0].WS_MacID);
        this.Protect_FieldCHW_HighTemp=String(obj[0].Protect_FieldCHW_HighTemp);
        this.Protect_ChillerAbnormal=String(obj[0].Protect_ChillerAbnormal);
        this.Protect_ChillerCW_HighTemp=String(obj[0].Protect_ChillerCW_HighTemp);
      }
    },
    err => { console.log(err); }
  );
}

 onCheckChange(tag,event){
   let Check="0";
    if(event.target.checked){
      Check="1";
    }
    else{
      Check="0";
    }
   if(tag=="Protect_FieldCHW_HighTemp"){
    this.Protect_FieldCHW_HighTemp=Check;
   }
   if(tag=="Protect_ChillerAbnormal"){
    this.Protect_ChillerAbnormal=Check;
  }
  if(tag=="Protect_ChillerCW_HighTemp"){
    this.Protect_ChillerCW_HighTemp=Check;
  }
 }
 onUpdate_Click(){
  if (confirm("確定要更新嗎?")) {
      this.Update();
  }
 }
 Update(){
  let sbody = {
    "WS_MacID":this.WS_MacID,
    "Protect_FieldCHW_HighTemp":this.Protect_FieldCHW_HighTemp,
    "Protect_ChillerAbnormal":this.Protect_ChillerAbnormal,
    "Protect_ChillerCW_HighTemp":this.Protect_ChillerCW_HighTemp,
    Key:localStorage.getItem("Token")
  };
  let stralert = this.WSSetting.UpdateData(sbody, this.MyCommon.SetHeaders());
  stralert.subscribe(
    (obj) => {
      this.MyCommon.CheckToken(obj);
      alert('已更新');
    },
    err => {  },
    () => { }
  );
 }

  GetObject(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.Object.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        this.DeviceData = obj;
        this.WaterSysData=this.DeviceData.filter(d=>d.Type_ID==100);
      },
      err => { console.log(err); }
    );
  }

   public splitbutton_data: Array<any> = [];

   public onPaste(obj): void {
     this.SelectItem=obj;
   }
  GetWeek(W){
   let Week='';
   let WeekList=['日','一','二','三','四','五','六'];
   for(let i in W.split(',')){
    if(W.split(',')[i]!=null){
      Week+=WeekList[Number(W.split(',')[i])%7]+',';
    }
   }
   return Week.slice(0,Week.length-1);
  }
  GetDevice(DevList){
    let List="";
    DevList=String(DevList);
    if(DevList!=null&&DevList!=""){
      let Device=this.DeviceData.filter(d=>DevList.split(',').indexOf(String(d.MAC_ID))>-1).map(d=>d.S_Name+"("+d.MAC_ID+")");
      List=Device.join(',');
    }
    return List;
  }
 //排序
 sortChange(sort: SortDescriptor[]): void {
   this.sort = sort;
   this.GetData();
 }
 //換頁
 protected pageChange(event: PageChangeEvent): void {
   this.skip = event.skip;
   this.GetData();
 }
 //取得資料
 GetData() {
   var StrWhere = "";
   let sbody = {
     TopN: " ",
     StrWhere: StrWhere,
     Sort: " ",
     Key:localStorage.getItem("Token")
   };

   this.ScheduleData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
     (obj) => {
       this.MyCommon.CheckToken(obj);
       this.gridView = {
         data: orderBy(obj, this.sort),
         total: obj.length
       };
     },
     err => { }
   );
 }
 //刪除資料
 Delete(del: any) {

   if (confirm("確定要刪除嗎?")) {
     let sbody = {
      nID: del.nID,
       Key:localStorage.getItem("Token")
     };
     var stralert = this.ScheduleData.DeleteData(sbody, this.MyCommon.SetHeaders());
     stralert.subscribe(
       (obj) => {
          this.MyCommon.CheckToken(obj);
          this.GetData(); },
       err => alert('刪除失敗'),
       () => { }
     );
   }
   
   }
 ChangeTimeData(Time_Range){
   let Time=Time_Range.split(';');
   let Times=[];
   for(let i in Time){
     if(Time[i]!="")
     Times.push({Time:Time[i].replace(',','~')});
   }
   return Times;
 }

}
