import { Component, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Permissions,iBas_Permissions, RealTimeService, SystemService } from '../../../DataService';
import { MyCommon } from '../../../App_Code/MyCommon';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    providers: [Permissions,iBas_Permissions, RealTimeService, SystemService, MyCommon]
})
export class SidebarComponent {

    isActive: boolean = false;
    showMenu: string = '';
    pushRightClass: string = 'push-right';

    items: any = [];

    constructor(
        private SystemData: SystemService,
        private RealTimeData: RealTimeService,
        private PermissionsData: Permissions,
        private iBas_PermissionsData: iBas_Permissions,
        private MyCommon: MyCommon,
        private translate: TranslateService,
        public router: Router
    ) {
        //this.translate.addLangs(['en', 'fr', 'ur', 'es', 'it', 'fa', 'de']);
        this.translate.setDefaultLang('en');
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|fr|ur|es|it|fa|de/) ? browserLang : 'en');

        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 1280 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });
    }
    ngOnInit() {
        if(localStorage.getItem("system")=="1"){
            this.GetIBasSiteMap();
        }else{
            this.GetSiteMap();
        }
       
    }


    GetSiteMap() {
        let sbody = {
            UrlList: "",
            Key: localStorage.getItem("Token")
        };

        let stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
        stralert.subscribe(
            (obj) => {
                this.MyCommon.CheckToken(obj);
                this.SetSiteMap(obj,"0");
            },
            err => {
                this.MyCommon.loadOut();

            },
            () => { }
        );
    }
    GetIBasSiteMap() {
        let sbody = {
            UrlList: "",
            Key: localStorage.getItem("Token")
        };

        let stralert = this.iBas_PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
        stralert.subscribe(
            (obj) => {
                this.MyCommon.CheckToken(obj);
                this.SetSiteMap(obj,"1");
            },
            err => {
                this.MyCommon.loadOut();

            },
            () => { }
        );
    }
    //設定SiteMap
    SetSiteMap(JsonData: any,SysID) {
        let SiteMap = JsonData;
        let Menu = [];
        let Level1_filter = SiteMap.filter(task => task.level == "1" && task.visible == "1");
       
        for (let i1 in Level1_filter) {
            Menu.push({ title: Level1_filter[i1]["name"], path: Level1_filter[i1]["path"], icon: Level1_filter[i1]["icon"] });
            //第二層
            let ItemLevel2 = [];
            let Level2_filter = SiteMap.filter(task => task.parent_id == Level1_filter[i1].id && task.visible == "1");
            for (let i2 in Level2_filter) {
                ItemLevel2.push({ title: Level2_filter[i2]["name"], path: Level2_filter[i2]["path"], icon: Level2_filter[i2]["icon"] });
                Menu[i1]["children"] = ItemLevel2;
                //第三層
                let ItemLevel3 = [];
                let Level3_filter = SiteMap.filter(task => task.parent_id == Level2_filter[i2].id && task.visible == "1");
                for (let i3 in Level3_filter) {
                    ItemLevel3.push({ title: Level3_filter[i3]["name"], path: Level3_filter[i3]["path"], icon: Level3_filter[i3]["icon"] });
                    ItemLevel2[i2]["children"] = ItemLevel3;
                    //第四層
                    let ItemLevel4 = [];
                    let Level4_filter = SiteMap.filter(task => task.parent_id == Level3_filter[i3].id && task.visible == "1");
                    for (let i4 in Level4_filter) {
                        ItemLevel4.push({ title: Level4_filter[i4]["name"], path: Level4_filter[i4]["path"], icon: Level4_filter[i4]["icon"] });
                        ItemLevel3[i3]["children"] = ItemLevel4;
                    }
                }
            }
        }
        if(SysID!="1"){
            this.SetSystem(Menu);
        }else{
            this.items = Menu;
        }


    }

    //設定系統
    SetSystem(MenuData: any) {

        let SetStatus = false;
        for (let i = 0; i < MenuData.length; i++) {
            if (MenuData[i].path == "RealTime") {
                SetStatus = true;
            }
        }
        let sbody = {
            TopN: " ",
            StrWhere:"",// " where counter>0 ",
            Sort: " ",
            Key: localStorage.getItem("Token")
        };
        if (SetStatus) {
            this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
                (obj) => {
                    this.MyCommon.CheckToken(obj);
                    let SYS_Data = obj;
                    let RealTimeList = [];
                    //組系統資料
                    let SYSData = SYS_Data.filter(task => task.ParentID == "-1");
                    if(SYSData.length>0){
                        for(let i in SYSData){
                            let Lv1_RealTimeList = { title: SYSData[i].Name, path: "/RealTime/" + String(SYSData[i].ID), icon: "" };
                            Lv1_RealTimeList=this.SetSYSMenu(SYSData[i].ID,SYS_Data,Lv1_RealTimeList);
                            RealTimeList.push(Lv1_RealTimeList);
                        }
                    }

                    //RealTimeList=this.SetSYSMenu("-1",SYS_Data,RealTimeList);
                    //console.log(RealTimeList);
                    //判斷有未分類資料就加入選單
                    /*
                    let sbody = {
                    TopN: " ",
                    StrWhere: '  WHERE IO_Info.View_Enabled=1   AND (LENGTH (IO_Info.SYS_ID)=0 or IO_Info.SYS_ID=0)',
                    Sort: " ",
                    Key:localStorage.getItem("Token")
                    };
                    this.RealTimeData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
                    (obj) => {
                    if (obj.length >0){
                        this.RealTimeList.push({ path: 'RealTime', data: { menu: { title: "未分類"} } });
                    }
                    },
                    err => { console.log(err); }
                    );*/

                    for (let i = 0; i < MenuData.length; i++) {
                        if (MenuData[i].path == "RealTime") {
                            //將系統資料塞到即時資料裡面
                            MenuData[i]["children"] = RealTimeList;
                        }
                    }

                    this.items = MenuData;
                },
                err => {
                    console.log(err);
                    this.items = MenuData;
                }
            );
        }
    }
  //設定即時資訊選單
  SetSYSMenu(ParentID: string,SysData: any,RealTimeList: any) {
    let SYS_Data = SysData.filter(task => task.ParentID == ParentID);
    //有子項
    if(SYS_Data.length>0){
        let List=[];
        for(let i in SYS_Data){
            let Lv1_RealTimeList = { title: SYS_Data[i].Name, path: "/RealTime/" + String(SYS_Data[i].ID), icon: "" };
            //遞回查項目
            Lv1_RealTimeList=this.SetSYSMenu(SYS_Data[i].ID,SysData,Lv1_RealTimeList);
            List.push(Lv1_RealTimeList);
        }
        RealTimeList["children"]=List;
    }
    return RealTimeList;
  }


    //組階層
    ConvertLevelData(MasterData, MACID) {
        let Next = [];
        Next = MasterData.filter(function (d) {
            return d.L0ParentID.toString() == MACID.toString();
        });

        if (Next.length > 0) {
            for (let i = 0; i < Next.length; i++) {
                let children = [];
                children = this.ConvertLevelData(MasterData, Next[i].L0MACID);
                if (children.length > 0) {
                    Next[i]["children"] = children;
                }
            }
        }

        return Next;
    }

    GoUrl(url) {
        let dir = "/pages/";
        if(localStorage.getItem("system")=="1"){
            dir = "/ibas/";
        }
        if (url != null && url != "") {
            this.router.navigate([dir + url]);
        }
        //else
        //console.log("空");
        //this.router.navigate([dir+url]);
    }

    onResize(event) {
        if (event.target.innerWidth <= 1280 &&this.isToggled()) {
            this.toggleSidebar();
        }else if(event.target.innerWidth > 1280 && !this.isToggled()){
            this.toggleSidebar();
        }
    }
    onMouseMove(event) {
        //判斷需要更新選單
        if(localStorage.getItem("SyncSide")=="1"){
            localStorage.setItem("SyncSide","0");
            this.GetSiteMap();
        }
    }

    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    /*rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    changeLang(language: string) {
        this.translate.use(language);
    }*/

    //刪除資料
    onLogout(){
        if (confirm("確定要登出嗎?")) {
            localStorage.removeItem("User");
            localStorage.removeItem("Account");
            localStorage.removeItem("Token");
            this.router.navigate(['/login']);
        }
    }
    
}
