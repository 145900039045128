import { Component,OnInit } from '@angular/core';


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: []
})
export class PagesComponent implements OnInit {

  constructor( 
  ) { }

  ngOnInit() {
  }

}
