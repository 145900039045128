import { Component, OnInit, Inject } from '@angular/core';
import { PowerAnalysisTypeService,Permissions } from '../../../../DataService';
import { MyCommon } from '../../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-PATypeSettingAdd',
  templateUrl: './PATypeSettingAdd.component.html',
  styleUrls: ['./PATypeSettingAdd.component.scss'],
  providers: [PowerAnalysisTypeService, MyCommon,Permissions]
})
export class PATypeSettingAddComponent implements OnInit {

  data:any=[{
    PAType_ID:0,
    PA_Class:-1,
    PAName:"",
    SortID:0,
    Color:"#d2d2d2",
    IsOther:"0"
  }]

  public ViewPermissions:boolean=false;
  
  constructor(
		@Inject(PowerAnalysisTypeService) private PATypeData: PowerAnalysisTypeService,
		@Inject(Permissions) private PermissionsData: Permissions,
		@Inject(MyCommon) private MyCommon: MyCommon,
		private activatedRoute: ActivatedRoute,
		private router: Router) { }

    ngOnInit() {
    //判斷頁面權限
    let sbody = {
      UrlList:"PATypeSettingAdd",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
      this.MyCommon.CheckToken(obj);
      for (let Site of obj) {
        if(Site.path=="PATypeSettingAdd")
          this.ViewPermissions=true;
      }
      try{
        this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
      }catch(err){}
      },
      err => {  },
      () => { }
    );
  }

  clicked() {
		if (confirm("確定要新增嗎?")) {
					this.Insert();
		}
	}

	Insert() {
    var Set=this.data[0];

		if (String(Set.PAName).trim()==''){
			alert('請輸入類型名稱!');
			return false;
		}


		let sbody = {
      "PAType_ID": Set.PAType_ID,
			"PA_Class": Set.PA_Class,
			"PAName": Set.PAName,
			"SortID": Set.SortID,
      "Color": Set.Color,
      "IsOther": Set.IsOther.toString()=="1"?true:false,
			Key:localStorage.getItem("Token")
		};

		this.PATypeData.InsertData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
        var ErrorStatus=this.MyCommon.CheckToken(obj);
        if(ErrorStatus){
          
        }else{
          alert('新增成功');
          this.router.navigate(['/pages/PATypeSetting']);
        }
			},
			err => { alert('新增失敗'); }
		);
	}

	back() {
		this.router.navigate(['/pages/PATypeSetting']);
	}
}
