import { Component, OnInit, Inject } from '@angular/core';
import { IOService } from '../../DataService/IO.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../DataService/User.service';

@Component({
  selector: 'app-IOSetting',
  templateUrl: './IOSetting.component.html',
  providers: [IOService, MyCommon,Permissions]
})
export class IOSettingComponent implements OnInit {

  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public ImportPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  //查詢參數
  Search_Category:any="IO_Info.IO_MAC";
  Search_Keyword:any="";
  IO_Type:any="-1";
  Search_Enable:any="-1";
  
  public SelectItem;
  
  constructor(
    @Inject(IOService) private IOData: IOService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
    //判斷頁面權限
      let sbody = {
        UrlList:"IOSettingView,IOSettingAdd,IOSettingEdit,IOSettingImport,IOSettingDelete",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="IOSettingView")
                  this.ViewPermissions=true;
              if(Site.path=="IOSettingAdd")
                  this.AddPermissions=true;
              if(Site.path=="IOSettingEdit")
                  this.EditPermissions=true;
              if(Site.path=="IOSettingImport")
                  this.ImportPermissions=true;
              if(Site.path=="IOSettingDelete")
                  this.DeletePermissions=true;
          }
          if(this.EditPermissions){
            this.splitbutton_data.push(
            {
              text: '修改',
              iconClass: 'fa fa-pencil-square-o',
              click: () => {this.router.navigate(['/pages/IOSettingEdit', this.SelectItem.IO_ID]); }
            });
          }
          if(this.DeletePermissions){
            this.splitbutton_data.push(
            {
              text: '刪除',
              iconClass: 'fa fa-trash-o',
              click: () => { this.Delete(this.SelectItem);}
            });
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
    this.GetData();
  }

  public splitbutton_data: Array<any> = [];

  public onPaste(obj): void {
    this.SelectItem=obj;
  }

  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    //this.isToken();
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }
  //取得系統資料
  GetData() {
    var StrWhere=" Where 1=1";
    if (this.Search_Enable !="-1"){
      StrWhere +=" AND View_Enabled='"+this.Search_Enable+"'";
    }
    if (this.Search_Keyword !=""){
      StrWhere +=" AND "+this.Search_Category+" Like '%"+this.Search_Keyword+"%'";
    }
    if (this.IO_Type !="-1"){
      StrWhere +=" AND IO_Info.IO_Type='"+this.IO_Type+"'";
    }

    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.IOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
       
        this.gridView = {
          data: orderBy(obj.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }

  Search(){
    this.skip=0;
    this.GetData();
  }

  IOImport(){
    this.router.navigate(['/pages/IOImport']);
  }

   //刪除資料
  Delete(del: any) {
    if (confirm("確定要刪除嗎?")) {

      var StrWhere=" where IO_MAC="+del.IO_MAC+" and IO_S="+del.IO_S;
      let sbody = {
        StrWhere: StrWhere,
        IsDelSys:"0",
        Key:localStorage.getItem("Token")
      };
      var stralert = this.IOData.DeleteData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
           this.MyCommon.CheckToken(obj);
           this.GetData(); },
        err => alert('刪除失敗'),
        () => { }
      );
      }
    }

}