import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class ReportService {

        constructor( @Inject(HttpClient)
        private http: HttpClient,
                public MyCommon: MyCommon) { }

        GetData(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetAPISPUrl('ReportSetting');
                return this.http.post(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
        GetIO(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetSettingAPISPUrl('ReportSettingIO');
                return this.http.post(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
        InsertData(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetSettingAPISPUrl('ReportSettingData');
                return this.http.post(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
        UpdateData(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetSettingAPISPUrl('ReportSettingData');
                return this.http.put(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
        DeleteData(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetAPISPUrl('ReportSettingDelete');
                return this.http.post(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
        GetIOExport(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetSettingAPISPUrl('ReportIOExport');
                return this.http.post(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
        ReportExport(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetAPISPUrl('ReportExport');
                return this.http.post(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
        
        GetReportDownload(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetAPISPUrl('ReportDownload');
                return this.http.post(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
        GetReportTask(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetAPISPUrl('ReportTask');
                return this.http.post(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
        ReportReExport(sbody: any, headers: any = "") {
                var url = this.MyCommon.GetSettingAPISPUrl('ReportReExport');
                return this.http.post(url, sbody, { headers: headers })
                        .map(res=>JSON.parse(JSON.stringify(res)));
        }
}