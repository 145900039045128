import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { RealTimeService } from '../../DataService/RealTime.service';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { RealTimeColumn } from './RealTimeColumn';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RealTimeSettingComponent } from './RealTimeSetting/RealTimeSetting.component';
import { SystemService } from '../../DataService/System.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Permissions } from '../../DataService/User.service';
import {Observable} from 'rxjs/Rx';
import { Paho } from 'ng2-mqtt/mqttws31';

@Component({
  selector: 'app-RealTime',
  templateUrl: './RealTime.component.html',
  styleUrls: ['./RealTime.component.scss'],
  providers: [RealTimeService, MyCommon, SystemService,Permissions,NgbModal]
})
export class RealTimeComponent implements OnInit {

  //預設欄位
  RealTimeColumn = new RealTimeColumn('', '', '', '', '');
  data: any;
  SYS_ID: any = "";
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;
  IO_Type_Source:any=['所有類型','AO','DO','AI','DI','VAO','VDO'];
  IO_Status_Source:any=[{id:-1,name:'所有設備'},{id:0,name:'正常'},{id:1,name:'異常'},{id:2,name:'斷線'}];
  IO_Type:any='所有類型';
  IO_Status:any=-1;
  IOList=[];


  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  public DevName;
  public SelectItem;
  tooltipOffset = { left: 0, top: 0 };
  tooltipVisible = false;
  tooltipValue = "";

  collection = [];
  collectionSearch = [];
  p: number = 1;
  IsCtrl:Boolean =false;

  public iconClass: string = 'fa fa-mail-reply fa-fw';

  public splitbutton_data: Array<any> = [{
      text: '趨勢查詢',
      icon: 'paste-plain-text',
      click: () => { this.ToHistory(this.SelectItem); }
  }, {
      text: '設定',
      icon: 'paste-as-html',
      click: () => { this.showPopup(this.SelectItem);}, 
  }];



  public splitbutton_data2: Array<any> = [{
      text: '趨勢查詢',
      icon: 'paste-plain-text',
      click: () => { this.ToHistory(this.SelectItem); }
  }];

  public splitbutton_data3: Array<any> = [ {
      text: '設定',
      icon: 'paste-as-html',
      click: () => { this.showPopup(this.SelectItem);},
  }];

  constructor( @Inject(RealTimeService) private RealTimeData: RealTimeService,
    @Inject(MyCommon) private MyCommon: MyCommon,
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(Permissions) private PermissionsData: Permissions,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }
  private parametersObservable: any;


  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id'] != null){
        if(Number(String(params['id']))>0)
          this.SYS_ID = String(params['id']);
        else
          this.router.navigate(['/pages/RealTime', "-1"]);
          //this.SYS_ID = "-1";
      }       
      else
        this.router.navigate(['/pages/RealTime', "-1"]);
      this.DevName='';

      //判斷頁面權限導頁
      let sbody2 = {
        UrlList:"RealTime",
        Key:localStorage.getItem("Token")
      };
      var stralert2 = this.PermissionsData.SiteMap(sbody2, this.MyCommon.SetHeaders());
      stralert2.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="RealTime")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => { 
  
         },
        () => { }
      );
      //判斷選單權限
      let sbody = {
        UrlList:"RealTimeView,RealTimeUpdate",
        Key:localStorage.getItem("Token")
      };

      let stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="RealTimeView")
                  this.ViewPermissions=true;
              if(Site.path=="RealTimeUpdate")
                  this.EditPermissions=true;
          }
          try{
            //this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
      this.PermissionsData.OtherPermissions(this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          let data=[];
          try{
            if(obj[0]=="["){
              data=JSON.parse(obj);
            }else{
              data=obj;
            }
          }catch(err){
  
          }
          this.IsCtrl=data[0]["IsCtrl"]=="0"?false:true;
          if(!this.IsCtrl){
            this.splitbutton_data= [{
              text: '趨勢查詢',
              icon: 'paste-plain-text',
              click: () => { this.ToHistory(this.SelectItem); }
            }];
        
            this.splitbutton_data3=[];
          }
       });
    
      /*if (this.SYS_ID == "")
        this.GetSysData();
      else*/
      // let Realtimer = Observable.timer(10000,1000);
      // var UpdateTime=this.MyCommon.GetUpdateTime();
      // this.SetData();
      // Realtimer.subscribe(t=> {
      //   if(this.router.url.indexOf("/pages/RealTime")>-1){
      //     if((t % UpdateTime)==0){
      //     this.SetData();
      //     }
      //   }
      // });

      //每分鐘刷新紀錄
      let timer2 = Observable.timer(1000,1000);
      timer2.subscribe(t=>{
        if(this.router.url.indexOf("/pages/RealTime")>-1){
          let NewSysID=this.router.url.replace("/pages/RealTime/","");
          if( this.SYS_ID!=NewSysID){
            this.SYS_ID=NewSysID;
            this.BindData();
          }
        }
        
      });
      this.BindData();
      this.MQTTData();
       
    });
  }
  BindData(){
    this.SetData();
    if(this.router.url.indexOf("/pages/RealTime")>-1){
    setTimeout(() => {
          this.BindData();
      }, this.MyCommon.GetUpdateTime()*1000);
    }
  }

  MQTT_Conn :any;
  MQTTData(){
      let Link=this.MyCommon.GetMQTTLink();
      let clientID ="RealTime"+ Math.random().toString(36).substring(8);
      this.MQTT_Conn = new Paho.MQTT.Client(Link.ip, Link.port, clientID);
      this.MQTT_Conn.connect({ userName: Link.acc, password: Link.pwd, useSSL:  false, timeout: 10,
      onSuccess: ()=>{
          console.log("onSuccess");
          this.MQTT_Conn.subscribe("wsn/LD", 2);
      } 
      , onFailure: ()=>{
          console.log("onFailure");
      }});
      //接收回傳
      this.MQTT_Conn.onMessageArrived = (message)=>{
        if(message.destinationName=="wsn/LD"){
          //console.log(message.payloadString,message.destinationName);
          this.BindDevice(message.payloadString);
        }
       
          
      }
      this.MQTT_Conn.onConnectionLost=(d)=>{
        setTimeout(()=>{
          this.MQTTData();
        },3000)
        console.log("onConnectionLost",d);
      }
  }

  //取得項目狀態
  BindDevice(payloadString){
    payloadString=payloadString.split("}")[0]+"}";
    let Data=payloadString.split(";").filter((d)=>
    {
      try {
        d=JSON.parse(d)
        return true;
      } catch (error) {
        return false;
      }
    }).map(d=>JSON.parse(d));
    
    for (let i in this.collection) {
      let Filter=Data.filter(d=>d["MacS"].indexOf(String(this.collection[i].IO_MAC)+"-"+String(this.collection[i].IO_S))>-1);
      if(Filter.length>0){
        this.collection[i].Value=Filter[0].Value;
      }
    }
  }
 

    public onPaste(obj): void {
      this.SelectItem=obj;
    }

  //取得系統資料
  GetSysData() {
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.SYS_ID = obj[0]["ID"];
        this.SetData();
      },
      err => { console.log(err); this.SetData(); }
    );
  }

  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.SetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.SetData();
  }
  //取得資料
  SetData() {
    var StrWhere = " WHERE IO_Info.View_Enabled=1 ";

    if (this.SYS_ID != "-1" && this.SYS_ID!="")
      StrWhere += "  AND IO_Info.SYS_ID=" + this.SYS_ID;
    else
      StrWhere +=" ";//"  AND (LENGTH (IO_Info.SYS_ID)=0 or IO_Info.SYS_ID=0)";
    if (this.IO_Type !="所有類型" && this.SYS_ID != "")
      StrWhere +=" AND IO_Info.IO_Type='"+this.IO_Type+"'";
  

    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.RealTimeData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.collection=obj;
        this.collectionSearch=obj;
        if(this.DevName!=null && this.DevName!='')
          this.collection=this.collectionSearch.filter(d => d.DevName.indexOf(this.DevName)>-1);

         if (this.IO_Status !=-1 )
          this.collection=this.collection.filter(d => d.IOStatus==this.IO_Status);

          var num=0;
          var collCount=this.collection.length;
          for (var i in this.collection){
            num=num+1;
            var index=Number(i) % 2;
            this.collection[i]["index"] = index;
            if (num==collCount)
              this.SetGrid();
          }
        
      },
      err => { console.log(err); }
    );
  }

  SetIndex(){

          for (var i in this.collection){
            var index=Number(i) % 2;
            this.collection[i]["index"] = index;
          }
  }

  SetGrid(){
  this.gridView = {
          data: orderBy(this.collection.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: this.collection.length
        };
  }


  //顯示Popup
  showPopup(dataitem: any) {
    this.RealTimeColumn.IO_MAC = dataitem.IO_MAC;
    this.RealTimeColumn.IO_S = dataitem.IO_S;
    this.RealTimeColumn.SetCtrlType = dataitem.SetCtrlType;
    this.RealTimeColumn.SetValue = dataitem.SetValue;
    this.RealTimeColumn.IO_Type = dataitem.IO_Type;
   //打開設定頁面
    const activeModal = this.modalService.open(RealTimeSettingComponent, { size: 'sm', backdrop: 'static' });
    //傳值到設定頁
     activeModal.componentInstance.RealTimeColumn = this.RealTimeColumn;
    //判斷在設定頁關閉後做SetData動作
    activeModal.result.then((result) => {
      this.SetData();
    });
  }

  //趨勢查詢
  ToHistory(dataitem: any){
    //讀出IOList
    this.IOList=JSON.parse(localStorage.getItem("IOList"));
    var IO_Data=dataitem.IO_MAC+'-S'+dataitem.IO_S;//dataitem.IO_MAC+'-'+dataitem.IO_S+'-'+dataitem.IO_Type;
    var DisplayName=dataitem.DevName;
    //判斷是否重複
    if (this.IOList != null){
      for (var item of this.IOList){
        if (item.id==IO_Data){
          alert('已完成資料選取');
          //this.router.navigate(['/pages/History']);
          return false;
        }
      }
    }else{
      this.IOList=[];
    }
    this.IOList.push({'id': IO_Data,'name': DisplayName});
    localStorage.setItem("IOList",JSON.stringify(this.IOList));
    alert('已完成資料選取');
    //this.router.navigate(['/pages/History']);
  }

  valuechange(newValue) {
    this.DevName = newValue;
    this.collection=this.collectionSearch.filter(d => d.DevName.indexOf(newValue)>-1);
    if (this.IO_Type !="所有類型" && this.SYS_ID != "")
      this.collection=this.collection.filter(d => d.IO_Type==this.IO_Type);
    if (this.IO_Status !=-1 )
      this.collection=this.collection.filter(d => d.IOStatus==this.IO_Status);

    this.SetIndex();
  }
  //選擇IO_Type
  IO_TypeChange(){
    this.skip=0;
    this.collection=this.collectionSearch.filter(d => d.DevName.indexOf(this.DevName)>-1);
    if (this.IO_Type !="所有類型" && this.SYS_ID != "")
      this.collection=this.collection.filter(d => d.IO_Type==this.IO_Type);
    if (this.IO_Status !=-1 )
      this.collection=this.collection.filter(d => d.IOStatus==this.IO_Status);

    this.SetIndex();

    //this.SetData();
  }
  //選擇IO_Status
  IO_StatusChange(){
    this.skip=0;
    this.collection=this.collectionSearch.filter(d => d.DevName.indexOf(this.DevName)>-1);
    if (this.IO_Type !="所有類型" && this.SYS_ID != "")
      this.collection=this.collection.filter(d => d.IO_Type==this.IO_Type);
    if (this.IO_Status !=-1 )
      this.collection=this.collection.filter(d => d.IOStatus==this.IO_Status);

    this.SetIndex();
    //this.SetData();
  }
  //顯示ToolTip
  showTooltip(event: any, data: any) {
        this.tooltipOffset = { left: event.pageX + 4, top: event.pageY + 4 };
        this.tooltipVisible = true;
        //console.log(data);
        this.tooltipValue = data.IO_MAC;
  }
  //隱藏ToolTip
  hideTooltip() {
        this.tooltipVisible = false;
  }
}