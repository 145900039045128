import { Component, OnInit ,Inject} from '@angular/core';
import { IOService } from '../../../DataService/IO.service';
import { SystemService } from '../../../DataService/System.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { Permissions } from '../../../DataService/User.service';
import { DevService } from '../../../DataService/Dev.service';

@Component({
  selector: 'app-IOSetting_Add',
  templateUrl: './IOSetting_Add.component.html',
  styleUrls: ['./IOSetting_Add.component.scss'],
  providers: [IOService,SystemService, MyCommon,DevService,Permissions]
})
export class IOSetting_AddComponent implements OnInit {

  public SysSource : any;
  public DevSource : any;
  public ViewPermissions:boolean=false;
  SYS_ID:any='0';
  IO_MAC:any='';
  IO_S:any='';
  Name:any='';
  IO_Type:any='AI';
  SetCtrlType:number=0;
  View_Enabled:string='1';

  constructor(
    @Inject(IOService) private IOData: IOService,
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(DevService) private DevData: DevService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {


    //判斷頁面權限
      let sbody = {
        UrlList:"IOSettingAdd",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="IOSettingAdd")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );

      this.GetDevData()
      this.GetSysData();

  }

  GetDevData(){
    let sbody = {
      TopN: " ",
      StrWhere: " where DataType=0",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DevData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.DevSource=obj;
      },
      err => { console.log(err); }
    );
  }

    GetSysData() {
      let sbody = {
        TopN: " ",
        StrWhere: " ",
        Sort: " ",
        Key:localStorage.getItem("Token")
      };
      this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.SysSource=obj;
        },
        err => { console.log(err); }
      );
    }
  clicked(){
    if (this.IO_MAC=='' || this.IO_S=='' || this.Name==''){
      alert("MACID、SID、IO名稱不能為空");
      return false;
    }

    if (confirm("確定要新增嗎?")) {
        this.Insert();
     }
  }

  Insert(){
     let sbody = {
      "IO_MAC": this.IO_MAC,
      "IO_S":this.IO_S,
      "Name":this.Name,
      "SYS_ID":this.SYS_ID,
      "IO_Type":this.IO_Type,
      "SetCtrlType":this.SetCtrlType,
      "View_Enabled":this.View_Enabled,
      Key:localStorage.getItem("Token")
    };

     var stralert = this.IOData.InsertBasis(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          if(obj[0].Status==1){
            alert('新增成功');
            this.router.navigate(['/pages/IOSetting']);
          }else{
            alert('新增失敗，已有重複的MACID、SID');
          }
        },
        err => { 

         },
        () => { }
      );
  }

  back(){
      this.router.navigate(['/pages/IOSetting']);
  }

}