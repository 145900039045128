import { Component, Input,Self,Output,EventEmitter } from '@angular/core';
import {ControlValueAccessor, NgModel} from '@angular/forms';

@Component({
  selector: 'sy-checkbox-list[ngModel]',
  templateUrl: './sy-checkbox-list.component.html',
  providers: [NgModel]
})
export class SyCheckboxListComponent implements ControlValueAccessor{
  @Input() data:any;
  @Input() set_data:String;
  @Input() name:String;
  //建立控制項事件
  @Output() ValueChanged = new EventEmitter<string>();
  public model: NgModel;
  public constructor(@Self() state:NgModel) {
    this.model = state;
    state.valueAccessor = this;
  }
  private onCheckChange(check: any,value: any): void {
    var temp_data=this.set_data;
    if(check){
      temp_data+=","+String(value);
    }
    else{
      temp_data= (","+temp_data+",").replace(","+String(value)+",",",");
    }
    var newdata=this.data.filter(task => (","+temp_data+",").indexOf(","+String(task.value)+",")>-1);
    this.set_data="";
    for(let i in newdata){
      this.set_data+=newdata[i].value+(Number(i)<newdata.length-1?",":"");
    }
    //觸發控制項事件
    this.ValueChanged.emit(String(this.set_data));
    this.onChange(this.set_data);
  }

  private Select(value: any){
    return (","+this.set_data+",").indexOf(","+value+",")>-1;
  }

  public onChange(value: any): void {

  }
  public onTouch(value: any): void {

  }
  public writeValue(_Value: any): void {
    this.set_data = _Value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = function(state: boolean) {
      this.writeValue(state);
      this.model.viewToModelUpdate(state);
    };
  }
  public registerOnTouched(fn: any): void { 
    this.onTouch = fn;
   }

}
