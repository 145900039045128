import { Component, OnInit,Inject  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridDataResult, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { IOService } from '../../../DataService/IO.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';
//import { saveAs } from 'file-saver/FileSaver';

@Component({
  selector: 'app-IOImport',
  templateUrl: './IOImport.component.html',
  providers: [IOService, MyCommon,Permissions]
})
export class IOImportComponent implements OnInit {

  csvData: any[] = [];
  GridData: Array<any> = [];
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;
  public ViewPermissions:boolean=false;
  

  constructor(
    @Inject(IOService) private IOData: IOService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router) { 
    
  }

  ngOnInit() {
      //判斷頁面權限
      let sbody = {
        UrlList:"IOSettingImport",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="IOSettingImport")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );

  }
//選擇文件
changeListener($event):void {
    this.upload($event.target);
}
//取得csv資料
upload(inputValue:any):void {
        var file:File = inputValue.files[0];
        var reader:FileReader = new FileReader();
        //reader.onloadend = function (e) {
        reader.onload = (e) => {
          
          let csvData = reader.result;
          var GridData: Array<any> = [];
          var DataList=String(csvData).split(/\r\n|\n/);
          var ID=0;
           
          for (var item of DataList){
            ID +=1;
            if (ID !=1){
            var GridDataList=item.replace(/\"/g,"").split(',');

            if (GridDataList.length==37){
              if (String(GridDataList[0]) !='' && String(GridDataList[1]) !='' && String(GridDataList[2]) !='')
              {
                GridData.push({Export:'1',
                              Sys_Name:GridDataList[0],
                              Dev_Name:GridDataList[1],
                              ViewName:GridDataList[2],
                              Function:GridDataList[3],
                              IO_Category:GridDataList[4],
                              MapIP:GridDataList[5],
                              MapPort:GridDataList[6],
                              CoordinatorID:GridDataList[7],
                              NodeID:GridDataList[8],
                              MapModbus:GridDataList[9],
                              Multiple:GridDataList[10],
                              S:GridDataList[11],
                              Unit:GridDataList[12],
                              Dev_MAC:GridDataList[13],
                              IO_MAC:GridDataList[14],
                              IO_S:GridDataList[15],
                              Name:GridDataList[16],
                              IO_Type:GridDataList[17],
                              Alarm_UpVal:GridDataList[18],
                              Alarm_DownVal:GridDataList[19],
                              DBend:GridDataList[20],
                              SMS_Enabled:GridDataList[21],
                              Mail_Enabled:GridDataList[22],
                              Line_Enabled:GridDataList[23],
                              Alarm_Sec:GridDataList[24],
                              Ctn_Sec:GridDataList[25],
                              Alarm_Type:GridDataList[26],
                              Ctn_UpVal:GridDataList[27],
                              Ctn_DownVal:GridDataList[28],
                              SetValue:GridDataList[29],
                              Ctn_IOID:GridDataList[30],
                              SetCtrlType:GridDataList[31],
                              View_Enabled:GridDataList[32],
                              UserChk:GridDataList[33],
                              AlarmStatus:GridDataList[34],
                              AlarmEventID:GridDataList[35],
                              Alarm_Enabled:GridDataList[36],
                              SMS_Enabled_ID:'SMS_Enabled'+ID,
                              Mail_Enabled_ID:'Mail_Enabled'+ID,
                              Line_Enabled_ID:'Line_Enabled'+ID,
                              View_Enabled_ID:'View_Enabled'+ID,
                              Alarm_Enabled_ID:'Alarm_Enabled'+ID
                            });
                }
              }
            }
            else{
            }
          }
            this.GridData=GridData;
            this.GetData();
        }
        reader.readAsText(file);
    }

  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }

//將資料載入gridview
GetData(){
   this.gridView = {
          data: this.GridData.slice(this.skip, this.skip + this.pageSize),
          total: this.GridData.length
          };
}
//IO資料匯入
Export(){
  if (this.gridView !=undefined){
    //判斷有無資料
    if (this.gridView.data.length==0)
      return false;
    if (confirm("若有已存在的IO不會異動資料，確定要匯入嗎?")) {
      //選出要匯入的資料
      var IO_filter = this.GridData.filter(
            task => task.Export == '1');
      //判斷有無資料
      if (IO_filter.length==0){
        alert("請選擇要匯入的IO!");
        return false;
      }
       this.InsertIO_Info(IO_filter);
    }
  }
}

//IO資料匯入
ReExport(){
  if (this.gridView !=undefined){
    //判斷有無資料
    if (this.gridView.data.length==0)
      return false;
    if (confirm("匯入IO會將先前資料清除，確定要匯入嗎?")) {
      //選出要匯入的資料
      var IO_filter = this.GridData.filter(
            task => task.Export == '1');
      //判斷有無資料
      if (IO_filter.length==0){
        alert("請選擇要匯入的IO!");
        return false;
      }
      this.DeleteIO_Info(IO_filter);
    }
  }
}


  //刪除舊資料
  DeleteIO_Info(IO_filter){
    let sbody = {
          StrWhere: "",
          IsDelSys:"1",
          Key:localStorage.getItem("Token")
          };
          this.IOData.DeleteData(sbody, this.MyCommon.SetHeaders()).subscribe(
            (obj) => {
                this.InsertIO_Info(IO_filter);
            },
            err => { console.log(err); }
          );
  }

  //新增IO_Info
  InsertIO_Info(IO_filter){
      //判斷格式
      for (let item of IO_filter){
        if (this.CheckInt(item.IO_MAC) || this.CheckInt(item.IO_S) || 
            this.CheckInt(item.Alarm_UpVal) || this.CheckInt(item.Alarm_DownVal) || this.CheckInt(item.DBend) || 
            this.CheckInt(item.Alarm_Sec) || this.CheckInt(item.Ctn_Sec) || this.CheckInt(item.Alarm_Type) || 
            this.CheckInt(item.Ctn_UpVal) || this.CheckInt(item.Ctn_DownVal) || this.CheckInt(item.SetValue) || 
            this.CheckInt(item.SetCtrlType) || this.CheckInt(item.UserChk)|| this.CheckInt(item.AlarmStatus)|| 
            this.CheckInt(item.AlarmEventID))
        {
          
          alert("IO_MAC:"+item.IO_MAC+"   IO_S:"+item.IO_S+"   資料有誤，請重新確認!")
          return false;
        } 
      }
      //匯入資料
      let sbody=IO_filter;
   
        this.IOData.InsertData(sbody, this.MyCommon.SetHeaders()).subscribe(
          (obj) => {
            alert('匯入成功');
            this.router.navigate(['/pages/IOSetting']);
          },
          err => { console.log(err); }
        );
  }

  //判斷是否是數值
  CheckInt(value){
    var CheckInt=0;
    CheckInt=Number(value);
    if (String(CheckInt) =='NaN')
      return true;
    else
      return false;
  }

}