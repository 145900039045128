import { Component, OnInit ,Inject} from '@angular/core';
import { LineNotifyService } from '../../../DataService/LineNotify.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-Line_Add',
  templateUrl: './Line_Add.component.html',
  styleUrls: ['./Line_Add.component.scss'],
  providers: [LineNotifyService, MyCommon,Permissions]
})
export class Line_AddComponent implements OnInit {

  Name:any='';
  public ViewPermissions:boolean=false;

  Client_ID:any='';
  Client_Secret:any='';
  Redirect_Uri:any='';
  Temp_Token:any='';

  constructor(
    @Inject(LineNotifyService) private LineNotifyData: LineNotifyService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
    //判斷頁面權限
    let sbody = {
      UrlList:"LineAdd",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
            if(Site.path=="LineAdd")
                this.ViewPermissions=true;
        }
        try{
          this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
        }catch(err){}
      },
      err => {  },
      () => { }
    );

      //取得Line資訊
      let sbody2 = {
        Key:localStorage.getItem("Token")
      };
      this.LineNotifyData.GetData(sbody2, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.Client_ID=obj[0].client_id;
          this.Client_Secret=obj[0].client_secret;
          this.Redirect_Uri=obj[0].redirect_uri;
          this.Temp_Token=obj[0].Temp_Token;
          console.log(this.Redirect_Uri);
        },
        err => { }
      );
  }

    //取得Token
    GetNewToken(){
      if (confirm("當LineNotify連動完成後，請重新刷新頁面!!")) {
        var Url='https://notify-bot.line.me/oauth/authorize?';
              Url += 'response_type=code';
              Url += '&client_id='+this.Client_ID;
              Url += '&redirect_uri='+this.Redirect_Uri;
              Url += '&scope=notify';
              Url += '&state=abcde';

        window.open(Url);
      }
    }

  clicked(){
  if (confirm("確定要新增嗎?")) {
    if (this.Name.trim() !='' && this.Temp_Token.trim() !='')
        this.Insert();
    else
        alert("名稱和Token不能為空!")
      }
  }

  Insert() {

    if (String(this.Name).trim()=='' || String(this.Temp_Token).trim()==''){
      alert('群組名稱和Token不能為空!');
      return false;
    }

    let sbody = {
      "Name": this.Name,
      "Token": this.Temp_Token,
      Key:localStorage.getItem("Token")
    };
    var stralert = this.LineNotifyData.InsertToken(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);

        let sbody2 = {
          "Temp_Token": ''
        };
        this.LineNotifyData.UpdateTempToken(sbody2, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          alert('新增成功');
        this.router.navigate(['/pages/Line']);
        },
        err => { }
      );
      },
      err => { alert('新增失敗'); },
      () => { }
    );
  }

  //離開
  back() {
        let sbody2 = {
          "Temp_Token": ''
        };
        this.LineNotifyData.UpdateTempToken(sbody2, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
        this.router.navigate(['/pages/Line']);
        },
        err => { });
  }

}
