import { Component, OnInit } from '@angular/core';
import { iBas_ScheduleIOService } from '../../../DataService';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { iBas_Permissions } from '../../../DataService';

@Component({
  selector: 'app-IO_Schedule',
  templateUrl: './IO_Schedule.component.html',
  styleUrls: ['./IO_Schedule.component.scss'],
  providers: [iBas_ScheduleIOService, iBas_Permissions, MyCommon]
})
export class IO_ScheduleComponent implements OnInit {
  //系統資料
  SYS_Source: any;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public SYS_ID: any = '-1';
  private pageSize: number = 10;
  private skip: number = 0;

  public ViewPermissions: boolean = false;
  public AddPermissions: boolean = false;
  public EditPermissions: boolean = false;
  public DeletePermissions: boolean = false;
  public pageable: any = "{numeric: false,previousNext: false,messages: {display: 'Showing {2} data items'}}";
  public SelectItem;
  constructor(private ScheduleIOData: iBas_ScheduleIOService,
    private PermissionsData: iBas_Permissions,
    private MyCommon: MyCommon,
    private router: Router) { }
  ngOnInit() {


    //判斷頁面權限
    let sbody = {
      UrlList: "IOScheduleView,IOScheduleAdd,IOScheduleEdit,IOScheduleDelete",
      Key: localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
          if (Site.path == "IOScheduleView")
            this.ViewPermissions = true;
          if (Site.path == "IOScheduleAdd")
            this.AddPermissions = true;
          if (Site.path == "IOScheduleEdit")
            this.EditPermissions = true;
          if (Site.path == "IOScheduleDelete")
            this.DeletePermissions = true;
        }
        if (this.EditPermissions) {
          this.splitbutton_data.push(
            {
              text: '修改',
              iconClass: 'fa fa-pencil-square-o',
              click: () => { this.router.navigate(['/ibas/IOScheduleEdit', this.SelectItem.ACO_ID]); }
            });
        }
        if (this.DeletePermissions) {
          this.splitbutton_data.push(
            {
              text: '刪除',
              iconClass: 'fa fa-trash-o',
              click: () => { this.Delete(this.SelectItem); }
            });
        }
        try {
          this.MyCommon.PermissionsDefailtPage(obj, !this.ViewPermissions);
        } catch (err) { }
      },
      err => { },
      () => { }
    );

    this.GetData();
  }

  public splitbutton_data: Array<any> = [];

  public onPaste(obj): void {
    this.SelectItem = obj;
  }


  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }
  //取得資料
  GetData() {
    var StrWhere = "";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key: localStorage.getItem("Token")
    };

    this.ScheduleIOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data: orderBy(obj, this.sort),
          total: obj.length
        };
      },
      err => { }
    );
  }
  //刪除資料
  Delete(del: any) {

    if (confirm("確定要刪除嗎?")) {
      let sbody = {
        ACO_ID: del.ACO_ID,
        Key: localStorage.getItem("Token")
      };
      var stralert = this.ScheduleIOData.DeleteData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.GetData();
        },
        err => alert('刪除失敗'),
        () => { }
      );
    }

  }
  ChangeTimeData(Time_Range) {
    let Time = Time_Range.split(';');
    let Times = [];
    for (let i in Time) {
      if (Time[i] != "")
        Times.push({ Time: Time[i].replace(',', '~') });
    }
    return Times;
  }


}
