import { Component, OnInit, Inject } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { EACSettingsColumn } from './EACSettingsColumn';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextMenuSelectEvent } from '@progress/kendo-angular-menu';
import { EACPopupComponent } from './EACPopup/EACPopup.component';
import { EACChartComponent } from './EACChart/EACChart.component';
import { Permissions } from '../../DataService/User.service';
import { EACService } from '../../DataService/EAC.service';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { State, process } from '@progress/kendo-data-query';

@Component({
  selector: 'app-EAC',
  templateUrl: './EAC.component.html',
  styleUrls: ['./EAC.component.scss'],
  providers: [EACService, Permissions, MyCommon]
})
export class EACComponent implements OnInit {
  public SelectItem;
  public SYS_Source: any;
  public gridView: GridDataResult;
  public sort: SortDescriptor[] = [];
  public DataSource: any = [];
  public DataSourceAll: any = [];
  public Register: any = [];
  EAC_ID = "-1";
  RW_Enable = "0";
  Layout_Enable = "0";
  Edit_Enable = "0";
  Layout_Path = "";

  i: number;
  ID: any;
  SetValue: any = 3;
  MACID: string = "";
  SID: string = "";
  RealStatus: boolean = false;
  Hid_Name: boolean = false;
  ContextMenuItems: any[] = [{ text: '趨勢圖', value: 'Chart' },{ text: '設定', value: 'Set' }];
  ContextMenuItems2: any[] = [{ text: '趨勢圖', value: 'Chart' }];
  EACSettingsColumn: any = new EACSettingsColumn('', '', '', '', '', '', '', '', '', '', '');
  IO_Type_Source:any=['所有類型','AO','DO','AI','DI','VAO','VDO'];
  IO_Type:any='所有類型';
  public ViewPermissions: boolean = false;
  public AddPermissions: boolean = false;
  public EditPermissions: boolean = false;
  public DeletePermissions: boolean = false;

  IsCtrl:Boolean =false;

  constructor(
    @Inject(EACService) private EAC: EACService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    
    let sbody = {
      UrlList: "EACView,EACAdd,EACEdit,EACDelete",
      Key: localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
          if (Site.path == "EACView")
            this.ViewPermissions = true;
          if (Site.path == "EACAdd")
            this.AddPermissions = true;
          if (Site.path == "EACEdit")
            this.EditPermissions = true;
          if (Site.path == "EACDelete")
            this.DeletePermissions = true;
        }
       
        try {
          this.MyCommon.PermissionsDefailtPage(obj, !this.ViewPermissions);
        } catch (err) { }
      },
      err => { },
      () => { }
    );
    this.PermissionsData.OtherPermissions(this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        let data=[];
        try{
          if(obj[0]=="["){
            data=JSON.parse(obj);
          }else{
            data=obj;
          }
        }catch(err){

        }
        this.IsCtrl=data[0]["IsCtrl"]=="0"?false:true;
        if(!this.IsCtrl){
          this.ContextMenuItems= [{ text: '趨勢圖', value: 'Chart' }];
        }
     });
    this.GetSysData();
    this.RealStatus = false;
    this.RefreshData();
    //this.GetSettingsData();

  }
  //圖層控制刷新
  RefreshData() {
    if (this.RealStatus&&this.RW_Enable=='0') {
      this.GetSettingsData();
    }
    setTimeout(() => { this.RefreshData(); }, 10000);
  }

  onShowEdit(status) {
    this.Edit_Enable = status;
  }

  editdata(ev) {
    this.SetValue = ev.target.value;
  }

  public onSelect(e: ContextMenuSelectEvent, data: any): void {
    //console.log(e.item.text);
    //console.log(e.item.value);
    if(e.item.value=="Set"){
      //打開設定頁面
      const activeModal = this.modalService.open(EACPopupComponent, { size: 'sm', backdrop: 'static' });
      //傳值到設定頁
      activeModal.componentInstance.EACSettingsColumn = new EACSettingsColumn(data.ID, data.EAC_ID, data.SetName, data.MAC, data.SID, data.Unit, data.SetValue, data.Note, data.LayX, data.LayY, data.IO_Type);//this.RealTimeColumn;
      activeModal.componentInstance.Title = data.SetName;
      //判斷在設定頁關閉後做SetData動作
      activeModal.result.then((result) => {
        this.GetSettingsData();
      });
    } else if(e.item.value=="Chart"){
      //打開設定頁面
      const activeModal = this.modalService.open(EACChartComponent, { size: 'lg', backdrop: 'static' });
      //傳值到設定頁
      activeModal.componentInstance.EACSettingsColumn = new EACSettingsColumn(data.ID, data.EAC_ID, data.SetName, data.MAC, data.SID, data.Unit, data.SetValue, data.Note, data.LayX, data.LayY, data.IO_Type);//this.RealTimeColumn;
      activeModal.componentInstance.Title = data.SetName;
      //判斷在設定頁關閉後做SetData動作
      activeModal.result.then((result) => {
      
      });
    }

  }

  GetSysData() {
    let where= " where Enabled=1 ";
    if(localStorage.getItem("Account").toLocaleLowerCase()=="admin"){
      where="";
    }
    let sbody = {
      TopN: " ",
      StrWhere: where,
      Sort: " ",
      Key: localStorage.getItem("Token")
    };
    this.EAC.GetEACSysData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.SYS_Source = obj.filter((d) => String(d.EAC_ID)!="0");
        let EAC_Item = obj.filter((d) => String(d.EAC_ID)=="0");
        if(this.SYS_Source.length>0)
          this.EAC_ID=this.SYS_Source[0].EAC_ID;
        this.SYSChange();
      },
      err => { console.log(err); }
    );
  }
  SYSChange() {
    let Check_EAC = this.SYS_Source.filter((d) => String(d.EAC_ID) == String(this.EAC_ID));
    this.Layout_Path = "";
    this.Layout_Enable = "0";
    if (Check_EAC.length > 0) {
      this.SelectItem=Check_EAC;
      if (Check_EAC[0].LayoutEdit == 1&&localStorage.getItem("Account").toLocaleLowerCase()=="admin")
        this.Layout_Enable = "1";
      this.Layout_Path = Check_EAC[0].LayoutPath;
      this.RW_Enable = "0";
    } else {
      this.EAC_ID="-1";
      this.Layout_Enable = "0";
      this.RW_Enable = "0";
    }

    this.GetSettingsData();

  }


  
  GetSettingsData() {
    let sbody = {
      TopN: " ",
      StrWhere: " where EAC_ID='" + this.EAC_ID + "' ",
      Sort: " ",
      Key: localStorage.getItem("Token")
    };
    this.EAC.GetEACSetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.DataSource = obj;
       
        this.gridView = {
          data: orderBy(obj.filter(d => this.ShowIOType(d.IO_Type)), this.sort),
          total: obj.length
        };
        this.RealStatus = true;
      },
      err => { console.log(err); }
    );
  }
  IO_TypeChange(){
    this.gridView = {
      data: orderBy(this.DataSource.filter(d =>(this.RW_Enable=="0" && this.ShowIOType(d.IO_Type))||(this.RW_Enable=="1" &&(d.IO_Type==this.IO_Type||this.IO_Type=="所有類型"))), this.sort),
      total: this.DataSource.length
    };
  }
  //顯示IO
  ShowIOType(IO_Type){
    let Show=false;
    let ShowIO=["AO","DO","VAO","VDO","None"];
    if(ShowIO.indexOf(IO_Type)>-1)
      Show=true;
    return Show;
  }

  //移動後
  onMoveEnd(event, item) {
    item.LayX = event.x;
    item.LayY = event.y;
  }
  SaveSingle() {
    //console.log(this.DataSource.ID)

    let sbody = {
      ID: this.DataSource.ID,
      EAC_ID: this.DataSource.EAC_ID,
      SetName: this.DataSource.SetName,
      MAC: this.DataSource.MAC,
      SID: this.DataSource.SID,
      Unit: this.DataSource.Unit,
      SetValue: this.DataSource.SetValue,
      Note: this.DataSource.Note,
      Key: localStorage.getItem("Token")
    };
    var stralert = this.EAC.UpdateEACSetData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('修改成功');
      },
      err => { alert('修改失敗'); console.log(err); },
      () => { }
    );
  }
  SaveItem(data: any) {
    let sbody = {
      ID: data.ID,
      EAC_ID: data.EAC_ID,
      SetName: data.SetName,
      MAC: data.MAC,
      SID: data.SID,
      Unit: data.Unit,
      SetValue: data.SetValue,
      Note: data.Note,
      LayX: data.LayX,
      LayY: data.LayY,
      Enabled: data.Enabled,
      Key: localStorage.getItem("Token")
    };
    let stralert = this.EAC.UpdateEACSetData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        //alert('修改成功');
      },
      err => {
        //alert('修改失敗');console.log(err);
      },
      () => { }
    );
  }
  SaveAll() {
    for (let i in this.DataSource) {
      let sbody = {
        ID: this.DataSource[i].ID,
        EAC_ID: this.DataSource[i].EAC_ID,
        SetName: this.DataSource[i].SetName,
        MAC: this.DataSource[i].MAC,
        SID: this.DataSource[i].SID,
        Unit: this.DataSource[i].Unit,
        SetValue: this.DataSource[i].SetValue,
        Note: this.DataSource[i].Note,
        LayX: this.DataSource[i].LayX,
        LayY: this.DataSource[i].LayY,
        Enabled: this.DataSource[i].Enabled,
        Key: localStorage.getItem("Token")
      };
      let stralert = this.EAC.UpdateEACSetData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          //alert('修改成功');
        },
        err => {
          //alert('修改失敗');console.log(err);
        },
        () => { }
      );
    }
  }

  cancelChanges() {

  }

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  public onStateChange(state: State) {
    this.gridState = state;
  }

  public cellClickHandler({ sender, rowIndex, column, columnIndex, dataItem, isEdited }) {
    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      this.EAC.assignValues(dataItem, formGroup.value);
      this.DataSource = dataItem;
      this.DataSourceAll.push = dataItem;
    }
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      'SetValue': dataItem.SetValue
    });
  }

  public splitbutton_data: Array<any> = [];

  onFileChange(event) {
    let reader = new FileReader();
    let files = event.target.files;
    if(files && files.length) {
      const [file] = event.target.files;
      reader.onload = (e)=>{

        this.Layout_Path=String(reader.result);
        if(this.SelectItem.length>0){
          let sbody = {
            EAC_ID: this.SelectItem[0].EAC_ID,
            EAC_Name: this.SelectItem[0].EAC_Name,
            LayoutName: this.SelectItem[0].LayoutName,
            LayoutEdit: this.SelectItem[0].LayoutEdit,
            LayoutPath: this.Layout_Path,
            Enabled: this.SelectItem[0].Enabled,
            Key: localStorage.getItem("Token")
          };
          let stralert = this.EAC.UpdateEACSysData(sbody, this.MyCommon.SetHeaders());
          stralert.subscribe(
            (obj) => {
              this.MyCommon.CheckToken(obj);
              
            },
            err => {
              //alert('修改失敗');console.log(err);
            },
            () => { }
          );
        }
      };
      reader.readAsDataURL(file);
    }
  }
  
}
