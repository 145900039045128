import { NgModule,NO_ERRORS_SCHEMA ,LOCALE_ID  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule} from 'ngx-pagination';
import { ColorPickerModule } from 'ngx-color-picker';
import { TreeviewModule } from 'ngx-treeview';
import { NgbDropdownModule,NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HeaderComponent,SidebarComponent } from './layout/MasterPage';
import { SyFromComponent,SySideFromComponent,SyTagComponent,SyCheckboxListComponent } from './layout/components';

import 'hammerjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule,ExcelModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { ContextMenuModule } from '@progress/kendo-angular-menu';



import { AngularDraggableModule } from 'angular2-draggable';

const NgxModels = [
  NgxPaginationModule
];

/*主板 */
const MasterPage = [
  HeaderComponent,
  SidebarComponent
];

/*自訂模塊 */
const CustomComponent = [
  SyFromComponent,
  SySideFromComponent,
  SyTagComponent,
  SyCheckboxListComponent
];

/*kendo 組件*/
const KendoModels = [
  //BrowserAnimationsModule,
  ChartsModule,
  LayoutModule,
  IntlModule,
  DateInputsModule,
  GridModule,
  DropDownsModule,
  ButtonsModule,
  PopupModule,
  ExcelModule,
  ExcelExportModule,
  ContextMenuModule
];


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ...NgxModels,
    NgbDropdownModule.forRoot(),
    NgbModule.forRoot(),
    ColorPickerModule,
    TreeviewModule.forRoot(),
    AngularDualListBoxModule,
    ...KendoModels,
    AngularDraggableModule
  ],
  providers: [
    //{ provide: LOCALE_ID, useValue: 'zh-Hant' }
  ],
  declarations: [
    ...MasterPage,
    ...CustomComponent
  ],exports:[
    ...MasterPage,
    ...CustomComponent,
    CommonModule, 
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ...NgxModels,
    NgbDropdownModule,
    NgbModule,
    ColorPickerModule,
    TreeviewModule,
    AngularDualListBoxModule,
    ...KendoModels,
    AngularDraggableModule 
  ],
  entryComponents: [
  
  ],
  schemas:[ NO_ERRORS_SCHEMA ]
  })
export class WebCommonModule { 
}
