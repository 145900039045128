import { Component, OnInit ,Inject} from '@angular/core';
import { ReportService } from '../../../DataService/Report.service';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-ReportSetting',
  templateUrl: './ReportSetting.component.html',
  providers: [ReportService, MyCommon,Permissions]
})
export class ReportSettingComponent implements OnInit {

  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

//查詢參數
  Search_Category:any="Rpt_Name";
  Search_Keyword:any="";
  Search_Enable:any="-1";

  public SelectItem;

  constructor(
    @Inject(ReportService) private ReportData: ReportService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

ngOnInit() {
   //判斷頁面權限
      let sbody = {
        UrlList:"ReportSettingView,ReportSettingAdd,ReportSettingEdit,ReportSettingDelete",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
            if(Site.path=="ReportSettingView")
              this.ViewPermissions=true;
            if(Site.path=="ReportSettingAdd")
              this.AddPermissions=true;
            if(Site.path=="ReportSettingEdit")
              this.EditPermissions=true;
            if(Site.path=="ReportSettingDelete")
              this.DeletePermissions=true;
          }

          if(this.EditPermissions){
            this.splitbutton_data.push(
            {
              text: '修改',
              iconClass: 'fa fa-pencil-square-o',
              click: () => {this.router.navigate(['/pages/ReportSettingEdit', this.SelectItem.Rpt_ID]); }
            });
          }
          if(this.DeletePermissions){
            this.splitbutton_data.push(
            {
              text: '刪除',
              iconClass: 'fa fa-trash-o',
              click: () => { this.Delete(this.SelectItem);}
            });
          }

          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
        this.GetData();
  }

  public splitbutton_data: Array<any> = [];

  public onPaste(obj): void {
    this.SelectItem=obj;
  }

 //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GetData();
  }

  //取得系統資料
  GetData() {
     var StrWhere=" Where 1=1";
    if (this.Search_Enable !="-1"){
      StrWhere +=" AND Rpt_Enabled='"+this.Search_Enable+"'";
    }
    if (this.Search_Keyword !=""){
      StrWhere +=" AND "+this.Search_Category+" Like '%"+this.Search_Keyword+"%'";
    }
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.ReportData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data: orderBy(obj, this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }
  //刪除資料
  Delete(del: any){
    if (confirm("確定要刪除嗎?")) {
      let sbody = {
        Rpt_ID: del.Rpt_ID,
        Key:localStorage.getItem("Token")
      };
      var stralert = this.ReportData.DeleteData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.GetData();},
        err => alert('刪除失敗'),
        () => { }
      );
      }
  }

  Search(){
    this.GetData();
  }

}