import { Component, OnInit, Inject } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy,groupBy  } from '@progress/kendo-data-query';
import { ActoolsCtrlService } from '../../../DataService/ActoolsCtrl.service';
import { SystemService } from '../../../DataService/System.service';
import { IOService } from '../../../DataService/IO.service';
import { DevService } from '../../../DataService/Dev.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ScheduleColumn } from '../ScheduleColumn'
import { Permissions } from '../../../DataService/User.service';
import { IOCtrlSettingColumn } from '../IOCtrlSettingColumn';

@Component({
  selector: 'app-ScheduleEdit',
  templateUrl: './ScheduleEdit.component.html',
  styleUrls: ['./ScheduleEdit.component.scss'],
  providers: [ActoolsCtrlService, SystemService, IOService,DevService, MyCommon,Permissions]
})
export class ScheduleEditComponent implements OnInit {

  //下拉選單資料
  //系統資料
  SYS_Source: any;
  //IO資料
  IO_Source: any;
    //設備
    Dev_Source: any=[];
  //執行周期間隔資料
  Week_Interval_Source = [{ id: 0, name: '連續無間斷/0' }, { id: 1, name: '走一週休一週/1' }, { id: 2, name: '走二週休一週/2' },{ id: 3, name: '走一週休二週/3' }, { id: 4, name: '走二週休二週/4' }];
  //模式設定資料
  ConditionControlMode_Source = [{ id: 1, name: '無條件控制' }, { id: 2, name: '在條件ON時啟動' }, { id: 3, name: '在條件OFF時啟動' }];
  //每周設定資料
   //每周設定資料
   public Operation_Period_Source = 
   [{value:1,text:"週一",enable:true},
   {value:2,text:"週二",enable:true},
   {value:3,text:"週三",enable:true},
   {value:4,text:"週四",enable:true},
   {value:5,text:"週五",enable:true},
   {value:6,text:"週六",enable:true},
   {value:7,text:"週日",enable:true}];
 
   Operation_Period:string="";

  //DatePicker
  StartDate: any = '2017/01/01 00:00:00';
  EndDate: any = '2017/01/01 00:00:00';

  public SYS_ID: any;
  public IO_Data: any;
  public IO_Type: any = "";
  public TextValue: any = '0';
  public SelectValue: any = '0';
  //預設欄位
  ScheduleColumn = new ScheduleColumn(-1,new Date(), new Date(), '', new Date(), new Date(), 0, '0',0, 0, 0, 0, '1','','');
  E_ID: any;
  public gridView: GridDataResult;
  public GridData:any=[];
  public sort: SortDescriptor[]=[{field: 'Sort',dir: 'asc'}];
  public IOCtrl_Condition_Data:any;
    //條件
    Conditional:string;
    //預設欄位
    IOCtrlSettingColumn = new IOCtrlSettingColumn(new Date(), new Date(), new Date(),'',0,0,0,0,'');

  public ViewPermissions:boolean=false;

  constructor( @Inject(ActoolsCtrlService) private ActoolsCtrl: ActoolsCtrlService,
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(DevService) private DevData: DevService,
    @Inject(IOService) private IOData: IOService,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router,
    private activatedRoute: ActivatedRoute, ) { }
  ngOnInit() {
      //判斷頁面權限
      let sbody = {
        UrlList:"ScheduleEdit",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="ScheduleEdit")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );

    this.SetData();
    this.GetDevData();
  }

  //取得系統資料
  GetSysData() {
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.SYS_Source = obj;
        this.GetIOData();
      },
      err => { console.log(err); }
    );
  }
   //取得設備資料
   GetDevData(){
    var StrWhere = " where  DEV.DataType=0";

    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DevData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.Dev_Source =obj;
      },
      err => { console.log(err); }
    );
  }
  //取得IO資料
  GetIOData() {
    var StrWhere = " where IO_Info.SYS_ID='" + this.SYS_ID + "' AND IO_Info.IO_Type in('DO','AO') ";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.IOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.IO_Source = obj;
      },
      err => { console.log(err); }
    );
  }

  SYSChange() {
    this.GetIOData();
  }

  IOChange() {
    var IODataList = String(this.IO_Data).split('-');
    this.ScheduleColumn.SC_MAC = Number(IODataList[0]);
    this.ScheduleColumn.SC_S = Number(IODataList[1]);
    this.IO_Type = IODataList[2];
  }
  //載入資料
  SetData() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.E_ID = params['id'];
    });
    var StrWhere = " where E_ID=" + this.E_ID;
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.ActoolsCtrl.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.StartDate = new Date("2017/" + obj[0].Start_Date + " " + obj[0].Start_Time.slice(0, -3));
        this.EndDate = new Date("2017/" + obj[0].End_Date + " " + obj[0].End_Time.slice(0, -3));

        this.ScheduleColumn.Start_Date = this.StartDate;
        this.ScheduleColumn.End_Date = this.EndDate;
        this.ScheduleColumn.Operation_Period = obj[0].Operation_Period;
        this.ScheduleColumn.Start_Time = this.StartDate;
        this.ScheduleColumn.End_Time = this.EndDate;
        this.ScheduleColumn.ModeType = obj[0].ModeType,
        this.ScheduleColumn.CdnCtrlMode = String(obj[0].CdnCtrlMode);
        this.ScheduleColumn.Week_Interval = obj[0].Week_Interval;
        this.ScheduleColumn.SC_MAC = obj[0].SC_MAC;
        this.ScheduleColumn.SC_S = obj[0].SC_S;
        this.ScheduleColumn.SetValue = obj[0].SetValue;
        this.ScheduleColumn.SC_Enabled = String(obj[0].SC_Enabled);
        this.IO_Data = obj[0].IO_Data;
        this.IO_Type = obj[0].IO_Type;
        this.SYS_ID = obj[0].SYS_ID;
        if (this.IO_Type == 'DO')
          this.SelectValue = this.ScheduleColumn.SetValue;
        else
          this.TextValue = this.ScheduleColumn.SetValue;
        this.GetSysData();
        this.SetCheckBox();
        
        var StrWhere2 = " where IOCtrl_ID=" + this.E_ID;
        let sbody2 = {
          TopN: " ",
          StrWhere: StrWhere2,
          Sort: " ",
          Key:localStorage.getItem("Token")
        };
        this.ActoolsCtrl.GetConditionData(sbody2, this.MyCommon.SetHeaders()).subscribe(
          (obj) => {
            this.GridData=obj;
            this.GetData();
          });
     
      
      }
    );
  }

  //載入每週設定
  SetCheckBox() {
    if(this.ScheduleColumn.Operation_Period!=""){
      var week = this.ScheduleColumn.Operation_Period.split(',');
      for (var item of week) {
        item = String(Number(item) - 1);
        this.Operation_Period_Source[item].checked = true;
      }
    }
  }

  clicked() {
    if (confirm("確定要更新嗎?")) {
      this.Update();
    }
  }
  GotoCtrlIOSetting(){
    localStorage.setItem('DevID',this.ScheduleColumn.SC_MAC+"-S"+this.ScheduleColumn.SC_S);
    this.router.navigate(['/pages/IOCtrlSetting/']);
  }

  //修改
  Update() {
    //判斷是否有勾選每週設定
    if (this.ScheduleColumn.Operation_Period == "" &&( this.ScheduleColumn.ModeType==2 || this.ScheduleColumn.ModeType==4)) {
      alert("請確認每週設定!");
      return false;
    }

    //放控制值
    if (this.IO_Type == 'DO')
      this.ScheduleColumn.SetValue = this.SelectValue;
    else
      this.ScheduleColumn.SetValue = this.TextValue;

    if (this.ScheduleColumn.SC_MAC <1 || this.ScheduleColumn.SC_S<1){
      alert("請選擇IO!");
      return false;
    }
    if (this.ScheduleColumn.ModeType<1){
      alert("請選擇模式設定!");
      return false;
    }
    if (String(this.ScheduleColumn.SetValue).trim()==''){
      alert("控制值不能為空!");
      return false;
    }

    let sbody = {
      E_ID: this.E_ID,
      Start_Date: String(this.MyCommon.SetZero(Number(this.ScheduleColumn.Start_Date.getMonth()) + 1))+"/"+this.MyCommon.SetZero(this.ScheduleColumn.Start_Date.getDate()),
      End_Date:String(this.MyCommon.SetZero(Number(this.ScheduleColumn.End_Date.getMonth()) + 1))+"/"+this.MyCommon.SetZero(this.ScheduleColumn.End_Date.getDate()),
      Operation_Period: this.ScheduleColumn.Operation_Period,
      Start_Time: this.MyCommon.FormateTime2(this.ScheduleColumn.Start_Time)+":00",
      End_Time: this.MyCommon.FormateTime2(this.ScheduleColumn.End_Time)+":00",
      ModeType: this.ScheduleColumn.ModeType,
      CdnCtrlMode: this.ScheduleColumn.CdnCtrlMode,
      Week_Interval: this.ScheduleColumn.Week_Interval,
      SC_MAC: this.ScheduleColumn.SC_MAC,
      SC_S: this.ScheduleColumn.SC_S,
      SetValue: this.ScheduleColumn.SetValue,
      SC_Enabled: Number(this.ScheduleColumn.SC_Enabled),
      CtrlCondition:this.Conditional,
      Condition_Data:this.IOCtrl_Condition_Data,
      Key:localStorage.getItem("Token")
    };
    var stralert = this.ActoolsCtrl.UpdateData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('修改成功');
        this.router.navigate(['/pages/Schedule']);
      },
      err => { alert('修改失敗'); console.log(err); },
      () => { }
    );
  }
    //顯示條件
    ShowConditional(){
      //重新排序
      let RowData=this.GridData.sort(function(a, b){ return ((a.Groups*100)+ a.Sort ) - ((b.Groups*100)+b.Sort)});//.sort(function(a, b){ return a.Sort - b.Sort});
      //群組資料
      let FinalData=groupBy(RowData, [{ field: "Groups" }]);
      this.Conditional='';
      for(let i in FinalData){
        let GroupLogic='',GroupCalc='';
        for(let j in FinalData[i]["items"]){
          let item=FinalData[i]["items"][j];
          let Parameter1="",Parameter2="";
          if(Number(item["Parameter1"])==-1)
            Parameter1=item["P1Value"];
          else
            Parameter1='['+item["Parameter1"]+'-S'+item["P1Value"]+']';
  
          if(Number(item["Parameter2"])==-1)
            Parameter2=item["P2Value"];
          else
            Parameter2='['+item["Parameter2"]+'-S'+item["P2Value"]+']';
  
          let Calc=''
          switch (Number(item["Judgment"])) {
            case 1:
            Calc='('+Parameter1+'='+Parameter2+')';
              break;
            case 2:
            Calc='('+Parameter1+'>'+Parameter2+')';
              break;
            case 3:
            Calc='('+Parameter1+'<'+Parameter2+')';
              break;
            case 4:
            Calc='('+Parameter1+'<='+Parameter2+')';
              break;
            case 5:
            Calc='('+Parameter1+'>='+Parameter2+')';
              break;
          }
          if(Number(j)>0){
            switch (Number(item["Logic"])) {
              case 1:
                Calc = " & "+Calc;
                break;
              case 2:
                Calc = " | "+Calc;
                break;
            }
          }else if (Number(j)==0&&Number(i)>0){
            switch (Number(item["Logic"])) {
              case 1:
                GroupLogic = " & ";
                break;
              case 2:
                GroupLogic = " | ";
                break;
            }
          }
          GroupCalc+=Calc;
        }
        if (FinalData[i]["items"].length == 1)
          this.Conditional += GroupLogic+GroupCalc;
        else
        this.Conditional += GroupLogic + '(' + GroupCalc + ')';
      }
       this.IOCtrlSettingColumn.CtrlCondition=this.Conditional;
       this.IOCtrl_Condition_Data=this.gridView.data;
    }
     //取得資料
   GetData() {
    this.gridView = {
      data: orderBy(this.GridData, this.sort),
      total: this.GridData.length
    };
    this.ShowConditional();
  }
  onItemInsert(){
    let MaxID=0,DefMACID=-1,MaxSort=0;
     //取得最大ID與排序
     for(let i in this.GridData){
      if(MaxID<this.GridData[i].ID)
        MaxID=this.GridData[i].ID;
      if(MaxSort<this.GridData[i].Sort)
        MaxSort=this.GridData[i].Sort;
    }
    //取得預設MACID
    if(this.Dev_Source.length>0){
      DefMACID=this.Dev_Source[0].MACID;
    }
    //新增資料
    this.GridData.push({ID:MaxID+1,IOCtrl_ID:1,Parameter1:DefMACID,P1Value:1,Judgment:1,Parameter2:-1,P2Value:1,Groups:-1,Sort:MaxSort+1,Logic:1});
  
    this.gridView = {
      data: orderBy(this.GridData, this.sort),
      total: this.GridData.length
    };
    this.ShowConditional();  
  }
  onItemDelete(item: any){
    //重新排序
    this.GridData=this.GridData.filter(function (d) {return d.ID != item.ID;}).sort(function(a, b){ return a.Sort - b.Sort});
    for(let i in this.GridData){
      this.GridData[i].Sort=(Number(i)+1);
    }
    this.gridView = {
      data: orderBy(this.GridData, this.sort),
      total: this.GridData.length
    };
    this.ShowConditional();
  }
  onItemSort(item: any,SortType:number){
    //取得替換的排序並重新設定
    let SortItem = this.GridData.filter(task => task.Sort == (item.Sort+SortType));
    if(SortItem.length>0){
      SortItem[0].Sort+=(SortType*-1);
    }
    //改變當列排序
    item.Sort+=SortType;
    
    this.gridView = {
      data: orderBy(this.GridData, this.sort),
      total: this.GridData.length
    };
    this.ShowConditional();
  }
  //離開
  back() {
    this.router.navigate(['/pages/Schedule']);
  }
}