import { Component, OnInit ,Inject} from '@angular/core';
import { MyCommon } from '../../../App_Code/MyCommon';
import { EACService } from '../../../DataService/EAC.service';

@Component({
  selector: 'app-EACSettings',
  templateUrl: './EACSettings.component.html',
  styleUrls: ['./EACSettings.component.css'],
  providers: [EACService, MyCommon]
})
export class EACSettingsComponent implements OnInit {

  public EAC_SYS_Source: any;
  public EAC_ID: any;
  public EAC_Item: any;
  public EAC_SetParameter: String;

  public EAC_Count:any=[];

  constructor(
    @Inject(EACService) private EAC: EACService,
    @Inject(MyCommon) private MyCommon: MyCommon,
  ) { }

  ngOnInit() {
    this.EAC_SetParameter="0-1-1-0";
    this.GetEACSysData();


  }
  onFileChange(item,event) {
    let reader = new FileReader();
    let files = event.target.files;
    if(files && files.length) {
      const [file] = event.target.files;
      reader.onload = function(reader_Event) {
        item.LayoutPath=reader_Event.target["result"];
      };
      reader.readAsDataURL(file);
    }
  }
  

  onUpdate(){
    if (confirm("確定要更新嗎?")) {
      this.Update();
    }
    
  }

  //修改
  Update() {
    if(this.EAC_Item.length>0){
      let sbody = {
        EAC_ID: this.EAC_Item[0].EAC_ID,
        EAC_Name: this.EAC_Item[0].EAC_Name,
        LayoutName: this.EAC_SetParameter,
        LayoutEdit: this.EAC_Item[0].LayoutEdit,
        LayoutPath: this.EAC_Item[0].LayoutPath,
        Enabled: this.EAC_Item[0].Enabled,
        Key: localStorage.getItem("Token")
      };
      let stralert = this.EAC.UpdateEACSysData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          alert('更新完成');
        },
        err => {
          //alert('修改失敗');console.log(err);
        },
        () => { }
      );
    }
  }
  
  GetEACSysData() {
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key: localStorage.getItem("Token")
    };
    this.EAC.GetEACSysData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        if(obj.length>0){
          this.EAC_SYS_Source = obj.filter((d) => String(d.EAC_ID)!="0");
          this.EAC_Item=obj.filter((d) => String(d.EAC_ID)=="0");
          if(this.EAC_Item.length>0){
            this.EAC_SetParameter=this.EAC_Item[0]["LayoutName"];
            this.EAC_ID=this.EAC_SetParameter.split('-')[0];
          }
        }

        this.MyCommon.CheckToken(obj);
       
      
      },
      err => { console.log(err); }
    );
  }

  onSysChange(value){
    if(this.EAC_ID=="0"){
      this.EAC_SetParameter=this.EAC_ID+"-1-1-0";
    }else{
      this.EAC_SetParameter=this.EAC_ID+"-1";
    }
  }

  onSysCountChange(value){
    let EAC_Name=this.EAC_SetParameter.split('-');
    if(EAC_Name.length>=2){
      let SetEAC_Name="";
      for(let i in EAC_Name){
        if(i=="1"){
          SetEAC_Name+=value+(Number(i)<EAC_Name.length-1?"-":"");
        }
        else{
          
          SetEAC_Name+=EAC_Name[i]+(Number(i)<EAC_Name.length-1?"-":"");
        }
        this.EAC_SetParameter=SetEAC_Name;
      }
    }else{
      if(this.EAC_ID=="0"){
        this.EAC_SetParameter=this.EAC_ID+"-"+value+"-1-0";
      }else{
        this.EAC_SetParameter=this.EAC_ID+"-"+value;
      }
    }
  }

  onTowerCountChange(item,value){
    let EAC_Name=item.EAC_Name.split('-');
    if(EAC_Name.length>=3){
      let SetEAC_Name="";
      for(let i in EAC_Name){
        if(i=="2"){
          SetEAC_Name+=value+(Number(i)<EAC_Name.length-1?"-":"");
        }
        else{
          SetEAC_Name+=EAC_Name[i]+(Number(i)<EAC_Name.length-1?"-":"");
        }
        item.EAC_Name=SetEAC_Name;
      }
    }else{
      item.EAC_Name=item.EAC_ID+"-1-"+value+"-0";
    }
  }

  onSysTypeChange(value){
    let EAC_Name=this.EAC_SetParameter.split('-');
    if(EAC_Name.length>=4){
      let SetEAC_Name="";
      for(let i in EAC_Name){
        if(i=="3"){
          SetEAC_Name+=value+(Number(i)<EAC_Name.length-1?"-":"");
        }
        else{
          SetEAC_Name+=EAC_Name[i]+(Number(i)<EAC_Name.length-1?"-":"");
        }
        this.EAC_SetParameter=SetEAC_Name;
      }
    }else{
      this.EAC_SetParameter=this.EAC_ID+"-1-1-"+value;
    }
  }

}
