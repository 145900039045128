import { Component, OnInit,Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { MyCommon } from '../../App_Code/MyCommon';
import { SystemService,Permissions,IBas_System_SettingService } from '../../DataService';

@Component({
  selector: 'app-Default',
  templateUrl: './Default.component.html',
  providers: [Permissions, SystemService, IBas_System_SettingService, MyCommon]
})
export class DefaultComponent implements OnInit {

  public ViewPermissions:boolean=false;

  constructor(
    private PermissionsData: Permissions,
    private SystemData: SystemService,
    private MyCommon: MyCommon,
    private System_Setting: IBas_System_SettingService,
    private router: Router
  ) { }

  ngOnInit() {
      //判斷頁面權限
        let sbody = {
          UrlList:"Itri",
          Key:localStorage.getItem("Token")
        };

        var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
        stralert.subscribe(
          (obj) => {
            this.MyCommon.CheckToken(obj);
            for (let Site of obj) {
              if(Site.path=="Itri")
                this.ViewPermissions=true;
            }
            try{
              this.CheckFirstLogin();
            }catch(err){}
            try{
                  if(!this.ViewPermissions){
                    if(obj[0].path=="RealTime"){
                            let sbody2 = {
                              TopN: " ",
                              StrWhere: " where Sys_Enabled=1",
                              Sort: " ",
                              Key:localStorage.getItem("Token")
                            };
                            this.SystemData.GetData(sbody2, this.MyCommon.SetHeaders()).subscribe(
                              (obj) => {
                                this.router.navigate(['/pages/RealTime', obj[0]["ID"]]);
                              },
                              err => { console.log(err); this.router.navigate(['/pages/RealTime']); }
                            );
                            
                      }else{
                          this.router.navigate(['/pages/'+obj[0].path]);
                      }
                  }
            }catch(err){}
          },
          err => {  },
          () => { }
        );
    }
    CheckFirstLogin(){
      let sbody = {
        TopN: " ",
        StrWhere: " ",
        Sort: " ",
        Key:localStorage.getItem("Token")
      };
      this.System_Setting.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          let Data=obj;
          if(Data[0].Enabled=="0"){
            this.router.navigate(['/FirstSetting']);
          }
        },
        err => { console.log(err); }
      );
    }
}
