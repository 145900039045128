import { Component, OnInit,Inject } from '@angular/core';
import { MyCommon } from '../../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { iBas_Permissions,iBas_UserService,iBas_UserGroupService } from '../../../../DataService';

@Component({
  selector: 'app-ibas_User_Edit',
  templateUrl: './ibas_User_Edit.component.html',
  styleUrls: ['./ibas_User_Edit.component.scss'],
   providers: [iBas_UserService,iBas_UserGroupService,iBas_Permissions, MyCommon]
})
export class ibas_User_EditComponent implements OnInit {

Group_Source:any;
U_ID:any
public FromData:any;
confirm_Passwd:any;

 public ViewPermissions:boolean=false;

  constructor(
    private UserData: iBas_UserService,
    private UserGroupData: iBas_UserGroupService,
    private PermissionsData: iBas_Permissions,
    private MyCommon: MyCommon,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    //判斷頁面權限
      let sbody = {
        UrlList:"UserEdit",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="UserEdit")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
     this.activatedRoute.params.subscribe(params => {
          this.U_ID = params['id'];
           this.SetGroup();
            this.GetData();
      });
  }

  SetGroup(){
  var StrWhere = "";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.UserGroupData.GetData(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
       this.Group_Source=obj;
      },
      err => { console.log(err); }
    );
}


GetData() {
  var StrWhere = " Where User.U_ID="+this.U_ID;
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    
      this.UserData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
         this.FromData = obj;
         if (this.FromData[0].U_Enabled)
          this.FromData[0].U_Enabled="1";
         else
          this.FromData[0].U_Enabled="0";

        if (this.FromData[0].IsCtrl)
          this.FromData[0].IsCtrl="1";
         else
          this.FromData[0].IsCtrl="0";

          this.confirm_Passwd=this.FromData[0].U_Passwd;
        },
        err => { console.log(err); }
      );
    }

clicked(){
 if (confirm("確定要修改嗎?")) {
    if (this.confirm_Passwd==this.FromData[0].U_Passwd)
        this.Update();
    else
        alert("密碼與確認密碼不符!")
      }
}

Update(){


 if (String(this.FromData[0].U_Name).trim()=='' || String(this.FromData[0].U_Account).trim()=='' 
  || String(this.FromData[0].U_Passwd).trim()=='' || this.FromData[0].w_UGrp_ID<1){
   alert("請確認名稱、帳號、密碼、群組是否有填寫!");
   return false;
 }

 let sbody = {
     "U_ID": Number(this.U_ID),
  "U_Name": this.FromData[0].U_Name,
  "U_Account": this.FromData[0].U_Account,
  "U_Passwd": this.FromData[0].U_Passwd,
  "U_Date": this.FromData[0].U_Date,
  "U_Enabled": Number(this.FromData[0].U_Enabled),
  "w_UGrp_ID": Number(this.FromData[0].w_UGrp_ID),
  "U_Mobile":  String(this.FromData[0].U_Mobile),
  "U_Email":  String(this.FromData[0].U_Email),
  "IsCtrl": Number(this.FromData[0].IsCtrl),
      Key:localStorage.getItem("Token")
    };

     var stralert = this.UserData.UpdateData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          alert('修改成功');
          this.router.navigate(['/ibas/User']);
        },
        err => { alert('修改失敗'); console.log(err); },
        () => { }
      );
}

//離開
  back() {
    this.router.navigate(['/ibas/User']);
  }

}