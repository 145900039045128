import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class ACTools_ScheduleService {

constructor(@Inject(HttpClient)  
        private http : HttpClient,
        public MyCommon:MyCommon) { }

  GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('Schedule');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    InsertData(sbody:any,headers:any=""){
     var url = this.MyCommon.GetAPISPUrl('ScheduleData');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('ScheduleData');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    DeleteData(sbody:any,headers:any=""){
        var sbody;
       var url = this.MyCommon.GetAPISPUrl('ScheduleDelete');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    /*GetNewID(headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetACTools_ScheduleNewID');
        return this.http.get(url,{headers: headers}).map(res=>JSON.parse(JSON.stringify(res))); //jsondata
    }*/
}