import { Component, Input } from '@angular/core';

@Component({
  selector: 'sy-SideFrom',
  templateUrl: './sy-SideFrom.component.html',
  styleUrls: ['./sy-SideFrom.component.scss'],
 
})
export class SySideFromComponent {
  @Input() SideWidth:String;
}
