import { Component, OnInit ,Inject} from '@angular/core';
import { DisconnAlarmService } from '../../../DataService/Alarm.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-DisconnAlarmSetting_Edit',
  templateUrl: './DisconnAlarmSetting_Edit.component.html',
  styleUrls: ['./DisconnAlarmSetting_Edit.component.scss'],
    providers: [DisconnAlarmService, MyCommon]
})
export class DisconnAlarmSetting_EditComponent implements OnInit {

  MACID:any;
    private sub:any;
    public FromData:string[];

  constructor(
    @Inject(DisconnAlarmService) private DisconnAlarmData: DisconnAlarmService,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
          this.MACID = params['id'];
            this.GetData();
      });
  }




  GetData() {
      let sbody = {
        TopN: " ",
        StrWhere: " where DEV.MACID="+this.MACID+" and DEV.DataType=0",
        Sort: " ",
        Key:localStorage.getItem("Token")
      };
      this.DisconnAlarmData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
         this.MyCommon.CheckToken(obj);

         this.FromData = obj;

        },
        err => { console.log(err); }
      );
    }

    clicked() {
    if (confirm("確定要更新嗎?")) {
        this.Update();
     }
  }

  Update() {
/*
    if (this.FromData[0]["Alarm_Enabled"].toString()=="true")
      this.FromData[0]["Alarm_Enabled"]=1;
    else
      this.FromData[0]["Alarm_Enabled"]=0;

    if (this.FromData[0]["SMS_Enabled"].toString()=="true")
      this.FromData[0]["SMS_Enabled"]=1;
    else
      this.FromData[0]["SMS_Enabled"]=0;

    if (this.FromData[0]["Mail_Enabled"].toString()=="true")
      this.FromData[0]["Mail_Enabled"]=1;
    else
      this.FromData[0]["Mail_Enabled"]=0;

    if (this.FromData[0]["Line_Enabled"].toString()=="true")
      this.FromData[0]["Line_Enabled"]=1;
    else
      this.FromData[0]["Line_Enabled"]=0;
*/
   let sbody = 
     {
        MACID:this.MACID,
        DevName:this.FromData[0]["DevName"],
        Alarm_Enabled:this.FromData[0]["Alarm_Enabled"],
        SMS_Enabled:this.FromData[0]["SMS_Enabled"],
        Mail_Enabled:this.FromData[0]["Mail_Enabled"],
        Line_Enabled:this.FromData[0]["Line_Enabled"],
        Key:localStorage.getItem("Token")
      };

     var stralert = this.DisconnAlarmData.UpdateData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          alert('修改成功');
          this.router.navigate(['/pages/DisconnAlarmSetting']);
        },
        err => { alert('修改失敗'); console.log(err); },
        () => { }
      );
      
  }
  back(){
      this.router.navigate(['/pages/DisconnAlarmSetting']);
  }


}
