import { Component, OnInit, Inject } from '@angular/core';
import { DemandAlarmService } from '../../DataService/DemandAlarm.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../DataService/User.service';

@Component({
  selector: 'app-MeterDemandAlarmSetting',
  templateUrl: './MeterDemandAlarmSetting.component.html',
  providers: [ MyCommon,DemandAlarmService,Permissions]
})
export class MeterDemandAlarmSettingComponent implements OnInit {
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  //查詢參數
  Search_Category:any="DEV.DevName";
  Search_KeyWord:any="";
  IO_Type:any="-1";
  Search_Enable:any="-1";

  public splitbutton_data: Array<any> = [];
  public SelectItem;

  constructor( 
  @Inject(DemandAlarmService) private DemandAlarmData: DemandAlarmService,
  @Inject(Permissions) private PermissionsData: Permissions,
  @Inject(MyCommon) private MyCommon: MyCommon,
  private router: Router) { }

  ngOnInit() {
    //判斷頁面權限
    let sbody = {
      UrlList:"MeterDemandAlarmSettingView,MeterDemandAlarmSettingAdd,MeterDemandAlarmSettingEdit,MeterDemandAlarmSettingDelete",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
            if(Site.path=="MeterDemandAlarmSettingView")
                this.ViewPermissions=true;
            if(Site.path=="MeterDemandAlarmSettingAdd")
                this.AddPermissions=true;
            if(Site.path=="MeterDemandAlarmSettingEdit")
                this.EditPermissions=true;
            if(Site.path=="MeterDemandAlarmSettingDelete")
                this.DeletePermissions=true;
        }
        if(this.EditPermissions){
          this.splitbutton_data.push(
          {
            text: '修改',
            iconClass: 'fa fa-pencil-square-o',
            click: () => {this.router.navigate(['/pages/MeterDemandAlarmSettingEdit', this.SelectItem.PM_ID]); }
          });
        }
        if(this.DeletePermissions){
          this.splitbutton_data.push(
          {
            text: '刪除',
            iconClass: 'fa fa-trash-o',
            click: () => { this.Delete(this.SelectItem);}
          });
        }
        try{
          this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
        }catch(err){}
      },
      err => {  },
      () => { }
    );
    this.GetData();
  }

  Search(){
    this.skip=0;
    this.GetData();
  }
  public onPaste(obj): void {
    this.SelectItem=obj;
  }
  //取得資料
  GetData() {
    var StrWhere=" Where 1=1";

    if (this.Search_KeyWord !=""){
      StrWhere +=" AND "+this.Search_Category+" Like '%"+this.Search_KeyWord+"%'";
    }

    if (this.Search_Enable !="-1"){
      StrWhere +=" AND enabled='"+this.Search_Enable+"'";
    }

    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DemandAlarmData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data: orderBy(obj.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }
  //刪除資料
  Delete(del: any) {
    if (confirm("確定要刪除嗎?")) {
      let sbody = {
        PM_ID: del.PM_ID,
        Key:localStorage.getItem("Token")
      };
      var stralert = this.DemandAlarmData.DeleteData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
            this.MyCommon.CheckToken(obj);
            this.GetData(); },
        err => alert('刪除失敗'),
        () => { }
      );
    }
  }
   //排序
   sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    //this.isToken();
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }
}
