import { Component, OnInit,Inject } from '@angular/core';
import { MyCommon } from '../../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { iBas_Permissions,iBas_UserService,iBas_UserGroupService } from '../../../../DataService';

@Component({
  selector: 'app-ibas_User_Add',
  templateUrl: './ibas_User_Add.component.html',
  styleUrls: ['./ibas_User_Add.component.scss'],
  providers: [iBas_UserService,iBas_UserGroupService,iBas_Permissions, MyCommon]
})
export class ibas_User_AddComponent implements OnInit {

Group_Source:any;
confirm_Passwd:any;
U_ID:any=1;
  U_Name:any='';
  U_Account:any='';
  U_Passwd:any='';
  U_Date:any;
  U_Enabled:any='1';
  w_UGrp_ID:any=0;
  U_Mobile:any;
  U_Email:any;
  IsCtrl:any='0';
  

 public ViewPermissions:boolean=false;

  constructor(
    private UserData: iBas_UserService,
    private UserGroupData: iBas_UserGroupService,
    private PermissionsData: iBas_Permissions,
    private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
      //判斷頁面權限
    let sbody = {
      UrlList:"UserAdd",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
            if(Site.path=="UserAdd")
                this.ViewPermissions=true;
        }
        try{
          this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
        }catch(err){}
      },
      err => {  },
      () => { }
    );
    this.SetGroup();
  }

SetGroup(){
  var StrWhere = "";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.UserGroupData.GetData(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
       this.Group_Source=obj;
      },
      err => { console.log(err); }
    );
}

clicked(){
 if (confirm("確定要新增嗎?")) {
    if (this.confirm_Passwd==this.U_Passwd)
        this.Insert();
    else
        alert("密碼與確認密碼不符!")
      }
}

Insert() {
var sNowDate = new Date();
this.U_Date=sNowDate.getFullYear()+'-'+String(Number(sNowDate.getMonth()) + 1)+'-'+sNowDate.getDate();

 if (String(this.U_Name).trim()=='' || String(this.U_Account).trim()=='' 
  || String(this.U_Passwd).trim()=='' || this.w_UGrp_ID<1){
   alert("請確認名稱、帳號、密碼、群組是否有填寫!");
   return false;
 }
 
 
 let sbody = {
  "U_Name": this.U_Name,
  "U_Account": this.U_Account,
  "U_Passwd": this.U_Passwd,
  "U_Date": this.U_Date,
  "U_Enabled": Number(this.U_Enabled),
  "w_UGrp_ID": Number(this.w_UGrp_ID),
  "U_Mobile":  this.U_Mobile==null?"":String(this.U_Mobile),
  "U_Email":  this.U_Email==null?"":String(this.U_Email),
  "IsCtrl": Number(this.IsCtrl),
      Key:localStorage.getItem("Token")
    };
    var stralert = this.UserData.InsertData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('新增成功');
        this.router.navigate(['/ibas/User']);
      },
      err => { alert('新增失敗'); },
      () => { }
    );
}

 //離開
  back() {
    this.router.navigate(['/ibas/User']);
  }

}