import { NgModule,NO_ERRORS_SCHEMA,LOCALE_ID  } from '@angular/core';
import { WebCommonModule } from '../common.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ibas_routing } from './ibas_pages.routing';
import { iBas_PagesComponent } from './ibas_pages.component';

import { iBas_DefaultComponent } from './Default/Default.component';

/*iBAS */
import { SystemLayoutComponent,SystemLayoutPopupComponent,SystemLayoutGroupPopupComponent,SystemLayoutChartComponent } from '../iBAS_Page/SystemLayout';
import { ibas_HistoryComponent,ibas_EventLogComponent} from './History';
import { LayoutObjectComponent,LayoutObjectAddComponent,LayoutObjectEditComponent,LayoutSettingComponent,LayoutSettingPopupComponent,LayoutSystemSettingsComponent } from '../iBAS_Page/SystemLayoutSetting';
import { Chiller_ParameterComponent,Chiller_Parameter_AddComponent,Chiller_Parameter_EditComponent
  ,Chiller_ScheduleComponent,Chiller_Schedule_AddComponent,Chiller_Schedule_EditComponent
  ,IO_ScheduleComponent,IO_Schedule_AddComponent,IO_Schedule_EditComponent} from './HVAC_Schedule';

import { ibas_UserComponent,ibas_User_AddComponent,ibas_User_EditComponent} from './User/ibas_User';
import { ibas_UserGroupComponent,ibas_UserGroup_AddComponent,ibas_UserGroup_EditComponent} from './User/ibas_UserGroup';
import { ibas_ReportDownloadComponent} from './Report/ReportDownload/ibas_ReportDownload.component';

import { SystemSettingPageComponent } from './WebSetting';

import { ObjectSettingComponent} from './ObjectSetting';
/*頁面 */
const iBASProjectPage = [
  iBas_DefaultComponent,
  SystemLayoutComponent,
  SystemLayoutPopupComponent,
  SystemLayoutGroupPopupComponent,
  SystemLayoutChartComponent,
  ibas_HistoryComponent,
  ibas_EventLogComponent,
  LayoutObjectComponent,
  LayoutObjectAddComponent,
  LayoutObjectEditComponent,
  LayoutSettingComponent,
  LayoutSettingPopupComponent,
  LayoutSystemSettingsComponent,
  Chiller_ParameterComponent,
  Chiller_Parameter_AddComponent,
  Chiller_Parameter_EditComponent,
  Chiller_ScheduleComponent,
  Chiller_Schedule_AddComponent,
  Chiller_Schedule_EditComponent,
  IO_ScheduleComponent,
  IO_Schedule_AddComponent,
  IO_Schedule_EditComponent,
  ibas_UserComponent,
  ibas_User_AddComponent,
  ibas_User_EditComponent,
  ibas_UserGroupComponent,
  ibas_UserGroup_AddComponent,
  ibas_UserGroup_EditComponent,
  ibas_ReportDownloadComponent,
  SystemSettingPageComponent,
  ObjectSettingComponent
];


@NgModule({
  imports: [
    WebCommonModule,
    FormsModule,
    ReactiveFormsModule,
    ibas_routing,
  ],
  providers: [
    //{ provide: LOCALE_ID, useValue: 'zh-Hant' }
  ],
  declarations: [
    iBas_PagesComponent,
    ...iBASProjectPage,
    //...MasterPage,
    //...CustomComponent
],
entryComponents: [
  LayoutSettingPopupComponent,SystemLayoutPopupComponent,SystemLayoutGroupPopupComponent,SystemLayoutChartComponent
],
schemas:      [ NO_ERRORS_SCHEMA ]
})
export class iBas_PagesModule { }
