import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class ActoolsCtrlService {

constructor(@Inject(HttpClient)  
        private http : HttpClient,
        public MyCommon:MyCommon) { }
    //取得控制設定
    GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('ACToolsCtrl');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetConditionData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('ACToolsCtrlCondition');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateConditionData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('ACToolsCtrlSettingCondition');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    InsertData(sbody:any,headers:any=""){
     var url = this.MyCommon.GetAPISPUrl('ACToolsCtrlSetting');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('ACToolsCtrlSetting');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    DeleteData(sbody:any,headers:any=""){
        var sbody;
       var url = this.MyCommon.GetAPISPUrl('ACToolsCtrlDelete');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

}