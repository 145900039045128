import { Component, OnInit, Inject } from '@angular/core';
import { MyCommon } from '../../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { iBas_Permissions } from '../../../../DataService';
import { iBas_ScheduleIOService,IBas_ObjectService,IBas_ObjectAttribService} from '../../../../DataService';

@Component({
  selector: 'app-IO_Schedule_Add',
  templateUrl: './IO_Schedule_Add.component.html',
  styleUrls: ['./IO_Schedule_Add.component.scss'],
  providers: [IBas_ObjectService,IBas_ObjectAttribService,iBas_ScheduleIOService, iBas_Permissions, MyCommon]
})
export class IO_Schedule_AddComponent implements OnInit {


  //DatePicker
  StartDate: any = '2017/01/01 00:00:00';
  EndDate: any = '2017/01/01 00:00:00';

  public ViewPermissions: boolean = false;

  ACO_ID: any = '';
  ACO_Name: any = '';
  Start_Date: Date;
  End_Date: Date;
  Operation_Period: any = '';
  Time_List: any=[];
  Start_Time: Date;
  End_Time: Date;
  ConditionControlMode: any = '';
  Week_Interval: any = '';
  InTime_Text: any = '';
  OutTime_Text: any = '';
  SC_Enabled: any = '';

  InTime_Data=[];
  OutTime_Data=[];

  Edit_Mode: boolean = false;

  Dev_Data: any = [];
  IO_Data: any = [];
  Set_In_IO_Data: any = [];
  Set_Out_IO_Data: any = [];

    //每周設定資料
    public Operation_Period_Source =
    [{ value: 1, text: "週一", enable: true },
    { value: 2, text: "週二", enable: true },
    { value: 3, text: "週三", enable: true },
    { value: 4, text: "週四", enable: true },
    { value: 5, text: "週五", enable: true },
    { value: 6, text: "週六", enable: true },
    { value: 7, text: "週日", enable: true }];


    constructor(private ScheduleIOData: iBas_ScheduleIOService,
      private PermissionsData: iBas_Permissions,
      private MyCommon: MyCommon,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private Object: IBas_ObjectService,
      private ObjectAttrib: IBas_ObjectAttribService,) { }
  
  ngOnInit() {

    //載入預設日期
    var sNowDate = new Date();
    this.StartDate = new Date(sNowDate.getFullYear() + '/' +String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
    this.Start_Date = this.StartDate;
    this.Start_Time = this.StartDate;
    //單位為一天的時間
    var DiffDay = (24 * 60 * 60 * 1000) * 7;
    var sDiffDate = new Date(sNowDate.getTime() + DiffDay);
    this.EndDate = new Date(sDiffDate.getFullYear() + '/' + String(Number(sDiffDate.getMonth()) + 1) + '/' + sDiffDate.getDate() + ' 00:00');
    this.End_Date = this.EndDate;
    this.End_Time = this.EndDate;

    this.ConditionControlMode=1;
    this.Week_Interval=0;
    this.InTime_Text="";
    this.OutTime_Text="";
    this.SC_Enabled="1";

    //判斷頁面權限
    let sbody = {
      UrlList: "IOScheduleAdd",
      Key: localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
          if (Site.path == "IOScheduleAdd")
            this.ViewPermissions = true;
        }
        try {
          this.MyCommon.PermissionsDefailtPage(obj, !this.ViewPermissions);
        } catch (err) { }
      },
      err => { },
      () => { }
    );
    this.Get_Dev_IO_Data();
  }
  SetInTime_MAC: any = "-1";
  SetInTime_SID: any = "-1";
  SetInTime_TimeStep:any="5";
  SetInTime_CtrlValue:any="0";
  SetInTime_IO_Type:any="";
  onInDevice_Change() {
    this.SetInTime_SID = "-1";
    this.Set_In_IO_Data = this.IO_Data.filter(d => String(d.MAC_ID) == this.SetInTime_MAC);
    this.SetInTime_CtrlValue="0";
  }
  onInIO_Change(){
    this.SetInTime_IO_Type=this.IO_Data.filter(IO => (String(IO.Data_Mac) + '-' + String(IO.Data_S)) == this.SetInTime_SID).map(d=>d.TypeID)[0];
    this.SetInTime_CtrlValue="0";
  }
  onInDevice_Item_Change(item) {
    item["IOData"] = this.IO_Data.filter(IO => String(IO.MAC_ID) == item.MACID);
    if (item["IOData"].length > 0){
      item.SID = item["IOData"][0].Data_Mac + "-" + item["IOData"][0].Data_S;
    }
    item.IO_Type=this.IO_Data.filter(IO => (String(IO.Data_Mac) + '-' + String(IO.Data_S)) == item.SID).map(d=>d.TypeID)[0];
    item.CtrlValue="0";
   
    this.BindInTime();
  }
  onInIO_Item_Change(item) {
    item.IO_Type=this.IO_Data.filter(IO => (String(IO.Data_Mac) + '-' + String(IO.Data_S)) == item.SID).map(d=>d.TypeID)[0];
    item.CtrlValue="0";
    this.BindInTime();
  }
  onInIO_Value_Change(item) {
    this.BindInTime();
  }
  onAddInTime() {
    if (this.SetInTime_MAC == "-1" || this.SetInTime_SID == "-1") {
      alert("請選擇設備");
      return;
    }
    let item = { MACID: "", SID: "",CtrlValue:"0", TimeStep: "5", Condition: "", TimeStep2: "" };
    item.MACID = this.SetInTime_MAC;
    item.SID = this.SetInTime_SID;
    item.TimeStep=this.SetInTime_TimeStep;
    item.CtrlValue=this.SetInTime_CtrlValue;
    item["IOData"] = this.IO_Data.filter(IO => String(IO.MAC_ID) == item.MACID);
    item["IO_Type"] = this.SetInTime_IO_Type;
    this.InTime_Data.push(item);
    this.BindInTime();
  }
  onRemoveInTime(index) {
    this.InTime_Data = this.InTime_Data.filter((item, i) => { return Number(index) != i; });
    this.BindInTime();
  }
  SetOutTime_MAC: any = "-1";
  SetOutTime_SID: any = "-1";
  SetOutTime_TimeStep: any = "5";
  SetOutTime_CtrlValue:any="0";
  SetOutTime_IO_Type:any="";
  onOutDevice_Change() {
    this.SetOutTime_SID = "-1";
    this.SetOutTime_CtrlValue="0";
    this.Set_Out_IO_Data = this.IO_Data.filter(d => String(d.MAC_ID) == this.SetOutTime_MAC);
  }
  onOutIO_Change(){
    this.SetOutTime_CtrlValue="0";
    this.SetOutTime_IO_Type=this.IO_Data.filter(IO => (String(IO.Data_Mac) + '-' + String(IO.Data_S)) == this.SetOutTime_SID).map(d=>d.TypeID)[0];
  }
  onOutDevice_Item_Change(item) {
    item["IOData"] = this.IO_Data.filter(IO => String(IO.MAC_ID) == item.MACID);
    if (item["IOData"].length > 0)
      item.SID = item["IOData"][0].Data_Mac + "-" + item["IOData"][0].Data_S;
    item.IO_Type=this.IO_Data.filter(IO => (String(IO.Data_Mac) + '-' + String(IO.Data_S)) == item.SID).map(d=>d.TypeID)[0];
    item.CtrlValue="0";
    this.BindOutTime();
  }
  onOutIO_Item_Change(item) {
    item.IO_Type=this.IO_Data.filter(IO => (String(IO.Data_Mac) + '-' + String(IO.Data_S)) == item.SID).map(d=>d.TypeID)[0];
    item.CtrlValue="0";
    this.BindOutTime();
  }
  onOutIO_Value_Change(item) {
    this.BindOutTime();
  }
  onAddOutTime() {
    if (this.SetOutTime_MAC == "-1" || this.SetOutTime_SID == "-1") {
      alert("請選擇設備");
      return;
    }
    let item = { MACID: "", SID: "", CtrlValue: "0", TimeStep: "5", Condition: "", TimeStep2: "" };
    item.MACID = this.SetOutTime_MAC;
    item.SID = this.SetOutTime_SID;
    item.TimeStep=this.SetOutTime_TimeStep;
    item.CtrlValue=this.SetOutTime_CtrlValue;
    item["IOData"] = this.IO_Data.filter(IO => String(IO.MAC_ID) == item.MACID);
    item["IO_Type"] = this.SetOutTime_IO_Type;
    this.OutTime_Data.push(item);
    this.BindOutTime();
  }
  onRemoveOutTime(index) {
    this.OutTime_Data = this.OutTime_Data.filter((item, i) => { return Number(index) != i; });
    this.BindOutTime();
  }
  InTime_Change() {
    this.BindInTime();
  }
  OutTime_Change() {
    this.BindOutTime();
  }
  BindInTime() {
    let InTime = "";
    for (let i in this.InTime_Data) {
      InTime += this.InTime_Data[i].SID + "," + this.InTime_Data[i].CtrlValue + "," + this.InTime_Data[i].TimeStep + ";" + this.InTime_Data[i].Condition + ";" + this.InTime_Data[i].TimeStep2 + ";";
      if (String(i) != String(this.InTime_Data.length - 1)) {
        InTime += "\r\n";
      }
    }
    this.InTime_Text = InTime;
  }
  BindOutTime() {
    let OutTime = "";
    for (let i in this.OutTime_Data) {
      OutTime += this.OutTime_Data[i].SID + "," + this.OutTime_Data[i].CtrlValue + "," + this.OutTime_Data[i].TimeStep + ";" + this.OutTime_Data[i].Condition + ";" + this.OutTime_Data[i].TimeStep2 + ";";
      if (String(i) != String(this.OutTime_Data.length - 1)) {
        OutTime += "\r\n";
      }
    }
    this.OutTime_Text = OutTime;
  }

  //判斷小時和分鐘補足兩位
  FormatDate(val: any) {
    if (String(val).length == 1)
      return "0" + val;
    else
      return val;
  }
  onAddTime(){
    this.Time_List.push({Start_Time: this.Start_Time,End_Time: this.End_Time});
  }

  onRemoveTime(index){
    this.Time_List=this.Time_List.filter((item,i)=>{return Number(index)!=i;});
  }

  Get_Dev_IO_Data(){
    let StrWhere = " ";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.Object.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.Dev_Data=obj;
      }
    );
    StrWhere = " ";
    sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.ObjectAttrib.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.IO_Data=obj;
        //篩選資料
        let ShowIO=["AO","DO","VAO","VDO"];
        this.IO_Data=this.IO_Data.filter(IO=>  ShowIO.indexOf(IO.TypeID)>-1 &&parseInt(IO.Data_Mac).toString()!= "NaN" && parseInt(IO.Data_S).toString()!= "NaN");
        this.Dev_Data=this.Dev_Data.filter(dev=> this.IO_Data.filter(IO=> String(IO.MAC_ID)==String(dev.MAC_ID)).length>0);
        for(let i in this.InTime_Data){
          let SelectIO=this.IO_Data.filter(IO=>(String(IO.Data_Mac)+'-'+String(IO.Data_S))==this.InTime_Data[i].SID );
          if(SelectIO.length>0){
            this.InTime_Data[i].MACID=SelectIO[0].MAC_ID;
          }
          this.InTime_Data[i]["IOData"]=this.IO_Data.filter(IO=>(String(IO.MAC_ID))==this.InTime_Data[i].MACID);
        }
        for(let i in this.OutTime_Data){
          let SelectIO=this.IO_Data.filter(IO=>(String(IO.Data_Mac)+'-'+String(IO.Data_S))==this.OutTime_Data[i].SID );
          if(SelectIO.length>0){
            this.OutTime_Data[i].MACID=SelectIO[0].MAC_ID;
          }
          this.OutTime_Data[i]["IOData"]=this.IO_Data.filter(IO=>(String(IO.MAC_ID))==this.OutTime_Data[i].MACID);
        }
      }
    );
  }


  //點選儲存
  clicked() {
    if (confirm("確定要新增嗎?")) {
      this.Insert();
    }
  }
  //新增
  Insert() {
   
    //判斷是否有勾選每週設定
    if (this.Operation_Period == "") {
      alert("請確認每週設定!");
      return false;
    }
    let time_Range='';
    for(let i in this.Time_List){
      time_Range+=this.MyCommon.FormateTime2(this.Time_List[i].Start_Time)+":00"+","+this.MyCommon.FormateTime2(this.Time_List[i].End_Time)+":00"+";";
    }
    if (time_Range == "") {
      alert("請設定時間範圍!");
      return false;
    }

    let sbody = {
      ACO_Name:this.ACO_Name,
      Start_Date: this.MyCommon.FormateDate2(this.Start_Date).substring(5,10).replace('-','/'),
      End_Date: this.MyCommon.FormateDate2(this.End_Date).substring(5,10).replace('-','/'),
      Operation_Period:this.Operation_Period,
      Time_Range: time_Range,
      End_Time: this.MyCommon.FormateTime2(this.End_Time)+":00",
      ConditionControlMode:this.ConditionControlMode,
      Week_Interval:this.Week_Interval,
      InTime_Text:this.InTime_Text,
      OutTime_Text:this.OutTime_Text,
      SC_Enabled:this.SC_Enabled,
      Key: localStorage.getItem("Token")
    };
    var stralert = this.ScheduleIOData.InsertData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('新增成功');
        this.router.navigate(['/ibas/IOSchedule']);
      },
      err => { alert('新增失敗'); },
      () => { }
    );
  }
  back() {
    this.router.navigate(['/ibas/IOSchedule']);
  }

}
