import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridDataResult, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ReportService } from '../../../DataService/Report.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {Observable} from 'rxjs/Rx';

@Component({
  selector: 'app-ReportMaintain',
  templateUrl: './ReportMaintain.component.html',
  providers: [ReportService,Permissions, MyCommon]
})
export class ReportMaintainComponent implements OnInit {
  Type:any="1";
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;

  Auto_ID:any=-1;
  Auto_StartDay:any;
  Auto_EndDay:any;
  Auto_Status:any;
  Auto_ReportType:any;
  Auto_HandlingDay:any;
  Auto_DoneTime:any;

  Manual_ID:any=-1;
  Manual_StartDay:any;
  Manual_EndDay:any;
  Manual_Status:any;
  Manual_ReportType:any;
  Manual_HandlingDay:any;
  Manual_DoneTime:any;
  

  constructor(
    private ReportData: ReportService,
    private PermissionsData: Permissions,
    private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
    //  //載入預設日期
    // var sNowDate = new Date();
    // this.StartDate = new Date(sNowDate.getFullYear() + '/' + String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
    // //單位為一天的時間
    // var DiffDay = (24 * 60 * 60 * 1000) * 1;
    // var sDiffDate = new Date(sNowDate.getTime() + DiffDay);
    // this.EndDate =  new Date(sDiffDate.getFullYear() + '/' + String(Number(sDiffDate.getMonth()) + 1) + '/' + sDiffDate.getDate() + ' 00:00');
    this.GetTask();
    this.GetData();
  }

  GetTask(){
    var StrWhere=" Where 1=1";
    
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.ReportData.GetReportTask(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        if(obj.length>0){
          let Auto=obj.filter(d=>d.ID==0);
          if(Auto.length>0){
            this.Auto_ID=Auto[0].ID;
            this.Auto_StartDay=Auto[0].StartDay;
            this.Auto_EndDay=Auto[0].EndDay;
            this.Auto_Status=Auto[0].Status.Status?"1":"0";
            this.Auto_ReportType=Auto[0].ReportType;
            this.Auto_HandlingDay=Auto[0].HandlingDay;
            this.Auto_DoneTime=Auto[0].DoneTime;
          }
          let sNowDate = new Date();
          let Manual=obj.filter(d=>d.ID==1);
          if(Manual.length>0){
            //有讀到資料時不刷新
            if(this.Manual_ID==-1){
              if(Manual[0].StartDay!='0000-00-00'&&Manual[0].StartDay!=''){
                this.Manual_StartDay=new Date(Manual[0].StartDay);
              }else{
                this.Manual_StartDay=new Date(sNowDate.getFullYear() + '/' + String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
              }
              if(Manual[0].EndDay!='0000-00-00'&&Manual[0].EndDay!=''){
                this.Manual_EndDay=new Date(Manual[0].EndDay);
              }else{
                this.Manual_EndDay=new Date(sNowDate.getFullYear() + '/' + String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
              }
            }
          
            this.Manual_ID=Manual[0].ID;
            
            this.Manual_Status=Manual[0].Status?"1":"0";
            //執行中時檢查
            if(this.Manual_Status=="1"){
              this.Timer();
            }
            this.Manual_ReportType=Manual[0].ReportType;
            this.Manual_HandlingDay=Manual[0].HandlingDay;
            this.Manual_DoneTime=Manual[0].DoneTime;
          }
        }
      },
      err => { console.log(err); }
    );
  }
  Timer(){
    if(this.router.url=="/pages/ReportMaintain"){
      setTimeout(()=>{
        this.GetTask();
        this.GetData();},5000);
    }
  }
  //取得目錄
  GetData(){

    let sbody = {
      Type:this.Type,
      Key:localStorage.getItem("Token")
    };
    this.ReportData.GetReportDownload(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        let data=[];
        try{
          if(obj[0]=="["){
            data=JSON.parse(obj);
          }else{
            data=obj;
          }
        }catch(err){

        }
        let ReportData=data;
        this.gridView = {
          data: orderBy(ReportData, this.sort),
          total: ReportData.length
        };
      },
      err => { console.log(err); }
    );
  }

  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GetData();
  }

  //下載
   Download2(Item: any) {
    var Url=Item.path;
    return Url;
  }
  //類型選擇
  TypeChange(){
    this.GetData();
  }

  ReExport(){
    let Day=(Number(this.Manual_EndDay)-Number(this.Manual_StartDay))/(1000*60*60*24);
    if(Day<=0){
      alert("請選擇正確的時間範圍！");
      return;
    }
    let sbody = {
      StartDay: this.MyCommon.FormateDateTime(this.Manual_StartDay),
      EndDay: this.MyCommon.FormateDateTime(this.Manual_EndDay),
      Key:localStorage.getItem("Token")
    };         
    this.ReportData.ReportReExport(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.GetTask();
        this.GetData();
      },
      err => { console.log(err); }
    );

  }


 

}
