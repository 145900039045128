import { Component, OnInit, Inject } from '@angular/core';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import 'hammerjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';
import { ZigbeeSettingService } from '../../../DataService/ZigbeeSetting.service';

@Component({
  selector: 'app-ZigbeeSetting',
  templateUrl: './ZigbeeSetting.component.html',
  styleUrls: ['./ZigbeeSetting.component.scss'],
  providers: [ZigbeeSettingService, MyCommon,Permissions]
})
export class ZigbeeSettingComponent implements OnInit {

  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  /*private pageSize: number = 10;
  private skip: number = 0;*/

  constructor(
    @Inject(ZigbeeSettingService) private ZigbeeData: ZigbeeSettingService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  public ZBList_Source = [];
  public ZBData_Source = [];
  public BasicData = [];
  public DevData = [];
  public SetItem = [];
  public Set_DevIndex: string = "";


  public ViewPermissions: boolean = false;
  public AddPermissions: boolean = false;
  public EditPermissions: boolean = false;
  public DeletePermissions: boolean = false;

  ngOnInit() {
    /*   //判斷頁面權限
       let sbody = {
        UrlList:"ZigbeeSetting",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
            if(Site.path=="ZigbeeSetting")
              this.ViewPermissions=true;
          } 
        
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );*/


    this.SetZBData(true);
  }

  SetZBData(load) {
    try {
      let sbody = {
        Key: localStorage.getItem("Token")
      };

      this.ZigbeeData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          //this.MyCommon.CheckToken(obj);
          try {
            let data=[];
            try{
              if(obj[0]=="["){
                data=JSON.parse(obj);
              }else{
                data=obj;
              }
            }catch(err){
    
            }
            this.ZBData_Source =data;
            this.BasicData = this.ZBData_Source[0];
            this.DevData = JSON.parse(this.BasicData["CozList"]);
            //編序號
            if (this.DevData != null) {
              this.ZBList_Source = this.DevData;
              for (let i in this.DevData) {
                this.DevData[i]["D_ID"] = parseInt(i) + 1;
                if (String(i) == "0") {
                  var DevIndex = this.DevData[i]["CozIndex"];
                  this.Set_DevIndex = DevIndex;
                  this.SetItem = this.DevData[i];
                }
              }
            } else {
              this.ZBData_Source = [];
              this.BasicData = [];
            }
          } catch (error) {
            this.ZBData_Source = [];
            this.BasicData = [];
          }

        },
        err => { console.log(err); }
      );
    } catch (err) { }
  }

  onInsert() {
    this.onAddDev();
    this.UpdateData();
  }
  onCopy() {
    this.onCopyDev();
    this.UpdateData();
  }

  onUdpate() {
    this.UpdateData();
  }

  onDelete() {
    if (confirm("確定要刪除嗎?")) {
      this.onDeleteDev();
      this.UpdateData();
    }
  }

  //新增設備
  onAddDev() {
    //取最大值
    var TempDevData = this.DevData.sort(function (a, b) { return  a.CozIndex-b.CozIndex  });
    var MaxDevIndex = 1;
    if (this.DevData.length > 0) {
      for(let i in this.DevData){
        if(MaxDevIndex<parseInt(TempDevData[i]["CozIndex"])){
          MaxDevIndex = parseInt(TempDevData[i]["CozIndex"]);
        }
      }
      MaxDevIndex = MaxDevIndex+ 1;

    }
    
    this.DevData.push({ "CozIndex": String(MaxDevIndex), "CoordinatorID": "-1", "ZigbeeName": "ZigBee" + String(MaxDevIndex), "IP": "127.0.0.1", "Port": "1433", "D_ID": -1 });
    //重設流水號
    for (let i in this.DevData.sort(function (a, b) { return a.CozIndex - b.CozIndex })) {
      this.DevData[i]["D_ID"] = parseInt(i) + 1;
      //設定新資料
      if (i == String(this.DevData.length - 1)) {
        var CozIndex = this.DevData[i]["CozIndex"];
        this.Set_DevIndex = CozIndex;
        this.SetItem = this.DevData[i];

      }
    }
  }
  //複製設備
  onCopyDev() {
    //設備取最大值
    var TempDevData = this.DevData.sort(function (a, b) { return b.CozIndex - a.CozIndex });
    var MaxDevIndex = 1;
    if (TempDevData.length > 0) {
      MaxDevIndex = parseInt(TempDevData[0]["CozIndex"]) + 1;
    }
    //取得複製值
    var Data = this.SetItem;
    this.DevData.push({
      "CozIndex": String(MaxDevIndex), "CoordinatorID": Data["CoordinatorID"], "ZigbeeName": Data["ZigbeeName"]
      , "IP": Data["IP"], "Port": Data["Port"], "D_ID": -1
    });
    //Sensor資料


    //重設流水號
    for (let i in this.DevData.sort(function (a, b) { return a.CozIndex - b.CozIndex })) {
      this.DevData[i]["D_ID"] = parseInt(i) + 1;
      //設定新資料
      if (i == String(this.DevData.length - 1)) {
        var CozIndex = this.DevData[i]["CozIndex"];
        this.Set_DevIndex = CozIndex;
        this.SetItem = this.DevData[i];
      }
    }

  }
  //刪除設備
  onDeleteDev() {
    var AfterCozIndex = this.SetItem["CozIndex"];
    //清除資料
    this.DevData = this.DevData.filter(function (d) { return d.CozIndex != AfterCozIndex }).sort(function (a, b) { return a.CozIndex - b.CozIndex });

    this.ZBList_Source = this.DevData;
    //重設流水號
    for (let i in this.DevData.sort(function (a, b) { return a.CozIndex - b.CozIndex })) {
      this.DevData[i]["D_ID"] = parseInt(i) + 1;
      //設定新資料
      if (i == String(this.DevData.length - 1)) {
        var DevIndex = this.DevData[i]["CozIndex"];
        this.Set_DevIndex = DevIndex;
        this.SetItem = this.DevData[i];
      }
    }
  }

  RindData(item) {
    this.SetItem = item;
    //取得設備編號
    var DevIndex = this.SetItem["CozIndex"];

  }

  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    //this.isToken();
    //this.SetData();
  }

  UpdateData() {
    //重新排列順序
    this.DevData = this.DevData.sort(function (a, b) { return a.CozIndex - b.CozIndex });
    let sbody = {
      Coz_Data: this.DevData,
      Key: localStorage.getItem("Token")
    };

    this.ZigbeeData.UpdateData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        //this.MyCommon.CheckToken(obj);
        alert("設定完成!");
      },
      err => { console.log(err); }
    );
  }

}
