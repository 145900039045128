import { Component, OnInit ,Inject} from '@angular/core';
import { DemandService } from '../../../DataService/Demand.service';
import { DemandAlarmService } from '../../../DataService/DemandAlarm.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { Permissions } from '../../../DataService/User.service';
@Component({
  selector: 'app-MeterDemandAlarmSetting_Edit',
  templateUrl: './MeterDemandAlarmSetting_Edit.component.html',
  styleUrls: ['./MeterDemandAlarmSetting_Edit.component.scss'],
  providers: [ MyCommon,DemandService,DemandAlarmService,Permissions]
})
export class MeterDemandAlarmSetting_EditComponent implements OnInit {
  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;
  Meter_Source:any;

  public FromData:any;
  PM_ID:any=-1;
  FCType:any='0';
  Up_Alarm:any=0;
  Down_Alarm:any=0;
  Phase_I:any=100;
  Phase_II:any=200;
  Phase_III:any=300;
  enabled:any='1';

  constructor(
    @Inject(DemandService) private DemandData: DemandService,
    @Inject(DemandAlarmService) private DemandAlarmData: DemandAlarmService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    //判斷頁面權限
    let sbody = {
      UrlList:"MeterDemandAlarmSettingEdit",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
            if(Site.path=="MeterDemandAlarmSettingEdit")
                this.EditPermissions=true;
        }
        try{
          this.MyCommon.PermissionsDefailtPage(obj,!this.EditPermissions);
        }catch(err){}
      },
      err => {  },
      () => { }
    );
    this.activatedRoute.params.subscribe(params => {
        this.PM_ID = params['id'];
        this.SetMeter();
        this.GetData();
    });
    //this.SetMeter();
  }

  SetMeter(){
    var StrWhere = "";
      let sbody = {
        TopN: " ",
        StrWhere: StrWhere,
        Sort: " ",
        Key:localStorage.getItem("Token")
      };
      this.DemandData.GetMeter(sbody,this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.Meter_Source=obj;
        },
        err => { console.log(err); }
      );
  }

  GetData() {
    var StrWhere = " Where PM_ID="+this.PM_ID;
      let sbody = {
        TopN: " ",
        StrWhere: StrWhere,
        Sort: " ",
        Key:localStorage.getItem("Token")
      };
      
        this.DemandAlarmData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
          (obj) => {
            this.MyCommon.CheckToken(obj);
          
           this.FromData = obj;
           this.FCType=String(this.FromData[0].FCType);
           this.Up_Alarm=this.FromData[0].Up_Alarm;
           this.Down_Alarm=this.FromData[0].Down_Alarm;
           this.Phase_I=Number(this.FromData[0].Phase_I);
           this.Phase_II=Number(this.FromData[0].Phase_II);
           this.Phase_III=Number(this.FromData[0].Phase_III);
           if (this.FromData[0].enabled)
            this.enabled="1";
           else
            this.enabled="0";
          },
          err => { console.log(err); }
        );
      }
  

  clicked() {
    if (confirm("確定要修改嗎?")) {
      if(this.PM_ID==-1){
        alert('請選擇電表');
        return false;
      }
      this.UpdateData();
      //this.Update();
    }
  }
  UpdateData(){
    let sbody = {
      "PM_ID": this.PM_ID,
      "FCType": this.FCType,
      "Up_Alarm": this.Up_Alarm,
      "Down_Alarm": this.Down_Alarm,
      "Phase_I": Number(this.Phase_I),
      "Phase_II": Number(this.Phase_II),
      "Phase_III": Number(this.Phase_III),
      "enabled": Number(this.enabled),
      Key:localStorage.getItem("Token")
    };

    var stralert = this.DemandAlarmData.UpdateData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('修改成功');
        this.router.navigate(['/pages/MeterDemandAlarmSetting']);  
      },
      err => { alert('修改失敗'); },
      () => { }
    );
  }

  back(){
      this.router.navigate(['/pages/MeterDemandAlarmSetting']);
  }

}
