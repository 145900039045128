import { Component, OnInit } from '@angular/core';
import { DemandCtrlService } from '../../DataService';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../DataService/User.service';
import { Observable } from 'rxjs/Rx';
import { MeterDemandCtrlSettingComponent } from './MeterDemandCtrlSetting/MeterDemandCtrlSetting.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Paho } from 'ng2-mqtt/mqttws31';

@Component({
  selector: 'app-MeterDemandCtrl',
  templateUrl: './MeterDemandCtrl.component.html',
  styleUrls: ['./MeterDemandCtrl.component.css'],
  providers: [ MyCommon,DemandCtrlService,Permissions]
})
export class MeterDemandCtrlComponent implements OnInit {
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;
  KW_Value:any="";
  DemandLimit_Value:any="";
  Switch_Value:any="";

  StartTime:any="";
  StatusContext:any="";
  EndTime:any="";

  LastTime:any="";

  ItemData:any;
  MQTTLogData:any=[];
  DBLogData:any=[];

  DataType:any="MQTT";
  SearchData:any='2020/01/01 00:00';
  DemandkW_IOID:any="";
  constructor(  
    private DemandCtrl: DemandCtrlService,
    private PermissionsData: Permissions,
    private MyCommon: MyCommon,
    private router: Router,
    private modalService: NgbModal,) { }

  ngOnInit() {
      let sNowDate = new Date();
      this.SearchData = new Date(sNowDate.getFullYear() + '/' + String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
      //判斷頁面權限
      let sbody = {
        UrlList:"MeterDemandCtrlView",
        Key:localStorage.getItem("Token")
      };
  
      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="MeterDemandCtrlView")
                  this.ViewPermissions=true;
          }
         
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
      this.GetContrllerData();
      this.GetItemData();
      this.GetShowMsgData();
      this.GetLogData();
      let timer = Observable.timer(2000,1000);
      timer.subscribe(t=>{
        if(this.router.url=="/pages/MeterDemandCtrl"){
          if(this.EndTime!=""){
            var End=new Date(this.EndTime);
            let Mini_Sec =  End.getTime()-new Date().getTime();
            let Sec=parseInt(String(Mini_Sec/1000));
            if(Sec>0){
              this.LastTime=this.ConvertTime(Sec);
            }
          }
        }
      });
      //每分鐘刷新紀錄
      let timer2 = Observable.timer(1000,this.MyCommon.GetDemandCtrlLogTimer());
      timer2.subscribe(t=>{
        if(this.router.url=="/pages/MeterDemandCtrl"&&this.DataType=='DB'){
          this.GetLogData();
        }
      });
      this.MQTTData();
  }
  MQTT_Conn :any;
  MQTTData(){
      let Link=this.MyCommon.GetMQTTLink();
      let clientID ="MeterDemandCtrl"+ Math.random().toString(36).substring(8);
      this.MQTT_Conn = new Paho.MQTT.Client(Link.ip, Link.port, clientID);
      this.MQTT_Conn.connect({ userName: Link.acc, password: Link.pwd, useSSL:  false, timeout: 10,
      onSuccess: ()=>{
          console.log("onSuccess");
          this.MQTT_Conn.subscribe("wsn/LD", 2);
          this.MQTT_Conn.subscribe("wsn/DemandController", 2);
      } 
      , onFailure: ()=>{
          console.log("onFailure");
      }});
      //接收回傳
      this.MQTT_Conn.onMessageArrived = (message)=>{
        if(message.destinationName=="wsn/LD"){
          //console.log(message.payloadString,message.destinationName);
          this.BindDevice(message.payloadString);
        }
        if(message.destinationName=="wsn/DemandController"){
          let data=message.payloadString;
          if(data.indexOf('"Title":"Msg"')>-1){
            this.BindStatus(data);
          }else if(data.indexOf('"Title":"Log"')>-1){
            this.BindLog(data);
          }
          
        }
          
      }
      this.MQTT_Conn.onConnectionLost=(d)=>{
        setTimeout(()=>{
          this.MQTTData();
        },3000)
        console.log("onConnectionLost",d);
      }
  }
  //取得項目狀態
  BindDevice(payloadString){
    payloadString=payloadString.split("}")[0]+"}";
   
    let Data=payloadString.split(";").filter((d)=>
    {
      try {
        d=JSON.parse(d)
        return true;
      } catch (error) {
        return false;
      }
    }).map(d=>JSON.parse(d));
  
    for (let i in this.ItemData) {
      let Filter=Data.filter(d=>d["MacS"].indexOf(this.ItemData[i].Snr_IOID)>-1);
      if(Filter.length>0){
        this.ItemData[i].LD1Value=Filter[0].Value;
      }
    }
    let kW_Filter=Data.filter(d=>d["MacS"].indexOf(this.DemandkW_IOID)>-1);
    if(kW_Filter.length>0){
      this.KW_Value =kW_Filter[0].Value;
    }
    //Snr_IOID
    //console.log(this.ItemData);
  }
  //取得降載狀態
  BindStatus(payloadString){
    payloadString=payloadString.split("}")[0]+"}";
    let Data=payloadString.split(";").filter((d)=>
    {
      try {
        d=JSON.parse(d)
        return true;
      } catch (error) {
        return false;
      }
    }).map(d=>JSON.parse(d));
   
    this.StartTime=(Data[0].StartTime==0?"降載尚未啟動":this.MyCommon.UTC_to_DateTimeFull(Data[0].StartTime*1000));
    this.StatusContext=Data[0].Status;
    this.EndTime=this.MyCommon.UTC_to_DateTimeFull(Data[0].EndTime*1000);
  }
  //取得log
  BindLog(payloadString){
    payloadString=payloadString.split("}")[0]+"}";
    let Data=payloadString.split(";").filter((d)=>
    {
      try {
        d=JSON.parse(d)
        return true;
      } catch (error) {
        return false;
      }
    }).map(d=>JSON.parse(d));
    for (let i in Data) {
      Data[i].DT=this.MyCommon.UTC_to_DateTimeFull(Data[i].DT*1000);
      if(this.MQTTLogData.filter(d=>d["DT"]==Data[i].DT&&d["Context"]==Data[i].Context).length==0)
        this.MQTTLogData.push(Data[i]);
    }
  }

  
  //顯示Popup
  showPopup() {
    //let result={"Ctrl_IOID":"10001-1","Snr_IOID":"65536-1","CtrlSetValue":"0","DevName":"水系統目標設定","DisplayName":"水系統目標設定[冰水溫差設定]","IO_Type":"VAO"}
  
   //打開設定頁面
    const activeModal = this.modalService.open(MeterDemandCtrlSettingComponent, { backdrop: 'static' });
    //傳值到設定頁
    //activeModal.componentInstance.RealTimeColumn = this.RealTimeColumn;
    
    activeModal.result.then((result) => {
      if(result!=null){
        
        let item={"DC_ID":-1,"SortNum":-1,"Ctrl_IOID":null,"Snr_IOID":null,"CtrlSetValue":null,"TempSaveValue":null,"TempSaveDT":null,"DevName":null,"DisplayName":null,"IO_Type":null,"LD1Value":null,"DataStatus":"1"};
        item.Ctrl_IOID=result.Ctrl_IOID;
        item.Snr_IOID=result.Snr_IOID;
        item.CtrlSetValue=result.CtrlSetValue;
        item.DevName=result.DevName;
        item.DisplayName=result.DisplayName;
        item.IO_Type=result.IO_Type;
        //console.log(JSON.stringify(item));

        this.ItemData.push(item);
        //重排順序
        let Sort=1;
        this.ItemData=this.ItemData.sort(function(a, b){ return a.SortNum - b.SortNum});
        for(let i in this.ItemData){
          if(this.ItemData[i]["DataStatus"]!="3")
          {
            this.ItemData[i].SortNum=Sort;
            if(this.ItemData[i]["DataStatus"]=="0"){
              this.ItemData[i]["DataStatus"]="2";
            }
            Sort++;
          }
        }

        this.gridView = {
          data: orderBy(this.ItemData.filter(d=>d.DataStatus!="3"), this.sort),
          total:  this.ItemData.filter(d=>d.DataStatus!="3").length
        };
        //console.log(JSON.stringify(result));
      }
    }, (reason) => {
      //console.log('Dismissed action: ' + reason);
    });
  }
  ConvertTime(SetSec:number){
    let ShowText="";
    let Hour=Math.floor(Math.abs(SetSec/3600));
    let Min=Math.floor((Math.abs(SetSec)-(Hour*3600))/60);
    let Sec=Math.abs(SetSec%60);
    if(Hour>0)
      ShowText+=Hour+"小時";
    if(Min>0)
      ShowText+=Min+"分鐘";
    if(Sec>=0)
      ShowText+=Sec+"秒";
    return ShowText;
  }
  GetContrllerData(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DemandCtrl.GetContrllerData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);

        if(obj.length>0){
          this.KW_Value=obj[0].LD1Value;
          this.DemandkW_IOID=obj[0].DemandkW_IOID;
          this.DemandLimit_Value=obj[0].DemandLimit;
          this.Switch_Value=obj[0].LD2Value;
        }
      },
      err => { console.log(err); }
    );
  }
  GetItemData(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DemandCtrl.GetItemData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.ItemData=obj;
        this.ItemData=this.ItemData.sort(function(a, b){ return a.SortNum - b.SortNum});
        for(let i in this.ItemData){
          this.ItemData[i]["DataStatus"]="0";
        }
        this.gridView = {
          data: orderBy(this.ItemData.filter(d=>d.DataStatus!="3"), this.sort),
          total:  this.ItemData.filter(d=>d.DataStatus!="3").length
        };
      },
      err => { console.log(err); }
    );
  }
  onItemChange(item: any){
    if(item["DataStatus"]=="1"){

    }else{
      item["DataStatus"]="2";
    }
  }
  onItemSort(item: any,SortType:number){
    //取得替換的排序並重新設定
    let SortItem = this.ItemData.filter(task => task.SortNum == (item.SortNum+SortType));
    if(SortItem.length>0){
      SortItem[0].SortNum+=(SortType*-1);
      if(SortItem[0]["DataStatus"]=="0"){
        SortItem[0]["DataStatus"]="2";
      }
    }
    //改變當列排序
    item.SortNum+=SortType;
    if( item["DataStatus"]=="0"){
      item["DataStatus"]="2";
    }
    this.ItemData=this.ItemData.sort(function(a, b){ return a.SortNum - b.SortNum});
   
    this.gridView = {
      data: orderBy(this.ItemData.filter(d=>d.DataStatus!="3"), this.sort),
      total: this.ItemData.filter(d=>d.DataStatus!="3").length
    };
  }

  onItemDelete(item: any){
    let data=[];
    if(item["DataStatus"]=="1"){
      //刪除資料
      data=this.ItemData.filter(d => d.SortNum != item.SortNum||(d.DC_ID !=-1&&d.SortNum == item.SortNum));
      //重新排列
      data=data.sort(function(a, b){ return a.SortNum - b.SortNum});
      let Sort=1;
      for(let i in data){
        if(data[i]["DataStatus"]!="3")
        {
          data[i].SortNum=Sort;
          if(data[i]["DataStatus"]=="0")
          {
            data[i]["DataStatus"]="2";
          }
          Sort++;
        }
      }
      //回寫設定
      this.ItemData=data;
    }else{
      item.SortNum=-1;
      item["DataStatus"]="3";

      let Sort=1;
      this.ItemData=this.ItemData.sort(function(a, b){ return a.SortNum - b.SortNum});
      for(let i in this.ItemData){
        if(this.ItemData[i]["DataStatus"]!="3")
        {
          this.ItemData[i].SortNum=Sort;
          if(this.ItemData[i]["DataStatus"]=="0")
          {
            this.ItemData[i]["DataStatus"]="2";
          }
          Sort++;
        }
      }
    }
    this.gridView = {
      data: orderBy(this.ItemData.filter(d=>d.DataStatus!="3"), this.sort),
      total: this.ItemData.filter(d=>d.DataStatus!="3").length
    };
  }

  GetShowMsgData(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DemandCtrl.GetShowMsgData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        if(obj.length>0){
          this.StartTime=obj[0].StartTime;
          this.StatusContext=obj[0].StatusContext;
          this.EndTime=obj[0].EndTime;
        }
      },
      err => { console.log(err); }
    );
  }

  
  GetLogData(){
    let sbody = {
      TopN: " ",
      StrWhere: " where DATE_FORMAT(DT,'%Y-%m-%d')='"+this.MyCommon.FormateDate2(this.SearchData) +"' ",
      Sort: " order by DT ",
      Key:localStorage.getItem("Token")
    };
    this.DemandCtrl.GetLogData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        if(obj.length>0){
          this.DBLogData=obj;
        }else{
          this.DBLogData=[];
          this.DBLogData.push({DT:"無紀錄",Context:""});
        }
      },
      err => { console.log(err); }
    );
  }
  ChangeDate(){
    this.GetLogData();
  }


  onUpdate(){
    console.log(this.ItemData);
    //console.log(JSON.stringify(this.ItemData));
    //console.log(this.DemandLimit_Value);
    if (confirm("確定要更新嗎?")) {
      let sbody = {
        DemandLimit: this.DemandLimit_Value,
        ItemData: this.ItemData,
        Key:localStorage.getItem("Token")
      };
      this.DemandCtrl.UpdateContrllerData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          alert("設定完成");
          this.GetContrllerData();
          this.GetItemData();
          this.GetShowMsgData();
          this.GetLogData();
        },
        err => { console.log(err); }
      );
    }
  }

}
