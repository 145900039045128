import { Component, OnInit ,Inject} from '@angular/core';
import { DemandService } from '../../../DataService/Demand.service';
import { DemandAlarmService } from '../../../DataService/DemandAlarm.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-MeterDemandAlarmSetting_Add',
  templateUrl: './MeterDemandAlarmSetting_Add.component.html',
  styleUrls: ['./MeterDemandAlarmSetting_Add.component.scss'],
  providers: [ MyCommon,DemandService,DemandAlarmService,Permissions]
})
export class MeterDemandAlarmSetting_AddComponent implements OnInit {

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;
  Meter_Source:any;

  PM_ID:any=-1;
  FCType:any='0';
  Up_Alarm:any=0;
  Down_Alarm:any=0;
  Phase_I:any=100;
  Phase_II:any=200;
  Phase_III:any=300;
  enabled:any='1';

  constructor(    
    @Inject(DemandService) private DemandData: DemandService,
    @Inject(DemandAlarmService) private DemandAlarmData: DemandAlarmService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

    ngOnInit() {
      //判斷頁面權限
      let sbody = {
        UrlList:"MeterDemandAlarmSettingAdd",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="MeterDemandAlarmSettingAdd")
                  this.AddPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.AddPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
      this.SetMeter();
    }

  SetMeter(){
    var StrWhere = "";
      let sbody = {
        TopN: " ",
        StrWhere: StrWhere,
        Sort: " ",
        Key:localStorage.getItem("Token")
      };
      this.DemandData.GetMeter(sbody,this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.Meter_Source=obj;
          if(this.Meter_Source.length>0){
            this.PM_ID=this.Meter_Source[0].MACID;

          }
        },
        err => { console.log(err); }
      );
  }

  clicked() {
    if (confirm("確定要新增嗎?")) {
      if(this.PM_ID==-1){
        alert('請選擇電表');
        return false;
      }
      this.InsertData();
      //this.Update();
    }
  }
  InsertData(){
    let sbody = {
      "PM_ID": this.PM_ID,
      "FCType": this.FCType,
      "Up_Alarm": this.Up_Alarm,
      "Down_Alarm": this.Down_Alarm,
      "Phase_I": Number(this.Phase_I),
      "Phase_II": Number(this.Phase_II),
      "Phase_III": Number(this.Phase_III),
      "enabled": Number(this.enabled),
      Key:localStorage.getItem("Token")
    };

    var stralert = this.DemandAlarmData.InsertData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        if(obj.length>0){
          alert(obj[0]["Message"]);
        }else{
          alert('新增成功');
          this.router.navigate(['/pages/MeterDemandAlarmSetting']);  
        }
        
      },
      err => { alert('新增失敗'); },
      () => { }
    );
  }

  back(){
      this.router.navigate(['/pages/MeterDemandAlarmSetting']);
  }

}
