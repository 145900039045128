import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class RS485Service {

constructor(@Inject(HttpClient)  
        private http : HttpClient,
        public MyCommon:MyCommon) { }

    GetList(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPIUrl('txt/GetRS485List');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPIUrl('txt/GetRS485');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    InsertData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPIUrl('txt/InsertRS485');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
   
    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPIUrl('txt/UpdateRS485');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    DeleteData(sbody:any,headers:any=""){
        var sbody;
       var url = this.MyCommon.GetAPIUrl('txt/DeleteRS485');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
}