export class IOCtrlSettingColumn {
  constructor(
    public TDate: Date,
    public STime:Date,
    public ETime:Date,
    public CtrlCondition:string,
    public CtrlValue:any,
    public CtrlType:number,
    public Ctrl_MAC:number,
    public Ctrl_S:number,
    public IOCtrl_Condition_Data:string,
  ) { }
}