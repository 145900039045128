import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MyCommon } from '../../../App_Code/MyCommon';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import 'hammerjs';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridDataResult, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { IBas_ObjectService, IBas_ObjectAttribService, iBas_Permissions,IBas_HistoryService} from '../../../DataService';
import { DateTimeProvider } from '../../../App_Code/datetime';

@Component({
  selector: 'app-ibas_History',
  templateUrl: './ibas_History.component.html',
  styleUrls: ['./ibas_History.component.scss'],
  providers: [IBas_ObjectService, IBas_ObjectAttribService, iBas_Permissions,IBas_HistoryService,DateTimeProvider, MyCommon]
})
export class ibas_HistoryComponent implements OnInit {

  //參數

  //Interval_Source: any = [{ name: '1分鐘', value: '1' }, { name: '3分鐘', value: '3' }, { name: '5分鐘', value: '5' }, { name: '15分鐘', value: '15' },
  //{ name: '30分鐘', value: '30' }, { name: '60分鐘', value: '60' }];
  Interval_Source: any = [{ name: '1分鐘', value: '1' }, { name: '3分鐘', value: '3' }, { name: '15分鐘', value: '15' }, { name: '30分鐘', value: '30' }, { name: '60分鐘', value: '60' }];

  public interval: any = '1';
  exportdata: any;

  public ViewPermissions: boolean = false;
  public AddPermissions: boolean = false;
  public EditPermissions: boolean = false;
  public DeletePermissions: boolean = false;



  //DatePick設定
  StartDate: any = '2017/01/01 00:00';
  EndDate: any = '2017/01/01 00:00';
  ReportStartDate: any = '2017/01/01 00:00';
  ReportEndDate: any = '2017/01/01 00:00';

  innerWidth: number;

  Status: string;

  DeviceData: any = [];
  Search_MAC:any="-1";
  Search_SID:any="-1";
  IOData: any = [];
  Set_IOData: any = [];
  Search_IOData: any = [];
  ChartEnable:any=false;

  constructor(
    private PermissionsData: iBas_Permissions,
    private Object: IBas_ObjectService,
    private ObjectAttrib: IBas_ObjectAttribService,
    private MyCommon: MyCommon,
    private DateTime: DateTimeProvider,
    private History: IBas_HistoryService,) {

  }

  ngOnInit() {
    //讀出IOList
    if(localStorage.getItem("IOList")!=null&&localStorage.getItem("IOList")!="")
      this.Search_IOData=JSON.parse(localStorage.getItem("IOList"));
    //載入預設日期
    let sNowDate = new Date(this.DateTime.Formate(new Date(),"yyyy/MM/dd"));
    this.StartDate = this.DateTime.AddDay(sNowDate,-1);
    this.ReportStartDate = this.DateTime.AddDay(sNowDate,-1);
    this.EndDate =this.DateTime.AddDay(sNowDate,0); 
    this.ReportEndDate =this.DateTime.AddDay(sNowDate,0);

    //判斷頁面權限
    let sbody = {
      UrlList: "History",
      Key: localStorage.getItem("Token")
    };

    let stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
          if (Site.path == "History")
            this.ViewPermissions = true;
        }
        try {
          this.MyCommon.PermissionsDefailtPage(obj, !this.ViewPermissions);
        } catch (err) { }
      },
      err => { },
      () => { }
    );
    this.GetDevData();
  }

  GetDevData() {

    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key: localStorage.getItem("Token")
    };

    this.Object.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {

        this.MyCommon.CheckToken(obj);
        this.DeviceData = obj;
      },
      err => { console.log(err); }
    );
    sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key: localStorage.getItem("Token")
    };

    this.ObjectAttrib.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {

        this.MyCommon.CheckToken(obj);
        this.IOData = obj;
        this.IOData=this.IOData.filter(IO=> parseInt(IO.MAC_ID).toString()!= "NaN" && parseInt(IO.S_Num).toString()!= "NaN");
        this.DeviceData=this.DeviceData.filter(dev=> this.IOData.filter(IO=> String(IO.MAC_ID)==String(dev.MAC_ID)).length>0);

        this.Set_IOData=[];
      },
      err => { console.log(err); }
    );
  } 
  onDevice_Change(){
    this.Set_IOData=this.IOData.filter(d=>String(d.MAC_ID)==this.Search_MAC);
  }
  //切換時間
  ChangeStartDate() {
    this.ReportStartDate = this.StartDate;
  }
  ChangeEndDate() {
    this.ReportEndDate = this.EndDate;
  }
  //增加查詢IO
  AddIO() {
    if (this.Search_SID == -1) {
      alert("請選擇裝置!")
    }
    else {
      //判斷是否重複
      let CheckIO = this.Search_IOData.filter(d => d.id == this.Search_SID);
      if (CheckIO.length == 0) {
        //篩選IO資料
        let IO_filter = this.IOData.filter(d => d.MAC_ID+"-"+d.S_Num ==  this.Search_SID);
        
        //增加選擇IO
        this.Search_IOData.push({ id: this.Search_SID, name: IO_filter[0].S_Name+"-"+IO_filter[0].Tag_Name });
        //存入localStorage
        localStorage.setItem("IOList", JSON.stringify(this.Search_IOData));
      }
    }
  }
  //刪除查詢IO
  DelIO(index) {
    this.Search_IOData=this.Search_IOData.filter((item,i)=>{return Number(index)!=i;});
     //存入localStorage
     localStorage.setItem("IOList",JSON.stringify(this.Search_IOData));
  }
  //刪除全部查詢IO
  AllDel() {
    localStorage.removeItem("IOList");
    this.Search_IOData=[];
  }
  //顯示圖表
  showChart() {
    if (this.Search_IOData.length > 0) {

      this.Status="查詢中";
      this.ChartEnable =true;
      let sbody = {
        IO_MAC_S: this.Search_IOData.map(d=>d.id).join(','),
        StartTime:this.MyCommon.FormateDateTime(this.StartDate),
        EndTime: this.MyCommon.FormateDateTime(this.EndDate),
        IntervalMinute: 1,
        Key:localStorage.getItem("Token")
      };
      this.data=[];
      this.History.GetHistoryChart(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.data = obj;
          this.Chart();
          this.Status="";
        },
        err => { }
      );
 
    }
    else {
      alert("請選擇裝置!")
    }
  }

  data:any=[];
  SetStep:any=[];
  Series:any=[];
  CategoryItem:any=[];
  Chart() {
    this.Series=[];
    this.CategoryItem=[];
    this.CategoryItem=this.data.map(d=>this.MyCommon.FormateTime2(new Date(d["DT"])));
    this.SetStep=parseInt(String(this.CategoryItem.length/4));

    this.Search_IOData.forEach(d => {
      let  LineItem={
        name: d.name
        ,data:this.data.map(r=>r[d.id])
        ,type:"line"};
        this.Series.push(LineItem);
    });
  }


  columns: any = [];
  columnsName: any = [];
  //輸出檔案
  public exportToExcel(grid: GridComponent): void {
    if (this.Search_IOData.length > 0) {
        /*let Day=Math.abs(Number(this.ReportEndDate)-Number(this.ReportStartDate))/(1000*60*60*24);
        if((this.interval==1 || this.interval==3|| this.interval==5) && Day>7){
          alert("匯出時間範圍不可大於7天");
          return;
        }*/
        if((this.data!=null && this.data.length!=0) && this.ReportStartDate>=this.StartDate && this.ReportEndDate<=this.EndDate){
          this.columns=[];
          this.columnsName=[];
          this.exportdata="";
          this.columns.push("DT");
          //組查詢IO
          for (let i in this.Search_IOData) {
            this.columns.push(this.Search_IOData[i].id);
            this.columnsName.push(this.Search_IOData[i].name);
          }

          let ReplaceData=JSON.stringify( this.data);
          for (let i in this.columns) {
            if (this.columns[i] !="DT"){
              let Oldcolumns=this.columns[i];
              let Newcolumns="MAC"+this.columns[i].replace("-","");
              ReplaceData=ReplaceData.replace(new RegExp(Oldcolumns,"gm"),Newcolumns);
              this.columns[i]=Newcolumns;
            }
          }
          this.exportdata=[];
          this.exportdata = JSON.parse(ReplaceData).filter(task => new Date(task.DT)>=this.ReportStartDate &&  new Date(task.DT)<=this.ReportEndDate);
          this.allData = this.allData.bind(this);
          setTimeout(()=> 
            grid.saveAsExcel()
                    , 3000);
        }else{
          this.columns=[];
          this.columnsName=[];
          this.exportdata="";
          this.columns.push("DT");
          //組查詢IO
  
          let sbody = {
            IO_MAC_S: this.Search_IOData.map(d=>d.id).join(','),
            StartTime:this.MyCommon.FormateDateTime(this.ReportStartDate),
            EndTime: this.MyCommon.FormateDateTime(this.ReportEndDate),
            IntervalMinute: this.interval,
            Key:localStorage.getItem("Token")
          };
          this.History.GetHistoryChart(sbody, this.MyCommon.SetHeaders()).subscribe(
            (obj) => {
              this.MyCommon.CheckToken(obj);
              //去除日期的T
              for (let item of obj){
                item.DT=item.DT.replace("T"," ");
              }

              for (let i in this.Search_IOData) {
                this.columns.push(this.Search_IOData[i].id);
                this.columnsName.push(this.Search_IOData[i].name);
              }
  
              let ReplaceData=JSON.stringify(obj);
              for (let i in this.columns) {
                if (this.columns[i] !="DT"){
                  let Oldcolumns=this.columns[i];
                  let Newcolumns="MAC"+this.columns[i].replace("-","");
                  ReplaceData=ReplaceData.replace(new RegExp(Oldcolumns,"gm"),Newcolumns);
                  this.columns[i]=Newcolumns;
                }
              }
              this.exportdata=JSON.parse(ReplaceData);
              this.allData = this.allData.bind(this);
              setTimeout(()=> 
                grid.saveAsExcel()
                        , 3000);
            },
            err => { }
          );
        }
    }
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.exportdata
    };
    return result;
  }

  ReplaceTitle(value) {
    if (value != "DT") {
      let indexOfNumber = this.columns.indexOf(value);
      return this.columnsName[Number(indexOfNumber) - 1];
    }
    else {
      return value;
    }
  }
}