import { Component, OnInit, Inject } from '@angular/core';
import { IOService } from '../../../DataService/IO.service';
import { SystemService } from '../../../DataService/System.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DualListComponent } from 'angular-dual-listbox/index';
import { Permissions } from '../../../DataService/User.service';

@Component({
	selector: 'app-SystemSetting_Add',
	templateUrl: './SystemSetting_Add.component.html',
	styleUrls: ['./SystemSetting_Add.component.scss'],
	providers: [IOService, SystemService, MyCommon,Permissions]
})
export class SystemSetting_AddComponent implements OnInit {


	keepSorted = true;
	key: string;
	display: string;
	filter = true;
	SysParent_Source:any;
	ParentID:number;
	source: Array<any>;
	confirmed: Array<any>;
	format: any = { add: '新增', remove: '移除', all: '全部選取', none: '取消選取', direction: 'left-to-right' };//DualListComponent.DEFAULT_FORMAT;
	private sourceChessmen: Array<any>;
	private confirmedChessmen: Array<any>;

	private chessmen: Array<any> = [];
	private SelectData: Array<any> = [];

	private useChessmen() {
		this.key = 'IO_ID';
		this.display = 'DisplayName';
		this.keepSorted = false;
		this.source = this.sourceChessmen;
		this.confirmed = this.confirmedChessmen;//被選的資料
	}

	ID: any;
	Name: any = '';
	Sys_Enabled: any = '1';

 	public ViewPermissions:boolean=false;

	constructor(
		@Inject(IOService) private IOData: IOService,
		@Inject(SystemService) private SystemData: SystemService,
		@Inject(Permissions) private PermissionsData: Permissions,
		@Inject(MyCommon) private MyCommon: MyCommon,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) { }
	ngOnInit() {
		//判斷頁面權限
		let sbody = {
			UrlList:"SystemSettingAdd",
			Key:localStorage.getItem("Token")
		};

		var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
		stralert.subscribe(
			(obj) => {
			this.MyCommon.CheckToken(obj);
			for (let Site of obj) {
				if(Site.path=="SystemSettingAdd")
					this.ViewPermissions=true;
			}
			try{
				this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
			}catch(err){}
			},
			err => {  },
			() => { }
		);
		this.SetSysParentData();
		this.SetIOData();
	}
	SetSysParentData(){
		let StrWhere="";//" Where ParentID=-1";
		let sbody = {
			TopN: " ",
			StrWhere: StrWhere,
			Sort: " ",
			Key:localStorage.getItem("Token")
		  };		
		  this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
			  this.MyCommon.CheckToken(obj);
			  this.SysParent_Source = obj;
			},
			err => { console.log(err); }
		  );
	}
	SetIOData() {
		let sbody = {
			TopN: " ",
			StrWhere: " WHERE SYS_ID in (0,-1)",
			Sort: " ",
			Key:localStorage.getItem("Token")
		};
		this.IOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				this.MyCommon.CheckToken(obj);
				this.chessmen = obj;
				this.doReset();
			},
			err => { console.log(err); }
		);
	}

	doReset() {
		this.sourceChessmen = JSON.parse(JSON.stringify(this.chessmen));
		this.confirmedChessmen = JSON.parse(JSON.stringify(this.SelectData));// new Array<any>();
		this.useChessmen();
	}

	clicked() {
		if (confirm("確定要新增嗎?")) {
			this.SystemData.GetNewID().subscribe(
				(obj) => {
					this.ID = obj[0].NewID;
					this.Insert();
				},
				err => { console.log(err); }
			);
		}
	}

	Insert() {
		//組IOList字串
		var IOList = "";
		for (var i in this.confirmed) {
			IOList += this.confirmed[i].IO_ID + ",";
		}
		IOList = IOList.slice(0, -1);

		if (this.Sys_Enabled.toString()=="1")
			this.Sys_Enabled=true;
		else
			this.Sys_Enabled=false;

		if (this.ParentID == null){
			alert('請輸入父群組!');
			return false;
		}
		if (String(this.Name).trim()==''){
			alert('請輸入名稱!');
			return false;
		}
		// if (IOList==''){
		// 	alert('請選擇IO!');
		// 	return false;
		// }
		let sbody = {
			"ID": this.ID,
			"Name": this.Name,
			"Sys_Enabled": this.Sys_Enabled,
			"ParentID":this.ParentID,
			"IOList": IOList,
			Key:localStorage.getItem("Token")
		};
		this.SystemData.InsertData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				this.MyCommon.CheckToken(obj);
				alert('新增成功');
				//設定同步更新左側選單
				localStorage.setItem("SyncSide","1");
				this.router.navigate(['/pages/SystemSetting']);
			},
			err => { alert('新增失敗'); }
		);
	}

	back() {
		this.router.navigate(['/pages/SystemSetting']);
	}

}