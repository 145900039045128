import { Component, OnInit, Inject } from '@angular/core';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router } from '@angular/router';
import { Permissions } from '../../DataService/User.service';
import { IOCtrlSettingService } from '../../DataService/IOCtrlSetting.service';
import { DevService } from '../../DataService/Dev.service';


@Component({
  selector: 'app-IOCtrlSetting',
  templateUrl: './IOCtrlSetting.component.html',
  styleUrls: ['./IOCtrlSetting.component.scss'],
  providers: [DevService,Permissions, MyCommon, IOCtrlSettingService ]
})

export class IOCtrlSettingComponent implements OnInit {

    private StartDate: any = '2017/01/01 00:00:00';
    private EndDate: any = '2017/01/01 00:00:00';
    private SearchEnabled: String = '-1';
    //系統資料
    public sort: SortDescriptor[] = [];
    public gridView: GridDataResult;
    public MACID: String = '-1';
    public Dev_Source: any=[];
    private pageSize: number = 10;
    private skip: number = 0;

    //查詢參數
    Search_Category:any="IO_Info.Name";
    Search_Keyword:any="";
  
    public ViewPermissions:boolean=false;
    public AddPermissions:boolean=false;
    public EditPermissions:boolean=false;
    public DeletePermissions:boolean=false;
    public pageable:any="{numeric: false,previousNext: false,messages: {display: 'Showing {2} data items'}}";
    public SelectItem;

  constructor(
  @Inject(IOCtrlSettingService) private IOCtrlSettingData: IOCtrlSettingService,
  @Inject(DevService) private DevData: DevService,
  @Inject(Permissions) private PermissionsData: Permissions,
  @Inject(MyCommon) private MyCommon: MyCommon,
  private router: Router) { }

 

  ngOnInit() {
      //載入預設日期和起始時間
      var sNowDate = new Date();
      this.StartDate = new Date(sNowDate.getFullYear() + '/' +String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
 
      //載入預設日期和結束時間
      var DiffDay = (24 * 60 * 60 * 1000) * 7;
      var sDiffDate = new Date(sNowDate.getTime() + DiffDay);
      this.EndDate = new Date(sDiffDate.getFullYear() + '/' + String(Number(sDiffDate.getMonth()) + 1) + '/' + sDiffDate.getDate() + ' 00:00');

    //判斷頁面權限
     let sbody = {
       UrlList:"IOCtrlSettingView,IOCtrlSettingAdd,IOCtrlSettingEdit,IOCtrlSettingDelete",
       Key:localStorage.getItem("Token")
     };

     var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
     stralert.subscribe(
       (obj) => {
         this.MyCommon.CheckToken(obj);
         for (let Site of obj) {
           if(Site.path=="IOCtrlSettingView")
             this.ViewPermissions=true;
           if(Site.path=="IOCtrlSettingAdd")
             this.AddPermissions=true;
           if(Site.path=="IOCtrlSettingEdit")
             this.EditPermissions=true;
           if(Site.path=="IOCtrlSettingDelete")
             this.DeletePermissions=true;
         } 
         
         if(this.EditPermissions){
          // this.splitbutton_data.push(
          //   {
          //     text: '複製',
          //     iconClass: 'fa fa-pencil-square-o',
          //     click: () => {this.router.navigate(['/pages/IOCtrlSetting', this.SelectItem.ID]); }
          //   });
           this.splitbutton_data.push(
            {
              text: '修改',
              iconClass: 'fa fa-pencil-square-o',
              click: () => {this.router.navigate(['/pages/IOCtrlSettingEdit', this.SelectItem.ID]); }
            });
         }
         this.splitbutton_data.push(
          {
            text: '執行',
            iconClass: 'fa fa-check',
            click: () => { this.DevRun(this.SelectItem);}
          });
         if(this.DeletePermissions){
           this.splitbutton_data.push(
           {
             text: '刪除',
             iconClass: 'fa fa-trash-o',
             click: () => { this.Delete(this.SelectItem);}
           });
         }
         try{
           this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
         }catch(err){}
       },
       err => {  },
       () => { }
     );

   this.GetDevData();
   this.GetData();
 }

 public splitbutton_data: Array<any> = [];

 public onPaste(obj): void {
   this.SelectItem=obj;
 }


 //排序
 sortChange(sort: SortDescriptor[]): void {
   this.sort = sort;
   this.GetData();
 }
 //換頁
 protected pageChange(event: PageChangeEvent): void {
   this.skip = event.skip;
   this.GetData();
 }
 
 Search(){
    this.skip=0;
    this.GetData();
  }
 //取得設備資料
 GetDevData(){
    var StrWhere = " where  DEV.DataType=0";

    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DevData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.Dev_Source =obj;
      },
      err => { console.log(err); }
    );
  }
 //取得資料
 GetData() {
  let StrWhere=" Where 1=1";
  if (this.Search_Keyword !=""){
    StrWhere +=" AND "+this.Search_Category+" Like '%"+this.Search_Keyword+"%'";
  }
  if (String(this.MACID) != "-1")
    StrWhere +=" AND Ctrl_MAC = "+this.MACID;
  if(localStorage.getItem('DevID')!=""&&localStorage.getItem('DevID')!=null)
  {
    StrWhere +=" AND concat(Ctrl_MAC,'-S',Ctrl_S)= '"+localStorage.getItem('DevID')+"'";
    localStorage.setItem('DevID','');
  }
   let sbody = {
     TopN: " ",
     StrWhere: StrWhere,
     Sort: " ",
     Key:localStorage.getItem("Token")
   };

   this.IOCtrlSettingData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
     (obj) => {
       this.MyCommon.CheckToken(obj);
       this.gridView = {
         data: orderBy(obj, this.sort),
         total: obj.length
       };
     },
     err => { }
   );
   
 }
 //刪除資料
 Delete(del: any) {
  if (confirm("確定要刪除嗎?")) {
     let sbody = {
       ID: del.ID,
       Key:localStorage.getItem("Token")
     };    
     var stralert = this.IOCtrlSettingData.DeleteData(sbody, this.MyCommon.SetHeaders());
     stralert.subscribe(
       (obj) => {      
            this.MyCommon.CheckToken(obj);
            this.GetData(); 
      },
       err => alert('刪除失敗'),
       () => { }
     );
     }
   }
   //執行
  DevRun(Item: any) {
    let sbody = {
      IOCtrl_ID: Item.ID,
      Key:localStorage.getItem("Token")
    };    
    var stralert = this.IOCtrlSettingData.IOCtrlRun(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {      
          this.MyCommon.CheckToken(obj);
          this.GetData(); 
      },
     
      () => { }
    );
  }
}
