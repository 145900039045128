import { Component, OnInit ,Inject} from '@angular/core';
import { AlarmService } from '../../../DataService/Alarm.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { SystemService } from '../../../DataService/System.service';
import { IOService } from '../../../DataService/IO.service';

@Component({
  selector: 'app-AlarmSetting_Edit',
  templateUrl: './AlarmSetting_Edit.component.html',
  styleUrls: ['./AlarmSetting_Edit.component.scss'],
    providers: [AlarmService, MyCommon,SystemService,IOService]
})
export class AlarmSetting_EditComponent implements OnInit {

  IO_ID : any;
  private sub:any;
  public FromData:string[];
  //系統資料
  SYS_Source: any;
  //IO資料
  IO_Source: any;
  Ctn_Type:any='';
  public SYS_ID: any;
  public IO_Data: any='-1';


  constructor(
    @Inject(AlarmService) private AlarmData: AlarmService,
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(IOService) private IOData: IOService,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
          this.IO_ID = params['id'];
            this.GetData();
      });
  }

    GetData() {
      let sbody = {
        TopN: " ",
        StrWhere: " where CONCAT(IO_Info.IO_MAC,'-S',IO_Info.IO_S)='"+this.IO_ID+"' ",
        Sort: " ",
        Key:localStorage.getItem("Token")
      };
      this.AlarmData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
         this.MyCommon.CheckToken(obj);
         this.FromData = obj;
         this.SetIO();
         this.GetSysData();
        },
        err => { console.log(err); }
      );
    }

  SetIO(){

    if (this.FromData["0"].Ctn_IOID=='-1'){
      this.Ctn_Type='null';
      this.FromData[0]["Ctn_UpVal"]='(NULL)';
      this.FromData[0]["Ctn_DownVal"]='(NULL)';
    }
    else{
      let sbody = {
        TopN: " ",
        StrWhere: " where CONCAT(IO_Info.IO_MAC,'-S',IO_Info.IO_S)='"+this.FromData["0"].Ctn_IOID+"' ",
        Sort: " ",
        Key:localStorage.getItem("Token")
      };

      this.AlarmData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.SYS_ID=obj["0"].Sys_ID;
          this.Ctn_Type=String(obj["0"].IO_Type);
          //判斷如果是DI DO就給預設值1
          if (this.Ctn_Type=='DO' || this.Ctn_Type=='DI'){
            this.FromData[0]["Ctn_UpVal"]='1';
          }
          },
        err => { console.log(err); }
      );
    }
  }

    //取得系統資料
  GetSysData() {
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.SYS_Source = obj;
        this.GetIOData();
      },
      err => { console.log(err); }
    );
  }
  //取得IO資料
  GetIOData() {
    var StrWhere = " where IO_Info.SYS_ID='" + this.SYS_ID + "' ";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.IOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.IO_Source = obj;
        if (String(this.FromData["0"].Ctn_IOID)=='')
          this.IO_Data='-1';
        else
          this.IO_Data=String(this.FromData["0"].Ctn_IOID);
      },
      err => { console.log(err); }
    );
  }
  //系統選擇選取
  SYSChange() {
    this.FromData["0"].Ctn_IOID='-1';
    this.SetIO();
    this.GetIOData();
  }
  //IO選擇選取
  IOChange(){
    this.FromData["0"].Ctn_IOID=this.IO_Data;
    this.SetIO();
  }

  ngOnDestroy() {
      // Clean sub to avoid memory leak
    this.sub.unsubscribe();
  }

  clicked() {
    if (confirm("確定要更新嗎?")) {
        this.Update();
     }
  }

  Update() {


    //將空值轉成null
    if (String(this.FromData[0]["Ctn_UpVal"]).trim()==""){
      this.FromData[0]["Ctn_UpVal"]=null;
    }
    if (String(this.FromData[0]["Ctn_DownVal"]).trim()==""){
      this.FromData[0]["Ctn_DownVal"]=null;
    }
    if (String(this.FromData[0]["Alarm_UpVal"]).trim()==""){
      this.FromData[0]["Alarm_UpVal"]=null;
    }
    if (String(this.FromData[0]["Alarm_DownVal"]).trim()==""){
      this.FromData[0]["Alarm_DownVal"]=null;
    }
    //判斷條件狀態和I/O警戒上下限是否至少有一項資料
    if (String(this.FromData[0]["IO_Type"])=='AI' || String(this.FromData[0]["IO_Type"])=='AO'){
      if (String(this.FromData[0]["Ctn_UpVal"])=="null" && String(this.FromData[0]["Ctn_DownVal"])=="null"){
        alert("條件狀態須至少填寫一項!");
        return false;
      }
      if (String(this.FromData[0]["Alarm_UpVal"])=="null" && String(this.FromData[0]["Alarm_DownVal"])=="null"){
        alert("I/O警戒上下限須至少填寫一項!");
        return false;
      }
    }

    var Ctn_DownVal= this.FromData[0]["Ctn_DownVal"];
    var Alarm_DownVal= this.FromData[0]["Alarm_DownVal"];
    if(this.FromData[0]["IO_Type"]=='DI' || this.FromData[0]["IO_Type"]=='DO')
    {
      if(this.FromData[0]["Alarm_UpVal"].toString()=="1")
        Alarm_DownVal="-1";
      else
        Alarm_DownVal="0";
    }

    //條件IO判斷
    if(this.Ctn_Type=='DI' || this.Ctn_Type=='DO')
    {
      if(this.FromData[0]["Ctn_UpVal"].toString()=="1")
        Ctn_DownVal="-1";
      else
        Ctn_DownVal="0";
    }


    if (this.FromData[0]["SMS_Enabled"].toString()=="true")
      this.FromData[0]["SMS_Enabled"]=1;
    else
      this.FromData[0]["SMS_Enabled"]=0;
    if (this.FromData[0]["Mail_Enabled"].toString()=="true")
      this.FromData[0]["Mail_Enabled"]=1;
    else
      this.FromData[0]["Mail_Enabled"]=0;
    if (this.FromData[0]["Alarm_Enabled"].toString()=="true")
      this.FromData[0]["Alarm_Enabled"]=1;
    else
      this.FromData[0]["Alarm_Enabled"]=0;

     
      //如果條件IO為空將值轉空白
      if (this.IO_Data=='-1')
        this.IO_Data='';


   let sbody = 
     {
        "IO_MAC":this.FromData[0]["IO_MAC"],
        "IO_S": this.FromData[0]["IO_S"],
        "Ctn_UpVal":  String(this.FromData[0]["Ctn_UpVal"]),
        "Ctn_DownVal":  String(Ctn_DownVal),
        "Ctn_Sec": this.FromData[0]["Ctn_Sec"],
        "Alarm_UpVal": String(this.FromData[0]["Alarm_UpVal"]),
        "Alarm_DownVal": String(Alarm_DownVal),
        "Alarm_Sec":this.FromData[0]["Alarm_Sec"],
        "Alarm_Enabled": this.FromData[0]["Alarm_Enabled"],
        "DBend": this.FromData[0]["DBend"],
        "SMS_Enabled": this.FromData[0]["SMS_Enabled"],
        "Mail_Enabled": this.FromData[0]["Mail_Enabled"],
        "Line_Enabled": this.FromData[0]["Line_Enabled"],
        "Ctn_IOID":this.IO_Data,
        Key:localStorage.getItem("Token")
      };

     var stralert = this.AlarmData.UpdateData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          alert('修改成功');
          this.router.navigate(['/pages/AlarmSetting']);
        },
        err => { alert('修改失敗'); console.log(err); },
        () => { }
      );
  }
  back(){
      this.router.navigate(['/pages/AlarmSetting']);
  }
}