import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { GridDataResult, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { ReportService } from '../../../DataService/Report.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-ReportDownload',
  templateUrl: './ReportDownload.component.html',
  providers: [ReportService,Permissions, MyCommon]
})
export class ReportDownloadComponent implements OnInit {

  public ViewPermissions:boolean=false;
  Type:any="1";
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;
  public SelectItem;
  public splitbutton_data: Array<any> = [];

  constructor(
     @Inject(ReportService) private ReportData: ReportService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {

      //判斷頁面權限
      let sbody = {
        UrlList:"ReportDownload",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="ReportDownload")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );

      this.GetData();
      /*
      this.splitbutton_data.push(
            {
              //text: '下載',
              text:' <a target="_blank" href="{{Download2(dataItem)}}">下載</a>',
              iconClass: 'fa fa-download',
              //click: () => { this.Download(this.SelectItem);}
            });*/
  }
  //取得目錄
  GetData(){

    let sbody = {
      Type:this.Type,
      Key:localStorage.getItem("Token")
    };
    this.ReportData.GetReportDownload(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        let data=[];
        try{
          if(obj[0]=="["){
            data=JSON.parse(obj);
          }else{
            data=obj;
          }
        }catch(err){

        }
        let ReportData=data;
        this.gridView = {
          data: orderBy(ReportData, this.sort),
          total: ReportData.length
        };
      },
      err => { console.log(err); }
    );
  }

    public onPaste(obj): void {
      this.SelectItem=obj;
    }

  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }

  //下載
   Download2(Item: any) {
    var Url=Item.path;
    return Url;
  }
  //類型選擇
  TypeChange(){
    this.GetData();
  }

}
