import { Component, OnInit, Inject } from '@angular/core';
import { PowerAnalysisService,PowerAnalysisTypeService,DevService,Permissions } from '../../../../DataService';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-PAGroupSettingEdit',
  templateUrl: './PAGroupSettingEdit.component.html',
  providers: [PowerAnalysisService,PowerAnalysisTypeService,DevService, MyCommon,Permissions]
})
export class PAGroupSettingEditComponent implements OnInit {

  PAGroup_ID:any=-1;
  SetGroupData:any=[];
  TypeData=[];
  MeterData=[];
  SetMeterData=[];
  SetPAType_ID:number=-1;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public meter_gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;

  constructor(
  @Inject(PowerAnalysisService) private PAGroupData: PowerAnalysisService,
  @Inject(PowerAnalysisTypeService) private PATypeData: PowerAnalysisTypeService,
  @Inject(DevService) private DevData: DevService,
  @Inject(Permissions) private PermissionsData: Permissions,
  @Inject(MyCommon) private MyCommon: MyCommon,
  private activatedRoute: ActivatedRoute,
  private router: Router) { }

  ngOnInit() {
    this.GetTypeData();
    this.GetDEVData();
    this.GetData();
  }

  GetData(){
    this.activatedRoute.params.subscribe((params: Params) => {
			this.PAGroup_ID = params['id'];
		});
    let sbody = {
      PAGroup_ID: this.PAGroup_ID,
      Key:localStorage.getItem("Token")
    };
  
    this.PAGroupData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        
        try{
          if(obj[0]=="["){
            this.SetGroupData=JSON.parse(obj);
          }else{
            this.SetGroupData=obj;
          }
          
          this.SetPAType_ID=this.SetGroupData[0].PAType_ID;
          this.SetData();
        }catch(err){
        }
      },
      err => { console.log(err); }
    );
  }
  SetData(){
    try{
      
      var MeterList=JSON.parse(this.SetGroupData[0].MeterList);
      for(let i in MeterList){
        this.SetMeterData.push({CP_ID:MeterList[i].CP_ID,BD_ID:MeterList[i].BD_ID,PAType_ID:MeterList[i].PAType_ID
          ,MACID:MeterList[i].MACID,DevName:MeterList[i].DevName,Coefficient:MeterList[i].Coefficient
          ,describe:MeterList[i].describe,PAGroup_ID:MeterList[i].PAGroup_ID,});
      }
     
      this.meter_gridView = {
        data: orderBy(this.SetMeterData.slice(this.skip, this.skip + this.pageSize), this.sort),
        total: this.MeterData.length
      };
      //左邊清單過濾
      var SetMACList=[];
      for(let i in this.SetMeterData){
        SetMACList.push(this.SetMeterData[i].MACID);
      }

      var SetData=this.MeterData;
      SetData = SetData.filter(task => SetMACList.findIndex(v => v==task.MACID)==-1);

      this.gridView = {
        data: orderBy(SetData.slice(this.skip, this.skip + this.pageSize), this.sort),
        total: SetData.length
      };
    }catch(err){
    }

  }

  GetTypeData(){

    let sbody = {
      TopN: " ",
      StrWhere:  " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.PATypeData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.TypeData=obj;
      },
      err => { console.log(err); }
    );
  }

  
  //取得電表資料
  GetDEVData() {
    let sbody = {
      TopN: " ",
      StrWhere:" ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.DevData.GetMeter(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.MeterData = obj;
      },
      err => { console.log(err); }
    );
  }

  public onAddMeter(obj): void {
    //不重複時加入
    var Data_filter = this.SetMeterData.filter(task => task.MACID ==obj.MACID);
    if(Data_filter.length==0){
      this.SetMeterData.push({CP_ID:0,BD_ID:0,PAType_ID:0,MACID:obj.MACID,DevName:obj.DevName,Coefficient:0,describe:"",PAGroup_ID:0,});
      this.meter_gridView = {
        data: orderBy(this.SetMeterData.slice(this.skip, this.skip + this.pageSize), this.sort),
        total: this.MeterData.length
      };
      //左邊清單過濾
      var SetMACList=[];
      for(let i in this.SetMeterData){
        SetMACList.push(this.SetMeterData[i].MACID);
      }
      var SetData=this.MeterData;
      SetData = SetData.filter(task => SetMACList.findIndex(v => v==task.MACID)==-1);

      this.gridView = {
        data: orderBy(SetData.slice(this.skip, this.skip + this.pageSize), this.sort),
        total: SetData.length
      };
    }
  }
  public onDelMeter(obj): void {
     //清除
    var Data_filter = this.SetMeterData.filter(task => task.MACID !=obj.MACID);
    this.SetMeterData=Data_filter;
    this.meter_gridView = {
      data: orderBy(this.SetMeterData.slice(this.skip, this.skip + this.pageSize), this.sort),
      total: this.MeterData.length
    };
    //左邊清單過濾
    var SetMACList=[];
    for(let i in this.SetMeterData){
      SetMACList.push(this.SetMeterData[i].MACID);
    }
    var SetData=this.MeterData;
    SetData = SetData.filter(task => SetMACList.findIndex(v => v==task.MACID)==-1);

    this.gridView = {
      data: orderBy(SetData.slice(this.skip, this.skip + this.pageSize), this.sort),
      total: SetData.length
    };
  }
  clicked(){
    if (confirm("確定要修改嗎?")) {
      this.Update();
    }
  }
  
  Update() {
    var MeterList=[];
    
    //設定寫入清單
    for(let i in this.SetMeterData){
      MeterList.push({
        CP_ID:0,BD_ID:0
        ,PAType_ID:this.SetPAType_ID,MACID:this.SetMeterData[i].MACID
        ,Coefficient:this.MyCommon.CheckNumber(this.SetMeterData[i].Coefficient),describe:this.SetMeterData[i].describe
        ,PAGroup_ID:this.SetGroupData[0].PAGroup_ID});
    }

    let sbody = {
      CP_ID: 0,
      BD_ID: 0,
      PAType_ID:this.SetPAType_ID,
      PAGroup_ID: this.SetGroupData[0].PAGroup_ID,
      PATable: MeterList,
			Key:localStorage.getItem("Token")
    };
		
		this.PAGroupData.UpdateData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				//this.MyCommon.CheckToken(obj);
        alert("儲存完成!");
        this.router.navigate(['/pages/PAGroupSetting']);
			},
			err => { console.log(err); }
		);
  }
	back() {
		this.router.navigate(['/pages/PAGroupSetting']);
	}
}
