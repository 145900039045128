import { Component, Input } from '@angular/core';

@Component({
  selector: 'sy-From',
  templateUrl: './sy-From.component.html',
  styleUrls: ['./sy-From.component.scss']
})
export class SyFromComponent{
  @Input() title:String;
  @Input() SetClass:String;
}
