import { Component, OnInit, Inject } from '@angular/core';
import { DemandCtrlSettingService } from '../../../DataService/DemandCtrlSetting.service';
import { DevService } from '../../../DataService/Dev.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';
import { DemandCtrlSettingColumn } from '../DemandCtrlSetting';

@Component({
  selector: 'app-DemandCtrlSetting_Edit',
  templateUrl: './DemandCtrlSetting_Edit.component.html',
  providers: [ DevService, MyCommon, Permissions,DemandCtrlSettingService ]
})
export class DemandCtrlSetting_EditComponent implements OnInit {

  public Meter_Source: any=[];
  public Dev_Source: any=[];

  constructor(
    @Inject(DemandCtrlSettingService) private DemandCtrlData: DemandCtrlSettingService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(DevService) private DevData: DevService,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router,
    private activatedRoute: ActivatedRoute, ) { }

  //預設欄位
  DemandCtrlSettingColumn = new DemandCtrlSettingColumn(0,0,0,0,1,0,0,0,0,0,0,1);
  public ViewPermissions:boolean=false;
  ngOnInit() {
     //判斷頁面權限
     let sbody = {
      UrlList:"DemandCtrlSettingEdit",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
            if(Site.path=="DemandCtrlSettingEdit")
                this.ViewPermissions=true;
        }
        try{
          this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
        }catch(err){}
      },
      err => {  },
      () => { }
    );
    this.SetData();
    this.GetMeterData();
    this.GetDevData();
  }

  //取得資料
SetData() {
  let ID=0;
  this.activatedRoute.params.subscribe((params: Params) => {
    ID = params['id'];
  });
  var StrWhere = " where ID=" + ID;
  
   let sbody = {
     TopN: " ",
     StrWhere: StrWhere,
     Sort: " ",
     Key:localStorage.getItem("Token")
   };
  
   this.DemandCtrlData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
     (obj) => {
       this.MyCommon.CheckToken(obj);
       if(obj.length>0){
        this.DemandCtrlSettingColumn.ID=ID;
        this.DemandCtrlSettingColumn.Enabled=obj[0].Enabled?1:0;
        this.DemandCtrlSettingColumn.DCType=obj[0].DCType;
        this.DemandCtrlSettingColumn.MACID=obj[0].MACID;
        this.DemandCtrlSettingColumn.CtrlMACID=obj[0].CtrlMACID;
        this.DemandCtrlSettingColumn.CtrlSID=obj[0].CtrlSID;
        this.DemandCtrlSettingColumn.Value_P1=obj[0].Value_P1;
        this.DemandCtrlSettingColumn.Value_P2=obj[0].Value_P2;
        this.DemandCtrlSettingColumn.Value_P3=obj[0].Value_P3;
        this.DemandCtrlSettingColumn.CtrlValue_P1=obj[0].CtrlValue_P1;
        this.DemandCtrlSettingColumn.CtrlValue_P2=obj[0].CtrlValue_P2;
        this.DemandCtrlSettingColumn.CtrlValue_P3=obj[0].CtrlValue_P3;
       }
     },
     err => { }
   );
   
  }

   //取得設備資料
   GetMeterData(){

		let sbody = {
			TopN: " ",
			StrWhere: " ",
			Sort: " ",
			Key:localStorage.getItem("Token")
		};
		this.DevData.GetMeter(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				this.MyCommon.CheckToken(obj);
        this.Meter_Source =obj;
			},
			err => { console.log(err); }
		);
  }
   //取得設備資料
   GetDevData(){
    var StrWhere = " where  DEV.DataType=0";

		let sbody = {
			TopN: " ",
			StrWhere: StrWhere,
			Sort: " ",
			Key:localStorage.getItem("Token")
		};
		this.DevData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				this.MyCommon.CheckToken(obj);
        this.Dev_Source =obj;
			},
			err => { console.log(err); }
		);
  }
  //點選儲存
  clicked() {
    if (confirm("確定要修改嗎?")) {
      this.Insert();
    }
  }
 //新增
 Insert() {
 
   let sbody = {
     "ID":this.DemandCtrlSettingColumn.ID,
     "DCType":this.DemandCtrlSettingColumn.DCType,
     "MACID":this.DemandCtrlSettingColumn.MACID,
     "CtrlMACID":this.DemandCtrlSettingColumn.CtrlMACID,
     "CtrlSID":this.DemandCtrlSettingColumn.CtrlSID,
     "Value_P1":this.DemandCtrlSettingColumn.Value_P1,
     "Value_P2":this.DemandCtrlSettingColumn.Value_P2,
     "Value_P3":this.DemandCtrlSettingColumn.Value_P3,
     "CtrlValue_P1":this.DemandCtrlSettingColumn.CtrlValue_P1,
     "CtrlValue_P2":this.DemandCtrlSettingColumn.CtrlValue_P2,
     "CtrlValue_P3":this.DemandCtrlSettingColumn.CtrlValue_P3,
     "Enabled":this.DemandCtrlSettingColumn.Enabled,
     Key: localStorage.getItem("Token")
   };

   var stralert = this.DemandCtrlData.UpdateData(sbody, this.MyCommon.SetHeaders());
   stralert.subscribe(
     (obj) => {
       this.MyCommon.CheckToken(obj);
       if (obj[0]["ID"] == "-1") {
          alert("無法進行存檔，資料重覆!");
        } else {
          alert('修改成功');
          this.router.navigate(['/pages/DemandCtrlSetting']);
        }
     },
     err => { alert('修改失敗'); },
     () => { }
   );
 }

 //離開
 back() {
   this.router.navigate(['/pages/DemandCtrlSetting']);
 }

}
