import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class DevService {

constructor(@Inject(HttpClient) 
        private http : HttpClient,
        public MyCommon:MyCommon) { }

    GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetDEV');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('DEVData');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

    GetMeter(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('GetMeter');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

}