import { Component, OnInit, Inject, ViewChild  } from '@angular/core';
import { HistoryService } from '../../../DataService/History.service';
import { DevService } from '../../../DataService/Dev.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { groupBy} from '@progress/kendo-data-query';
import 'hammerjs';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import {  GridComponent } from '@progress/kendo-angular-grid';
import { Permissions } from '../../../DataService/User.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-PowerSearch',
  templateUrl: './PowerSearch.component.html',
  styleUrls: ['./PowerSearch.component.scss'],
  providers: [HistoryService, DevService,Permissions, MyCommon]
})
export class PowerSearchComponent implements OnInit {

  //參數
  DEV_Source: any;
  Interval_Source: any = [{ name: '15分鐘', value: '1' }, { name: '30分鐘', value: '2' },  { name: '1小時', value: '6' }, { name: '1天', value: '3' },
                          { name: '1周', value: '4' },{ name: '1個月', value: '5' }];
  
  public Dev_MACID: any = '-1';
  public interval: any = '1';
  public SetStep:number=30;
  data: any;
  exportdata:any;
  color:any=['#2F733D','#fb9b51','#45CDB1'];

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  public ChartData: Array<any> = [
    { data: [], label: '' }
  ];
  public ChartLabels: Array<any> = [];
  public ChartEnable: any = "0";

  //DatePick設定
  StartDate: any = '2017/01/01 00:00';
  EndDate: any = '2017/01/01 00:00';

  //選擇IO
  DEV_Data:any=-1;
  Select_DEV = [];
  Search_DEV: any = "";

  series: any;
  SeriesData=[];
  step:any=1;
  Status:string;

  constructor( @Inject(HistoryService) private HistoryData: HistoryService,
    @Inject(DevService) private DevData: DevService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon) { }

  ngOnInit() {
    //載入預設日期
    var sNowDate = new Date();
    this.StartDate = new Date(sNowDate.getFullYear() + '/' + String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
    //單位為一天的時間
    var DiffDay = (24 * 60 * 60 * 1000) * 1;
    var sDiffDate = new Date(sNowDate.getTime() + DiffDay);
    this.EndDate =  new Date(sDiffDate.getFullYear() + '/' + String(Number(sDiffDate.getMonth()) + 1) + '/' + sDiffDate.getDate() + ' 00:00');

      //判斷頁面權限
      let sbody = {
        UrlList:"PowerSearch",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="PowerSearch")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
      this.GetDEVData();
  }

  //取得電表資料
  GetDEVData() {
    var StrWhere = " ";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.DevData.GetMeter(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.DEV_Source = obj;
      },
      err => { console.log(err); }
    );
  }

  

  //增加查詢DEV
  AddDEV() {
    if (this.DEV_Data == -1) {
      alert("請選擇裝置!")
    }
    else {
  //判斷是否重複
  var CheckDEV=this.Select_DEV.filter(
  task => task.id == this.DEV_Data);
  if (CheckDEV.length==0)
  {
      //篩選DEV資料
      var DEV_filter = this.DEV_Source.filter(
        task => String(task.MACID) == String(this.DEV_Data));
      //增加選擇DEV
      this.Select_DEV.push({ id: this.DEV_Data, name: DEV_filter[0].DevName });
      }
    }
  }
  //刪除查詢DEV
  DelDEV(id: any) {
    var DEV_filter = this.Select_DEV.filter(
      task => task.id != id);
    this.Select_DEV = DEV_filter;
  }
  //刪除全部查詢DEV
  AllDel(){
    this.Select_DEV=[];
  }

  //判斷小時和分鐘補足兩位
  FormatDate(val: any) {
    if (String(val).length == 1)
      return "0" + val;
    else
      return val;
  }

  //顯示圖表
  showChart() {
    if (this.Select_DEV.length > 0) {

      //組查詢DEV
      this.Search_DEV = "";
      for (let i in this.Select_DEV) {
        this.Search_DEV += this.Select_DEV[i].id + ",";
      }
      this.Search_DEV = this.Search_DEV.slice(0, -1);

      //打開chart
      this.ChartEnable = '1';

      let sbody = {
        MACID: this.Search_DEV,
        StartTime: this.MyCommon.FormateDateTime(this.StartDate),
        EndTime: this.MyCommon.FormateDateTime(this.EndDate),
        IntervalMinute: this.interval,
        Key:localStorage.getItem("Token")
      };
      this.data="";
      this.Status="查詢中";
      this.HistoryData.GetPowerSearchChart(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj); 
          //取代別名
          this.data=obj.map(d=>{
            let item={};
            item["DT"]=d.DT;
            for (let i in this.Select_DEV){
              item["Dev"+String(this.Select_DEV[i].id)] =d[String(this.Select_DEV[i].id)];
             }
            return item;
          });
          this.Chart();
          this.Status="";
        },
        err => { 
          this.Status="查詢逾時";
        }
      );

    }
    else {
      alert("請選擇裝置!")
    }
  }

Chart(){
  
  this.SeriesData=[];
  for (var i = 0; i < this.data.length ; i++) {
    for (let j in this.Select_DEV) {
      
      var labeleList=this.data[i]["DT"].split(' ');
      var labele=labeleList[0]+"\n"+labeleList[1];
      this.SeriesData.push({ interval: labele, service: this.Select_DEV[j].id,value: this.data[i]["Dev"+this.Select_DEV[j].id]});
    }
  }
  
   this.series = groupBy(this.SeriesData, [{ field: "service"}]);
   var i=0;
   for (var item of this.series){
    //將Legend名稱轉成DEV名稱
    item.value=this.Select_DEV[i].name;

    //載入顏色
    this.series[i]["color"]=this.color[i];
    i++;
   }
   
  //計算lables的step
  this.SetStep=Math.round(this.data.length/6);
  this.ChartEnable = '1';
}

columns:any=[];
columnsName:any=[];
//輸出檔案
public exportToExcel(grid: GridComponent): void {
  if (this.Select_DEV.length > 0) {
    if(this.data!=null && this.data.length!=0){
      this.exportAsExcelFile(this.data,'_excel');
    }else{
      //組查詢DEV
      this.Search_DEV = "";
      for (let i in this.Select_DEV) {
        this.Search_DEV += this.Select_DEV[i].id + ",";
      }
      this.Search_DEV = this.Search_DEV.slice(0, -1);
      let sbody = {
        MACID: this.Search_DEV,
        StartTime: this.MyCommon.FormateDateTime(this.StartDate),
        EndTime: this.MyCommon.FormateDateTime(this.EndDate),
        IntervalMinute: this.interval,
        Key:localStorage.getItem("Token")
      };
      this.HistoryData.GetPowerSearchChart(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          obj=obj.map(d=>{
            let item={};
            item["DT"]=this.MyCommon.FormateDateTime2(new Date(d.DT));
            for (let i in this.Select_DEV){
              item[String(this.Select_DEV[i].name)] =d[String(this.Select_DEV[i].id)];
             }
            return item;
          });
          this.exportAsExcelFile(obj,'_excel');
        },
        err => { }
      );
    }
  }else{
    alert("請選擇裝置!")
  }
}

  //建立Excel
  public exportAsExcelFile(json: any[], excelFileName: string){
   
    //取代名稱
   for (let i in this.Select_DEV){
     json = JSON.parse(JSON.stringify(json).split('"Dev'+this.Select_DEV[i].id+'":').join('"'+this.Select_DEV[i].name+'":'));

    }
    json=json.map(d=>{
      d.DT=this.MyCommon.FormateDateTime2(new Date(d.DT));
      return d;
    });
    let Table_Name="耗電量查詢";
    //匯出設定
   const workBook = XLSX.utils.book_new(); // create a new blank book
   let SheetBook= XLSX.utils.book_new(); // create a new blank book
   let Sheet="{ '"+Table_Name+"':[]}";
   Sheet=Sheet.replace(/\'/g, '"');
   let SetSheet=[];
   SetSheet=JSON.parse(Sheet);
   SetSheet[Table_Name]=XLSX.utils.json_to_sheet(json);
   //const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

   var wscols = [{wpx: 100}];
   SetSheet[Table_Name]['!cols'] = wscols ;

   SheetBook=Object.assign(SheetBook,SetSheet);
   XLSX.utils.book_append_sheet(workBook, SetSheet[Table_Name],Table_Name); // add the worksheet to the book

   let sNowDate = new Date();
   let fileName=Table_Name+sNowDate.getFullYear()+this.FormatDate(String(Number(sNowDate.getMonth()) + 1))+sNowDate.getDate()+this.FormatDate(sNowDate.getHours())+this.FormatDate(sNowDate.getMinutes())+"_excel_backup";
   XLSX.writeFile(workBook, fileName+'.xlsx');


 }
 
 private wrapAndCenterCell(cell: XLSX.CellObject) {
  const wrapAndCenterCellStyle = { alignment: { wrapText: true, vertical: 'center', horizontal: 'center' } };
  this.setCellStyle(cell, wrapAndCenterCellStyle);
}

private setCellStyle(cell: XLSX.CellObject, style: {}) {
  cell.s = style;
}

private saveAsExcelFile(buffer: any, fileName: string): void {
  const data: Blob = new Blob([buffer], {
    type: EXCEL_TYPE
  });
  FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.exportdata
    };
    return result;
  }

  ReplaceTitle(value){
    if (value != "DT"){
      var indexOfNumber=this.columns.indexOf(value);
      return this.columnsName[Number(indexOfNumber)-1];
    }
    else{
      return value;
    }
  }

}
