import { Component, OnInit,Inject } from '@angular/core';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import 'hammerjs';
import { DemandService } from '../../../DataService/Demand.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-MeterDemandReal',
  templateUrl: './MeterDemandReal.component.html',
  providers: [ MyCommon,DemandService,Permissions]
})
export class MeterDemandRealComponent implements OnInit {
  public ViewPermissions:boolean=false;


  constructor(@Inject(DemandService) private DemandData: DemandService,
  @Inject(Permissions) private PermissionsData: Permissions,
  @Inject(MyCommon) private MyCommon: MyCommon,
  private activatedRoute: ActivatedRoute,
  private router: Router) { }
  
  public SetStep:number=30;

  Meter_Source=[];
  SetItem=[];

  SeriesData=[];
  series: any[];
  categories: string[];

  ngOnInit() {
     //判斷頁面權限
     let sbody = {
      UrlList:"MeterDemandReal",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
            if(Site.path=="MeterDemandReal")
                this.ViewPermissions=true;
        }
        try{
          this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
        }catch(err){}
      },
      err => {  },
      () => { }
    );
    this.SetMeter();
  }

  SetMeter(){
    var StrWhere = "";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DemandData.GetMeter(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.Meter_Source=obj;
        if(this.Meter_Source.length>0){
          this.SetItem=this.Meter_Source[0];
          this.SetChart();
        }
      },
      err => { console.log(err); }
    );
  }

  RindChart(item){
    this.SetItem=item;
    this.SetChart();
    console.log(item);
  }
  
  SetChart(){
    var StrWhere = " where PMKWH_RTD.DT between '"+this.MyCommon.FormateDate(new Date())+" 00:00:00' and '"+this.MyCommon.FormateDate(new Date())+" 23:59:59' and PMKWH_RTD.MACID="+this.SetItem["MACID"];
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DemandData.GetDemand(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        var DemandJson=obj;
        this.series=[];
        this.categories=[];
        var Demand1=[],Demand2=[];
        for(let i in DemandJson){
          Demand1.push(DemandJson[i]["KWH"]);
          Demand2.push(DemandJson[i]["Demand_FC"]);
          
          this.categories.push(DemandJson[i]["DT"].replace("T","\\n"));
          
        }
        this.series=[{
            name: '需量',
            data: Demand1,
            color:"#99C62A"
        }, {
            name: '需量預測',
            data: Demand2,
            color:"#27ADCC"
        }];
        this.SetStep=Math.round(DemandJson.length/6);
      },
      err => { console.log(err); }
    );
  }
}
