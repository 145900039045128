import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class UserService {

constructor(@Inject(HttpClient) 
        private http : HttpClient,
        public MyCommon:MyCommon) { }

    GetData(sbody:any,headers:any=""){
       var url = this.MyCommon.GetAPISPUrl('User');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    InsertData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('UserData');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('UserData');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    DeleteData(sbody:any,headers:any=""){
        var sbody;
       var url = this.MyCommon.GetAPISPUrl('UserDelete');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

}
export class UserGroupService {

constructor(@Inject(HttpClient) 
        private http : HttpClient,
        public MyCommon:MyCommon) { }

    GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('UserGroup');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    InsertData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('UserGroupData');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('UserGroupData');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    DeleteData(sbody:any,headers:any=""){
        var sbody;
       var url = this.MyCommon.GetAPISPUrl('UserGroupDelete');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
}

export class Permissions {

constructor(@Inject(HttpClient) 
        private http : HttpClient,
        public MyCommon:MyCommon) { }

    Login(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('Login');
        return this.http.post(url,sbody,{headers: headers}).map(res=>JSON.parse(JSON.stringify(res)));
    }

    OtherPermissions(headers:any=""){
        let sbody = { Key:localStorage.getItem("Token")};
        var url = this.MyCommon.GetAPISPUrl('OtherPermissions');
        return this.http.post(url,sbody,{headers: headers}).map(res=>JSON.parse(JSON.stringify(res)));
    }

    RestoreLogin(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('RestoreLogin');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

    SiteMap(sbody:any,headers:any=""){
        var url = this.MyCommon.GetSettingAPISPUrl('SiteMap');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
}