import { Component, OnInit, Inject } from '@angular/core';
import 'hammerjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { DemandService } from '../../../DataService/Demand.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-MeterDemandSearch2',
  templateUrl: './MeterDemandSearch2.component.html',
  providers: [MyCommon, DemandService,Permissions]
})
export class MeterDemandSearch2Component implements OnInit {
  //DatePick設定
  StartDate: any = '2018/01/01 00:00';
  EndDate: any = '2018/01/01 23:59';

  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public pageSize: number = 10;
  public skip: number = 0;

  public ViewPermissions: boolean = false;

  constructor(@Inject(DemandService) private DemandData: DemandService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  public SetStep: number = 30;

  Meter_Source: any = [];
  SetItem: any = [];
  //series: any;
  SeriesData = [];
  series: any[];
  categories: string[];


  ngOnInit() {
     //載入預設日期
     var sNowDate = new Date();
     this.StartDate = new Date(sNowDate.getFullYear() + '/' + String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
     this.EndDate= new Date(sNowDate.getFullYear() + '/' + String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 23:59');
     //單位為一天的時間
     /*var DiffDay = ((24 * 60 * 60 * 1000) * 1)-1;
     var sDiffDate = new Date(sNowDate.getTime() + DiffDay);
     this.EndDate = sDiffDate.getFullYear() + '/' + String(Number(sDiffDate.getMonth()) + 1) + '/' + sDiffDate.getDate() + ' 23:59';*/
    //判斷頁面權限
    let sbody = {
      UrlList:"MeterDemandSearch2",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
            if(Site.path=="MeterDemandSearch2")
                this.ViewPermissions=true;
        }
        try{
          this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
        }catch(err){}
      },
      err => {  },
      () => { }
    );
    this.SetMeter();
  }

  SetMeter() {
    var StrWhere = "";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key: localStorage.getItem("Token")
    };
    this.DemandData.GetMeter(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.Meter_Source = obj;
        if (this.Meter_Source.length > 0) {
          this.SetItem = this.Meter_Source[0];
          this.SetChart();
          this.SetData();
        }
      },
      err => { console.log(err); }
    );
  }

  RindChart(item) {
    this.SetItem = item;
    this.SetChart();
    this.SetData();
  }

  onSearch(){
    this.SetChart();
    this.SetData();
  }

  SetChart() {
    var StrWhere = " where PMKWH_RTD.DT between '"+this.MyCommon.FormateDateTime(this.StartDate) +"' and '"+this.MyCommon.FormateDateTime(this.EndDate)+"' and PMKWH_RTD.MACID=" + this.SetItem["MACID"];
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key: localStorage.getItem("Token")
    };
    this.DemandData.GetDemand(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        var DemandJson = obj;
        this.series = [];
        this.categories = [];
        var Demand1 = [], Demand2 = [];
        for (let i in DemandJson) {
          Demand1.push(DemandJson[i]["KWH"]);
          Demand2.push(DemandJson[i]["Demand_FC"]);

          var D=new Date(DemandJson[i]["DT"]);
          this.categories.push(this.MyCommon.FormateDate2(D)+"\r\n"+this.MyCommon.FormateTime(D));

        }
        this.series = [{
          name: '需量',
          data: Demand1,
          color: "#99C62A"
        }, {
          name: '需量預測',
          data: Demand2,
          color: "#27ADCC"
        }];
        this.SetStep = Math.round(DemandJson.length / 6);
      },
      err => { console.log(err); }
    );
  }
  //取得資料
  SetData() {
    var StrWhere = " where AlarmTime between '"+this.MyCommon.FormateDateTime(this.StartDate) +"' and '"+this.MyCommon.FormateDateTime(this.EndDate)+"' and PM_ID=" + this.SetItem["MACID"];
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key: localStorage.getItem("Token")
    };
    this.DemandData.GetAlarmEvent(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data: orderBy(obj.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }
  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    //this.isToken();
    this.SetData();
  }
  //換頁
  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.SetData();
  }
}
