import { Component, OnInit, Inject } from '@angular/core';
import { SystemService } from '../../DataService/System.service';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../DataService/User.service';



@Component({
  selector: 'app-SystemSetting',
  templateUrl: './SystemSetting.component.html',
  providers: [SystemService, MyCommon,Permissions]
})
export class SystemSettingComponent implements OnInit {

  //系統資料
  SYS_Source: any;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
    private pageSize: number = 10;
  private skip: number = 0;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  //查詢參數
  Search_Category:any="ID";
  Search_Keyword:any="";
  Search_Enable:any="-1";

  public SelectItem;

  constructor(
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
     //判斷頁面權限
      let sbody = {
        UrlList:"SystemSettingView,SystemSettingAdd,SystemSettingEdit,SystemSettingDelete",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="SystemSettingView")
                this.ViewPermissions=true;
              if(Site.path=="SystemSettingAdd")
                this.AddPermissions=true;
              if(Site.path=="SystemSettingEdit")
                this.EditPermissions=true;
              if(Site.path=="SystemSettingDelete")
                this.DeletePermissions=true;
          }
          if(this.EditPermissions){
            this.splitbutton_data.push(
            {
              text: '修改',
              iconClass: 'fa fa-pencil-square-o',
              click: () => {this.router.navigate(['/pages/SystemSettingEdit', this.SelectItem.ID]); }
            });
          }
          if(this.DeletePermissions){
            this.splitbutton_data.push(
            {
              text: '刪除',
              iconClass: 'fa fa-trash-o',
              click: () => { this.Delete(this.SelectItem);}
            });
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
      this.GetData();
  }

   public splitbutton_data: Array<any> = [];

    public onPaste(obj): void {
      this.SelectItem=obj;
    }

public onItemTap(args) {
        console.log(args);
    }

 //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    //this.isToken();
    this.GetData();
  }
   //換頁
  protected pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.GetData();
    }

  //取得系統資料
  GetData() {
    var StrWhere=" Where 1=1";
    if (this.Search_Enable !="-1"){
      StrWhere +=" AND Sys_Enabled='"+this.Search_Enable+"'";
    }
    if (this.Search_Keyword !=""){
      StrWhere +=" AND "+this.Search_Category+" Like '%"+this.Search_Keyword+"%'";
    }
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data:  orderBy(obj.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }
  //刪除資料
  Delete(del: any){
    if (confirm("確定要刪除嗎?")) {
      let sbody = {
        ID: del.ID,
        Key:localStorage.getItem("Token")
      };
      var stralert = this.SystemData.DeleteData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          //設定同步更新左側選單
				  localStorage.setItem("SyncSide","1");
          alert('刪除成功'); this.GetData(); },
        err => alert('刪除失敗'),
        () => { }
      );
      }
  }

  Search(){
    this.skip=0;
    this.GetData();
  }
}