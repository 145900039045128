import { Component, OnInit ,Inject} from '@angular/core';
import { LineNotifyService } from '../../DataService/LineNotify.service';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../DataService/User.service';

@Component({
  selector: 'app-Line',
  templateUrl: './Line.component.html',
  providers: [LineNotifyService, MyCommon,Permissions]
})
export class LineComponent implements OnInit {

  //查詢參數
  Search_Category:any="Name";
  Search_Keyword:any="";


  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;

  public SelectItem;
    public splitbutton_data: Array<any> = [];

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  constructor(
     @Inject(LineNotifyService) private LineNotifyData: LineNotifyService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
    //判斷頁面權限
      let sbody = {
        UrlList:"LineView,LineAdd,LineEdit,LineDelete",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
            if(Site.path=="LineView")
              this.ViewPermissions=true;
            if(Site.path=="LineAdd")
              this.AddPermissions=true;
            if(Site.path=="LineEdit")
              this.EditPermissions=true;
            if(Site.path=="LineDelete")
              this.DeletePermissions=true;
          }
          if(this.EditPermissions){
            this.splitbutton_data.push(
            {
              text: '修改',
              iconClass: 'fa fa-pencil-square-o',
              click: () => {this.router.navigate(['/pages/LineEdit', this.SelectItem.ID]); }
            });
          }
          if(this.DeletePermissions){
            this.splitbutton_data.push(
            {
              text: '刪除',
              iconClass: 'fa fa-trash-o',
              click: () => { this.Delete(this.SelectItem);}
            });
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
      this.GetData();
  }

public onPaste(obj): void {
    this.SelectItem=obj;
  }


  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    //this.isToken();
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }
  //取得系統資料
  GetData() {
    var StrWhere=" Where 1=1";
    if (this.Search_Keyword !=""){
      StrWhere +=" AND "+this.Search_Category+" Like '%"+this.Search_Keyword+"%'";
    }
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
 
    this.LineNotifyData.GetToken(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);

        //將Token部份隱藏
        for (var item of obj){
          if (item.Token.length>2){
            var HidCount=Math.floor(item.Token.length/2);
            var HidToken='';
            for (var i=0;i<item.Token.length;i++){
              if (i<Number(HidCount))
                HidToken +=item.Token.substring(i,i+1);
              else
                HidToken +='*';
            }
            item.Token=HidToken;
          }

        }

        this.gridView = {
          data: orderBy(obj.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }

  //刪除資料
  Delete(del: any){
    
    if (confirm("確定要刪除嗎?")) {
       let sbody = {
        ID: del.ID,
        Key:localStorage.getItem("Token")
      };
      var stralert = this.LineNotifyData.DeleteToken(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
            this.GetData(); },
        err => alert('刪除失敗'),
        () => { }
      );
      }
  }

   Search(){
     this.skip=0;
    this.GetData();
  }

}
