import { Component, OnInit, Inject } from '@angular/core';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import 'hammerjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';
import { SQLDeviceService } from '../../../DataService/SQLDevice.service';


@Component({
  selector: 'app-SQLDevice',
  templateUrl: './SQLDevice.component.html',
  styleUrls: ['./SQLDevice.component.scss'],
	providers: [SQLDeviceService, MyCommon,Permissions]
})
export class SQLDeviceComponent implements OnInit {

 
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  /*private pageSize: number = 10;
  private skip: number = 0;*/

  constructor(
    private SQLDeviceData: SQLDeviceService,
    private PermissionsData: Permissions,
    private MyCommon: MyCommon,
  private activatedRoute: ActivatedRoute,
  private router: Router) { }

  public SQLDeviceList_Source=[];
  public SQLDeviceData_Source=[];
  public BasicData=[];
  public DevData=[];
  public SensorData=[];
  public SetItem=[];
  public Set_DevData=[];
  public Set_DevIndex:string="";
  public Set_SnrData=[];


  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  ngOnInit() {
     /*  //判斷頁面權限
       let sbody = {
        UrlList:"SQLDevice",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
            if(Site.path=="SQLDevice")
              this.ViewPermissions=true;
          } 
        
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );*/


    this.SetSQLDeviceList(true);
  }

  SetSQLDeviceList(load){
   
		let sbody = {
			Key:localStorage.getItem("Token")
		};
		
		this.SQLDeviceData.GetList(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
        //this.MyCommon.CheckToken(obj);
        try {
          let data=[];
          try{
            if(obj[0]=="["){
              data=JSON.parse(obj);
            }else{
              data=obj;
            }
          }catch(err){
  
          }
          this.SQLDeviceList_Source=data;
          if(this.SQLDeviceList_Source.length>0){
            if(load)
              this.SetItem=this.SQLDeviceList_Source[0];
            else{
              this.SetItem=this.SQLDeviceList_Source[this.SQLDeviceList_Source.length-1];
            }
            this.SetSQLDeviceData();
          }else{
            this.SQLDeviceData_Source=[];
            this.BasicData=[];
          }
        } catch (error) {
          
        }
        
			},
			err => { console.log(err); }
		);
  }

  SetSQLDeviceData(){
    try{
      let sbody = {
        FileName:this.SetItem["name"],
        Key:localStorage.getItem("Token")
      };
      
      this.SQLDeviceData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          //this.MyCommon.CheckToken(obj);
          try {
            let data=[];
            try{
              if(obj[0]=="["){
                data=JSON.parse(obj);
              }else{
                data=obj;
              }
            }catch(err){
    
            }
            this.SQLDeviceData_Source=data;
            if(this.SQLDeviceData_Source.length>0){
              this.BasicData=this.SQLDeviceData_Source[0];
              this.DevData=JSON.parse(this.BasicData["DevList"]);
              this.SensorData=JSON.parse(this.BasicData["SensorList"]);
              //編序號
              if(this.DevData !=null){
                for(let i in this.DevData){
                  this.DevData[i]["D_ID"]=parseInt(i)+1;
                  if(String(i)=="0"){
                    var DevIndex=this.DevData[i]["DevIndex"];
                    this.Set_DevIndex=DevIndex;
                    this.Set_DevData=this.DevData[i];
                    this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex;});
                    this.gridView = {
                      data: orderBy(this.Set_SnrData, this.sort),
                      total: this.Set_SnrData.length
                    };
                  }
                }
              }else{
                this.SQLDeviceData_Source=[];
                this.BasicData=[];
              }
              //console.log(this.BasicData);
              //console.log(this.DevData);
              //console.log(this.SensorData);
            }
          } catch (error) {
            this.SQLDeviceData_Source=[];
            this.BasicData=[];
          }
          
        },
        err => { console.log(err); }
      );
    }catch(err){}
  }
  //切換設備資料
  onDevCheck(item){
    //取得設備編號
    var DevIndex=this.Set_DevData["DevIndex"];
    //排除原本資料
    var TempDevData=this.DevData.filter(function (d) {return d.DevIndex != DevIndex;});
    //回寫原本資料
    TempDevData.push(this.Set_DevData);
    TempDevData = TempDevData.sort(function(a, b){ return a.D_ID - b.D_ID});
    this.DevData=TempDevData;
    //更新資料
    this.Set_DevData=item;

    //Sensor資料
    this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == item.DevIndex}).sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
    this.gridView = {
      data: orderBy(this.Set_SnrData, this.sort),
      total: this.Set_SnrData.length
    };
    
  }
  //新增設備
  onAddDev(){
    //取最大值
    var TempDevData = this.DevData.sort(function(a, b){ return  b.DevIndex-a.DevIndex});
    var MaxDevIndex=1;
    if(TempDevData.length>0){
      MaxDevIndex=parseInt(TempDevData[0]["DevIndex"])+1;

    }
    this.DevData.push({"DevIndex":String(MaxDevIndex),"Type":"-1","DevName":"SQLDevice Sensor","DevID":"-1","SnrCount":"0","KeyName":"-","KeyValue":"0","D_ID":"-1"});
    //重設流水號
    for(let i in this.DevData.sort(function(a, b){ return  a.DevIndex-b.DevIndex})){
      this.DevData[i]["D_ID"]=parseInt(i)+1;
      //設定新資料
      if(i==String(this.DevData.length-1)){
        var DevIndex=this.DevData[i]["DevIndex"];
        this.Set_DevIndex=DevIndex;
        this.Set_DevData=this.DevData[i];
        this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex;});
        this.gridView = {
          data: orderBy(this.Set_SnrData, this.sort),
          total: this.Set_SnrData.length
        };
      }
    }
  }
   //複製設備
   onCopyDev(){
     //設備取最大值
    var TempDevData = this.DevData.sort(function(a, b){ return  b.DevIndex-a.DevIndex});
    var MaxDevIndex=1;
    if(TempDevData.length>0){
      MaxDevIndex=parseInt(TempDevData[0]["DevIndex"])+1;
    }
    //感測點取最大值
    var TempDevSensorData = this.SensorData.sort(function(a, b){ return  b.SnrIndex-a.SnrIndex});
    var MaxSnrIndex=1;
    if(TempDevSensorData.length>0){
      MaxSnrIndex=parseInt(TempDevSensorData[0]["SnrIndex"])+1;
    }
    //取複製前編號
    var AfterDevIndex=this.Set_DevData["DevIndex"];
    //取得複製值
    var Data=this.Set_DevData;
    this.DevData.push({"DevIndex":String(MaxDevIndex),"Type":Data["Type"],"DevName":Data["DevName"]
    ,"DevID":Data["DevID"],"SnrCount":Data["SnrCount"],"KeyName":Data["KeyName"],"KeyValue":Data["KeyValue"],"D_ID":"-1"});
    //Sensor資料
     
    var SnrData=this.SensorData.filter(function (d) {return d.DevIndex == AfterDevIndex}).sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
    for(let i in SnrData){
      this.SensorData.push({"SnrIndex":String(MaxSnrIndex+Number(i)),"DevIndex":String(MaxDevIndex)
      ,"ReadLocation":SnrData[i]["ReadLocation"],"SnrName":SnrData[i]["SnrName"]
      ,"Coefficient":SnrData[i]["Coefficient"],"Unit":SnrData[i]["Unit"]
      ,"Code":SnrData[i]["Code"]});
    }
    //重設流水號
    for(let i in this.DevData.sort(function(a, b){ return  a.DevIndex-b.DevIndex})){
      this.DevData[i]["D_ID"]=parseInt(i)+1;
      //設定新資料
      if(i==String(this.DevData.length-1)){
        var DevIndex=this.DevData[i]["DevIndex"];
        this.Set_DevIndex=DevIndex;
        this.Set_DevData=this.DevData[i];
        this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex;});
        this.gridView = {
          data: orderBy(this.Set_SnrData, this.sort),
          total: this.Set_SnrData.length
        };
      }
    }

  }
   //刪除設備
   onDeleteDev(){
    var AfterDevIndex=this.Set_DevData["DevIndex"];
    //清除資料
    this.DevData=this.DevData.filter(function (d) {return d.DevIndex != AfterDevIndex}).sort(function(a, b){ return  a.DevIndex-b.DevIndex});
    this.SensorData=this.SensorData.filter(function (d) {return d.DevIndex != AfterDevIndex}).sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
     //重設流水號
     for(let i in this.DevData.sort(function(a, b){ return  a.DevIndex-b.DevIndex})){
      this.DevData[i]["D_ID"]=parseInt(i)+1;
      //設定新資料
      if(i==String(this.DevData.length-1)){
        var DevIndex=this.DevData[i]["DevIndex"];
        this.Set_DevIndex=DevIndex;
        this.Set_DevData=this.DevData[i];
        this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex;});
        this.gridView = {
          data: orderBy(this.Set_SnrData, this.sort),
          total: this.Set_SnrData.length
        };
      }
    }
   }
  //新增感測點
  onAddSensor(){
    //取最大值
    var TempDevSensorData = this.SensorData.sort(function(a, b){ return  b.SnrIndex-a.SnrIndex});
    var MaxSnrIndex=1;
    if(TempDevSensorData.length>0){
      MaxSnrIndex=parseInt(TempDevSensorData[0]["SnrIndex"])+1;

    }
    var DevIndex=this.Set_DevData["DevIndex"];
    this.SensorData.push({"SnrIndex":String(MaxSnrIndex),"DevIndex":DevIndex,"ReadLocation":"-","SnrName":"感測名稱"+String(MaxSnrIndex)
    ,"Coefficient":"0","Unit":"-","Code":"0"});
    //重Bind Sensor資料
    this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex}).sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
    this.gridView = {
      data: orderBy(this.Set_SnrData, this.sort),
      total: this.Set_SnrData.length
    };
    this.Set_DevData["SnrCount"]=this.Set_SnrData.length;
  }
  //刪除感測點
  onDelSensor(dataItem){
    
    var TempDevSensorData=this.SensorData.filter(function (d) {return d.SnrIndex != dataItem.SnrIndex;});
    //回寫原本資料
    TempDevSensorData = TempDevSensorData.sort(function(a, b){ return Number(a.SnrIndex) - Number(b.SnrIndex)});
    this.SensorData=TempDevSensorData;
    //重Bind Sensor資料
    var DevIndex=this.Set_DevData["DevIndex"];
    this.Set_SnrData=this.SensorData.filter(function (d) {return d.DevIndex == DevIndex}).sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
    this.gridView = {
      data: orderBy(this.Set_SnrData, this.sort),
      total: this.Set_SnrData.length
    };
    this.Set_DevData["SnrCount"]=this.Set_SnrData.length;
    //
  }

  onInsert(){
    let sbody = {
			Key:localStorage.getItem("Token")
		};
		
		this.SQLDeviceData.InsertData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
        this.SetSQLDeviceList(false);
			},
			err => { console.log(err); }
		);
  }

  onUdpate(){

    this.UpdateData();
  }

  onDelete(){
    if (confirm("確定要刪除嗎?")) {
      this.DeleteData();
    }
  }

  RindData(item){
    this.SetItem=item;
    this.SetSQLDeviceData();
  }

  //排序
    sortChange(sort: SortDescriptor[]): void {
      this.sort = sort;
      //this.isToken();
      //this.SetData();
    }

  UpdateData() {
    //重新排列順序
    this.DevData=this.DevData.sort(function(a, b){ return  a.DevIndex-b.DevIndex});
    this.SensorData=this.SensorData.sort(function(a, b){ return  a.SnrIndex-b.SnrIndex});
		let sbody = {
      FileName: this.SetItem["name"],
      TypeName: this.BasicData["TypeName"],
      CoordinatorID: this.BasicData["CoordinatorID"],
      ServerName: this.BasicData["ServerName"],
      SQL_IP: this.BasicData["SQL_IP"],
      SQL_Port: this.BasicData["SQL_Port"],
      SQL_DB: this.BasicData["SQL_DB"],
      SQL_User: this.BasicData["SQL_User"],
      SQL_Password: this.BasicData["SQL_Password"],
      SQL_TableName: this.BasicData["SQL_TableName"],
      Dev_Data: this.DevData,
			Sensor_Data: this.SensorData,
			Key:localStorage.getItem("Token")
		};
		
		this.SQLDeviceData.UpdateData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				//this.MyCommon.CheckToken(obj);
        alert("儲存完成!");
			},
			err => { console.log(err); }
		);
  }
  
  DeleteData() {
		let sbody = {
      FileName: this.SetItem["name"],
			Key:localStorage.getItem("Token")
		};
		
		this.SQLDeviceData.DeleteData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				//this.MyCommon.CheckToken(obj);
        alert("刪除完成!");
        this.SetSQLDeviceList(true);
			},
			err => { console.log(err); }
		);
	}

}
