import { Component, OnInit ,Inject} from '@angular/core';
import { MyCommon } from '../../../App_Code/MyCommon';
import { IBas_System_SettingService } from '../../../DataService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-LayoutSystemSettings',
  templateUrl: './LayoutSystemSettings.component.html',
  styleUrls: ['./LayoutSystemSettings.component.css'],
  providers: [IBas_System_SettingService, MyCommon]
})
export class LayoutSystemSettingsComponent implements OnInit {
  public System_Type: any="0";
  public System_Count: any="1";
  public Tower_Count: any="0";
  public Tower_Type: any="0";
  public Tower_Mode: any="0";

  constructor(
    private System_Setting: IBas_System_SettingService,
    private MyCommon: MyCommon,
    private router: Router
  ) { }
  System_Setting_Data={System_Type:null,WaterS_Num:null,ChillerG_Num:null,Chiller_Num:null,CHP_Num:null,CWP_Num:null,ZPG_Num:null,ZP_Num:null,CTG_Num:null,CT_Num:null,CT_Type:null,CT_CtrlMode:null,ZP_CtrlMode:null}
  ngOnInit() {
    this.GetSettingData();
  }
  GetSettingData(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    //AI
    this.System_Setting.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        let Data=obj;
        this.System_Setting_Data.System_Type=Data[0].System_Type;
        this.System_Setting_Data.WaterS_Num=Data[0].WaterS_Num;
        this.System_Setting_Data.ChillerG_Num=Data[0].ChillerG_Num;
        this.System_Setting_Data.Chiller_Num=Data[0].Chiller_Num;
        this.System_Setting_Data.CHP_Num=Data[0].CHP_Num;
        this.System_Setting_Data.CWP_Num=Data[0].CWP_Num;
        this.System_Setting_Data.ZPG_Num=Data[0].ZPG_Num;
        this.System_Setting_Data.ZP_Num=Data[0].ZP_Num;
        this.System_Setting_Data.CTG_Num=Data[0].CTG_Num;
        this.System_Setting_Data.CT_Num=Data[0].CT_Num;
        this.System_Setting_Data.CT_Type=Data[0].CT_Type;
        this.System_Setting_Data.CT_CtrlMode=Data[0].CT_CtrlMode;
        this.System_Setting_Data.ZP_CtrlMode=Data[0].ZP_CtrlMode;
          
        this.System_Type=Data[0].System_Type;
        if( this.System_Type=="0"){
          this.System_Count=String(Data[0].Chiller_Num);
          this.Tower_Count=String(Data[0].CT_Num);
          this.Tower_Type=String(Data[0].CT_Type);
          this.Tower_Mode=String(Data[0].CT_CtrlMode);
        }
        if( this.System_Type=="1"){
          this.System_Count=String(Data[0].ZP_Num);
          this.Tower_Count=String(Data[0].CT_Num);
          this.Tower_Type=String(Data[0].CT_Type);
          this.Tower_Mode=String(Data[0].ZP_CtrlMode);
        }
        if( this.System_Type=="2"){
          this.System_Count=String(Data[0].CT_Num);
          this.Tower_Count="0";
          this.Tower_Type="1";
          this.Tower_Mode=String(Data[0].CT_CtrlMode);
        }
        if( this.System_Type=="3"){
          this.System_Count=String(Data[0].Chiller_Num);
          this.Tower_Count="0";
          this.Tower_Type="0";
          this.Tower_Mode="0";
        }

      },
      err => { console.log(err); }
    );
  }

  onSystemTypeChange(){
    this.System_Count="1";
    this.onSystemCountChange();
  }

  onSystemCountChange(){
    if(this.System_Type=="0")
      this.Tower_Count="0";
    else{
      /*if(this.System_Type=="1"||this.System_Type=="3"){
        this.Tower_Mode="0";
      }else{
        this.Tower_Mode="1";
      }*/
      this.Tower_Mode="0";
    }
  }

  onTowerCountChange(){
    this.Tower_Type="0";
    this.Tower_Mode="0";
  }

  onTowerTypeChange(){
    this.Tower_Mode="0";
  }
  

  onUpdate(){
    if (confirm("重要警告!\r\n若按下確定會讓系統所有設定重新回到預設值， 您確定要這樣做嗎?")) {
      if (confirm("重要警告!\r\n請您再確認一次，謝謝!")) {
        alert("提醒!\r\n請記得將系統重新啟動，關閉電源後再開啟。");
        this.Update();
      }
    }
  }

  //修改
  Update() {
    //水系統設定
    if(this.System_Type=="0"){
      this.System_Setting_Data.System_Type=this.System_Type;
      this.System_Setting_Data.WaterS_Num="1";
      this.System_Setting_Data.ChillerG_Num="1";
      if(this.Tower_Count=="2"&&this.Tower_Type=="0")
        this.System_Setting_Data.ChillerG_Num="2";
      this.System_Setting_Data.Chiller_Num=this.System_Count;
      this.System_Setting_Data.CHP_Num=this.System_Count;
      this.System_Setting_Data.CWP_Num=this.System_Count;
      this.System_Setting_Data.ZPG_Num="0";
      this.System_Setting_Data.ZP_Num="0";
      this.System_Setting_Data.CTG_Num="0";
      if((this.Tower_Count=="2"&&this.Tower_Count!="0")||(this.Tower_Count=="1"&&this.Tower_Count!="0"))
        this.System_Setting_Data.CTG_Num=this.System_Setting_Data.ChillerG_Num;
      this.System_Setting_Data.CT_Num=this.Tower_Count;
      this.System_Setting_Data.CT_Type=this.Tower_Type;
      this.System_Setting_Data.CT_CtrlMode=this.Tower_Mode;
      this.System_Setting_Data.ZP_CtrlMode="0";
    }
    //區域泵
    if(this.System_Type=="1"){
      this.System_Setting_Data.System_Type=this.System_Type;
      this.System_Setting_Data.WaterS_Num="1";
      this.System_Setting_Data.ChillerG_Num="0";
      this.System_Setting_Data.Chiller_Num="0";
      this.System_Setting_Data.CHP_Num="0";
      this.System_Setting_Data.CWP_Num="0";
      this.System_Setting_Data.ZPG_Num="1";
      this.System_Setting_Data.ZP_Num=this.System_Count;
      this.System_Setting_Data.CTG_Num="0";
      this.System_Setting_Data.CT_Num="0";
      this.System_Setting_Data.CT_Type="0";
      this.System_Setting_Data.CT_CtrlMode="0";
      this.System_Setting_Data.ZP_CtrlMode=this.Tower_Mode;
    }
    //水塔組
    if(this.System_Type=="2"){
      this.System_Setting_Data.System_Type=this.System_Type;
      this.System_Setting_Data.WaterS_Num="1";
      this.System_Setting_Data.ChillerG_Num="0";
      this.System_Setting_Data.Chiller_Num="0";
      this.System_Setting_Data.CHP_Num="0";
      this.System_Setting_Data.CWP_Num="0";
      this.System_Setting_Data.ZPG_Num="0";
      this.System_Setting_Data.ZP_Num="0";
      this.System_Setting_Data.CTG_Num="1";
      this.System_Setting_Data.CT_Num=this.System_Count;
      this.System_Setting_Data.CT_Type="1";
      this.System_Setting_Data.CT_CtrlMode=this.Tower_Mode;
      this.System_Setting_Data.ZP_CtrlMode="0";
    }
    //冰機組
    if(this.System_Type=="3"){
      this.System_Setting_Data.System_Type=this.System_Type;
      this.System_Setting_Data.WaterS_Num="1";
      this.System_Setting_Data.ChillerG_Num="1";
      this.System_Setting_Data.Chiller_Num=this.System_Count;
      this.System_Setting_Data.CHP_Num="0";
      this.System_Setting_Data.CWP_Num="0";
      this.System_Setting_Data.ZPG_Num="0";
      this.System_Setting_Data.ZP_Num="0";
      this.System_Setting_Data.CTG_Num="0";
      this.System_Setting_Data.CT_Num="0";
      this.System_Setting_Data.CT_Type="0";
      this.System_Setting_Data.CT_CtrlMode="0";
      this.System_Setting_Data.ZP_CtrlMode="0";

    }
    let sbody = {
      System_Type: this.System_Setting_Data.System_Type,
      WaterS_Num: this.System_Setting_Data.WaterS_Num,
      Chiller_Num: this.System_Setting_Data.Chiller_Num,
      ChillerG_Num: this.System_Setting_Data.ChillerG_Num,
      CHP_Num: this.System_Setting_Data.CHP_Num,
      CWP_Num: this.System_Setting_Data.CWP_Num,
      ZPG_Num: this.System_Setting_Data.ZPG_Num,
      ZP_Num: this.System_Setting_Data.ZP_Num,
      CTG_Num: this.System_Setting_Data.CTG_Num,
      CT_Num: this.System_Setting_Data.CT_Num,
      CT_Type: this.System_Setting_Data.CT_Type,
      CT_CtrlMode: this.System_Setting_Data.CT_CtrlMode,
      ZP_CtrlMode: this.System_Setting_Data.ZP_CtrlMode,
      Key: localStorage.getItem("Token")
    };
    let stralert = this.System_Setting.UpdateData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.router.navigate(['/ibas/default']);
      },
      err => {
        //alert('修改失敗');console.log(err);
      },
      () => { }
    );
  }

}
