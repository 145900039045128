import { Component, OnInit } from '@angular/core';
import { IBas_ObjectService,IBas_ObjectAttribService } from '../../DataService';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MyCommon } from '../../App_Code/MyCommon';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import '@progress/kendo-ui';
import * as $ from 'jquery';

@Component({
  selector: 'app-ObjectSetting',
  templateUrl: './ObjectSetting.component.html',
  styleUrls: ['./ObjectSetting.component.css'],
  providers: [IBas_ObjectService,IBas_ObjectAttribService, MyCommon]
})
export class ObjectSettingComponent implements OnInit {
  ObjectData:any=[];
  SelectGrid:any="Set";
  SelectItem:any={};
  GridSet:any=[];
  GridAI:any=[];
  GridDI:any=[];
  GridAO:any=[];
  GridDO:any=[];
  GridCalc:any=[];
  
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;
  constructor(    private router: Router,
    private Object: IBas_ObjectService,
    private ObjectAttrib: IBas_ObjectAttribService,
    private MyCommon: MyCommon,) { 

    }

  ngOnInit() {


 /* let menu = $("#menu").kendoContextMenu({ 
    target: "#treeview", 
    filter: ".k-in",
    animation: {
      open: { effects: "fadeIn" },
      duration: 500
    },
    dataSource: [{text: "編輯",value: "Edit"},
    //{text: "複製",value: "Copy"},
    //{text: "刪除",value: "Delete"}
    ]
    ,
    select: function(e) {
      let tree = $("#treeview").data("kendoTreeView");
      let TreeItem = tree.dataItem(e.target);//對象

      let SelectValue=e.sender.dataSource.options.data[$(e.item).index()].value;
      console.log(TreeItem);
      console.log(SelectValue);
    }
  }).data("kendoContextMenu");*/
    this.GetObject();
  }
  GetObject(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.Object.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
          
          this.MyCommon.CheckToken(obj);
          
          this.ObjectData=[];
          this.SelectItem=[];
          this.GridSet=[];
          this.GridAI=[];
          this.GridDI=[];
          this.GridAO=[];
          this.GridDO=[];
          this.GridCalc=[];
          $("#treeview").empty();
          this.ObjectData = this.ConvertLevelData(obj , "0");
          let treeview = $("#treeview").kendoTreeView({
              dragAndDrop: false,
              dataSource: this.ObjectData,
              dataTextField: [ "DisplayName"],
              drop: (e)=> {
              /*  let tree = $("#treeview").data("kendoTreeView");
                let movingItem = tree.dataItem(e.sourceNode);//對象
                console.log(movingItem);
                let TargetItem = tree.dataItem(e.destinationNode);//目標
                console.log(TargetItem);
                console.log(e.dropPosition);//位置over、before、after*/
              }, 
              select: (e)=> {
                let tree = $("#treeview").data("kendoTreeView");
                this.SelectItem=tree.dataItem(e.node);//對象
                this.GetObjectAttrib();
              }
          }).data("kendoTreeView");
          treeview.expand(".k-item");
          /*treeview.items().each(function (i, el) {
            $(el).on("click", function (event) {
             
            });
          });*/
        },
        err => { console.log(err); }
        
      );
  }

  GetObjectAttrib(){
    let MACID="-1";
    if(this.SelectItem.MAC_ID!=null&&this.SelectItem.MAC_ID!="")
      MACID=this.SelectItem.MAC_ID;
    let sbody = {
      TopN: " ",
      StrWhere: " where MAC_ID = "+MACID,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
     //AI
     this.ObjectAttrib.GetDataAI(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.GridAI = obj;
      },
      err => { console.log(err); }
    );
    //AO
    this.ObjectAttrib.GetDataAO(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.GridAO = obj;
      },
      err => { console.log(err); }
    );
    //DI
    this.ObjectAttrib.GetDataDI(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.GridDI = obj;
      },
      err => { console.log(err); }
    );
    //DO
    this.ObjectAttrib.GetDataDO(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.GridDO = obj;
      },
      err => { console.log(err); }
    );
    //Set
    this.ObjectAttrib.GetDataSET(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.GridSet = obj;
      },
      err => { console.log(err); }
    );
    //Set
    this.ObjectAttrib.GetDataCalc(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.GridCalc = obj;
      },
      err => { console.log(err); }
    );
  }
    //組階層
  ConvertLevelData(MasterData, MACID) {
    let Next = [];
    Next = MasterData.filter(function (d) {
        return d.Parent_ID.toString() == MACID.toString();
    });

    if (Next.length > 0) {
        for (let i = 0; i < Next.length; i++) {
            let children = [];
            children = this.ConvertLevelData(MasterData, Next[i].MAC_ID);
            if (children.length > 0) {
                Next[i]["items"] = children;
            }
        }
    }

    return Next;
  }
  onInputChange(item){
    item["update"]="1";
  }
  onSave(){
    console.log();
    if (confirm("確定要儲存更新嗎?")) {
      if(this.SelectItem.MAC_ID!=null&&this.SelectItem.MAC_ID!=""){

        let sbody = {
          MAC_ID: this.SelectItem.MAC_ID,
          S_Name: this.SelectItem.S_Name,
          Brand_Model: this.SelectItem.Brand_Model,
          Location: this.SelectItem.Location,
          Floor: this.SelectItem.Floor,
          AI_Data: this.GridAI.filter(d=>d.update=="1").map((d)=>{return {MAC_ID:d.MAC_ID,S_Num:d.S_Num,S_Name:d.S_Name,Attrib_Name_TC:d.Attrib_Name_TC,Attrib_Unit:d.Attrib_Unit}}),
          AO_Data: this.GridDO.filter(d=>d.update=="1").map((d)=>{return {MAC_ID:d.MAC_ID,S_Num:d.S_Num,S_Name:d.S_Name,Attrib_Name_TC:d.Attrib_Name_TC,Tag_Name:d.Tag_Name}}),
          DI_Data:this.GridDI.filter(d=>d.update=="1").map((d)=>{return {MAC_ID:d.MAC_ID,S_Num:d.S_Num,S_Name:d.S_Name,Attrib_Name_TC:d.Attrib_Name_TC,Tag_Name_ON:d.Tag_Name_ON,Tag_Name_OFF:d.Tag_Name_OFF}}),
          DO_Data: this.GridDO.filter(d=>d.update=="1").map((d)=>{return {MAC_ID:d.MAC_ID,S_Num:d.S_Num,S_Name:d.S_Name,Attrib_Name_TC:d.Attrib_Name_TC,Tag_Name:d.Tag_Name}}),
          Set_Data: this.GridSet.filter(d=>d.update=="1").map((d)=>{return {MAC_ID:d.MAC_ID,S_Num:d.S_Num,S_Name:d.S_Name,Attrib_Name:d.Attrib_Name,Attrib_Unit:d.Attrib_Unit}}),
          Calc_Data: this.GridCalc.filter(d=>d.update=="1").map((d)=>{return {MAC_ID:d.MAC_ID,S_Num:d.S_Num,S_Name:d.S_Name,Attrib_Name_TC:d.Attrib_Name_TC,Attrib_Unit:d.Attrib_Unit}}),
          Key:localStorage.getItem("Token")
        };
        let stralert = this.Object.UpdateDataSetting(sbody, this.MyCommon.SetHeaders());
        stralert.subscribe(
          (obj) => {
            this.MyCommon.CheckToken(obj);
            alert('儲存完成');
            this.GetObject();
          },
          err => alert('儲存失敗'),
          () => { }
        );
      }else{
        alert('請選擇設備');
      }
    }
  }
}
