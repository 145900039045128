import { Component, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router } from '@angular/router';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { IBas_ObjectAttribService } from '../../../DataService';


@Component({
  selector: 'app-SystemLayoutGroupPopup',
  templateUrl: './SystemLayoutGroupPopup.component.html',
  providers: [MyCommon,IBas_ObjectAttribService]
})
export class SystemLayoutGroupPopupComponent implements OnInit {
  //預設欄位
  Data_MAC_S:string;
  IOType:string;
  AttribData:string;
  Title:string;

  MAC_ID:string;
  S_Num:string;
  AO_DO_MOA:any="2";
  AO_DO_C_Value:any="0";
  
  constructor(private activeModal: NgbActiveModal,
    private ObjectAttrib: IBas_ObjectAttribService,
    private MyCommon: MyCommon,
              private router: Router
            ) { }

  ngOnInit() {
    if(this.AttribData.length>0){
      this.MAC_ID=this.AttribData[0]["MAC_ID"];
      this.S_Num=this.AttribData[0]["S_Num"];

      if(this.IOType=="AI"){
        this.Chart();

      }
      if(this.IOType=="AO"||this.IOType=="DO"){
       
       this.AO_DO_MOA=this.AttribData[0]["MOA"];
       this.AO_DO_C_Value=this.AttribData[0]["C_Value"];

      }
    }
   
   
    }
    //儲存設定
    IOList:any=[];
    data:any=[];
    Status:any=[];
    SetStep:any=[];

    SeriesData:any=[];
    series:any=[];
    Chart(){
      this.SeriesData=[];
    
    
      for (var i = 0; i < this.data.length; i++) {
      
        var labeleList=this.data[i]["DT"].split(' ');
        var labele=labeleList[0]+"\n"+labeleList[1];
        let IO_ID="";//this.EACSettingsColumn.MAC+'-S'+this.EACSettingsColumn.SID;
        this.SeriesData.push({ interval: labele, service: IO_ID,value: IO_ID});
      }
      
       this.series = groupBy(this.SeriesData, [{ field: "service"}]);
       //將Legend名稱轉成Io名稱
       var i=0;
       for (var item of this.series){
        item.value=this.Title;
        i++;
       }
    
      //計算lables的step
      this.SetStep=Math.round(this.data.length/6);
    
    }
  //隱藏Popup
  back() {
    this.activeModal.close();
  }
  //儲存設定
  onSave() {
    if(this.IOType=="AI"){
     
      this.activeModal.close();
    }
    if(this.IOType=="AO"){
      let sbody = {
        MAC_ID:this.MAC_ID,
        S_Num: this.S_Num,
        MOA: this.AO_DO_MOA,
        C_Value:this.AO_DO_C_Value,
        Key:localStorage.getItem("Token")
      };
      //AI
      this.ObjectAttrib.UpdateDataAO(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.activeModal.close();
        },
        err => { alert('更新失敗');console.log(err); }
      );
    }
    if(this.IOType=="DI"){
      this.activeModal.close();
    }
    if(this.IOType=="DO"){
      let sbody = {
        MAC_ID:this.MAC_ID,
        S_Num: this.S_Num,
        MOA: this.AO_DO_MOA,
        C_Value:this.AO_DO_C_Value,
        Key:localStorage.getItem("Token")
      };
      //AI
      this.ObjectAttrib.UpdateDataDO(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.activeModal.close();
        },
        err => { alert('更新失敗');console.log(err); }
      );
    }
  }


 
}
