import { Component, OnInit, Inject } from '@angular/core';
import { AlarmService } from '../../DataService/Alarm.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../DataService/User.service';
import { SystemService } from '../../DataService/System.service';

@Component({
  selector: 'app-AlarmSetting',
  templateUrl: './AlarmSetting.component.html',
  providers: [AlarmService, MyCommon,Permissions,SystemService]
})
export class AlarmSettingComponent implements OnInit {

  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;
  public ColSize: number = 100;
  public SYS_ID: any="-1";
  //系統資料
  SYS_Source: any;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

//查詢參數
Search_Category:any="1";//"CONCAT(ifnull(DEV.DevName,''),'(',IO_Info.IO_MAC,')',IO_Info.Name)";
Search_Keyword:any="";
IO_Type:any="-1";

  constructor(
    @Inject(AlarmService) private AlarmData: AlarmService,
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
      //判斷頁面權限
      let sbody = {
        UrlList:"AlarmSettingView,AlarmSettingEdit",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="AlarmSettingView")
                  this.ViewPermissions=true;
              if(Site.path=="AlarmSettingEdit")
                  this.EditPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );

    this.GetData();
    this.GetSysData();
  }

  //取得系統資料
  GetSysData() {
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.SYS_Source = obj;
      },
      err => { console.log(err); }
    );
  }

  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }
  //取得系統資料
  GetData() {
    var StrWhere=" Where 1=1";
    if (this.IO_Type !="-1"){
      StrWhere +=" AND IO_Info.IO_Type='"+this.IO_Type+"'";
    }
    var Search="";
    if(this.Search_Category=="1")
      Search="CONCAT(ifnull(DEV.DevName,''),'(',IO_Info.IO_MAC,')',IO_Info.Name)";
    if (this.Search_Keyword !=""){
      StrWhere +=" AND "+Search+" Like '%"+this.Search_Keyword+"%'";
    }
    if (this.SYS_ID !="-1"){
      StrWhere +=" AND Sys_ID = '"+this.SYS_ID+"'";
    }

    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.AlarmData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.gridView = {
          data: orderBy(obj.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }

  Search(){
    this.skip=0;
    this.GetData();
  }

}