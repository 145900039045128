import { Component, OnInit, Inject } from '@angular/core';
import { PowerAnalysisTypeService } from '../../../DataService/PowerAnalysis.service';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-PATypeSetting',
  templateUrl: './PATypeSetting.component.html',
  providers: [PowerAnalysisTypeService, MyCommon,Permissions]
})
export class PATypeSettingComponent implements OnInit {


 public sort: SortDescriptor[] = [];
 public gridView: GridDataResult;
   private pageSize: number = 10;
 private skip: number = 0;

 public ViewPermissions:boolean=false;
 public AddPermissions:boolean=false;
 public EditPermissions:boolean=false;
 public DeletePermissions:boolean=false;

 //查詢參數
 Search_Category:any="PowerAnalysisType.PAName";
 Search_Keyword:any="";

 public SelectItem;

 constructor(
   @Inject(PowerAnalysisTypeService) private PAData: PowerAnalysisTypeService,
   @Inject(Permissions) private PermissionsData: Permissions,
   @Inject(MyCommon) private MyCommon: MyCommon,
   private router: Router
 ) { }

 ngOnInit() {
    //判斷頁面權限
     let sbody = {
       UrlList:"PATypeSettingView,PATypeSettingAdd,PATypeSettingEdit,PATypeSettingDelete",
       Key:localStorage.getItem("Token")
     };

     var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
     stralert.subscribe(
       (obj) => {
         this.MyCommon.CheckToken(obj);
         for (let Site of obj) {
             if(Site.path=="PATypeSettingView")
               this.ViewPermissions=true;
             if(Site.path=="PATypeSettingAdd")
               this.AddPermissions=true;
             if(Site.path=="PATypeSettingEdit")
               this.EditPermissions=true;
             if(Site.path=="PATypeSettingDelete")
               this.DeletePermissions=true;
         }
         if(this.EditPermissions){
           this.splitbutton_data.push(
           {
             text: '修改',
             iconClass: 'fa fa-pencil-square-o',
             click: () => {this.router.navigate(['/pages/PATypeSettingEdit', this.SelectItem.PAType_ID]); }
           });
         }
         if(this.DeletePermissions){
           this.splitbutton_data.push(
           {
             text: '刪除',
             iconClass: 'fa fa-trash-o',
             click: () => { this.Delete(this.SelectItem);}
           });
         }
         try{
           this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
         }catch(err){}
       },
       err => {  },
       () => { }
     );
     this.GetData();
 }

  public splitbutton_data: Array<any> = [];

   public onPaste(obj): void {
     this.SelectItem=obj;
   }

public onItemTap(args) {
       console.log(args);
   }

//排序
 sortChange(sort: SortDescriptor[]): void {
   this.sort = sort;
   //this.isToken();
   this.GetData();
 }
  //換頁
 protected pageChange(event: PageChangeEvent): void {
       this.skip = event.skip;
       this.GetData();
   }

 //取得系統資料
 GetData() {
   var StrWhere=" Where 1=1";
   if (this.Search_Keyword !=""){
     StrWhere +=" AND "+this.Search_Category+" Like '%"+this.Search_Keyword+"%'";
   }
   let sbody = {
     TopN: " ",
     StrWhere: StrWhere,
     Sort: " ",
     Key:localStorage.getItem("Token")
   };
 
   this.PAData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
     (obj) => {
       this.MyCommon.CheckToken(obj);
       this.gridView = {
         data:  orderBy(obj.slice(this.skip, this.skip + this.pageSize), this.sort),
         total: obj.length
       };
     },
     err => { console.log(err); }
   );
 }
 //刪除資料
 Delete(del: any){
   if (confirm("確定要刪除嗎?")) {
     let sbody = {
        PAType_ID: del.PAType_ID,
       Key:localStorage.getItem("Token")
     };
     var stralert = this.PAData.DeleteData(sbody, this.MyCommon.SetHeaders());
     stralert.subscribe(
       (obj) => {
          this.MyCommon.CheckToken(obj);
          alert('刪除成功'); 
          this.GetData();
         },
       err => alert('刪除失敗'),
       () => { }
     );
     }
 }

 Search(){
   this.skip=0;
   this.GetData();
 }

}
