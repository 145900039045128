export class DemandCtrlSettingColumn {
  constructor(
    public ID: number,
    public DCType:number,
    public MACID:number,
    public CtrlMACID:number,
    public CtrlSID:number,
    public Value_P1:number,
    public Value_P2:number,
    public Value_P3:number,
    public CtrlValue_P1:number,
    public CtrlValue_P2:number,
    public CtrlValue_P3:number,
    public Enabled:number,
  ) { }
}