import { Component, OnInit ,Inject} from '@angular/core';
import { IOService } from '../../../DataService/IO.service';
import { SystemService } from '../../../DataService/System.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-IOSetting_Edit',
  templateUrl: './IOSetting_Edit.component.html',
  styleUrls: ['./IOSetting_Edit.component.scss'],
  providers: [IOService,SystemService, MyCommon,Permissions]
})
export class IOSetting_EditComponent implements OnInit {
  public SysSetData : any;
  IO_ID : any;

  private sub:any;
  public FromData:any[];
  public SetCtrlType:number;
  public ViewPermissions:boolean=false;

  constructor(
    @Inject(IOService) private IOData: IOService,
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
      //判斷頁面權限
      let sbody = {
        UrlList:"IOSettingEdit",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="IOSettingEdit")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );

       this.sub = this.activatedRoute.params.subscribe(params => {
          this.IO_ID = params['id'];
            this.GetData();
            this.GetSysData();
      }); 
  }

  ngOnDestroy() {
      // Clean sub to avoid memory leak
    this.sub.unsubscribe();
  }
  GetData() {
      let sbody = {
        TopN: " ",
        StrWhere: " where CONCAT(IO_Info.IO_MAC,'-S',IO_Info.IO_S)='"+this.IO_ID+"' ",
        Sort: " ",
        Key:localStorage.getItem("Token")
      };
      this.IOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.FromData = obj;
      
          this.SetCtrlType= obj[0].SetCtrlType;
        },
        err => { console.log(err); }
      );
    }
    GetSysData() {
      let sbody = {
        TopN: " ",
        StrWhere: " ",
        Sort: " ",
        Key:localStorage.getItem("Token")
      };
      this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.SysSetData=obj;
        },
        err => { console.log(err); }
      );
    }

  clicked() {
    if (confirm("確定要更新嗎?")) {
        this.Update();
     }
  }

  Update() {
    
   let sbody = {
      "IO_MAC": this.FromData[0]["IO_MAC"],
      "IO_S": this.FromData[0]["IO_S"],
      "Name": this.FromData[0]["Name"],
      "SYS_ID": this.FromData[0]["SYS_ID"],
      "IO_Type": this.FromData[0]["IO_Type"],
      "SetCtrlType": this.SetCtrlType,
      "View_Enabled": this.FromData[0]["View_Enabled"],
      Key:localStorage.getItem("Token")
    };

     var stralert = this.IOData.UpdateData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          alert('修改成功');
          this.router.navigate(['/pages/IOSetting']);
        },
        err => { alert('修改失敗'); console.log(err); },
        () => { }
      );
  }
  back(){
      this.router.navigate(['/pages/IOSetting']);
  }
}