﻿import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Injectable()
export class MyCommon {

    constructor(
        private router: Router,
    ) {


    }
    GetMQTTLink() {
        return {ip:location.host,port:9001,acc:"wsn",pwd:"wsn"};

    }
    GetDemandCtrlLogTimer() {
        return 3*1000;
    }
    GetAPIUrl(name: string) {
        return this.PageHost() + name;

    }

    GetAPISPUrl(name: string) {
        return this.PageHost() + 'sp/' + name;
    }

    GetIBasAPISPUrl(name: string) {
        return this.PageHost() + 'uBEMS/sp/' + name;
    }

    GetSettingAPISPUrl(name: string) {
        var Host = '';
        var API = 'sp/';
        if (this.PageMode() == "ibas") {
            API = 'uBEMS/sp/';
        }
        return this.PageHost() + API + name;
    }
    /*頁面設定與網址location.host*/
    PageHost() {
        var Host = '';
        // if (String(location.host) == 'ng2.kali.com.tw' || String(location.host).indexOf('localhost') > -1) {
        //     Host = '10.88.1.30';
        // }
        // else {
             Host = location.host;
        // }
        //Host = '59.124.140.180';
        return 'http://'+Host+'/webapi/';
        //return 'http://localhost:49921/';
    }
    PageMode() {
        //return "ibas"; 
        return "normal";
    }

    /*即時資訊更新時間*/
    GetUpdateTime() {
        return 600;
    }
    FormateDateTime(D:Date){
        return D.getFullYear() + '-' + String(Number(D.getMonth()) + 1) + '-' + D.getDate() + ' '+D.getHours()+':'+D.getMinutes();
    }
    FormateDate(D:Date){
        return D.getFullYear() + '-' + String(Number(D.getMonth()) + 1) + '-' + D.getDate();
    }
    FormateTime(D:Date){
        return D.getHours()+':'+D.getMinutes();
    }
    FormateDateTime2(D:Date){
        return  this.SetZero(D.getFullYear()) + '-' + String(this.SetZero(Number(D.getMonth()) + 1)) + '-' + this.SetZero(D.getDate()) + ' '+this.SetZero(D.getHours())+':'+this.SetZero(D.getMinutes());
    }
    FormateDateTimeFull(D:Date){
        return  this.SetZero(D.getFullYear()) + '-' + String(this.SetZero(Number(D.getMonth()) + 1)) + '-' + this.SetZero(D.getDate()) + ' '+this.SetZero(D.getHours())+':'+this.SetZero(D.getMinutes())+':'+this.SetZero(D.getSeconds());
      }
    FormateDate2(D:Date){
        return this.SetZero(D.getFullYear()) + '-' + String(this.SetZero(Number(D.getMonth()) + 1)) + '-' + this.SetZero(D.getDate());
    }
    FormateTime2(D:Date){
        return this.SetZero(D.getHours())+':'+this.SetZero(D.getMinutes());
    }
    UTC_to_Date(MilliSecond:number){
        return this.FormateDate2(new Date(MilliSecond));
    }
    UTC_to_DateTime(MilliSecond:number){
        return this.FormateDateTime2(new Date(MilliSecond));
    }
    UTC_to_DateTimeFull(MilliSecond:number){
      return this.FormateDateTimeFull(new Date(MilliSecond));
    }
    //補零
    SetZero(n:number){
        return n<10?"0"+n:n;
    }
    CheckNumber(v:any){
        var n="0";
        if(!isNaN(v)){
            n=v;
        }
        return n;
    }

    SetHeaders() {
        var token = '';//localStorage.getItem('token');
        let headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append('Authorization', 'Bearer ' + token);
        return headers
    }

    CheckToken(JsonObj) {
        var ErrorStatus=false; 
        try {
            if (JsonObj[0]["ErrorCode"] != null) {
                if (JsonObj[0]["ErrorCode"] == "101") {
                    alert(JsonObj[0]["ErrorMessage"]);
                 } else {
                    //alert(JsonObj[0]["ErrorMessage"]);
                    localStorage.removeItem("User");
                    localStorage.removeItem("Account");
                    localStorage.removeItem("Token");
                    this.router.navigate(['/login']);
                }
                ErrorStatus=true; 
            }
        } catch (err) {

        }
        return ErrorStatus;
    }

    PermissionsDefailtPage(JsonObj, Status) {
        if (Status) {
            alert('無此頁面權限');
            if (JsonObj[0].path == "RealTime") {
                this.router.navigate(['/pages/RealTime']);
            }
            else if (JsonObj[0].path == "RealTimeStatus") {
                this.router.navigate(['/pages/RealTimeStatus']);
            }
            else
                this.router.navigate(['/pages/' + JsonObj[0].path]);
        }
    }

    Round(Number, Index) {
       if(Number==null||Number=="")
        return "";
       else
        return Math.round(Number*Math.pow(10, Index))/Math.pow(10, Index);
    }
    
    loadOut() {
        alert("資料庫連線中斷，請重新登入!");
        localStorage.removeItem("User");
        localStorage.removeItem("Account");
        localStorage.removeItem("Token");
        this.router.navigate(['/login']);
    }
}