import { Component, OnInit, Inject } from '@angular/core';
import { EventLogService } from '../../../DataService/Alarm.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-EventLog',
  templateUrl: './EventLog.component.html',
  styleUrls: ['./EventLog.component.scss'],
  providers: [EventLogService, MyCommon,Permissions]
})
export class EventLogComponent implements OnInit {

  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  private pageSize: number = 10;
  private skip: number = 0;
  public ColSize: number = 100;

  public ViewPermissions:boolean=false;


  //查詢參數
  Search_Category:any="ErrorMsg";
  Search_Keyword:any="";
  ErrorCode:any='-1';
  OrderID:any='-1';
  Search_UserChk:any='-1';

  //DatePick設定
  StartDate: any = '2017/01/01 00:00';
  EndDate: any = '2017/01/01 00:00';

  public splitbutton_data: Array<any> = [];
  public splitbutton_data2: Array<any> = [];
  public SelectItem;


  constructor(
    @Inject(EventLogService) private EventLogData: EventLogService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
      //判斷頁面權限
      let sbody = {
        UrlList:"EventLog",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="EventLog")
                  this.ViewPermissions=true;    
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
/*
       this.splitbutton_data.push(
                  {
                    //disabled:this.test(),
                    text: '警報已確認',
                    iconClass: 'fa fa-check',
                    click: () => {this.UserChkCheck();}
                  });

       this.splitbutton_data2.push(
                  {
                    text: '警報解除',
                    iconClass: 'fa fa-pencil-square-o',
                    click: () => {this.UserChkSolve();}
                  });
*/

    //載入預設日期
    var sNowDate = new Date();
    var eDiffDay = (24 * 60 * 60 * 1000) * 1;
    var eDiffDate = new Date(sNowDate.getTime() + eDiffDay);
    this.EndDate =  new Date(eDiffDate.getFullYear() + '/' + String(Number(eDiffDate.getMonth()) + 1) + '/' + eDiffDate.getDate() + ' 00:00');
    //單位為一天的時間
    var DiffDay = (24 * 60 * 60 * 1000) * 30;
    var sDiffDate = new Date(sNowDate.getTime() - DiffDay);
    this.StartDate =  new Date(sDiffDate.getFullYear() + '/' + String(Number(sDiffDate.getMonth()) + 1) + '/' + sDiffDate.getDate() + ' 00:00');


    this.GetData();
  }

 



  //警報已確認
  UserChkCheck(obj){
    this.SelectItem=obj;
    if (confirm("確定修改嗎?")) {
      this.UpdateUserChk('1');
    }
  }
  //警報解除
  UserChkSolve(obj){
    this.SelectItem=obj;
    if (confirm("確定修改嗎?")) {
      this.UpdateUserChk('2');
    }
  }
  //更新UserChk
  UpdateUserChk(sUserChk:any){
    var IO_S='-1';
    var IO_MAC='-1';
    if (this.SelectItem.IO_S !=null)
      IO_S=this.SelectItem.IO_S;
    if (this.SelectItem.IO_MAC !=null)
      IO_MAC=this.SelectItem.IO_MAC;

    let sbody = {
      EventLogID:this.SelectItem.ID,
      sIO_MAC: IO_MAC,
      sIO_S: IO_S,
      sUserChk: sUserChk,
      Key:localStorage.getItem("Token")
    };
    
    this.EventLogData.UpdateData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        alert("修改成功!")
        this.GetData();
      },
      err => { console.log(err); }
    );
  }

  public onPaste(obj): void {
      this.SelectItem=obj;
    }

  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GetData();
  }
  //換頁
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.GetData();
  }
  //取得系統資料
  GetData() {

    //查看是否要預設未確認警報
    var EventLogStorage=localStorage.getItem("EventLog");
    if (EventLogStorage !=null){
      this.Search_UserChk=0;
      localStorage.removeItem("EventLog");
    }

    var StrWhere=" Where 1=1";
    if (this.Search_Keyword !=""){
      StrWhere +=" AND "+this.Search_Category+" Like '%"+this.Search_Keyword+"%'";
    }
    if (this.ErrorCode !="-1"){
       StrWhere +=" AND EventLog.ErrorCode='"+this.ErrorCode+"'";
    }
    /*
    if (this.OrderID !="-1"){
       StrWhere +=" AND EventLog.OrderID='"+this.OrderID+"'";
    }*/
    if (this.Search_UserChk !="-1"){
      if(this.Search_UserChk==0){
        StrWhere +=" AND EventLog.UserChk in(-1,0)";
      }else if(this.Search_UserChk==1){
        StrWhere +=" AND EventLog.UserChk='"+this.Search_UserChk+"'";
      }else if(this.Search_UserChk==2){
        StrWhere +=" AND EndTime is not null";
      }else if(this.Search_UserChk==3){
        StrWhere +=" AND EndTime is  null";
      }
    }
    StrWhere +=" AND (EventLog.EventTime between '"+this.MyCommon.FormateDateTime(this.StartDate)+"' and '"+this.MyCommon.FormateDateTime(this.EndDate)+"' )";

    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    
    this.EventLogData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        //將時間中的T取代成空白
        var ReturnData=obj;
        for (var item of ReturnData){
          item.EventTime=item.EventTime.replace('T',' ');
          if (item.EndTime !=null && item.EndTime !='')
            item.EndTime=item.EndTime.replace('T',' ');
          if (item.CheckTime !=null && item.CheckTime !='')
            item.CheckTime=item.CheckTime.replace('T',' ');
        }
        this.gridView = {
          data: orderBy(ReturnData.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: obj.length
        };
      },
      err => { console.log(err); }
    );
  }
  Search(){
    this.skip=0;
    this.GetData();
  }

}