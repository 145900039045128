import { Component, OnInit, Inject } from '@angular/core';
import { PowerAnalysisTypeService,Permissions } from '../../../../DataService';
import { MyCommon } from '../../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-PATypeSettingEdit',
  templateUrl: './PATypeSettingEdit.component.html',
  styleUrls: ['./PATypeSettingEdit.component.scss'],
  providers: [PowerAnalysisTypeService, MyCommon,Permissions]
})
export class PATypeSettingEditComponent implements OnInit {

  data:any=[{
    PAType_ID:-1,
    PA_Class:-1,
    PAName:"",
    SortID:0,
    Color:"#d2d2d2",
    IsOther:"0"
  }]
  PAType_ID:any;
  public ViewPermissions:boolean=false;
  
  constructor(@Inject(PowerAnalysisTypeService) private PATypeData: PowerAnalysisTypeService,
		@Inject(Permissions) private PermissionsData: Permissions,
		@Inject(MyCommon) private MyCommon: MyCommon,
		private activatedRoute: ActivatedRoute,
		private router: Router) { }

  ngOnInit() {
     //判斷頁面權限
     let sbody = {
      UrlList:"PATypeSettingEdit",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
      this.MyCommon.CheckToken(obj);
      for (let Site of obj) {
        if(Site.path=="PATypeSettingEdit")
          this.ViewPermissions=true;
      }
      try{
        this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
      }catch(err){}
      },
      err => {  },
      () => { }
    );

    this.SetData();
  }

  SetData() {
		this.activatedRoute.params.subscribe((params: Params) => {
			this.PAType_ID = params['id'];
		});
		var StrWhere = " where PowerAnalysisType.PAType_ID=" + this.PAType_ID;

		let sbody = {
			TopN: " ",
			StrWhere: StrWhere,
			Sort: " ",
			Key:localStorage.getItem("Token")
		};
		
		this.PATypeData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
        this.MyCommon.CheckToken(obj);
        obj[0].IsOther=String(obj[0].IsOther);
				this.data = obj;
	
			},
			err => { console.log(err); }
		);
	}

  
  clicked() {
		if (confirm("確定要修改嗎?")) {
					this.Update();
		}
  }
  
  Update() {
    var Set=this.data[0];
		if (Set.IsOther.toString()=="1"){
      Set.IsOther=true;
    }else{
      Set.IsOther=false;
    }


		if (String(Set.PAName).trim()==''){
			alert('請輸入類型名稱!');
			return false;
		}


		let sbody = {
      "PAType_ID": Set.PAType_ID,
			"PA_Class": Set.PA_Class,
			"PAName": Set.PAName,
			"SortID": Set.SortID,
      "Color": Set.Color,
      "IsOther": Set.IsOther,
			Key:localStorage.getItem("Token")
		};

		this.PATypeData.UpdateData(sbody, this.MyCommon.SetHeaders()).subscribe(
			(obj) => {
				this.MyCommon.CheckToken(obj);
				alert('修改成功');
				this.router.navigate(['/pages/PATypeSetting']);
			},
			err => { alert('修改失敗'); }
		);
	}

	back() {
		this.router.navigate(['/pages/PATypeSetting']);
	}

}
