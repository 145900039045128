import { Component, OnInit,Inject } from '@angular/core';
import { MyCommon } from '../../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserGroupService } from '../../../../DataService/User.service';
import { Permissions } from '../../../../DataService/User.service';
import {
    TreeviewI18n, TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent,
    TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem
} from 'ngx-treeview';

@Component({
  selector: 'app-UserGroup_Edit',
  templateUrl: './UserGroup_Edit.component.html',
  styleUrls: ['./UserGroup_Edit.component.scss'],
  providers: [UserGroupService, MyCommon,Permissions
        ,{ provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }]
})
export class UserGroup_EditComponent implements OnInit {

UGrp_ID:any;
public FromData:any;
private sub:any;

public ViewPermissions:boolean=false;
public PermissionsString;
treeviewData=[];


  config:any={
   isShowAllCheckBox: false,
   isShowFilter: false,
   isShowCollapseExpand: true,
   maxHeight: 500
};
items=[];

  constructor(
    @Inject(UserGroupService) private UserGroupData: UserGroupService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

ngOnInit() {

   //判斷頁面權限
    let sbody = {
      UrlList:"UserGroupEdit",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
            if(Site.path=="UserGroupEdit")
                this.ViewPermissions=true;
        }
        try{
          this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
        }catch(err){}
      },
      err => {  },
      () => { }
        );
      this.sub=this.activatedRoute.params.subscribe(params => {
        this.UGrp_ID = params['id'];
        this.GetData();
      });
  }

    //取得SiteMap
  GetSiteMap() {
    var Permission="UGrp_perm";
        
    if(this.MyCommon.PageMode()=="ibas"){
        Permission='UGrp_iBAS_perm';
    }
    let sbody = {
      UrlList:"default",
      Key:localStorage.getItem("Token")
    };
    this.PermissionsString=this.FromData[0][Permission];
        var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
        stralert.subscribe(
          (obj) => {
            this.MyCommon.CheckToken(obj);
            this.treeviewData=obj;
            this.SetTreeView();
          },
          err => {  },
          () => { }
        );
  }

   //載入TreeView
  SetTreeView(){
   
     var ItemList=[];
      var ItemLevel0={text: '首頁', value: this.treeviewData[0]["id"]};
      var ItemLevel1=[];
      
      
      //第一層
      var Level1_filter = this.treeviewData.filter(task => task.level == "1");
      for (let i1 in Level1_filter){
        ItemLevel1.push({text: Level1_filter[i1].name, value: Level1_filter[i1].id});
        
        
        //第二層
        var ItemLevel2=[];
        var Level2_filter = this.treeviewData.filter(task => task.parent_id == Level1_filter[i1].id);
        for (let i2 in Level2_filter){
          ItemLevel2.push({text: Level2_filter[i2].name, value: Level2_filter[i2].id});
          ItemLevel1[i1]["children"]=ItemLevel2;
          
          //第三層
          var ItemLevel3=[];
          var Level3_filter = this.treeviewData.filter(
                    task => task.parent_id == Level2_filter[i2].id);
          for (let i3 in Level3_filter){
            ItemLevel3.push({text: Level3_filter[i3].name, value: Level3_filter[i3].id});
            ItemLevel2[i2]["children"]=ItemLevel3;
            //第四層
            var ItemLevel4=[];
            var Level4_filter = this.treeviewData.filter(
                      task => task.parent_id == Level3_filter[i3].id);
            for (let i4 in Level4_filter){
              ItemLevel4.push({text: Level4_filter[i4].name, value: Level4_filter[i4].id});
              ItemLevel3[i3]["children"]=ItemLevel4;
            }
          }
        }
      }
      ItemLevel0["children"]=ItemLevel1;
      ItemList.push( new TreeviewItem(ItemLevel0));
     //載入權限資料
      for (var i0 of ItemList)
      {
        i0.internalChecked=this.CheckChecked(i0.value)
        //第一層
        for (var i1 of i0.internalChildren){
          i1.internalChecked=this.CheckChecked(i1.value)
          //第二層
          if (i1.internalChildren !=undefined){
              for (var i2 of i1.internalChildren){
                i2.internalChecked=this.CheckChecked(i2.value);
                //第三層
                if (i2.internalChildren !=undefined){
                    for (var i3 of i2.internalChildren){
                      i3.internalChecked=this.CheckChecked(i3.value)
                      //第四層
                      if (i3.internalChildren !=undefined){
                          for (var i4 of i3.internalChildren){
                            i4.internalChecked=this.CheckChecked(i4.value)
                          }
                      }
                    }
                }
              }
          }
        }
      }
      this.items=ItemList; 
    }

  CheckChecked(value){
    //預設勾勾不再權限內取消勾勾
    if ((","+this.PermissionsString+",").indexOf(","+value+",")>-1)
      return true;
    else
      return false;
  }


  //權限選擇
    onSelectedChange(downlineItems: DownlineTreeviewItem[]) {
        var Permission="UGrp_perm";
        
        if(this.MyCommon.PageMode()=="ibas"){
            Permission='UGrp_iBAS_perm';
        }
        this.FromData[0][Permission]=[];
        downlineItems.forEach(downlineItem => {
            const item = downlineItem.item;
            const value = [item.value];
            const texts = [item.text];
            let parent = downlineItem.parent;
            
            while (parent != null){
              value.push(parent.item.value);
                parent = parent.parent;
            }
            //排除重複ID
            for (var valueitem of value)
            {
              //檢測無重複時加入
              if ((","+this.FromData[0][Permission]+",").indexOf(","+valueitem+",")==-1)
              {
                this.FromData[0][Permission].push(valueitem);
              }
            }
        });
    }
  ngOnDestroy() {
      // Clean sub to avoid memory leak
    //this.sub.unsubscribe();
  }

  GetData() {
    var StrWhere = " Where UserGroup.UGrp_ID="+this.UGrp_ID;
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
      this.UserGroupData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
         this.FromData = obj;
         if (this.FromData[0].UGrp_Enabled)
          this.FromData[0].UGrp_Enabled="1";
         else
          this.FromData[0].UGrp_Enabled="0";
		      this.GetSiteMap();
           
        },
        err => { console.log(err); }
      );
    }

  clicked() {
     if (confirm("確定要修改嗎?")) {
       this.Update();
      }
   }

  Update(){

    if (String(this.FromData[0].UGrp_name).trim()==''){
      alert('請輸入名稱');
      return false;
    }

    var Permission="UGrp_perm";
        
    if(this.MyCommon.PageMode()=="ibas"){
        Permission='UGrp_iBAS_perm';
    }

    let sbody = {
      "UGrp_ID": Number(this.UGrp_ID),
      "UGrp_name": this.FromData[0].UGrp_name,
      "UGrp_perm": String(this.FromData[0][Permission]),
      "UGrp_Enabled": Number(this.FromData[0].UGrp_Enabled),
      Key:localStorage.getItem("Token")
    };

     var stralert = this.UserGroupData.UpdateData(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
           this.MyCommon.CheckToken(obj);
          alert('修改成功');
          this.router.navigate(['/pages/UserGroup']);
        },
        err => { alert('修改失敗'); console.log(err); },
        () => { }
      );
  }

    //離開
  back() {
    this.router.navigate(['/pages/UserGroup']);
  }

}