import { Component, OnInit ,Inject} from '@angular/core';
import { LineNotifyService } from '../../../DataService/LineNotify.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-Line_Edit',
  templateUrl: './Line_Edit.component.html',
  styleUrls: ['./Line_Edit.component.scss'],
  providers: [LineNotifyService, MyCommon,Permissions]
})
export class Line_EditComponent implements OnInit {

  ID:any='';
  Name:any='';
  public ViewPermissions:boolean=false;

  Client_ID:any='';
  Client_Secret:any='';
  Redirect_Uri:any='';
  Temp_Token:any='';

  constructor(
    @Inject(LineNotifyService) private LineNotifyData: LineNotifyService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    //判斷頁面權限
    let sbody = {
      UrlList:"LineAdd",
      Key:localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
            if(Site.path=="LineAdd")
                this.ViewPermissions=true;
        }
        try{
          this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
        }catch(err){}
      },
      err => {  },
      () => { }
    );

     this.activatedRoute.params.subscribe(params => {
          this.ID = params['id'];
            this.GetData();
      });

  }

  GetData(){
    var StrWhere=" where ID="+this.ID;
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
 
    this.LineNotifyData.GetToken(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
          this.Name=obj[0].Name;
          this.Temp_Token=obj[0].Token;
        },
      err => { console.log(err); }
    );
  }

  clicked(){
 if (confirm("確定要修改嗎?")) {
    if (this.Name.trim() !='' && this.Temp_Token.trim() !='')
        this.Update();
    else
        alert("名稱和Token不能為空!")
      }
}

Update() {

    if (String(this.Name).trim()=='' || String(this.Temp_Token).trim()==''){
      alert('群組名稱和Token不能為空!');
      return false;
    }


    let sbody = {
      "ID": this.ID,
      "Name": this.Name,
      "Token": this.Temp_Token,
      Key:localStorage.getItem("Token")
    };
    var stralert = this.LineNotifyData.UpdateToken(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);

        let sbody2 = {
          "Temp_Token": ''
        };
        this.LineNotifyData.UpdateTempToken(sbody2, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          alert('修改成功');
        this.router.navigate(['/pages/Line']);
        },
        err => { }
      );
      },
      err => { alert('修改失敗'); },
      () => { }
    );
}

 //離開
  back() {
    this.router.navigate(['/pages/Line']);
  }


}
