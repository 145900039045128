import { Component, OnInit, Inject,ViewChild,ElementRef  } from '@angular/core';
import { SystemService } from '../../../DataService/System.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { LineNotifyService } from '../../../DataService/LineNotify.service';
import { Permissions } from '../../../DataService/User.service';


type AOA = Array<Array<any>>;

function s2ab(s: string): ArrayBuffer {
	const buf = new ArrayBuffer(s.length);
	const view = new Uint8Array(buf);
	for (let i = 0; i !== s.length; ++i) {
		view[i] = s.charCodeAt(i) & 0xFF;
	};
	return buf;
}


@Component({
  selector: 'app-SystemPage',
  templateUrl: './SystemPage.component.html',
  styleUrls: ['./SystemPage.component.scss'],
  providers: [SystemService, MyCommon,LineNotifyService,Permissions]
})
export class SystemPageComponent implements OnInit {

  @ViewChild('btnRestore') btnRestore: any;
  @ViewChild('RestStatusScroll') private RestStatusScroll: ElementRef;
  TableList:any=['ACTools_Schedule','DEV','IO_Info','PMData','Report_Custom','Report_Custom_IO','SYS_Info'
              ,'UserGroup','User','Dev_Info'];
  TableIndex:any=0;
  RestStatus:any='';
  JsonData:any=[{ACTools_Schedule:[]},{DEV:[]},{IO_Info:[]},{PMData:[]},{Report_Custom:[]},{Report_Custom_IO:[]},{SYS_Info:[]},{User:[]},{UserGroup:[]},{Dev_Info:[]}];

  public ViewPermissions:boolean=false;
  public BackupPermissions:boolean=false;
  public RestorePermissions:boolean=false;
  public LineUpdatePermissions:boolean=false;
  public LineSendPermissions:boolean=false;
  public MailUpdatePermissions:boolean=false;
  public LineViewPermissions:boolean=false;
  public MailViewPermissions:boolean=false;


  //Line參數
  BackupData:any;
  Client_ID:any='';
  Client_Secret:any='';
  Redirect_Uri:any='';
  Code:any='';
  Token:any='';
  Message:any='';
  //Mail參數
  smtp_host:any='';
  smtp_port:any='';
  address:any='';
  smtp_account:any='';
  smtp_password:any='';
  smtp_ssl:any='0';
  //其他參數
  Banner_Time_Sec:any='';
  //備份還原參數
  ACTools_Schedule_Data:AOA=[];
  DEV_Data:AOA=[];
  IO_Info_Data:AOA=[];
  PMData_Data:AOA=[];
  Report_Custom_Data:AOA=[];
  Report_Custom_IO_Data:AOA=[];
  SYS_Info_Data:AOA=[];
  User_Data:AOA=[];
  UserGroup_Data:AOA=[];
  Dev_Info_Data:AOA=[];

  wopts: XLSX.WritingOptions = { bookType:'xlsx', type:'binary' };
  fileName: string = "SheetJS.xlsx";

  constructor(
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(LineNotifyService) private LineNotifyData: LineNotifyService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router) { }




  ngOnInit() {

      //判斷頁面權限
      let sbody2 = {
        UrlList:"SystemPage,SystemPageBackup,SystemPageRestore,SystemPageLineUpdate,SystemPageLineSend,SystemPageMailUpdate,SystemPageLineView,SystemPageMailView",
        Key:localStorage.getItem("Token")
      }; 
      var stralert = this.PermissionsData.SiteMap(sbody2, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="SystemPage")
                  this.ViewPermissions=true;
              if(Site.path=="SystemPageBackup")
                  this.BackupPermissions=true;
              if(Site.path=="SystemPageRestore")
                  this.RestorePermissions=true;
              if(Site.path=="SystemPageLineUpdate")
                  this.LineUpdatePermissions=true;
              if(Site.path=="SystemPageLineSend")
                  this.LineSendPermissions=true;
              if(Site.path=="SystemPageMailUpdate")
                  this.MailUpdatePermissions=true; 
              if(Site.path=="SystemPageLineView")
                  this.LineViewPermissions=true;
              if(Site.path=="SystemPageMailView")
                  this.MailViewPermissions=true;  

          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
      
      this.GetSystem_Setting();
     
  }

  //取得資訊
  GetSystem_Setting(){
      let sbody = {
        TopN:" ",
        StrWhere: " ",
        Sort: " "
      };
      this.SystemData.GetSystemSetting(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
            this.smtp_host=obj[0].smtp_host;
            this.smtp_port=obj[0].smtp_port;
            this.address=obj[0].address;
            this.smtp_account=obj[0].smtp_account;
            this.smtp_password=obj[0].smtp_password;
            this.smtp_ssl=String(obj[0].smtp_ssl);
            this.Client_ID=obj[0].client_id;
            this.Client_Secret=obj[0].client_secret;
            this.Redirect_Uri=obj[0].redirect_uri;
            this.Token=obj[0].Temp_Token;
            this.Banner_Time_Sec=obj[0].Banner_Time_Sec;
        },
        err => { }
      );
  }

  //取得備份資料
  GetBackup(){
    let sbody = {
        Key:localStorage.getItem("Token")
      };

      this.SystemData.GetBackup(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          if(obj[0]=="["){
            this.BackupData=JSON.parse(obj);
          }else{
            this.BackupData=obj;
          }
          for(let Table_Name of Object.keys(this.BackupData[0])){
            let data=[];
            if(this.BackupData[0][Table_Name][0]=="["){
              data=JSON.parse(this.BackupData[0][Table_Name]);
            }else{
              data=this.BackupData[0][Table_Name];
            }
            this.BackupData[0][Table_Name]=this.ReplaceNull(data);
            
          }
          
          this.exportAsExcelFile(this.BackupData,'_excel');
        },
        err => { }
      );
  }
  //將空白和null取代
  ReplaceNull(Data:any){
    
    for (let Table of Data){
      for(let row of Object.keys(Table)){
        if (String(Table[row])=='' ){
          Table[row]='(Empty)';
        }
        if (String(Table[row])=='null'){
          Table[row]='(NULL)';
        }
      }
    }
    return Data;
  }
  //建立Excel
  public exportAsExcelFile(json: any[], excelFileName: string): void {

      const workBook = XLSX.utils.book_new(); // create a new blank book
      let SheetBook={};

      for(let Table_Name of Object.keys(json[0])){
        let Sheet="{ '"+Table_Name+"':[]}";
        Sheet=Sheet.replace(/\'/g, '"');
        let SetSheet=[];
        SetSheet=JSON.parse(Sheet);
        SetSheet[Table_Name]=XLSX.utils.json_to_sheet(this.BackupData[0][Table_Name]);

        SheetBook=Object.assign(SheetBook,SetSheet);
        XLSX.utils.book_append_sheet(workBook, SetSheet[Table_Name],Table_Name); // add the worksheet to the book
      }

       let sNowDate = new Date();
       let fileName=sNowDate.getFullYear()+this.FormatDate(String(Number(sNowDate.getMonth()) + 1))+sNowDate.getDate()+this.FormatDate(sNowDate.getHours())+this.FormatDate(sNowDate.getMinutes())+"_excel_backup";
       XLSX.writeFile(workBook, fileName+'.xlsx');
    }

    //判斷小時和分鐘補足兩位
    FormatDate(val: any) {
      if (String(val).length == 1)
        return "0" + val;
      else
        return val;
    }

    onClickFile(evt: any){
      if (confirm("還原資料會將原先資料清除，請問確認要還原資料嗎?")) {
        this.onFileChange(evt);
      }else{

        return false;
      }
    }

    //讀取Excel
    onFileChange(evt: any) {
        this.RestStatus="";
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(evt.target);
        if(target.files.length != 1) { throw new Error("Cannot upload multiple files on the entry") };
        const reader = new FileReader();
        reader.onload = (e: any) => {
          /* read workbook */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {type:'binary'});

          for(let Table_Name of wb.SheetNames){
            /* save data */
            let Data = (XLSX.utils.sheet_to_json(wb.Sheets[Table_Name], {header:1}));
            for(let i in Data){
              Data[i]=JSON.parse(JSON.stringify(Data[i])).join('∣');
            }
            
            this.CallRestore(Table_Name,Data);
          }
        };
        reader.readAsBinaryString(target.files[0]);
        //將input的資料清空
        //this.btnRestore.nativeElement.value = "";
    } 

    //呼叫要還原的資料庫
    CallRestore(table_name,data){
          //記錄還原狀態和將狀態固定在最底部
          this.RestStatus=this.RestStatus+"開始還原資料表"+table_name+"\r\n";
          this.RestStatusScroll.nativeElement.scrollTop = this.RestStatusScroll.nativeElement.scrollHeight;
          let sbody = {
            Table_Name:table_name,
            Data:data,
            Key:localStorage.getItem("Token")
          };

          this.SystemData.Restore(sbody, this.MyCommon.SetHeaders()).subscribe(
            (obj) => {
              this.RestStatus=this.RestStatus+"資料表"+table_name+"還原完成"+obj[0].ErrorMessage+"\r\n";
            },
            err => { console.log(err)}
          );
    }

    
    Clear(){
      if (confirm("確定要做一鍵清除功能?")) {
          let sbody = {
            Key:localStorage.getItem("Token")
          };
        
          //刪除全部資料
          this.SystemData.ClearTable(sbody, this.MyCommon.SetHeaders()).subscribe(
            (obj) => { 
              alert("清除成功!");
            },
            err => { console.log(err)}
          );
      }
    }

    //儲存Line資訊
    SvaeClient(){
      let sbody = {
        client_id:this.Client_ID,
        client_secret:this.Client_Secret,
        redirect_uri:this.Redirect_Uri,
        Key:localStorage.getItem("Token")
      };

      this.LineNotifyData.UpdateData(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          alert("儲存成功!");
        },
        err => { alert("儲存失敗!");}
      );
    }
    //取得Token
    LinkLineNotify(){
      var Url='https://notify-bot.line.me/zh_TW/';
      window.open(Url);
      /*
      if (confirm("當LineNotify連動完成後，請重新刷新頁面!!")) {
        var Url='https://notify-bot.line.me/oauth/authorize?';
              Url += 'response_type=code';
              Url += '&client_id='+this.Client_ID;
              Url += '&redirect_uri='+this.Redirect_Uri;
              Url += '&scope=notify';
              Url += '&state=abcde';

        window.open(Url);
        
      }*/
    }
    //傳送Line訊息
    SendMessage(){
      let sbody = {
        token:this.Token,
        message:this.Message,
        Key:localStorage.getItem("Token")
      };

      this.LineNotifyData.SendMessage(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          alert("已傳送Line訊息");
        },
        err => { }
      );
    }

    SaveMail(){
      let sbody = {
        smtp_host:this.smtp_host,
        smtp_port:this.smtp_port,
        address:this.address,
        smtp_account:this.smtp_account,
        smtp_password:this.smtp_password,
        smtp_ssl:this.smtp_ssl
      };
      this.SystemData.UpdateSystemSetting(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          alert("儲存成功!");
        },
        err => { alert("儲存失敗!");}
      );

    }

SaveOther(){
      let sbody = {
        Banner_Time_Sec:this.Banner_Time_Sec,
        Key:localStorage.getItem("Token")
      };
      this.SystemData.UpdateOther(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          alert("儲存成功!");
        },
        err => { alert("儲存失敗!");}
      );

}



}
