import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class IBas_ObjectAttribService {

constructor(@Inject(HttpClient)  
            private http : HttpClient,
            public MyCommon:MyCommon) { }

    GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetObjectAttrib');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetDataReal(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetObjectReal');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetDataAI(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetObjectAI');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetDataAO(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetObjectAO');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateDataAO(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('UpdateObjectAO');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetDataDI(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetObjectDI');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetDataDO(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetObjectDO');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateDataDO(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('UpdateObjectDO');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetDataSET(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetObjectSET');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateDataSET(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('UpdateObjectSET');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetDataCalc(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetObjectCalc');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    
}