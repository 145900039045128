import { Component, OnInit, Inject } from '@angular/core';
import { IOService } from '../../../../DataService/IO.service';
import { ReportService } from '../../../../DataService/Report.service';
import { MyCommon } from '../../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DualListComponent } from 'angular-dual-listbox/index';
import { Permissions } from '../../../../DataService/User.service';

@Component({
  selector: 'app-ReportSetting_Edit',
  templateUrl: './ReportSetting_Edit.component.html',
  styleUrls: ['./ReportSetting_Edit.component.scss'],
  providers: [IOService, ReportService, MyCommon,Permissions]
})
export class ReportSetting_EditComponent implements OnInit {

  keepSorted = true;
  key: string;
  display: string;
  filter = true;
  source: Array<any>;
  confirmed: Array<any>;
  format: any = { add: '新增', remove: '移除', all: '全部選取', none: '取消選取', direction: 'left-to-right' };//DualListComponent.DEFAULT_FORMAT;
  private sourceChessmen: Array<any>;
  private confirmedChessmen: Array<any>;

  private chessmen: Array<any> = [];
  private SelectData: Array<any> = [];

  private useChessmen() {
    this.key = 'IO_ID';
    this.display = 'DisplayName';
    this.keepSorted = false;
    this.source = this.sourceChessmen;
    this.confirmed = this.confirmedChessmen;//被選的資料
  }

  Rpt_ID: any;
  Rpt_Name: any='';
  Rpt_Value: any="1";
  Rpt_Enabled: any = "1";
  IOList: any='';
  CheckBox1: any = true;
  CheckBox2: any = true;
  CheckBox3: any = true;
  CheckBox4: any = true;

  public ViewPermissions:boolean=false;

  constructor(
    @Inject(IOService) private IOData: IOService,
    @Inject(ReportService) private ReportData: ReportService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
      //判斷頁面權限
      let sbody = {
        UrlList:"ReportSettingEdit",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="ReportSettingEdit")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );

    this.SetData();
  }

  SetData() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.Rpt_ID = params['id'];
    });

    var StrWhere = " where Report_Custom.Rpt_ID=" + this.Rpt_ID;

    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.ReportData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.Rpt_Name = obj[0].Rpt_Name;
        this.Rpt_Value = String(obj[0].Rpt_Value);
        this.Rpt_Enabled = String(obj[0].Rpt_Enabled);
        //this.SetCheckBox();
        this.setIO();
      },
      err => { console.log(err); }
    );
  }

  SetCheckBox() {
    if (this.Rpt_Value.indexOf('1') == -1)
      this.CheckBox1 = false;
    if (this.Rpt_Value.indexOf('2') == -1)
      this.CheckBox2 = false;
    if (this.Rpt_Value.indexOf('3') == -1)
      this.CheckBox3 = false;
    if (this.Rpt_Value.indexOf('4') == -1)
      this.CheckBox4 = false;
  }

  setIO() {
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.IOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.chessmen = obj;
        this.SetSelectIO();
      },
      err => { console.log(err); }
    );
  }

  SetSelectIO() {
    let sbody = {
      ID: this.Rpt_ID,
      Key:localStorage.getItem("Token")
    };
    this.ReportData.GetIO(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.SelectData = obj;
        this.doReset();
      },
      err => { console.log(err); }
    );
  }


  doReset() {
    this.sourceChessmen = JSON.parse(JSON.stringify(this.chessmen));
    this.confirmedChessmen = JSON.parse(JSON.stringify(this.SelectData));// new Array<any>();
    this.useChessmen();
  }

  clicked() {
    if (confirm("確定要修改嗎?")) {
      this.Update();
    }
  }

  Update() {
    //組IOList字串
    var IOList = "";
    var IOListCount=0;
    for (var i in this.confirmed) {
      IOListCount=IOListCount+1;
			if (IOListCount>20){
				alert("IO設定不能超過20筆!");
				return false;
			}
      IOList += this.confirmed[i].IO_ID + ",";
    }
    /*
    IOList = IOList.slice(0, -1);
    //組checkbox字串
    var CheckBoxList = "";
    if (this.CheckBox1)
      CheckBoxList += "1,";
    if (this.CheckBox2)
      CheckBoxList += "2,";
    if (this.CheckBox3)
      CheckBoxList += "3,";
    if (this.CheckBox4)
      CheckBoxList += "4,";
    CheckBoxList = CheckBoxList.slice(0, -1);
    this.Rpt_Value = CheckBoxList;
    */

    if (String(this.Rpt_Name).trim()==''){
			alert("請輸入名稱!");
			return false;
		}		
		if (IOList==''){
			alert("請選擇IO!");
			return false;
		}

    let sbody = {
      "Rpt_ID": this.Rpt_ID,
      "Rpt_Name": this.Rpt_Name,
      "Rpt_Value": this.Rpt_Value,
      "Rpt_Enabled": Number(this.Rpt_Enabled),
      "IOList": IOList,
      Key:localStorage.getItem("Token")
    };

    this.ReportData.UpdateData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('修改成功');
        this.router.navigate(['/pages/ReportSetting']);
      },
      err => { alert('修改失敗'); }
    );
  }

  back() {
    this.router.navigate(['/pages/ReportSetting']);
  }

}