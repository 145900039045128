import { Component, OnInit, Inject } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy,groupBy  } from '@progress/kendo-data-query';
import { ActoolsCtrlService } from '../../../DataService/ActoolsCtrl.service';
import { SystemService } from '../../../DataService/System.service';
import { IOService } from '../../../DataService/IO.service';
import { DevService } from '../../../DataService/Dev.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ScheduleColumn } from '../ScheduleColumn'
import { Permissions } from '../../../DataService/User.service';
import { IOCtrlSettingColumn } from '../IOCtrlSettingColumn';

@Component({
  selector: 'app-ScheduleConditionEdit',
  templateUrl: './ScheduleConditionEdit.component.html',
  providers: [ActoolsCtrlService, SystemService, IOService,DevService, MyCommon,Permissions]
})
export class ScheduleConditionEditComponent implements OnInit {

  //設備
  Dev_Source: any=[];
  E_ID: any;
  public gridView: GridDataResult;
  public GridData:any=[];
  public sort: SortDescriptor[]=[{field: 'Sort',dir: 'asc'}];
  public IOCtrl_Condition_Data:any;
  //條件
  Conditional:string;
  CdnCtrlMode:string;
  //預設欄位
  IOCtrlSettingColumn = new IOCtrlSettingColumn(new Date(), new Date(), new Date(),'',0,0,0,0,'');

  public ViewPermissions:boolean=false;

  constructor( @Inject(ActoolsCtrlService) private ActoolsCtrl: ActoolsCtrlService,
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(DevService) private DevData: DevService,
    @Inject(IOService) private IOData: IOService,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router,
    private activatedRoute: ActivatedRoute, ) { }
  ngOnInit() {
      //判斷頁面權限
      let sbody = {
        UrlList:"ScheduleEdit",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="ScheduleEdit")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );

    this.SetData();
    this.GetDevData();
  }

   //取得設備資料
   GetDevData(){
    var StrWhere = " where  DEV.DataType=0";

    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DevData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.Dev_Source =obj;
      },
      err => { console.log(err); }
    );
  }
  //載入資料
  SetData() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.E_ID = params['id'];
    });
    var StrWhere = " where E_ID=" + this.E_ID;
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.ActoolsCtrl.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
       
        this.CdnCtrlMode= String(obj[0].CdnCtrlMode);

        var StrWhere2 = " where IOCtrl_ID=" + this.E_ID;
        let sbody2 = {
          TopN: " ",
          StrWhere: StrWhere2,
          Sort: " ",
          Key:localStorage.getItem("Token")
        };
        this.ActoolsCtrl.GetConditionData(sbody2, this.MyCommon.SetHeaders()).subscribe(
          (obj) => {
            this.GridData=obj;
            this.GetData();
          });
     
      
      }
    );
  }

  clicked() {
    if (confirm("確定要更新嗎?")) {
      this.Update();
    }
  }

  //修改
  Update() {

    let sbody = {
      E_ID: this.E_ID,
      CtrlCondition:this.Conditional,
      Condition_Data:this.IOCtrl_Condition_Data,
      Key:localStorage.getItem("Token")
    };
    var stralert = this.ActoolsCtrl.UpdateConditionData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('修改成功');
        this.router.navigate(['/pages/Schedule']);
      },
      err => { alert('修改失敗'); console.log(err); },
      () => { }
    );
  }
    //顯示條件
    ShowConditional(){
      //重新排序
      let RowData=this.GridData.sort(function(a, b){ return ((a.Groups*100)+ a.Sort ) - ((b.Groups*100)+b.Sort)});//.sort(function(a, b){ return a.Sort - b.Sort});
      //群組資料
      let FinalData=groupBy(RowData, [{ field: "Groups" }]);
      this.Conditional='';
      for(let i in FinalData){
        let GroupLogic='',GroupCalc='';
        for(let j in FinalData[i]["items"]){
          let item=FinalData[i]["items"][j];
          let Parameter1="",Parameter2="";
          if(Number(item["Parameter1"])==-1)
            Parameter1=item["P1Value"];
          else
            Parameter1='['+item["Parameter1"]+'-S'+item["P1Value"]+']';
  
          if(Number(item["Parameter2"])==-1)
            Parameter2=item["P2Value"];
          else
            Parameter2='['+item["Parameter2"]+'-S'+item["P2Value"]+']';
  
          let Calc=''
          switch (Number(item["Judgment"])) {
            case 1:
            Calc='('+Parameter1+'='+Parameter2+')';
              break;
            case 2:
            Calc='('+Parameter1+'>'+Parameter2+')';
              break;
            case 3:
            Calc='('+Parameter1+'<'+Parameter2+')';
              break;
            case 4:
            Calc='('+Parameter1+'<='+Parameter2+')';
              break;
            case 5:
            Calc='('+Parameter1+'>='+Parameter2+')';
              break;
          }
          if(Number(j)>0){
            switch (Number(item["Logic"])) {
              case 1:
                Calc = " & "+Calc;
                break;
              case 2:
                Calc = " | "+Calc;
                break;
            }
          }else if (Number(j)==0&&Number(i)>0){
            switch (Number(item["Logic"])) {
              case 1:
                GroupLogic = " & ";
                break;
              case 2:
                GroupLogic = " | ";
                break;
            }
          }
          GroupCalc+=Calc;
        }
        if (FinalData[i]["items"].length == 1)
          this.Conditional += GroupLogic+GroupCalc;
        else
        this.Conditional += GroupLogic + '(' + GroupCalc + ')';
      }
       this.IOCtrlSettingColumn.CtrlCondition=this.Conditional;
       this.IOCtrl_Condition_Data=this.gridView.data;
    }
     //取得資料
   GetData() {
    this.gridView = {
      data: orderBy(this.GridData, this.sort),
      total: this.GridData.length
    };
    this.ShowConditional();
  }
  onItemInsert(){
    let MaxID=0,DefMACID=-1,MaxSort=0;
     //取得最大ID與排序
     for(let i in this.GridData){
      if(MaxID<this.GridData[i].ID)
        MaxID=this.GridData[i].ID;
      if(MaxSort<this.GridData[i].Sort)
        MaxSort=this.GridData[i].Sort;
    }
    //取得預設MACID
    if(this.Dev_Source.length>0){
      DefMACID=this.Dev_Source[0].MACID;
    }
    //新增資料
    this.GridData.push({ID:MaxID+1,IOCtrl_ID:1,Parameter1:DefMACID,P1Value:1,Judgment:1,Parameter2:-1,P2Value:1,Groups:-1,Sort:MaxSort+1,Logic:1});
  
    this.gridView = {
      data: orderBy(this.GridData, this.sort),
      total: this.GridData.length
    };
    this.ShowConditional();  
  }
  onItemDelete(item: any){
    //重新排序
    this.GridData=this.GridData.filter(function (d) {return d.ID != item.ID;}).sort(function(a, b){ return a.Sort - b.Sort});
    for(let i in this.GridData){
      this.GridData[i].Sort=(Number(i)+1);
    }
    this.gridView = {
      data: orderBy(this.GridData, this.sort),
      total: this.GridData.length
    };
    this.ShowConditional();
  }
  onItemSort(item: any,SortType:number){
    //取得替換的排序並重新設定
    let SortItem = this.GridData.filter(task => task.Sort == (item.Sort+SortType));
    if(SortItem.length>0){
      SortItem[0].Sort+=(SortType*-1);
    }
    //改變當列排序
    item.Sort+=SortType;
    
    this.gridView = {
      data: orderBy(this.GridData, this.sort),
      total: this.GridData.length
    };
    this.ShowConditional();
  }
  //離開
  back() {
    this.router.navigate(['/pages/Schedule']);
  }

}
