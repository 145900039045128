import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { IBas_ObjectService,IBas_ScheduleEquipVarService } from '../../../../DataService';
import { MyCommon } from '../../../../App_Code/MyCommon';

@Component({
  selector: 'app-Chiller_Parameter_Edit',
  templateUrl: './Chiller_Parameter_Edit.component.html',
  styleUrls: ['./Chiller_Parameter_Edit.component.scss'],
  providers: [IBas_ObjectService,IBas_ScheduleEquipVarService, MyCommon]
})
export class Chiller_Parameter_EditComponent implements OnInit {

  DeviceData:any=[];
  WaterSystem_Data:any=[];
  Chiller_Data:any=[];
  CHP_Data:any=[];
  CT_Data:any=[];

  //每月設定資料
  Month_Period_Source =[{value:1,text:"1月",enable:true},
  {value:2,text:"2月",enable:true},
  {value:3,text:"3月",enable:true},
  {value:4,text:"4月",enable:true},
  {value:5,text:"5月",enable:true},
  {value:6,text:"6月",enable:true},
  {value:7,text:"7月",enable:true},
  {value:8,text:"8月",enable:true},
  {value:9,text:"9月",enable:true},
  {value:10,text:"10月",enable:true},
  {value:11,text:"11月",enable:true},
  {value:12,text:"12月",enable:true}];
  //每周設定資料
  Operation_Period_Source =
  [{ value: 1, text: "週一", enable: true },
  { value: 2, text: "週二", enable: true },
  { value: 3, text: "週三", enable: true },
  { value: 4, text: "週四", enable: true },
  { value: 5, text: "週五", enable: true },
  { value: 6, text: "週六", enable: true },
  { value: 7, text: "週日", enable: true }];

  EquipVar_Data:any={EquipVarID:null
  ,WaterSystemID:null
  ,ChillerID:""
  ,Chiller_To_SP:""
  ,Chwp_dT_SP:""
  ,Cwp_dT_SP:""
  ,SCHWPGroup_ID:""
  ,Field_P_SP:""
  ,Field_P_LL:""
  ,Field_T_HL:""
  ,CTowerGroup_ID:""
  ,CTower_SetMode:"1"
  ,CTower_ApprTemp:"0"
  ,Sch_Month:"1,2,3,4,5,6,7,8,9,10,11,12"
  ,Sch_Week:"1,2,3,4,5,6,7"
  ,StartTime:null
  ,EndTime:null
  ,WholeDay:"0"};

  Hour_Source:any=[];
  Minute_Source:any=[];

  Select_StartHour:any="00";
  Select_StartMinute:any="00";
  Select_EndHour:any="23";
  Select_EndMinute:any="59";

  constructor(private router: Router,
    private Object: IBas_ObjectService,
    private ScheduleEquipVar: IBas_ScheduleEquipVarService,
    private MyCommon: MyCommon,
    private activatedRoute: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.Hour_Source=[];
    this.Minute_Source=[];
    for(let i=0;i<24;i++){
      this.Hour_Source.push({name:(i<10?"0"+String(i):String(i)),value:(i<10?"0"+String(i):String(i))});
    }
    for(let i=0;i<60;i++){
      this.Minute_Source.push({name:(i<10?"0"+String(i):String(i)),value:(i<10?"0"+String(i):String(i))});
    }
  
    this.activatedRoute.params.subscribe(params => {
      this.EquipVar_Data.EquipVarID = params['id'];
      this.GetData();
    }); 
    this.GetDeviceData();
  }
  GetData(){
    var StrWhere = " where EquipVarID= "+this.EquipVar_Data.EquipVarID;
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
 
    this.ScheduleEquipVar.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        if(obj.length>0){
          this.EquipVar_Data.ChillerID=obj[0].ChillerID;
          this.EquipVar_Data.Chiller_To_SP=obj[0].Chiller_To_SP;
          this.EquipVar_Data.Chwp_dT_SP=obj[0].Chwp_dT_SP;
          this.EquipVar_Data.Cwp_dT_SP=obj[0].Cwp_dT_SP;
          this.EquipVar_Data.SCHWPGroup_ID=obj[0].SCHWPGroup_ID;
          this.EquipVar_Data.Field_P_SP=obj[0].Field_P_SP;
          this.EquipVar_Data.Field_P_LL=obj[0].Field_P_LL;
          this.EquipVar_Data.Field_T_HL=obj[0].Field_T_HL;
          this.EquipVar_Data.CTowerGroup_ID=obj[0].CTowerGroup_ID;
          this.EquipVar_Data.CTower_SetMode=String(obj[0].CTower_SetMode);
          this.EquipVar_Data.CTower_ApprTemp=obj[0].CTower_ApprTemp;
          this.EquipVar_Data.Sch_Month=obj[0].Sch_Month;
          this.MonthChange(obj[0].Sch_Month);
          this.EquipVar_Data.Sch_Week=obj[0].Sch_Week;
          this.OperationChange(obj[0].Sch_Week);
          this.EquipVar_Data.StartTime=obj[0].StartTime;
          this.EquipVar_Data.EndTime=obj[0].EndTime;
          this.Select_StartHour=obj[0].StartTime.split(':')[0];
          this.Select_StartMinute=obj[0].StartTime.split(':')[1];
          this.Select_EndHour=obj[0].EndTime.split(':')[0];
          this.Select_EndMinute=obj[0].EndTime.split(':')[1];
        }
      },
      err => { }
    );
  }
  GetDeviceData(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.Object.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.DeviceData = obj;
        this.WaterSystem_Data=this.DeviceData.filter(d=>d.Type_ID==100);
        if(this.WaterSystem_Data.length>0){
          if(this.EquipVar_Data.WaterSystemID==null){
            this.EquipVar_Data.WaterSystemID=this.WaterSystem_Data[0].MAC_ID;
          }
        } 
        this.Chiller_Data=this.DeviceData.filter(d=>d.Type_ID==106);
        for(let i in this.Chiller_Data){
          if(this.EquipVar_Data.ChillerID.split(',').indexOf(String(this.Chiller_Data[i].MAC_ID))>-1)
            this.Chiller_Data[i]["checked"]=true;
          else
            this.Chiller_Data[i]["checked"]=false;
        }

        this.CHP_Data=this.DeviceData.filter(d=>d.Type_ID==102);
        for(let i in this.CHP_Data){
          if(this.EquipVar_Data.SCHWPGroup_ID.split(',').indexOf(String(this.CHP_Data[i].MAC_ID))>-1)
            this.CHP_Data[i]["checked"]=true;
          else
            this.CHP_Data[i]["checked"]=false;
        }

        this.CT_Data=this.DeviceData.filter(d=>d.Type_ID==104);
        for(let i in this.CT_Data){
          if(this.EquipVar_Data.CTowerGroup_ID.split(',').indexOf(String(this.CT_Data[i].MAC_ID))>-1)
            this.CT_Data[i]["checked"]=true;
          else
            this.CT_Data[i]["checked"]=false;
        }
      },
      err => { console.log(err); }
    );
  }

  onDeviceItemCheck(item,event){
    item["checked"]=event.target.checked;
  }

  onWholeDay(event){
    if(event.target.checked){
      this.EquipVar_Data.WholeDay="1";
      this.Select_StartHour="00";
      this.Select_StartMinute="00";
      this.Select_EndHour="23";
      this.Select_EndMinute="55";
    }
    else{
      this.EquipVar_Data.WholeDay="0";
    }
  }
  all_month:any=true;
  onAll_Month(event){
    if(event.target.checked){
      for(let i in this.Month_Period_Source){
        this.Month_Period_Source[i].enable=true;
      }
      this.EquipVar_Data.Sch_Month="1,2,3,4,5,6,7,8,9,10,11,12";
    }
  }
 
  MonthChange(e){
    if(e=="1,2,3,4,5,6,7,8,9,10,11,12")
      this.all_month=true;
    else
      this.all_month=false;
  }
  all_week:any=true;
  onAll_Operation(event){
    if(event.target.checked){
      for(let i in this.Operation_Period_Source){
        this.Operation_Period_Source[i].enable=true;
      }
      this.EquipVar_Data.Sch_Week="1,2,3,4,5,6,7";
    }
  }

  OperationChange(e){
    if(e=="1,2,3,4,5,6,7")
      this.all_week=true;
    else
      this.all_week=false;
  }

  clicked(){

    if (this.EquipVar_Data.Sch_Month=="")
    {
        alert("請選擇月份");
        return false;
    }
    if (this.EquipVar_Data.Sch_Week=="")
    {
        alert("請選擇週天數");
        return false;
    }
    this.EquipVar_Data.ChillerID=this.Chiller_Data.filter(d=>d.checked).map(d=>d.MAC_ID).join(",");
    this.EquipVar_Data.SCHWPGroup_ID=this.CHP_Data.filter(d=>d.checked).map(d=>d.MAC_ID).join(",");
    this.EquipVar_Data.CTowerGroup_ID=this.CT_Data.filter(d=>d.checked).map(d=>d.MAC_ID).join(",");

    if (this.EquipVar_Data.ChillerID == "" && this.EquipVar_Data.SCHWPGroup_ID == "" && this.EquipVar_Data.CTowerGroup_ID == "")
    {
      alert("冰機、二次冰水泵組或水塔組至少要選擇一組，請重新設定!");
      return false;
    }
    //冰機
    if (this.EquipVar_Data.ChillerID != "")
    {
      let CheckData=[
      {name:"冰機出水溫設定值",min:6,max:15,value:this.EquipVar_Data.Chiller_To_SP },
      {name:"冰水溫差目標值",min:0,max:8,value:this.EquipVar_Data.Chwp_dT_SP },
      {name:"冷卻水溫差目標值",min:0,max:8,value:this.EquipVar_Data.Cwp_dT_SP }];
      let msg="";
      CheckData.forEach(d => {
          if(d.value==""){
            msg+="請輸入"+d.name+"\r\n";
          }else if(this.MyCommon.CheckNumber(d.value)!=d.value){
            msg+=d.name+"非數字\r\n";
          }else{
            if(Number(d.value)<d.min||Number(d.value)>d.max){
              msg+=d.name+"超出"+d.min+"~"+d.max+"範圍\r\n";
            }
          }
      });
      if (msg!="")
      {
        alert(msg);
        return false;
      }
    }
    else
    {
      this.EquipVar_Data.Chiller_To_SP = "不用設定";
      this.EquipVar_Data.Chwp_dT_SP = "不用設定";
      this.EquipVar_Data.Cwp_dT_SP = "不用設定";
    }
    //二次冰水泵組部分
    if (this.EquipVar_Data.SCHWPGroup_ID != "")
    {
        let CheckData=[
        {name:"場域壓差目標值",min:0,max:5,value:this.EquipVar_Data.Field_P_SP },
        {name:"場域壓差下限值",min:0,max:5,value:this.EquipVar_Data.Field_P_LL },
        {name:"場域水溫上限值",min:5,max:25,value:this.EquipVar_Data.Field_T_HL }];
        let msg="";
        CheckData.forEach(d => {
          if(d.value==""){
            msg+="請輸入"+d.name+"\r\n";
          }else if(this.MyCommon.CheckNumber(d.value)!=d.value){
            msg+=d.name+"非數字\r\n";
          }else{
            if(Number(d.value)<d.min||Number(d.value)>d.max){
              msg+=d.name+"超出"+d.min+"~"+d.max+"範圍\r\n";
            }
          }
        });
        if (msg!="")
        {
          alert(msg);
          return false;
        }
    }
    else
    {
      this.EquipVar_Data.Field_P_SP = "不用設定";
      this.EquipVar_Data.Field_P_LL = "不用設定";
      this.EquipVar_Data.Field_T_HL = "不用設定";
    }
     //水塔
     if (this.EquipVar_Data.CTowerGroup_ID != "")
     {
      if (this.EquipVar_Data.CTower_SetMode== "0"){
        let CheckData=[
          {name:"固定趨近溫度值",min:-3,max:10,value:this.EquipVar_Data.CTower_ApprTemp },];
          let msg="";
          CheckData.forEach(d => {
            if(d.value==""){
              msg+="請輸入"+d.name+"\r\n";
            }else if(this.MyCommon.CheckNumber(d.value)!=d.value){
              msg+=d.name+"非數字\r\n";
            }else{
              if(Number(d.value)<d.min||Number(d.value)>d.max){
                msg+=d.name+"超出"+d.min+"~"+d.max+"範圍\r\n";
              }
            }
          });
          if (msg!="")
          {
            alert(msg);
            return false;
          }
        }
     }
     else
     {
      this.EquipVar_Data.CTower_ApprTemp = "不用設定";
     }
     this.EquipVar_Data.StartTime=this.Select_StartHour+":"+this.Select_StartMinute;
     this.EquipVar_Data.EndTime=this.Select_EndHour+":"+this.Select_EndMinute;
    if (confirm("確定要修改嗎?")) {
        this.Update();
     }
  }

  Update(){
     let sbody = {
      "EquipVarID":this.EquipVar_Data.EquipVarID,
      "WaterSystemID":this.EquipVar_Data.WaterSystemID,
      "ChillerID":this.EquipVar_Data.ChillerID,
      "Chiller_To_SP":this.EquipVar_Data.Chiller_To_SP,
      "Chwp_dT_SP":this.EquipVar_Data.Chwp_dT_SP,
      "Cwp_dT_SP":this.EquipVar_Data.Cwp_dT_SP,
      "SCHWPGroup_ID":this.EquipVar_Data.SCHWPGroup_ID,
      "Field_P_SP":this.EquipVar_Data.Field_P_SP,
      "Field_P_LL":this.EquipVar_Data.Field_P_LL,
      "Field_T_HL":this.EquipVar_Data.Field_T_HL,
      "CTowerGroup_ID":this.EquipVar_Data.CTowerGroup_ID,
      "CTower_SetMode":this.EquipVar_Data.CTower_SetMode,
      "CTower_ApprTemp":this.EquipVar_Data.CTower_ApprTemp,
      "Sch_Month":this.EquipVar_Data.Sch_Month,
      "Sch_Week":this.EquipVar_Data.Sch_Week,
      "StartTime":this.EquipVar_Data.StartTime,
      "EndTime":this.EquipVar_Data.EndTime,
      "WholeDay":this.EquipVar_Data.WholeDay,
      Key:localStorage.getItem("Token")
    };
    let stralert = this.ScheduleEquipVar.UpdateData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('修改成功');
        this.router.navigate(['/ibas/ChillerParameter']);
      },
      err => {  },
      () => { }
    );
  }

  back() {
    this.router.navigate(['/ibas/ChillerParameter']);
  }
}
