export class ScheduleColumn {
  constructor(
    public E_ID: number,
    public Start_Date: Date,
    public End_Date: Date,
    public Operation_Period: string,
    public Start_Time:Date,
    public End_Time:Date,
    public ModeType:number,
    public CdnCtrlMode:string,
    public Week_Interval:number,
    public SC_MAC:number,
    public SC_S:number,
    public SetValue:any,
    public SC_Enabled:string,
    public CtrlCondition:string,
    public Condition_Data:string
  ) { }
}