import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class IBas_Layout_ObjectService {

constructor(@Inject(HttpClient)  
            private http : HttpClient,
            public MyCommon:MyCommon) { }

    GetDataList(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetLayoutObject');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetDataAll(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetLayoutObjectDataAll');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('GetLayoutObjectData');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    InsertData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('InsertLayoutObject');
            return this.http.post(url,sbody,{headers: headers})
            .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('UpdateLayoutObject');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    DeleteData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('DeleteLayoutObject');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

}