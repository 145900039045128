import { Component, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router } from '@angular/router';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { IBas_HistoryService} from '../../../DataService';
import { DateTimeProvider } from '../../../App_Code/datetime';

@Component({
  selector: 'app-SystemLayoutChart',
  templateUrl: './SystemLayoutChart.component.html',
  providers: [ MyCommon,DateTimeProvider,IBas_HistoryService]
})
export class SystemLayoutChartComponent implements OnInit {

  //預設欄位
  Data_MAC_S:string;
  IOType:string;
  AttribData:string;
  Title:string;

  constructor(private activeModal: NgbActiveModal,
             private MyCommon: MyCommon,
             private History: IBas_HistoryService,
             private router: Router,
             private DateTime: DateTimeProvider,
            ) { }

  ngOnInit() {
    
    if(this.AttribData.length>0){
      if(this.AttribData[0]["Tag_Name"]!=null){
        this.Title=this.AttribData[0]["S_Name"]+'-'+this.AttribData[0]["Tag_Name"].replace("SID","");
      }else if(this.AttribData[0]["Attrib_Name_TC"]!=null){
        this.Title=this.AttribData[0]["S_Name"]+'-'+this.AttribData[0]["Attrib_Name_TC"].replace("SID","");
      }else if(this.AttribData[0]["Attrib_Name"]!=null){
        this.Title=this.AttribData[0]["S_Name"]+'-'+this.AttribData[0]["Attrib_Name"].replace("SID","");
      }
    }
    this.GetChart();
  }

  GetChart(){
     let sbody = {
       IO_MAC_S: this.AttribData[0]["MAC_ID"]+"-"+this.AttribData[0]["S_Num"],
       StartTime:this.DateTime.Formate(this.DateTime.AddHour(new Date(),-1),"yyyy/MM/dd HH:mm"),
       EndTime: this.DateTime.Formate(new Date(),"yyyy/MM/dd HH:mm"),
       IntervalMinute: 1,
       Key:localStorage.getItem("Token")
     };
     this.data=[];
     this.History.GetHistoryChart(sbody, this.MyCommon.SetHeaders()).subscribe(
       (obj) => {
         this.MyCommon.CheckToken(obj);
         this.data = obj;
         this.Chart();
         /*
         this.Status="";*/
       },
       err => { }
     );

  }

   data:any=[];
   SetStep:any=[];

   Series:any=[];
   CategoryItem:any=[];
   Chart(){
    this.CategoryItem=this.data.map(d=>this.MyCommon.FormateTime2(new Date(d["DT"])));
    this.SetStep=parseInt(String(this.CategoryItem.length/4));

    let  LineItem={
    name: this.Title
    ,data:this.data.map(d=>d[this.AttribData[0]["Data_Mac"]+"-S"+this.AttribData[0]["Data_S"]])
    ,type:"line"};
    this.Series.push(LineItem);
   }
   AddChart(){

    //讀出IOList
    
    let IOList=[];
    if(localStorage.getItem("IOList")!=null&&localStorage.getItem("IOList")!="")
      IOList=JSON.parse(localStorage.getItem("IOList"));

    //增加選擇IO
    IOList.push({ id: this.AttribData[0]["Data_Mac"]+"-"+this.AttribData[0]["Data_S"], name: this.Title });
    //存入localStorage
    localStorage.setItem("IOList", JSON.stringify(IOList));
    alert('已完成資料選取');
    this.activeModal.close();
   }
  //隱藏Popup
  back() {
    this.activeModal.close();
  }

 
}
