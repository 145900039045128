import { Component, OnInit,Inject ,Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router } from '@angular/router';
import { IOService,DevService,SystemService } from '../../../DataService';
@Component({
  selector: 'app-MeterDemandCtrlSetting',
  templateUrl: './MeterDemandCtrlSetting.component.html',
  //styleUrls: ['./MeterDemandCtrlSetting.component.css'],
  providers: [MyCommon,IOService,SystemService,DevService]
})
export class MeterDemandCtrlSettingComponent implements OnInit {

//  //預設欄位
//   public SetCtrlType:number;
//   PopType:number;
//   RealTimeColumn = new RealTimeColumn('', '', '', '', '');
//   //判斷是否能夠控制數值
//   IsDisabled:any=true;
DevSource : any;
IO_Source : any;
Snr_Dev_Source : any;
Snr_IO_Source : any;
Snr_MAC_ID:any='0';
Snr_IO_S:any='';
Ctrl_Dev_Source : any;
Ctrl_IO_Source : any;
Ctrl_MAC_ID:any='0';
Ctrl_IO_S:any='';

Ctrl_IO_Type:any='AI';

CtrlSetValue:any='0';
  private DateList:any=[];
  constructor(private activeModal: NgbActiveModal,
              private MyCommon: MyCommon,
              private router: Router,
              private DevData: DevService,
              private IOData: IOService,
            ) { }

  ngOnInit() {
    //帶入資料
    //this.BindPopup();
    this.GetDevData();
    //this.GetIOData();
  }

  GetDevData(){
    let sbody = {
      TopN: " ",
      StrWhere: " where DataType=0",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.DevData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.DevSource=obj;
        this.GetIOData();
      },
      err => { console.log(err); }
    );
  }
 //取得IO資料
 GetIOData() {
    var StrWhere = "  ";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key: localStorage.getItem("Token")
    };
    this.IOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.IO_Source = obj;
        //監測Device
        let Snr_MAC_List=this.IO_Source.filter(d=>["AI","DI"].indexOf(d.IO_Type)>-1).map(d=>d.IO_MAC);
        this.Snr_Dev_Source=this.DevSource.filter(d=>Snr_MAC_List.indexOf(d.MACID)>-1);
        if(this.Snr_Dev_Source.length>0){
          this.Snr_MAC_ID=this.Snr_Dev_Source[0].MACID;
        }
        //控制Device
        let Ctrl_MAC_List=this.IO_Source.filter(d=>["VAO","VDO","AO","DO"].indexOf(d.IO_Type)>-1).map(d=>d.IO_MAC);
        this.Ctrl_Dev_Source=this.DevSource.filter(d=>Ctrl_MAC_List.indexOf(d.MACID)>-1);
        if(this.Ctrl_Dev_Source.length>0){
          this.Ctrl_MAC_ID=this.Ctrl_Dev_Source[0].MACID;
        }
        if(this.IO_Source.length>0){
          this.Snr_IO_Source=this.IO_Source.filter(d=>d.IO_MAC==this.Snr_MAC_ID && ["AI","DI"].indexOf(d.IO_Type)>-1);
          if(this.Snr_IO_Source.length>0){
            this.Snr_IO_S=this.Snr_IO_Source[0].IO_MAC+'-'+this.Snr_IO_Source[0].IO_S;
          }
          this.Ctrl_IO_Source=this.IO_Source.filter(d=>d.IO_MAC==this.Ctrl_MAC_ID && ["VAO","VDO","AO","DO"].indexOf(d.IO_Type)>-1);
          if(this.Ctrl_IO_Source.length>0){
            this.Ctrl_IO_S=this.Ctrl_IO_Source[0].IO_MAC+'-'+this.Ctrl_IO_Source[0].IO_S;
            this.Ctrl_IO_Type=this.Ctrl_IO_Source[0].IO_Type;
          }
        }
      },
      err => { console.log(err); }
    );
  }
  onSnrDevChange(){
    if(this.IO_Source.length>0){
      this.Snr_IO_Source=this.IO_Source.filter(d=>d.IO_MAC==this.Snr_MAC_ID && ["AI","DI"].indexOf(d.IO_Type)>-1);
      if(this.Snr_IO_Source.length>0){
        this.Snr_IO_S=this.Snr_IO_Source[0].IO_MAC+'-'+this.Snr_IO_Source[0].IO_S;
      }
    }
  }

  onCtrlDevChange(){
    if(this.IO_Source.length>0){
      this.Ctrl_IO_Source=this.IO_Source.filter(d=>d.IO_MAC==this.Ctrl_MAC_ID && ["VAO","VDO","AO","DO"].indexOf(d.IO_Type)>-1);
      if(this.Ctrl_IO_Source.length>0){
        this.Ctrl_IO_S=this.Ctrl_IO_Source[0].IO_MAC+'-'+this.Ctrl_IO_Source[0].IO_S;
        this.Ctrl_IO_Type=this.Ctrl_IO_Source[0].IO_Type;
      }
    }
  }
  onCtrlIOChange(){
   let  Type=this.Ctrl_IO_Source.filter(d=>(d.IO_MAC+'-'+d.IO_S)==this.Ctrl_IO_S).map(d=>d.IO_Type);
   this.Ctrl_IO_Type=Type[0];
  }
  onAdd(){
    let item={};
    item["Ctrl_IOID"]=this.Ctrl_IO_S;
    item["Snr_IOID"]=this.Snr_IO_S;
    item["CtrlSetValue"]=this.CtrlSetValue;
    let IO=this.Ctrl_IO_Source.filter(d=>(d.IO_MAC+'-'+d.IO_S)==this.Ctrl_IO_S);
    if(IO.length>0){
      item["DevName"]=IO[0].DisplayName.split("-")[0];
      item["DisplayName"]=IO[0].DisplayName.split("-")[0]+"["+IO[0].DisplayName.split("-")[1]+"]";
      item["IO_Type"]=IO[0].IO_Type;
    }else{
      item["DevName"]="";
      item["DisplayName"]="";
      item["IO_Type"]="";
    }
  
    this.activeModal.close(item);
  }
   //隱藏Popup
  back() {
    this.activeModal.close();
  }
 

}