import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class iBas_ScheduleIOService {

constructor(@Inject(HttpClient)  
            private http : HttpClient,
            public MyCommon:MyCommon) { }

    GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('ScheduleIO');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

    InsertData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('InsertScheduleIO');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('UpdateScheduleIO');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

    DeleteData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('DeleteScheduleIO');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

}