import { HashLocationStrategy, LocationStrategy,CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { MenuModule } from '@progress/kendo-angular-menu';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { PagesModule } from './Page/pages.module';
import { iBas_PagesModule } from './iBAS_Page/ibas_pages.module';


// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
   imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      HttpClientModule,
      TranslateModule.forRoot(),
      AppRoutingModule,
      MenuModule,
      ButtonsModule,
      PagesModule,
      iBas_PagesModule
   ],
   declarations: [
      AppComponent
   ],
   bootstrap: [
      AppComponent
   ],
   providers: [
     
   ]
})
export class AppModule {}
