import { Component, OnInit, Inject } from '@angular/core';
import { SystemService } from '../../../DataService/System.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-RealConfig',
  templateUrl: './RealConfig.component.html',
  styleUrls: ['./RealConfig.component.scss'],
  providers: [SystemService, MyCommon]
})
export class RealConfigComponent implements OnInit {


  public sysconfigData:any;

  constructor(
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
    //取得sysconfig.ini的內容
    let sbody = {
        FilePath:"",
        Key: localStorage.getItem("Token")
      };
      this.SystemData.ReadConfig(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.sysconfigData=obj;
        },
        err => { }
      );
  }

  clicked(){
      //修改sysconfig.ini的內容
      let sbody=this.sysconfigData;
      this.SystemData.WriteConfig(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
         alert(obj);
        },
        err => { }
      );
  }

  //控制欄位隱藏
  IsShow(Value){
    var Enabled=true;
    switch (Value)
    {
      case "":
        Enabled = false;
        break;
      case "Exec_SQL":
        Enabled = false;
        break;
      case "Exec_Zigbee":
        Enabled = false;
        break;
      case "Exec_ModbusRS485":
        Enabled = false;
        break;
      case "Exec_ModbusTCP":
        Enabled = false;
        break;
      case "EmbODBCName": 
        Enabled=false; 
        break;
      case "MasterODBCName": 
        Enabled=false; 
        break;
      case "RunWF": 
        Enabled=false; 
        break;
      case "RunPowCp": 
        Enabled=false; 
        break;
      case "RunSC": 
        Enabled=false; 
        break;
      case "RunAlarm": 
        Enabled=false;
        break;
      case "RunConnectStatus": 
        Enabled=false;
        break;
      case "RunUpoadToRemote": 
        Enabled=false;
        break;
      case "RunKeepDataSize": 
        Enabled=false;
        break;
      case "RunAlarmSend": 
        Enabled=false;
        break;
      case "SendMsg_OnlyOnce": 
        Enabled=false; 
        break;
      case "SendMsg_Sec": 
        Enabled=false; 
        break;
      case "DisConn_Sec": 
        Enabled=false; 
        break;
      case "TaskInterval_Sec": 
        Enabled=false; 
        break;
      case "KeepMonth": 
        Enabled=false; 
        break;
      case "KeepDT": 
        Enabled=false; 
        break;
    }
    return Enabled;
  }

   //控制欄位隱藏
   IsShowRadio(Value){
    var Enabled=false;
    switch (Value)
    {
      case "Exec_SQL":
        Enabled = true;
        break;
      case "Exec_Zigbee":
        Enabled = true;
        break;
      case "Exec_ModbusRS485":
        Enabled = true;
        break;
      case "Exec_ModbusTCP":
        Enabled = true;
        break;
    }
    return Enabled;
  }

  /*
  SetData(Data,index){
    var DataList=Data.split('=');
    return DataList[index];
    
  }*/


}
