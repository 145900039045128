import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { GridDataResult, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { ReportService } from '../../../DataService/Report.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';

@Component({
  selector: 'app-Report',
  templateUrl: './Report.component.html',
  //styleUrls: ['./Report.component.css'],
  providers: [ReportService,Permissions, MyCommon]
})
export class ReportComponent implements OnInit {

//DatePick設定
   StartDate: any='2017/01/01 00:00';


  Report_Source:any;
  public gridView: GridDataResult
  public sort: SortDescriptor[] = [];
  public ColSize: number = 100;
  public ShowDayGrid:any = false;
  public ShowMonthGrid:any = false;
  public ShowYearGrid:any = false;

  public FileNameDay:any='';
  public FileNameMonth:any='';
  public FileNameYear:any='';

  public ParameterRptID: any;
  public ReportType:any= [{ id: 1, name: '日報表' }, { id: 2, name: '月報表' }, { id: 4, name: '年報表' }];
  public ParameterYMD: any;

  public gridView1: GridDataResult;
  public gridView2: GridDataResult;
  public gridView3: GridDataResult;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  constructor(
    @Inject(ReportService) private ReportData: ReportService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
    //載入預設日期
    var sNowDate = new Date();
    this.StartDate =new Date(sNowDate.getFullYear()+'/'+String(Number(sNowDate.getMonth()) + 1)+'/'+ sNowDate.getDate()+' 00:00');
    this.ParameterYMD=1;
    this.SetReport();
    this.ShowDayGrid = false;
    this.ShowMonthGrid = false;
    this.ShowYearGrid = false;
    this.ColSize=100;

    
      //判斷頁面權限
      let sbody = {
        UrlList:"Report",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="Report")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );
  }
 
  //排序
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    //this.isToken();
    this.GetData();
  }
  //取得資料
  SetReport() {
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.ReportData.GetData(sbody,this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
       this.Report_Source=obj;
       this.ParameterRptID=obj[0]["Rpt_ID"];
      },
      err => { console.log(err); }
    );
  }

  public ShowToExcel(): void {
    this.GetData();
  }

  GetData(){

  var DateList=[this.StartDate.getFullYear(),this.StartDate.getMonth(),this.StartDate.getDate()]
  this.FileNameDay=DateList["0"]+DateList["1"]+DateList["2"]+'日報表.xlsx';
  this.FileNameMonth=DateList["0"]+DateList["1"]+'月報表.xlsx';
  this.FileNameYear=DateList["0"]+'年報表.xlsx';

  let sbody = {
        RptID: this.ParameterRptID,
        ReportDate: this.StartDate,
        YMD:  this.ParameterYMD,
        Key:localStorage.getItem("Token")
      };

      this.ReportData.GetIOExport(sbody,this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.ShowDayGrid = false;
          this.ShowMonthGrid = false;
          this.ShowYearGrid = false;
          if(this.ParameterYMD==1){
            this.ShowDayGrid = true;
            this.gridView1 = {
              data: orderBy(obj, this.sort),
              total: obj.length
            };
          }
          if(this.ParameterYMD==2){
            this.ShowMonthGrid = true;
            this.gridView2 = {
              data: orderBy(obj, this.sort),
              total: obj.length
            };
          }
          if(this.ParameterYMD==4){
              this.ShowYearGrid = true;
              this.gridView3 = {
              data: orderBy(obj, this.sort),
              total: obj.length
            };
          }
        },
        err => { console.log(err); }
      );
  }

  public exportToExcel(grid: GridComponent): void {

    grid.saveAsExcel();
  }


}