import { Component, OnInit,Inject ,Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RealTimeColumn } from '../RealTimeColumn'
import { RealTimeService } from '../../../DataService/RealTime.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router } from '@angular/router';
import { IOCtrlSettingService } from '../../../DataService/IOCtrlSetting.service';

@Component({
  selector: 'app-RealTimeSetting',
  templateUrl: './RealTimeSetting.component.html',
  //styleUrls: ['./RealTimeSetting.component.css'],
  providers: [RealTimeService, MyCommon,IOCtrlSettingService]
})
export class RealTimeSettingComponent implements OnInit {

 //預設欄位
  public SetCtrlType:number;
  PopType:number;
  RealTimeColumn = new RealTimeColumn('', '', '', '', '');
  //判斷是否能夠控制數值
  IsDisabled:any=true;
  private DateList:any=[];
  constructor(private activeModal: NgbActiveModal,
              @Inject(RealTimeService) private RealTimeData: RealTimeService,
              @Inject(MyCommon) private MyCommon: MyCommon,          
              @Inject(IOCtrlSettingService) private IOCtrlSettingData: IOCtrlSettingService,
              private router: Router
            ) { }

  ngOnInit() {
    //帶入資料
    this.BindPopup();
  }
 
  BindPopup(){
    //自動控制類型
    if(this.RealTimeColumn.SetCtrlType=="0")
      this.PopType=0;
    //手動控制類型  
    if(this.RealTimeColumn.SetCtrlType=="1")
      this.PopType=1;
    //條件類型  
    if(this.RealTimeColumn.SetCtrlType=="3")
      this.PopType=2;
    //排程類型
    if(this.RealTimeColumn.SetCtrlType=="2"||this.RealTimeColumn.SetCtrlType=="4")
      this.PopType=3;
    //VAO-VDO
    if(this.RealTimeColumn.IO_Type=='VAO' || this.RealTimeColumn.IO_Type=='VDO')
      this.PopType=4;
        
    if (this.PopType==0||this.PopType==2||this.PopType==3||this.PopType==4)
      this.IsDisabled=true;
    else
      this.IsDisabled=false;
  /*自動抓現在值
  手動顯示可設定數值
  排程顯示前三筆與可設定數值
  條件顯示可設定數值
  排程條件顯示前三筆與可設定數值*/

  }
  
//隱藏Popup
  back() {
    this.activeModal.close();
  }
  //儲存設定
  onCtrl() {
    let sbody = {
      IO_MAC: this.RealTimeColumn.IO_MAC,
      IO_S: this.RealTimeColumn.IO_S,
      SetValue: this.RealTimeColumn.SetValue,
      SetCtrlType: this.RealTimeColumn.SetCtrlType,
      Key:localStorage.getItem("Token")
    };
    //修改IO_Info
    var stralert = this.RealTimeData.UpdateRealTimeData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
            this.activeModal.close();
      },
      err => { alert('修改失敗'); console.log(err); },
      () => { }
    );
  }

  GotoCtrlIOSetting(){
    localStorage.setItem('DevID', this.RealTimeColumn.IO_MAC+"-S"+this.RealTimeColumn.IO_S);
    localStorage.setItem('ModeType', this.RealTimeColumn.SetCtrlType);
    this.activeModal.close();
    this.router.navigate(['/pages/Schedule/']);
  }

  SetCtrlTypeClick(){
    this.BindPopup();
    if (this.RealTimeColumn.SetCtrlType=='1')
      this.IsDisabled=false;
    else
      this.IsDisabled=true;
  }

}