import { Component, OnInit, Inject, ViewChild  } from '@angular/core';
import { HistoryService } from '../../../DataService/History.service';
import { SystemService } from '../../../DataService/System.service';
import { IOService } from '../../../DataService/IO.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import 'hammerjs';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Permissions } from '../../../DataService/User.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-History',
  templateUrl: './History.component.html',
  styleUrls: ['./History.component.scss'],
  providers: [HistoryService, SystemService,Permissions, IOService, MyCommon]
})
export class HistoryComponent implements OnInit {

  //參數
  SYS_Source: any;
  IO_Source: any;
  //Interval_Source: any = [{ name: '1分鐘', value: '1' }, { name: '3分鐘', value: '3' }, { name: '5分鐘', value: '5' }, { name: '15分鐘', value: '15' },
  //{ name: '30分鐘', value: '30' }, { name: '60分鐘', value: '60' }];
  Interval_Source: any = [{ name: '1分鐘', value: '1' }, { name: '15分鐘', value: '15' },{ name: '30分鐘', value: '30' }, { name: '60分鐘', value: '60' }];
  public SYS_ID: any = '-1';
  public IO_Data: any = '-1';
  public interval: any = '1';
  public SetStep:number=30;
  data: any;
  exportdata:any;

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;

  public ChartData: Array<any> = [
    { data: [], label: '' }
  ];
  public ChartLabels: Array<any> = [];
  public ChartEnable: any = "0";


  //DatePick設定
  StartDate: any = '2017/01/01 00:00';
  EndDate: any = '2017/01/01 00:00';
  ReportStartDate: any = '2017/01/01 00:00';
  ReportEndDate: any = '2017/01/01 00:00';
  //選擇IO
  Select_IO = [];
  Search_IO: any = "";
  //chart設定
  //labels="{ visible: true }";//"{ rotation: '-25',step:3 }";
  //majorGridLines:any="";//"{ visible: false,step:20 }";
  //majorTicks:any="";//{ visible: true }";
  series: any;
  SeriesData=[];
  step:any=1;

innerWidth: number;

Status:string;

  constructor( @Inject(HistoryService) private HistoryData: HistoryService,
    @Inject(SystemService) private SystemData: SystemService,
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(IOService) private IOData: IOService,
    @Inject(MyCommon) private MyCommon: MyCommon) { 
    
    }

  ngOnInit() {
    //讀出IOList
    this.Select_IO=JSON.parse(localStorage.getItem("IOList"));
    if (this.Select_IO == null){
      this.Select_IO=[];
    }
    //載入預設日期
    var sNowDate = new Date();
    this.StartDate = new Date(sNowDate.getFullYear() + '/' + String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
    this.ReportStartDate = new Date(sNowDate.getFullYear() + '/' + String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
    //單位為一天的時間
    var DiffDay = (24 * 60 * 60 * 1000) * 1;
    var sDiffDate = new Date(sNowDate.getTime() + DiffDay);
    this.EndDate = new Date(sDiffDate.getFullYear() + '/' + String(Number(sDiffDate.getMonth()) + 1) + '/' + sDiffDate.getDate() + ' 00:00');
    this.ReportEndDate = new Date(sDiffDate.getFullYear() + '/' + String(Number(sDiffDate.getMonth()) + 1) + '/' + sDiffDate.getDate() + ' 00:00');

      //判斷頁面權限
      let sbody = {
        UrlList:"History",
        Key:localStorage.getItem("Token")
      };

      var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
      stralert.subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          for (let Site of obj) {
              if(Site.path=="History")
                  this.ViewPermissions=true;
          }
          try{
            this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
          }catch(err){}
        },
        err => {  },
        () => { }
      );

    //載入系統資料
    this.GetSysData();
  }
  //切換時間
  ChangeStartDate() {
    this.ReportStartDate=this.StartDate;
  }
  ChangeEndDate() {
    this.ReportEndDate=this.EndDate;
  }
  //增加查詢IO
  AddIO() {
    if (this.IO_Data == -1) {
      alert("請選擇裝置!")
    }
    else {
  //判斷是否重複
  var CheckIO=this.Select_IO.filter(
  task => task.id === this.IO_Data);
  if (CheckIO.length==0)
  {
      //篩選IO資料
      var IO_filter = this.IO_Source.filter(
        task => task.IO_ID === this.IO_Data);
      //增加選擇IO
      this.Select_IO.push({ id: this.IO_Data, name: IO_filter[0].DisplayName });
      //存入localStorage
      localStorage.setItem("IOList",JSON.stringify(this.Select_IO));
      }
    }
  }
  //刪除查詢IO
  DelIO(id: any) {
    var IO_filter = this.Select_IO.filter(
      task => task.id != id);
    this.Select_IO = IO_filter;
    //存入localStorage
    localStorage.setItem("IOList",JSON.stringify(this.Select_IO));
  }
  //刪除全部查詢IO
  AllDel(){
    localStorage.removeItem("IOList");
    this.Select_IO=[];
  }

  //取得系統資料
  GetSysData() {
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.SystemData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.SYS_Source = obj;
        this.GetIOData();
      },
      err => { console.log(err); }
    );
  }

  //取得IO資料
  GetIOData() {
    var StrWhere = " where IO_Info.SYS_ID='" + this.SYS_ID + "'";
    let sbody = {
      TopN: " ",
      StrWhere: StrWhere,
      Sort: " ",
      Key:localStorage.getItem("Token")
    };

    this.IOData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.IO_Source = obj;
      },
      err => { console.log(err); }
    );
  }
  SYSChange() {
    this.IO_Data = -1;
    this.GetIOData();
  }

  IOChange() {

  }

//判斷小時和分鐘補足兩位
  FormatDate(val: any) {
    if (String(val).length == 1)
      return "0" + val;
    else
      return val;
  }

  //顯示圖表
  showChart() {
    if (this.Select_IO.length > 0) {

      //組查詢IO
      this.Search_IO = "";
      for (let i in this.Select_IO) {
        this.Search_IO += this.Select_IO[i].id + ",";
      }
      this.Search_IO = this.Search_IO.slice(0, -1);

      //打開chart
      this.ChartEnable = '1';
      let Day=Math.abs(Number(this.EndDate)-Number(this.StartDate))/(1000*60*60*24);
      if((this.interval==1 || this.interval==3|| this.interval==5) && Day>31){
        alert("查詢時間範圍不可大於31天");
        return;
      }
      let sbody = {
        IO_MAC_S: this.Search_IO,
        StartTime:this.MyCommon.FormateDateTime(this.StartDate),
        EndTime: this.MyCommon.FormateDateTime(this.EndDate),
        IntervalMinute: this.interval,
        Key:localStorage.getItem("Token")
      };
      this.Status="查詢中";
      this.data="";
      this.HistoryData.GetHistoryChart(sbody, this.MyCommon.SetHeaders()).subscribe(
        (obj) => {
          this.MyCommon.CheckToken(obj);
          this.data = obj;
          this.Chart();
          this.Status="";
        },
        err => { }
      );

    }
    else {
      alert("請選擇裝置!")
    }
  }

Chart(){
  this.SeriesData=[];
  //var spliIO = this.Search_IO.split(',');
  var IOCount = 0;
    IOCount = this.Select_IO.length;
  //var dataCount=0;

  for (var i = 0; i < this.data.length; i++) {
    //for (let j in spliIO) {
    for (let j in this.Select_IO) {
      var labeleList=this.data[i]["DT"].split(' ');
      var labele=labeleList[0]+"\n"+labeleList[1];
      //this.SeriesData.push({ interval: labele, service: spliIO[j],value: this.data[i][spliIO[j]]});
      this.SeriesData.push({ interval: labele, service: this.Select_IO[j].id,value: this.data[i][this.Select_IO[j].id]});
    }
  }
  
   this.series = groupBy(this.SeriesData, [{ field: "service"}]);
   //將Legend名稱轉成Io名稱
   var i=0;
   for (var item of this.series){
    item.value=this.Select_IO[i].name;
    i++;
   }

  //計算lables的step
  this.SetStep=Math.round(this.data.length/6);
  this.ChartEnable = '1';
}


columns:any=[];
columnsName:any=[];
//輸出檔案
public exportToExcel(){
  if (this.Select_IO.length > 0) {
      let Day=Math.abs(Number(this.ReportEndDate)-Number(this.ReportStartDate))/(1000*60*60*24);
      if((this.interval==1 || this.interval==3|| this.interval==5) && Day>7){
        alert("匯出時間範圍不可大於7天");
        return;
      }
      if((this.data!=null && this.data.length!=0) && this.ReportStartDate>=this.StartDate && this.ReportEndDate<=this.EndDate){
        this.exportAsExcelFile( this.data,'_excel');
      }else{

        let sbody = {
          IO_MAC_S: this.Search_IO,
          StartTime:this.MyCommon.FormateDateTime(this.ReportStartDate),
          EndTime: this.MyCommon.FormateDateTime(this.ReportEndDate),
          IntervalMinute: this.interval,
          Key:localStorage.getItem("Token")
        };
        this.HistoryData.GetHistoryChart(sbody, this.MyCommon.SetHeaders()).subscribe(
          (obj) => {
            this.exportAsExcelFile(obj,'_excel');
          },
          err => { }
        );
    }
  }
 
  
}
  //建立Excel
  public exportAsExcelFile(json: any[], excelFileName: string): void {
   
     //取代名稱
    for (let i in this.Select_IO){
      json = JSON.parse(JSON.stringify(json).split('"'+this.Select_IO[i].id+'":').join('"'+this.Select_IO[i].name+'":'));

     }
 let Table_Name="IO趨勢查詢";
     //匯出設定
    const workBook = XLSX.utils.book_new(); // create a new blank book
    let SheetBook= XLSX.utils.book_new(); // create a new blank book
    let Sheet="{ '"+Table_Name+"':[]}";
    Sheet=Sheet.replace(/\'/g, '"');
    let SetSheet=[];
    SetSheet=JSON.parse(Sheet);
    SetSheet[Table_Name]=XLSX.utils.json_to_sheet(json);
    //const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    var wscols = [{wpx: 100}];
    SetSheet[Table_Name]['!cols'] = wscols ;

    SheetBook=Object.assign(SheetBook,SetSheet);
    XLSX.utils.book_append_sheet(workBook, SetSheet[Table_Name],Table_Name); // add the worksheet to the book

    let sNowDate = new Date();
    let fileName=Table_Name+sNowDate.getFullYear()+this.FormatDate(String(Number(sNowDate.getMonth()) + 1))+sNowDate.getDate()+this.FormatDate(sNowDate.getHours())+this.FormatDate(sNowDate.getMinutes())+"_excel_backup";
    XLSX.writeFile(workBook, fileName+'.xlsx');    
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.exportdata
    };
    return result;
  }

  ReplaceTitle(value){
  if (value != "DT"){
    var indexOfNumber=this.columns.indexOf(value);
    return this.columnsName[Number(indexOfNumber)-1];
  }
  else{
    return value;
  }
}
}