import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { IBas_ObjectService,IBas_ScheduleService } from '../../../../DataService';
import { MyCommon } from '../../../../App_Code/MyCommon';

@Component({
  selector: 'app-Chiller_Schedule_Add',
  templateUrl: './Chiller_Schedule_Add.component.html',
  styleUrls: ['./Chiller_Schedule_Add.component.scss'],
  providers: [IBas_ObjectService,IBas_ScheduleService, MyCommon]
})
export class Chiller_Schedule_AddComponent implements OnInit {
  DeviceData:any=[];
  WaterSystem_Data:any=[];
  Chiller_Data:any=[];
  CHP_Data:any=[];

  //每月設定資料
  Month_Period_Source =[{value:1,text:"1月",enable:true},
  {value:2,text:"2月",enable:true},
  {value:3,text:"3月",enable:true},
  {value:4,text:"4月",enable:true},
  {value:5,text:"5月",enable:true},
  {value:6,text:"6月",enable:true},
  {value:7,text:"7月",enable:true},
  {value:8,text:"8月",enable:true},
  {value:9,text:"9月",enable:true},
  {value:10,text:"10月",enable:true},
  {value:11,text:"11月",enable:true},
  {value:12,text:"12月",enable:true}];
  //每周設定資料
  Operation_Period_Source =
  [{ value: 1, text: "週一", enable: true },
  { value: 2, text: "週二", enable: true },
  { value: 3, text: "週三", enable: true },
  { value: 4, text: "週四", enable: true },
  { value: 5, text: "週五", enable: true },
  { value: 6, text: "週六", enable: true },
  { value: 7, text: "週日", enable: true }];

  Hour_Source:any=[];
  Minute_Source:any=[];

  /*int S_CWP_ID = 0;   //主要 ID, 不可重覆
    int nID = 0;   //相同情境下之排程ID
    string Schedule_Title = "";  //排程名稱
    string Start_Date = " 2019/03/07 13:10:00 ";  //起始日期與時間
    string End_Date = “ 2019/03/11 13:10:00”;   //結束日期與時間，也可為NULL代表無
    string Operation_Period = "1,2,3,4,5,6,7";   //周日數
    int Week_Interval = 0;  //周運轉頻率  
    int WholeDay=0;  //日時段之全日判斷，0代表無全日，1代表全日(不判斷起訖時間)  新增欄位
    string Start_Time = "09:00";   //日時段起始時間
    string End_Time = "19:50";    //日時段結束時間
    int Water_System = 100;  //水系統ID
    string Chiller = “103,105”; //冰機ID矩陣，也可為NULL代表無
    string Local_CHP = "null";  //二次冰水泵組ID矩陣，也可為NULL代表無
    int AutoChangeChiller = 0;  //是否有冰機加減機，，0代表無，1代表有
    int MasterChiller = 103;   //冰機加減機時的主冰機ID，若無則填0
*/
SC_Data:any={
  S_CWP_ID:null,
  nID:null,
  Schedule_Title:"",
  Start_Date:new Date("2019/03/07 13:10:00"),
  End_Date:new Date("2019/03/07 13:10:00"),
  Operation_Period:"",
  WholeDay:"0",
  Start_Time:null,
  End_Time:null,
  Water_System:null,
  Chiller:"",
  Local_CHP:"",
  Week_Interval:"0",
  AutoChangeChiller:"0",
  MasterChiller:"0",
  Sch_Month:""};

  EndDate_Enabled:any="0";
  Select_StartHour:any="00";
  Select_StartMinute:any="00";
  Select_EndHour:any="23";
  Select_EndMinute:any="59";

  constructor(private router: Router,
    private Object: IBas_ObjectService,
    private Schedule: IBas_ScheduleService,
    private MyCommon: MyCommon,
    ) { }

  ngOnInit() {
    this.Hour_Source=[];
    this.Minute_Source=[];
    for(let i=0;i<24;i++){
      this.Hour_Source.push({name:(i<10?"0"+String(i):String(i)),value:(i<10?"0"+String(i):String(i))});
    }
    for(let i=0;i<60;i++){
      this.Minute_Source.push({name:(i<10?"0"+String(i):String(i)),value:(i<10?"0"+String(i):String(i))});
    }

    //載入預設日期
    let sNowDate = new Date();
    let StartDate = new Date(sNowDate.getFullYear() + '/' +String(Number(sNowDate.getMonth()) + 1) + '/' + sNowDate.getDate() + ' 00:00');
    this.SC_Data.Start_Date =StartDate;
    //單位為一天的時間
    var DiffDay = (24 * 60 * 60 * 1000) * 7;
    var sDiffDate = new Date(sNowDate.getTime() + DiffDay);
    let EndDate = new Date(sDiffDate.getFullYear() + '/' + String(Number(sDiffDate.getMonth()) + 1) + '/' + sDiffDate.getDate() + ' 00:00');
    this.SC_Data.End_Date = EndDate;
    this.GetDeviceData();
  }

  GetDeviceData(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.Object.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.DeviceData = obj;
        this.WaterSystem_Data=this.DeviceData.filter(d=>d.Type_ID==100);
        if(this.WaterSystem_Data.length>0){
          if(this.SC_Data.Water_System==null){
            this.SC_Data.Water_System=this.WaterSystem_Data[0].MAC_ID;
          }
        } 
        this.Chiller_Data=this.DeviceData.filter(d=>d.Type_ID==106);
        for(let i in this.Chiller_Data){
          if(this.SC_Data.Chiller.split(',').indexOf(String(this.Chiller_Data[i].MAC_ID))>-1)
            this.Chiller_Data[i]["checked"]=true;
          else
            this.Chiller_Data[i]["checked"]=false;
        }
        
        this.CHP_Data=this.DeviceData.filter(d=>d.Type_ID==102);
        for(let i in this.CHP_Data){
          if(this.SC_Data.Local_CHP.split(',').indexOf(String(this.CHP_Data[i].MAC_ID))>-1)
            this.CHP_Data[i]["checked"]=true;
          else
            this.CHP_Data[i]["checked"]=false;
        }
      },
      err => { console.log(err); }
    );
  }
  onWholeDay(event){
    if(event.target.checked){
      this.SC_Data.WholeDay="1";
      this.Select_StartHour="00";
      this.Select_StartMinute="00";
      this.Select_EndHour="23";
      this.Select_EndMinute="55";
    }
    else{
      this.SC_Data.WholeDay="0";
    }
  }
  all_month:any=false;
  onAll_Month(event){
    if(event.target.checked){
      for(let i in this.Month_Period_Source){
        this.Month_Period_Source[i].enable=true;
      }
      this.SC_Data.Sch_Month="1,2,3,4,5,6,7,8,9,10,11,12";
    }
  }
 
  MonthChange(e){
    if(e=="1,2,3,4,5,6,7,8,9,10,11,12")
      this.all_month=true;
    else
      this.all_month=false;
  }
  all_week:any=false;
  onAll_Operation(event){
    if(event.target.checked){
      for(let i in this.Operation_Period_Source){
        this.Operation_Period_Source[i].enable=true;
      }
      this.SC_Data.Operation_Period="1,2,3,4,5,6,7";
    }
  }

  OperationChange(e){
    if(e=="1,2,3,4,5,6,7")
      this.all_week=true;
    else
      this.all_week=false;
  }


  onDeviceItemCheck(item,event){
    item["checked"]=event.target.checked;
  }

  onAutoChangeChiller(event){
    if(event.target.checked){
      this.SC_Data.AutoChangeChiller="1";
    }
    else{
      this.SC_Data.AutoChangeChiller="0";
    }
  }

  clicked(){
    if (this.SC_Data.Schedule_Title==''){
      alert("排程名稱");
      return false;
    }
    if (this.SC_Data.Sch_Month=="")
    {
        alert("請選擇月份");
        return false;
    }
    if (this.SC_Data.Operation_Period=="")
    {
        alert("請選擇週天數");
        return false;
    }
    this.SC_Data.Chiller=this.Chiller_Data.filter(d=>d.checked).map(d=>d.MAC_ID).join(",");
    this.SC_Data.Local_CHP=this.CHP_Data.filter(d=>d.checked).map(d=>d.MAC_ID).join(",");
    if (this.SC_Data.SC_Data==""&&this.SC_Data.Local_CHP=="")
    {
        alert("請選擇冰機或二次冰水泵組");
        return false;
    }
    if(this.SC_Data.AutoChangeChiller=="1"&&this.SC_Data.SC_Data==""){
        alert("請選擇冰機");
        return false;
    }
    if(this.SC_Data.AutoChangeChiller=="0"){
      this.SC_Data.MasterChiller="0";
    }
    this.SC_Data.Start_Time=this.Select_StartHour+":"+this.Select_StartMinute;
    this.SC_Data.End_Time=this.Select_EndHour+":"+this.Select_EndMinute;
    if (confirm("確定要新增嗎?")) {
        this.Insert();
     }
  }

  Insert(){
     let sbody = {
      "Schedule_Title":this.SC_Data.Schedule_Title,
      "Start_Date":this.MyCommon.FormateDateTime2(this.SC_Data.Start_Date),
      "End_Date":this.EndDate_Enabled=="0"?"":this.MyCommon.FormateDateTime2(this.SC_Data.End_Date),
      "Operation_Period":this.SC_Data.Operation_Period,
      "Week_Interval":this.SC_Data.Week_Interval,
      "WholeDay":this.SC_Data.WholeDay,
      "Start_Time":this.SC_Data.Start_Time,
      "End_Time":this.SC_Data.End_Time,
      "Water_System":this.SC_Data.Water_System,
      "Chiller":this.SC_Data.Chiller,
      "Local_CHP":this.SC_Data.Local_CHP,
      "AutoChangeChiller":this.SC_Data.AutoChangeChiller,
      "MasterChiller":this.SC_Data.MasterChiller,
      "Sch_Month":this.SC_Data.Sch_Month,
      Key:localStorage.getItem("Token")
    };
    let stralert = this.Schedule.InsertData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('新增成功');
        this.router.navigate(['/ibas/ChillerSchedule']);
      },
      err => {  },
      () => { }
    );
  }
  back() {
    this.router.navigate(['/ibas/ChillerSchedule']);
  }
}
