import { Component, OnInit, Inject } from '@angular/core';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MyCommon } from '../../App_Code/MyCommon';
import { Router } from '@angular/router';
import { Permissions } from '../../DataService/User.service';
import { DemandCtrlSettingService } from '../../DataService';

@Component({
  selector: 'app-DemandCtrlSetting',
  templateUrl: './DemandCtrlSetting.component.html',
  providers: [DemandCtrlSettingService,Permissions, MyCommon ]
})
export class DemandCtrlSettingComponent implements OnInit {

  private SearchEnabled: String = '-1';
  //系統資料
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public MACID: String = '-1';
  public Dev_Source: any=[];
  private pageSize: number = 10;
  private skip: number = 0;

  //查詢參數
  Search_Category:any="IO_Info.Name";
  Search_Keyword:any="";

  public ViewPermissions:boolean=false;
  public AddPermissions:boolean=false;
  public EditPermissions:boolean=false;
  public DeletePermissions:boolean=false;
  public pageable:any="{numeric: false,previousNext: false,messages: {display: 'Showing {2} data items'}}";
  public SelectItem;

  constructor( @Inject(DemandCtrlSettingService) private DemandCtrlData: DemandCtrlSettingService,
  @Inject(Permissions) private PermissionsData: Permissions,
  @Inject(MyCommon) private MyCommon: MyCommon,
  private router: Router) { }

  ngOnInit() {
   //判斷頁面權限
   let sbody = {
     UrlList:"DemandCtrlSettingView,DemandCtrlSettingAdd,DemandCtrlSettingEdit,DemandCtrlSettingDelete",
     Key:localStorage.getItem("Token")
   };

   var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
   stralert.subscribe(
     (obj) => {
       this.MyCommon.CheckToken(obj);
       for (let Site of obj) {
         if(Site.path=="DemandCtrlSettingView")
           this.ViewPermissions=true;
         if(Site.path=="DemandCtrlSettingAdd")
           this.AddPermissions=true;
         if(Site.path=="DemandCtrlSettingEdit")
           this.EditPermissions=true;
         if(Site.path=="DemandCtrlSettingDelete")
           this.DeletePermissions=true;
       } 
      
       if(this.EditPermissions){
         this.splitbutton_data.push(
          {
            text: '修改',
            iconClass: 'fa fa-pencil-square-o',
            click: () => {this.router.navigate(['/pages/DemandCtrlSettingEdit', this.SelectItem.ID]); }
          });
       }
       if(this.DeletePermissions){
         this.splitbutton_data.push(
         {
           text: '刪除',
           iconClass: 'fa fa-trash-o',
           click: () => { this.Delete(this.SelectItem);}
         });
       }
       try{
         this.MyCommon.PermissionsDefailtPage(obj,!this.ViewPermissions);
       }catch(err){}
     },
     err => {  },
     () => { }
   );

 this.GetData();
}

public splitbutton_data: Array<any> = [];

public onPaste(obj): void {
 this.SelectItem=obj;
}


//排序
sortChange(sort: SortDescriptor[]): void {
 this.sort = sort;
 this.GetData();
}
//換頁
protected pageChange(event: PageChangeEvent): void {
 this.skip = event.skip;
 this.GetData();
}

Search(){
  this.skip=0;
  this.GetData();
}

//取得資料
GetData() {
  
let StrWhere=" Where 1=1";

 let sbody = {
   TopN: " ",
   StrWhere: StrWhere,
   Sort: " ",
   Key:localStorage.getItem("Token")
 };

 this.DemandCtrlData.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
   (obj) => {
     this.MyCommon.CheckToken(obj);
     this.gridView = {
       data: orderBy(obj, this.sort),
       total: obj.length
     };
   },
   err => { }
 );
 
}
//刪除資料
Delete(del: any) {
if (confirm("確定要刪除嗎?")) {
   let sbody = {
     ID: del.ID,
     Key:localStorage.getItem("Token")
   };    
   var stralert = this.DemandCtrlData.DeleteData(sbody, this.MyCommon.SetHeaders());
   stralert.subscribe(
     (obj) => {      
          this.MyCommon.CheckToken(obj);
          this.GetData(); 
    },
     err => alert('刪除失敗'),
     () => { }
   );
   }
 }
}
