import { Component, OnInit, Inject } from '@angular/core';
import { MyCommon } from '../../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { iBas_UserGroupService,iBas_Permissions } from '../../../../DataService';
import {
  TreeviewI18n, TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent,
  TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem
} from 'ngx-treeview';

@Component({
  selector: 'app-ibas_UserGroup_Add',
  templateUrl: './ibas_UserGroup_Add.component.html',
  styleUrls: ['./ibas_UserGroup_Add.component.scss'],
  providers: [iBas_UserGroupService, MyCommon, iBas_Permissions
    , { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }]
})
export class ibas_UserGroup_AddComponent implements OnInit {

  UGrp_ID: any = '1';
  UGrp_name: any = '';
  UGrp_perm: any = '';
  UGrp_Enabled: any = '1';

  public ViewPermissions: boolean = false;
  treeviewData = [];


  config: any = {
    isShowAllCheckBox: false,
    isShowFilter: false,
    isShowCollapseExpand: true,
    maxHeight: 500
  };
  items = [];

  constructor(
    private UserGroupData: iBas_UserGroupService,
    private PermissionsData: iBas_Permissions,
    private MyCommon: MyCommon,
    private router: Router
  ) { }

  ngOnInit() {
    //判斷頁面權限
    let sbody = {
      UrlList: "UserGroupAdd",
      Key: localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        for (let Site of obj) {
          if (Site.path == "UserGroupAdd")
            this.ViewPermissions = true;
        }
        try {
          this.MyCommon.PermissionsDefailtPage(obj, !this.ViewPermissions);
        } catch (err) { }
      },
      err => { },
      () => { }
    );
    this.GetSiteMap();
  }

  //取得SiteMap
  GetSiteMap() {
    let sbody = {
      UrlList: "default",
      Key: localStorage.getItem("Token")
    };

    var stralert = this.PermissionsData.SiteMap(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.treeviewData = obj;
        this.SetTreeView();
      },
      err => { },
      () => { }
    );
  }
  //載入TreeView
  SetTreeView() {
    var ItemList = [];
    var ItemLevel0 = { text: '首頁', value: this.treeviewData[0]["id"] };
    var ItemLevel1 = [];


    //第一層
    var Level1_filter = this.treeviewData.filter(task => task.level == "1");
    for (let i1 in Level1_filter) {
      ItemLevel1.push({ text: Level1_filter[i1].name, value: Level1_filter[i1].id });


      //第二層
      var ItemLevel2 = [];
      var Level2_filter = this.treeviewData.filter(task => task.parent_id == Level1_filter[i1].id);
      for (let i2 in Level2_filter) {
        ItemLevel2.push({ text: Level2_filter[i2].name, value: Level2_filter[i2].id });
        ItemLevel1[i1]["children"] = ItemLevel2;

        //第三層
        var ItemLevel3 = [];
        var Level3_filter = this.treeviewData.filter(
          task => task.parent_id == Level2_filter[i2].id);
        for (let i3 in Level3_filter) {
          ItemLevel3.push({ text: Level3_filter[i3].name, value: Level3_filter[i3].id });
          ItemLevel2[i2]["children"] = ItemLevel3;
          //第四層
          var ItemLevel4 = [];
          var Level4_filter = this.treeviewData.filter(
            task => task.parent_id == Level3_filter[i3].id);
          for (let i4 in Level4_filter) {
            ItemLevel4.push({ text: Level4_filter[i4].name, value: Level4_filter[i4].id });
            ItemLevel3[i3]["children"] = ItemLevel4;
          }
        }
      }
    }
    ItemLevel0["children"] = ItemLevel1;
    ItemList.push(new TreeviewItem(ItemLevel0));
    //載入權限資料
    for (var i0 of ItemList) {
      i0.internalChecked = false;
      //第一層
      for (var i1 of i0.internalChildren) {
        i1.internalChecked = false;
        //第二層
        if (i1.internalChildren != undefined) {
          for (var i2 of i1.internalChildren) {
            i2.internalChecked = false;
            //第三層
            if (i2.internalChildren != undefined) {
              for (var i3 of i2.internalChildren) {
                i3.internalChecked = false;
                //第四層
                if (i3.internalChildren != undefined) {
                  for (var i4 of i3.internalChildren) {
                    i4.internalChecked = false;
                  }
                }
              }
            }
          }
        }
      }
    }
    this.items = ItemList;
  }
  //權限選擇
  onSelectedChange(downlineItems: DownlineTreeviewItem[]) {
    this.UGrp_perm = [];
    downlineItems.forEach(downlineItem => {
      const item = downlineItem.item;
      const value = [item.value];
      const texts = [item.text];
      let parent = downlineItem.parent;

      while (parent != null) {
        value.push(parent.item.value);
        parent = parent.parent;
      }
      //排除重複ID
      for (var valueitem of value) {
        //檢測無重複時加入
        if (("," + this.UGrp_perm + ",").indexOf("," + valueitem + ",") == -1) {
          this.UGrp_perm.push(valueitem);
        }
      }
    });
  }

  clicked() {
    if (confirm("確定要新增嗎?")) {
      this.Insert();
    }
  }

  Insert() {

    if (String(this.UGrp_name).trim() == '') {
      alert('請輸入名稱');
      return false;
    }

    let sbody = {
      UGrp_name: this.UGrp_name,
      UGrp_perm: String(this.UGrp_perm),
      UGrp_Enabled: Number(this.UGrp_Enabled),
      Key: localStorage.getItem("Token")
    };

    var stralert = this.UserGroupData.InsertData(sbody, this.MyCommon.SetHeaders());
    stralert.subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        alert('新增成功');
        this.router.navigate(['/ibas/UserGroup']);
      },
      err => { alert('新增失敗'); },
      () => { }
    );
  }

  //離開
  back() {
    this.router.navigate(['/ibas/UserGroup']);
  }

}