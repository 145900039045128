import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { IBas_Layout_ObjectService,IBas_ObjectService,IBas_ObjectAttribService
  ,IBas_Layout_SystemService,IBas_Layout_SystemSettingService,Permissions,IBas_System_SettingService,IBas_IOAlarmService } from '../../DataService';
import { MyCommon } from '../../App_Code/MyCommon';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemLayoutPopupComponent } from './SystemLayoutPopup/SystemLayoutPopup.component';
//import { SystemLayoutGroupPopupComponent } from './SystemLayoutGroupPopup/SystemLayoutGroupPopup.component';
import { SystemLayoutChartComponent } from './SystemLayoutChart/SystemLayoutChart.component';
@Component({
  selector: 'app-SystemLayout',
  templateUrl: './SystemLayout.component.html',
  styleUrls: ['./SystemLayout.component.scss'],
  providers: [IBas_Layout_ObjectService,IBas_ObjectService,IBas_ObjectAttribService,IBas_Layout_SystemService,IBas_Layout_SystemSettingService
    , MyCommon,Permissions,IBas_System_SettingService,IBas_IOAlarmService]
})
export class SystemLayoutComponent implements OnInit {

 
  
  ShowSetting:any=true;
  LayoutData:any=[];
  ObjectData:any=[];
  DeviceData:any=[];
  DeviceTypeData:any=[];
  IOData:any=[];
  IORealData:any=[];
  AI_Data:any=[];
  AO_Data:any=[];
  DI_Data:any=[];
  DO_Data:any=[];
  SET_Data:any=[];
  IOAlarm_Data:any=[];

  SelectLayout:any="";
  LayoutSystemName:any = "";
  LayoutName:any = "";
  LayoutPath:any = "";

  ContextMenuItems: any[] = [{ text: '趨勢圖', value: 'Chart' },{ text: '設定', value: 'Set' }];

  constructor(
    private router: Router,
    private Layout_Object: IBas_Layout_ObjectService,
    private Object: IBas_ObjectService,
    private ObjectAttrib: IBas_ObjectAttribService,
    private LayoutSystem: IBas_Layout_SystemService,
    private System_Setting: IBas_System_SettingService,
    private IOAlarm: IBas_IOAlarmService,
    private MyCommon: MyCommon,

    private modal: NgbModal,
  ) { }
  

  LayoutItemData:any=[];
  
  ngOnInit() {
    this.LayoutPath="../assets/Test.jpg";
    this.GetObject();
    this.GetDevData();
    this.GetDevAttrib();
    this.GetDevAttribReal();
    this.GetSettingData();
    
  }
  GetSettingData(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.System_Setting.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        let Data=obj;
        if(Data[0].System_Type=="0"){
          this.LayoutSystemName="HVAC_"+String(Data[0].Chiller_Num)+"_"+String(Data[0].CT_Num);
          if(String(Data[0].CT_Num)=="2"){
            this.LayoutSystemName=this.LayoutSystemName+"_"+String(Data[0].CT_Type);
          }
        }
        if(Data[0].System_Type=="1"){
          this.LayoutSystemName="ZP_"+String(Data[0].ZP_Num);
        }
        if(Data[0].System_Type=="2"){
          this.LayoutSystemName="CT_"+String(Data[0].CT_Num);
        }
        if(Data[0].System_Type=="3"){
          this.LayoutSystemName="CH_"+String(Data[0].Chiller_Num);
        }
        this.GetLayout();
      },
      err => { console.log(err); }
    );
  }

  GetLayout(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.LayoutSystem.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        this.LayoutData = obj;
        if(this.LayoutData.length>0){
          let LayoutItem=this.LayoutData.filter(d=>d.SystemName==this.LayoutSystemName);
          if(LayoutItem.length>0){
            this.SelectLayout= LayoutItem[0].Lay_ID;
            this.LayoutPath= LayoutItem[0].LayoutPath;
            this.LayoutName=LayoutItem[0].LayoutName;
          }
        }
        this.GetLayoutSetting();
      },
      err => { console.log(err); }
    );
  }
  GetLayoutSetting(){
    let sbody = {
      Lay_ID: this.SelectLayout,
      Key:localStorage.getItem("Token")
    };
  
    this.LayoutSystem.GetDataAllSetting(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        let data=[];
        try{
          if(obj[0]=="["){
            data=JSON.parse(obj);
          }else{
            data=obj;
          }
        }catch(err){

        }
        let ALL_Setting=data;
        let SettingData=JSON.parse(ALL_Setting[0].Setting);
        let SettingItemData=JSON.parse(ALL_Setting[0].SettingItem);
        
        this.LayoutItemData=[];
        SettingData.forEach(d => {
          let Children=[];
          if(Number(d.ItemType)==2){
            let Object=this.ObjectData.filter(o=>String(o.Object_ID)==d.ObjectID);
            //轉為獨立JSON物件
            Object=JSON.parse(JSON.stringify(Object));
            if(Object.length>0){
              let Tags=[];
              try {
                Tags=JSON.parse(Object[0].Taglist);
              } catch(e) {
                Tags=Object[0].Taglist;
              }
              for(let j in Tags){
                Tags[j]["Label"]="";
                Tags[j]["Data_MAC_S"]="-1";
                Tags[j]["IOType"]="AI";
                Tags[j]["ShowEnabled"]="1";
                let SettingItem=SettingItemData.filter(s=>String(s.Tag_ID)==d.Tag_ID && String(s.ItemID)== Tags[j].ItemID);
                if(SettingItem.length>0){
                  let RealData=this.IORealData.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==SettingItem[0].Data_MAC_S &&d.TypeID==SettingItem[0].IOType);
                  let Label="";
                  if(RealData.length>0)
                    Label=RealData[0].Label;
                  Tags[j]["Label"]=Label;
                  Tags[j]["Data_MAC_S"]=SettingItem[0].Data_MAC_S;
                  Tags[j]["IOType"]=SettingItem[0].IOType;
                  Tags[j]["ShowEnabled"]=SettingItem[0].ShowEnabled;
                }

              }
              Object[0].Taglist=Tags;
              Children= Object;
            }
          
          }
          let GroupMenu=[];
          if(Number(d.ItemType)==3){
              let Data=[];
           
              Data=this.AI_Data.filter(AI=>(String(AI.NodeType)==String(d.GroupID)));
              
              Data.forEach(f => {
                f["IOType"]="AI";
                if(GroupMenu.filter(g=>(String(g.NodeType)+"_"+String(g.S_Num)+"_"+String(g.IOType)==String(f.NodeType)+"_"+String(f.S_Num)+"_AI")).length==0)
                  GroupMenu.push(f);
              });
              Data=this.AO_Data.filter(AO=>(String(AO.NodeType)==String(d.GroupID)));
              Data.forEach(f => {
                f["IOType"]="AO";
                if(GroupMenu.filter(g=>(String(g.NodeType)+"_"+String(g.S_Num)+"_"+String(g.IOType)==String(f.NodeType)+"_"+String(f.S_Num)+"_AO")).length==0)
                  GroupMenu.push(f);
              });
              Data=this.DI_Data.filter(DI=>(String(DI.NodeType)==String(d.GroupID)));
              Data.forEach(f => {
                f["IOType"]="DI";
                if(GroupMenu.filter(g=>(String(g.NodeType)+"_"+String(g.S_Num)+"_"+String(g.IOType)==String(f.NodeType)+"_"+String(f.S_Num)+"_DI")).length==0)
                  GroupMenu.push(f);
              });
              Data=this.DO_Data.filter(DO=>(String(DO.NodeType)==String(d.GroupID)));
              Data.forEach(f => {
                f["IOType"]="DO";
                if(GroupMenu.filter(g=>(String(g.NodeType)+"_"+String(g.S_Num)+"_"+String(g.IOType)==String(f.NodeType)+"_"+String(f.S_Num)+"_DO")).length==0)
                  GroupMenu.push(f);
              });
          }

          if(Number(d.ItemType)==5){
              let Data=[];
              Data=this.AO_Data.filter(AO=>(String(AO.MAC_ID)==String(d.GroupID)&&AO.Data_Mac!="??"));
              Data.forEach(f => {
                f["IOType"]="AO";
                if(GroupMenu.filter(g=>(String(g.MAC_ID)+"_"+String(g.S_Num)+"_"+String(g.IOType)==String(f.MAC_ID)+"_"+String(f.S_Num)+"_AO")).length==0)
                  GroupMenu.push(f);
              });
              Data=this.DO_Data.filter(DO=>(String(DO.MAC_ID)==String(d.GroupID)&&DO.Data_Mac!="??"));
              Data.forEach(f => {
                f["IOType"]="DO";
                if(GroupMenu.filter(g=>(String(g.MAC_ID)+"_"+String(g.S_Num)+"_"+String(g.IOType)==String(f.MAC_ID)+"_"+String(f.S_Num)+"_DO")).length==0)
                  GroupMenu.push(f);
              });
          }

          let IORealData=this.IORealData.filter(i=>(String(i.MAC_ID)+'-S'+String(i.S_Num))==d.Data_MAC_S &&i.TypeID==d.IOType);
          let IO_Label="";
          if(IORealData.length>0)
            IO_Label=IORealData[0].Label;
         
          this.LayoutItemData.push({Tag_ID:Number(d.Tag_ID),Label:(Number(d.ItemType)!=4?IO_Label:d.Label)
            ,Data_MAC_S:d.Data_MAC_S,ObjectID:Number(d.ObjectID),GroupID:Number(d.GroupID)
            ,LayX:Number(d.LayX),LayY:Number(d.LayY),ItemType:Number(d.ItemType),IOType:d.IOType,ShowEnabled:d.ShowEnabled,children:Children,menu:GroupMenu});
        });
       
      },
      err => { console.log(err); }
    );
  }
  
  GetObject(){
    let sbody = {
      Key:localStorage.getItem("Token")
    };
  
    this.Layout_Object.GetDataAll(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        let data=[];
        try{
          if(obj[0]=="["){
            data=JSON.parse(obj);
          }else{
            data=obj;
          }
        }catch(err){

        }
        this.ObjectData=data;
      },
      err => { console.log(err); }
    );
  }
  
  GetDevData(){
   
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.Object.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        this.DeviceData = obj;
      },
      err => { console.log(err); }
    );

    sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.Object.GetDataType(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        this.DeviceTypeData = obj;
      },
      err => { console.log(err); }
    );

    sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
  
    this.ObjectAttrib.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        
        this.MyCommon.CheckToken(obj);
        this.IOData = obj;
      },
      err => { console.log(err); }
    );
  }
  GetDevAttrib(){
    
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    //AI
    this.ObjectAttrib.GetDataAI(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.AI_Data = obj;
      },
      err => { console.log(err); }
    );
    //AO
    this.ObjectAttrib.GetDataAO(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.AO_Data = obj;
      },
      err => { console.log(err); }
    );
    //DI
    this.ObjectAttrib.GetDataDI(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.DI_Data = obj;
      },
      err => { console.log(err); }
    );
    //DO
    this.ObjectAttrib.GetDataDO(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.DO_Data = obj;
      },
      err => { console.log(err); }
    );
    //Set
    this.ObjectAttrib.GetDataSET(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.SET_Data = obj;
      },
      err => { console.log(err); }
    );
     //IOAlarm
     this.IOAlarm.GetData(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.IOAlarm_Data = obj;
        //console.log(this.IOAlarm_Data);
      },
      err => { console.log(err); }
    );
  }
  GetDevAttribReal(){
    let sbody = {
      TopN: " ",
      StrWhere: " ",
      Sort: " ",
      Key:localStorage.getItem("Token")
    };
    this.ObjectAttrib.GetDataReal(sbody, this.MyCommon.SetHeaders()).subscribe(
      (obj) => {
        this.MyCommon.CheckToken(obj);
        this.IORealData = obj;
        //判斷狀態
        for(let i in this.IORealData){
          let label="";
          if(this.IORealData[i].TypeID=="AI"){
            if(this.IORealData[i].LDValue==null){
              label="N/A";
            }
            else{
              label=this.IORealData[i].LDValue+" "+this.IORealData[i].Tag_Name;
            }
          }
          if(this.IORealData[i].TypeID=="AO"){
            if(this.IORealData[i].LDValue==null){
              label="N/A";
            }
            else{
              label=this.IORealData[i].Tag_Name;
            }
          }
          if(this.IORealData[i].TypeID=="DI"){
            if(this.IORealData[i].LDValue==null){
              label=this.IORealData[i].Tag_Name_OFF;
            }
            else if(this.IORealData[i].LDValue=="1"){
              label=this.IORealData[i].Tag_Name_ON;
            }
            else if(this.IORealData[i].LDValue=="0"){
              label=this.IORealData[i].Tag_Name_OFF;
            }
          }
          if(this.IORealData[i].TypeID=="DO"){
            if(this.IORealData[i].LDValue==null){
              label=this.IORealData[i].Tag_Name_OFF;
            }
            else if(this.IORealData[i].LDValue=="1"){
              label=this.IORealData[i].Tag_Name_ON;
            }
            else if(this.IORealData[i].LDValue=="0"){
              label=this.IORealData[i].Tag_Name_OFF;
            }
          }
          if(this.IORealData[i].TypeID=="SET"){
            label=this.IORealData[i].Tag_Name;
          }
          this.IORealData[i]["Label"]=label;
        }
      },
      err => { console.log(err); }
    );
  }

  LayoutChange(){
    let SetLayout=this.LayoutData.filter(d=>d.Lay_ID==this.SelectLayout);
    if(SetLayout.length>0){
      this.LayoutPath= SetLayout[0].LayoutPath;
      this.LayoutName=SetLayout[0].LayoutName;
    }
    this.GetLayoutSetting();
  }
  
  //開啟
  public onSetting(item,index){
    let Data=[];
    if(item.IOType=="AI")
      Data=this.AI_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==item.Data_MAC_S);
    else if(item.IOType=="AO")
      Data=this.AO_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==item.Data_MAC_S);
    else if(item.IOType=="DI")
      Data=this.DI_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==item.Data_MAC_S);
    else if(item.IOType=="DO")
      Data=this.DO_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==item.Data_MAC_S);
    else if(item.IOType=="SET")
      Data=this.SET_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==item.Data_MAC_S);
    if(item.IOType=="SET"){
    //打開設定頁面
    const activeModal = this.modal.open(SystemLayoutPopupComponent, { size: 'sm', backdrop: 'static',centered: true  });
      //傳值到設定頁
      activeModal.componentInstance.Data_MAC_S=item.Data_MAC_S;
      activeModal.componentInstance.IOType=item.IOType;
      activeModal.componentInstance.AttribData=Data;
      activeModal.componentInstance.IOAlarm_Data=this.IOAlarm_Data;

      //判斷在設定頁關閉後做SetData動作
      activeModal.result.then((result) => {
        //this.GetSettingsData();
        this.GetDevAttrib();
      });
    }
  }

  
 /* public onMenuSelect({ item }): void {
    
   
      if(item.data.menu!=null){
        let tag_item=item.data.item;
        let menu=item.data.menu;
        let Data=[];
        if(menu.IOType=="AI")
          Data=this.AI_Data.filter(d=>(String(d.NodeType)+'-'+String(d.S_Num))==String(tag_item.GroupID)+'-'+String(menu.S_Num));
        else if(menu.IOType=="AO")
          Data=this.AO_Data.filter(d=>(String(d.NodeType)+'-'+String(d.S_Num))==String(tag_item.GroupID)+'-'+String(menu.S_Num));
        else if(menu.IOType=="DI")
          Data=this.DI_Data.filter(d=>(String(d.NodeType)+'-'+String(d.S_Num))==String(tag_item.GroupID)+'-'+String(menu.S_Num));
        else if(menu.IOType=="DO")
          Data=this.DO_Data.filter(d=>(String(d.NodeType)+'-'+String(d.S_Num))==String(tag_item.GroupID)+'-'+String(menu.S_Num));
        //打開設定頁面
        const activeModal = this.modal.open(SystemLayoutGroupPopupComponent, { size: 'sm', backdrop: 'static',centered: true  });
        //傳值到設定頁
        activeModal.componentInstance.Title=menu.Attrib_Name_TC;
        activeModal.componentInstance.Data_GroupID=tag_item.GroupID;
        activeModal.componentInstance.IOType=menu.IOType;
        activeModal.componentInstance.AttribData=Data;
        //判斷在設定頁關閉後做SetData動作
        activeModal.result.then((result) => {
          this.GetDevAttrib();
        });
      }
  }*/

  public onMenuCtlSelect({ item }): void {
    if(item.data.menu!=null){
      let menu=item.data.menu;
      //打開設定頁面
      const activeModal = this.modal.open(SystemLayoutPopupComponent, { size: 'sm', backdrop: 'static',centered: true  });
      //傳值到設定頁
      activeModal.componentInstance.Data_MAC_S=menu.MAC_ID+'-S'+menu.S_Num;
      activeModal.componentInstance.IOType=menu.IOType;
      activeModal.componentInstance.IOAlarm_Data=this.IOAlarm_Data;
      let AttribData=[];
      AttribData.push(menu);
      activeModal.componentInstance.AttribData=AttribData;
      //判斷在設定頁關閉後做SetData動作
      activeModal.result.then((result) => {
        //console.log(result);
        this.GetDevAttrib();
        this.GetLayoutSetting();
      });
    }
  }

  public onMenuSensorSelect({ item }): void {
    if(item.data.item!=null){
      let tag_item=item.data.item;
      let item_value=item.data.value;
      let Data=[];
      if(tag_item.IOType=="AI")
        Data=this.AI_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==tag_item.Data_MAC_S);
      else if(tag_item.IOType=="AO")
        Data=this.AO_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==tag_item.Data_MAC_S);
      else if(tag_item.IOType=="DI")
        Data=this.DI_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==tag_item.Data_MAC_S);
      else if(tag_item.IOType=="DO")
        Data=this.DO_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==tag_item.Data_MAC_S);
      if(item_value=="pop"){
     
        //打開設定頁面
        const activeModal = this.modal.open(SystemLayoutPopupComponent, { size: 'sm', backdrop: 'static',centered: true  });
          //傳值到設定頁
          activeModal.componentInstance.Data_MAC_S=tag_item.Data_MAC_S;
          activeModal.componentInstance.IOType=tag_item.IOType;
          activeModal.componentInstance.AttribData=Data;
          activeModal.componentInstance.IOAlarm_Data=this.IOAlarm_Data;
          //判斷在設定頁關閉後做SetData動作
          activeModal.result.then((result) => {
            //this.GetSettingsData();
            this.GetDevAttrib();
          });
      }else if (item_value=="chart"){
        let Data=[];
        if(tag_item.IOType=="AI")
          Data=this.AI_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==tag_item.Data_MAC_S);
        else if(tag_item.IOType=="AO")
          Data=this.AO_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==tag_item.Data_MAC_S);
        else if(tag_item.IOType=="DI")
          Data=this.DI_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==tag_item.Data_MAC_S);
        else if(tag_item.IOType=="DO")
          Data=this.DO_Data.filter(d=>(String(d.MAC_ID)+'-S'+String(d.S_Num))==tag_item.Data_MAC_S);
        //打開設定頁面
        const activeModal = this.modal.open(SystemLayoutChartComponent, { size: 'lg', backdrop: 'static',centered: true  });
          //傳值到設定頁
          activeModal.componentInstance.Data_MAC_S=tag_item.Data_MAC_S;
          activeModal.componentInstance.IOType=tag_item.IOType;
          activeModal.componentInstance.AttribData=Data;
          //判斷在設定頁關閉後做SetData動作
          activeModal.result.then((result) => {
          });
      }
    }
    /*if(item.data.item!=null){
      let tag_item=item.data.item;
      let menu=item.data.menu;
      //打開設定頁面
     const activeModal = this.modal.open(SystemLayoutPopupComponent, { size: 'sm', backdrop: 'static',centered: true  });
        //傳值到設定頁
        activeModal.componentInstance.Data_MAC_S=menu.MAC_ID+'-S'+menu.S_Num;
        activeModal.componentInstance.IOType=menu.IOType;
        let AttribData=[];
        AttribData.push(menu);
        activeModal.componentInstance.AttribData=AttribData;
        //判斷在設定頁關閉後做SetData動作
        activeModal.result.then((result) => {
          //this.GetSettingsData();
          this.GetDevAttrib();
        });
    }*/
    /*if(select_value=="chart"){
      let menu=item.data.menu;
      //打開設定頁面
      const activeModal = this.modal.open(SystemLayoutChartComponent, { size: 'sm', backdrop: 'static',centered: true  });
      //傳值到設定頁
      activeModal.componentInstance.Data_MAC_S=menu.MAC_ID+'-S'+menu.S_Num;
      activeModal.componentInstance.IOType=menu.IOType;
      let AttribData=[];
      AttribData.push(menu);
      activeModal.componentInstance.AttribData=AttribData;
      //判斷在設定頁關閉後做SetData動作
      activeModal.result.then((result) => {
        //this.GetSettingsData();
        this.GetDevAttrib();
      });
    }*/
  }
 
 
 

}
