import { Component, OnInit, Inject,ViewChild,ElementRef  } from '@angular/core';
import { SystemService } from '../../../DataService/System.service';
import { MyCommon } from '../../../App_Code/MyCommon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Permissions } from '../../../DataService/User.service';





@Component({
  selector: 'app-SystemSettingPage',
  templateUrl: './SystemSettingPage.component.html',
  styleUrls: ['./SystemSettingPage.component.scss'],
  providers: [SystemService, MyCommon,Permissions]
})
export class SystemSettingPageComponent implements OnInit {



  constructor(
    @Inject(Permissions) private PermissionsData: Permissions,
    @Inject(MyCommon) private MyCommon: MyCommon,
    private router: Router) { }

  ngOnInit() {

    
     
  }

}
