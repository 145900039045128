import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

export class IBas_IOAlarmService {

    constructor(@Inject(HttpClient) 
            private http : HttpClient,
            public MyCommon:MyCommon) { }
    
        GetData(sbody:any,headers:any=""){
            var url = this.MyCommon.GetIBasAPISPUrl('IOAlarm');
            return this.http.post(url,sbody,{headers: headers})
            .map(res=>JSON.parse(JSON.stringify(res)));
        }
        UpdateData(sbody:any,headers:any=""){
            var url = this.MyCommon.GetIBasAPISPUrl('UpdateIOAlarm');
            return this.http.put(url,sbody,{headers: headers})
            .map(res=>JSON.parse(JSON.stringify(res)));
        }
    
}
export class IBas_EventLogService {

constructor(@Inject(HttpClient) 
        private http : HttpClient,
        public MyCommon:MyCommon) { }

    GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('EventLog');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetIBasAPISPUrl('EventLog');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

}