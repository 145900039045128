import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class HistoryService {

constructor(@Inject(HttpClient)  
        private http : HttpClient,
        public MyCommon:MyCommon) { }

    GetHistoryChart(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetHistoryChart');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

    GetPowerSearchChart(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetPowerSearchChart');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }

}