import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { MyCommon } from '../App_Code/MyCommon';

@Injectable()
export class PowerAnalysisService {
    constructor(@Inject(HttpClient)  
        private http : HttpClient,
        public MyCommon:MyCommon) { }
    GetDataList(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetPAGroupList');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetPAGroupData');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    InsertData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('InsertPAGroupData');
           return this.http.post(url,sbody,{headers: headers})
           .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('UpdatePAGroupData');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    DeleteData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('DeletePAGroup');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    
    GetDataRealTime(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetPADataRealTime');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }       
    GetDataPie(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetPADataPieChart');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    GetDataBar(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetPADataBarChart');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }    
    GetDataLine(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetPADataLineChart');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }       
}
export class PowerAnalysisTypeService {

constructor(@Inject(HttpClient)  
        private http : HttpClient,
        public MyCommon:MyCommon) { }

    GetData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('GetPATypeSetting');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    InsertData(sbody:any,headers:any=""){
     var url = this.MyCommon.GetAPISPUrl('InsertPATypeSetting');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    UpdateData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('UpdatePATypeSetting');
        return this.http.put(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
    DeleteData(sbody:any,headers:any=""){
        var url = this.MyCommon.GetAPISPUrl('DeletePATypeSetting');
        return this.http.post(url,sbody,{headers: headers})
        .map(res=>JSON.parse(JSON.stringify(res)));
    }
}