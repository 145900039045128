import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-LayoutSettingPopup',
  templateUrl: './LayoutSettingPopup.component.html',
  styleUrls: ['./LayoutSettingPopup.component.css']
})
export class LayoutSettingPopupComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal,) { }

  ngOnInit() {
  }
  //隱藏Popup
  back() {
    this.activeModal.close();
  }
}
